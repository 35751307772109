/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'

import SubStepper from '@e2grnd/ui-core/dist/containers/SubStepper'

function Step5() {
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        <SubStepper subStepIndex={0} />
      </div>
    </div>
  )
}

Step5.propTypes = {}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(Step5)
