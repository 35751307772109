import React, { Component } from 'react'
import { connect } from 'react-redux'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'

class Step7 extends Component {
  static propTypes = {
    problemType: PropTypes.string,
    results: PropTypes.object,
    style: PropTypes.object,
    activeUnitSystem: PropTypes.string,
    graphs: PropTypes.object,
    priorNonIntrusiveVisualInspection: PropTypes.string,
    priorIntrusiveVisualInspection: PropTypes.string,
    priorNdeInspection: PropTypes.string,
    runQualMethodsBool: PropTypes.bool,
    runCondensation: PropTypes.bool,
    failureTimeXInterpProb: PropTypes.object,
    failureTimeXInterpProbAfterInspections: PropTypes.object,
    componentAgeVsInstallDateBool: PropTypes.bool,
  }

  render() {
    const {
      results,
      style,
      activeUnitSystem,
      problemType,
      graphs,
      priorNonIntrusiveVisualInspection,
      priorIntrusiveVisualInspection,
      priorNdeInspection,
      runQualMethodsBool,
      runCondensation,
      failureTimeXInterpProb,
      failureTimeXInterpProbAfterInspections,
      componentAgeVsInstallDateBool,
    } = this.props
    let graphsToDisplay
    if(problemType === 'PROB1' || problemType === 'PROB3') {
      if(typeof results.poppedJsonOutComponentFailureTime !== 'undefined') {
        if((priorNonIntrusiveVisualInspection === 'No' && priorIntrusiveVisualInspection === 'No' && priorNdeInspection === 'No') && results.manyTableInspectionPlottingBool[0] === '0') {
          graphsToDisplay = pick(graphs, ['graphTemp1', 'failureTimeCDF'])
        } else {
          graphsToDisplay = pick(graphs, ['graphTemp1', 'graphTemp3', 'failureTimeCDF', 'failureTimeCDFAfterInspections'])
        }
      }
    } else if(problemType === 'PROB2') {
      if(priorNonIntrusiveVisualInspection === 'No' && priorIntrusiveVisualInspection === 'No' && priorNdeInspection === 'No' && results.manyTableInspectionPlottingBool[0] === '0') {
        if(results.returnCondensationBool[0] === 0) {
          graphsToDisplay = pick(graphs, ['graphTemp1a', 'graphTemp1b', 'graphTemp1c', 'failureTimeCDF'])
        } else {
          graphsToDisplay = pick(graphs, ['graphTemp1a', 'graphTemp1b', 'graphTemp1c', 'graphTemp1d', 'graphTemp1e', 'failureTimeCDF'])
        }
      } else {
        if(results.returnCondensationBool[0] === 0) {
          graphsToDisplay = pick(graphs, ['graphTemp1a', 'graphTemp1b', 'graphTemp1c', 'graphTemp3a', 'graphTemp3b', 'graphTemp3c', 'failureTimeCDF', 'failureTimeCDFAfterInspections'])
        } else {
          graphsToDisplay = pick(graphs, ['graphTemp1a', 'graphTemp1b', 'graphTemp1c', 'graphTemp1d', 'graphTemp1e', 'graphTemp3a', 'graphTemp3b', 'graphTemp3c', 'graphTemp3d', 'graphTemp3e', 'failureTimeCDF', 'failureTimeCDFAfterInspections'])
        }
      }
    }
    return (
      <div>
        {results
          ? (
            <div className="cf w-100">
              {results.returnCondensationBool[0] === 0 && runCondensation === true
                ? (
                  <p>The condensation networks were not included in the model due to the operating temperature always exceeding the dew point temperature. As a result, condensation effects are not likely.</p>
                )
                : ''}
              {results.inspections[0] === 1 && problemType === 'PROB3'
                ? (
                  <div><p><strong>After Inspection Results</strong></p></div>
                )
                : ''}
              {problemType === 'PROB1' || problemType === 'PROB3'
                ? (
                  <div className="fl w-100">
                    <div className="fl w-25-l w-50-m w-100">
                      <E2GTextField name="coatingFailureTimeEV" style={style} value={results.coatingFailureTimeEV} label="Coating Failure Time Mean" type="number" textWidth="90%" disabled />
                      {problemType === 'PROB1'
                        ? (
                          <div>
                            <E2GTextField name="jacketingMissingFailureTimeEV" style={style} value={results.jacketingMissingFailureTimeEV} label="Missing Jacketing Failure Time Mean" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingSealantFailureTimeEV" style={style} value={results.jacketingSealantFailureTimeEV} label="Jacketing Sealant Failure Time Mean" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingPrimaryFailureTimeEV" style={style} value={results.jacketingPrimaryFailureTimeEV} label="Jacketing Primary Failure Time Mean" type="number" textWidth="90%" disabled />
                          </div>
                        )
                        : ''}
                      {problemType === 'PROB3'
                        ? (
                          <div>
                            <E2GTextField name="jacketingMissingFailureTimeEV" style={style} value={results.jacketingMissingFailureTimeEV} label="Missing Jacketing Failure Time Mean" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingSealantFailureTimeEV" style={style} value={results.jacketingSealantFailureTimeEV} label="Jacketing Sealant Failure Time Mean" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingCorrosionFailureTimeEV" style={style} value={results.jacketingCorrosionFailureTimeEV} label="Jacketing Corrosion Failure Time Mean" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingDesignFailureTimeEV" style={style} value={results.jacketingDesignFailureTimeEV} label="Jacketing Design Failure Time Mean" type="number" textWidth="90%" disabled />
                          </div>
                        )
                        : ''}
                      {problemType === 'PROB1'
                        ? (
                          <div>
                            {results.returnCondensationBool[0] === 1
                              ? (
                                <div>
                                  <E2GTextField name="vaporBarrierMissingFailureTimeEV" style={style} value={results.vaporBarrierMissingFailureTimeEV} label="Missing Vapor Barrier Failure Time Mean" type="number" textWidth="90%" disabled />
                                  <E2GTextField name="vaporBarrierSealantFailureTimeEV" style={style} value={results.vaporBarrierSealantFailureTimeEV} label="Vapor Barrier Sealant Failure Time Mean" type="number" textWidth="90%" disabled />
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                      <E2GTextField name="jacketingFailureTimeEV" style={style} value={results.jacketingFailureTimeEV} label="Jacketing Failure Time Mean" type="number" textWidth="90%" disabled />
                      <E2GTextField name="initiationTimeEV" style={style} value={results.initiationTimeEV} label="Component Initiation Time Mean" type="number" textWidth="90%" disabled />
                      <E2GTextField name="damageRateEV" style={style} value={results.damageRateEV} label="Component Damage Rate Mean" type="number" textWidth="90%" disabled />
                      <E2GTextField name="failureTimeEV" style={style} value={results.failureTimeEV} label="Component Failure Time Mean" type="number" textWidth="90%" disabled />
                      {componentAgeVsInstallDateBool === true
                        ? (
                          <div>
                            <E2GTextField name="pofAfterJacketing" style={style} value={results.pofAfterJacketing} label="Jacketing POF at Component Age" type="number" textWidth="90%" disabled />
                            <E2GTextField name="pofAfter" style={style} value={results.pofAfter} label="Component POF at Component Age" type="number" textWidth="90%" disabled />
                          </div>
                        )
                        : ''}
                      {componentAgeVsInstallDateBool === false
                        ? (
                          <div>
                            <E2GTextField name="pofAfterJacketing" style={style} value={results.pofAfterJacketing} label="Jacketing POF at Analysis Date" type="number" textWidth="90%" disabled />
                            <E2GTextField name="pofAfter" style={style} value={results.pofAfter} label="Component POF at Analysis Date" type="number" textWidth="90%" disabled />
                          </div>
                        )
                        : ''}
                      <div className="fl w-50-nl w-100">
                        {results.whichTable[0] === 'beforeInspections'
                          ? (
                            <div>
                              {/* <p>Failure Times Before Inspections</p> */}
                              <DataTableDisplay
                                id="failureTimeXInterpProb"
                                table={failureTimeXInterpProb}
                                colHeaders={{
                                  USCustomary: [
                                    'Inspection',
                                    '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                  ],
                                  Metric: [
                                    'Inspection',
                                    '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                  ],
                                }}
                              />
                            </div>
                          )
                          : ''}
                        {results.whichTable[0] === 'afterInspections'
                          ? (
                            <div>
                              {/* <p>Failure Times Before and After Inspections</p> */}
                              <DataTableDisplay
                                id="failureTimeXInterpProbAfterInspections"
                                table={failureTimeXInterpProbAfterInspections}
                                colHeaders={{
                                  USCustomary: [
                                    'Inspection',
                                    '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                  ],
                                  Metric: [
                                    'Inspection',
                                    '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                  ],
                                }}
                              />
                            </div>
                          )
                          : ''}
                      </div>
                    </div>
                    <div className="fl w-25-l w-50-m w-100">
                      <E2GTextField name="coatingFailureTimeSD" style={style} value={results.coatingFailureTimeSD} label="Coating Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                      {problemType === 'PROB1'
                        ? (
                          <div>
                            <E2GTextField name="jacketingMissingFailureTimeSD" style={style} value={results.jacketingMissingFailureTimeSD} label="Missing Jacketing Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingSealantFailureTimeSD" style={style} value={results.jacketingSealantFailureTimeSD} label="Jacketing Sealant Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingPrimaryFailureTimeSD" style={style} value={results.jacketingPrimaryFailureTimeSD} label="Jacketing Primary Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                          </div>
                        )
                        : ''}
                      {problemType === 'PROB3'
                        ? (
                          <div>
                            <E2GTextField name="jacketingMissingFailureTimeSD" style={style} value={results.jacketingMissingFailureTimeSD} label="Missing Jacketing Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingSealantFailureTimeSD" style={style} value={results.jacketingSealantFailureTimeSD} label="Jacketing Sealant Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingCorrosionFailureTimeSD" style={style} value={results.jacketingCorrosionFailureTimeSD} label="Jacketing Corrosion Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                            <E2GTextField name="jacketingDesignFailureTimeSD" style={style} value={results.jacketingDesignFailureTimeSD} label="Jacketing Design Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                          </div>
                        )
                        : ''}
                      {problemType === 'PROB1'
                        ? (
                          <div>
                            {results.returnCondensationBool[0] === 1
                              ? (
                                <div>
                                  <E2GTextField name="vaporBarrierMissingFailureTimeSD" style={style} value={results.vaporBarrierMissingFailureTimeSD} label="Missing Vapor Barrier Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                                  <E2GTextField name="vaporBarrierSealantFailureTimeSD" style={style} value={results.vaporBarrierSealantFailureTimeSD} label="Vapor Barrier Sealant Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                      <E2GTextField name="jacketingFailureTimeSD" style={style} value={results.jacketingFailureTimeSD} label="Jacketing Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                      <E2GTextField name="initiationTimeSD" style={style} value={results.initiationTimeSD} label="Component Initiation Time Standard Deviation" type="number" textWidth="90%" disabled />
                      <E2GTextField name="damageRateSD" style={style} value={results.damageRateSD} label="Component Damage Rate Standard Deviation" type="number" textWidth="90%" disabled />
                      <E2GTextField name="failureTimeSD" style={style} value={results.failureTimeSD} label="Component Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
                      {componentAgeVsInstallDateBool === true
                        ? (
                          <div>
                            <E2GTextField name="pofAfterCoating" style={style} value={results.pofAfterCoating} label="Coating POF at Component Age" type="number" textWidth="90%" disabled />
                          </div>
                        )
                        : ''}
                      {componentAgeVsInstallDateBool === false
                        ? (
                          <div>
                            <E2GTextField name="pofAfterCoating" style={style} value={results.pofAfterCoating} label="Coating POF at Analysis Date" type="number" textWidth="90%" disabled />
                          </div>
                        )
                        : ''}
                    </div>
                    <div className="fl w-50-l w-50-m w-100">
                      <div className="fl w-100-l w-100-m w-100">
                        {graphs
                          ? <DataGraphDisplay plotClass="data-graph-75" graphs={graphsToDisplay} />
                          : ''}
                      </div>
                    </div>
                  </div>
                )
                : ''}
              {problemType === 'PROB2'
                ? (
                  <div className="fl w-100">
                    <div className="fl w-25-l w-50-m w-100">
                      <E2GTextField name="coatingFirstFailureTimeOutputEV" style={style} value={results.coatingFirstFailureTimeOutputEV} label="Coating First Failure Time Mean" type="number" textWidth="95%" disabled />
                      <E2GTextField name="failureTimeMissingJacketingOutputEV" style={style} value={results.failureTimeMissingJacketingOutputEV} label="Missing Jacketing First Failure Time Mean" type="number" textWidth="95%" disabled />
                      <E2GTextField name="sealantFailureTimeOutputEV" style={style} value={results.sealantFailureTimeOutputEV} label="Jacketing Sealant First Failure Time Mean" type="number" textWidth="95%" disabled />
                      <E2GTextField name="corrosionFailureTimeOutputEV" style={style} value={results.corrosionFailureTimeOutputEV} label="Jacketing Corrosion First Failure Time Mean" type="number" textWidth="95%" disabled />
                      {results.returnCondensationBool[0] === 1
                        ? (
                          <div>
                            <E2GTextField name="vaporBarrierFailureTimeMissingOutputEV" style={style} value={results.vaporBarrierFailureTimeMissingOutputEV} label="Missing Vapor Barrier Failure Time Mean" type="number" textWidth="95%" disabled />
                            <E2GTextField name="vaporBarrierFailureTimeOutputEV" style={style} value={results.vaporBarrierFailureTimeOutputEV} label="Vapor Barrier Sealant Failure Time Mean" type="number" textWidth="95%" disabled />
                            <E2GTextField name="condensationFailureTimeEV" style={style} value={results.condensationFailureTimeEV} label="Component Condensation Failure Time Mean" type="number" textWidth="95%" disabled />
                          </div>
                        )
                        : ''}
                      <E2GTextField name="failureTimeEV" style={style} value={results.failureTimeEV} label="Component Failure Time Mean" type="number" textWidth="95%" disabled />
                      {componentAgeVsInstallDateBool === true
                        ? (
                          <div>
                            <E2GTextField name="failureCoating1POF" style={style} value={results.failureCoating1POF} label="Coating POF at Component Age" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureJacketingMissingPOF" style={style} value={results.failureJacketingMissingPOF} label="Missing Jacketing POF at Component Age" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureJacketingSealantPOF" style={style} value={results.failureJacketingSealantPOF} label="Jacketing Sealant POF at Component Age" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureJacketingCorrosionPOF" style={style} value={results.failureJacketingCorrosionPOF} label="Jacketing Corrosion POF at Component Age" type="number" textWidth="95%" disabled />
                            {results.returnCondensationBool[0] === 1
                              ? (
                                <div>
                                  <E2GTextField name="failureVaporBarrierMissingPOF" style={style} value={results.failureVaporBarrierMissingPOF} label="Missing Vapor Barrier POF at Component Age" type="number" textWidth="95%" disabled />
                                  <E2GTextField name="failureVaporBarrierPOF" style={style} value={results.failureVaporBarrierPOF} label="Vapor Barrier Sealant POF at Component Age" type="number" textWidth="95%" disabled />
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                      {componentAgeVsInstallDateBool === false
                        ? (
                          <div>
                            <E2GTextField name="failureCoating1POF" style={style} value={results.failureCoating1POF} label="Coating POF at Analysis Date" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureJacketingMissingPOF" style={style} value={results.failureJacketingMissingPOF} label="Missing Jacketing POF at Analysis Date" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureJacketingSealantPOF" style={style} value={results.failureJacketingSealantPOF} label="Jacketing Sealant POF at Analysis Date" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureJacketingCorrosionPOF" style={style} value={results.failureJacketingCorrosionPOF} label="Jacketing Corrosion POF at Analysis Date" type="number" textWidth="95%" disabled />
                            {results.returnCondensationBool[0] === 1
                              ? (
                                <div>
                                  <E2GTextField name="failureVaporBarrierMissingPOF" style={style} value={results.failureVaporBarrierMissingPOF} label="Missing Vapor Barrier POF at Analysis Date" type="number" textWidth="95%" disabled />
                                  <E2GTextField name="failureVaporBarrierPOF" style={style} value={results.failureVaporBarrierPOF} label="Vapor Barrier Sealant POF at Analysis Date" type="number" textWidth="95%" disabled />
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                      <div className="fl w-50-nl w-100">
                        {results.whichTable[0] === 'beforeInspections'
                          ? (
                            <div>
                              {/* <p>Failure Times Before Inspections</p> */}
                              <DataTableDisplay
                                id="failureTimeXInterpProb"
                                table={failureTimeXInterpProb}
                                colHeaders={{
                                  USCustomary: [
                                    'Inspection',
                                    '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                  ],
                                  Metric: [
                                    'Inspection',
                                    '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                  ],
                                }}
                              />
                            </div>
                          )
                          : ''}
                        {results.whichTable[0] === 'afterInspections'
                          ? (
                            <div>
                              {/* <p>Failure Times Before and After Inspections</p> */}
                              <DataTableDisplay
                                id="failureTimeXInterpProbAfterInspections"
                                table={failureTimeXInterpProbAfterInspections}
                                colHeaders={{
                                  USCustomary: [
                                    'Inspection',
                                    '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                  ],
                                  Metric: [
                                    'Inspection',
                                    '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                    '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                  ],
                                }}
                              />
                            </div>
                          )
                          : ''}
                      </div>
                    </div>
                    <div className="fl w-25-l w-50-m w-100">
                      <E2GTextField name="coatingFirstFailureTimeOutputSD" style={style} value={results.coatingFirstFailureTimeOutputSD} label="Coating First Failure Time Standard Deviation" type="number" textWidth="95%" disabled />
                      <E2GTextField name="failureTimeMissingJacketingOutputSD" style={style} value={results.failureTimeMissingJacketingOutputSD} label="Missing Jacketing First Failure Time Standard Deviation" type="number" textWidth="95%" disabled />
                      <E2GTextField name="sealantFailureTimeOutputSD" style={style} value={results.sealantFailureTimeOutputSD} label="Jacketing Sealant First Failure Time Standard Deviation" type="number" textWidth="95%" disabled />
                      <E2GTextField name="corrosionFailureTimeOutputSD" style={style} value={results.corrosionFailureTimeOutputSD} label="Jacketing Corrosion First Failure Time Standard Deviation" type="number" textWidth="95%" disabled />
                      {results.returnCondensationBool[0] === 1
                        ? (
                          <div>
                            <E2GTextField name="vaporBarrierFailureTimeMissingOutputSD" style={style} value={results.vaporBarrierFailureTimeMissingOutputSD} label="Missing Vapor Barrier Failure Time Standard Deviation" type="number" textWidth="95%" disabled />
                            <E2GTextField name="vaporBarrierFailureTimeOutputEV" style={style} value={results.vaporBarrierFailureTimeOutputEV} label="Vapor Barrier Sealant Failure Time Standard Deviation" type="number" textWidth="95%" disabled />
                            <E2GTextField name="condensationFailureTimeSD" style={style} value={results.condensationFailureTimeSD} label="Component Condensation Failure Time Standard Deviation" type="number" textWidth="95%" disabled />
                          </div>
                        )
                        : ''}
                      <E2GTextField name="failureTimeSD" style={style} value={results.failureTimeSD} label="Component Failure Time Standard Deviation" type="number" textWidth="95%" disabled />
                      {componentAgeVsInstallDateBool === true
                        ? (
                          <div>
                            <E2GTextField name="failureMissingPOF" style={style} value={results.failureMissingPOF} label="Component Missing Jacketing POF at Component Age" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureSealantPOF" style={style} value={results.failureSealantPOF} label="Component Jacketing Sealant POF at Component Age" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureCorrosionPOF" style={style} value={results.failureCorrosionPOF} label="Component Jacketing Corrosion POF at Component Age" type="number" textWidth="95%" disabled />
                            {results.returnCondensationBool[0] === 1
                              ? (
                                <div>
                                  <E2GTextField name="failureMissingVaporBarrierPOF" style={style} value={results.failureMissingVaporBarrierPOF} label="Component Missing Vapor Barrier POF at Component Age" type="number" textWidth="95%" disabled />
                                  <E2GTextField name="failureCondensationSeamsPOF" style={style} value={results.failureCondensationSeamsPOF} label="Component Vapor Barrier Sealant POF at Component Age" type="number" textWidth="95%" disabled />
                                </div>
                              )
                              : ''}
                            <E2GTextField name="finalComponentPOF" style={style} value={results.finalComponentPOF} label="Component POF at Component Age" type="number" textWidth="95%" disabled />
                          </div>
                        )
                        : ''}
                      {componentAgeVsInstallDateBool === false
                        ? (
                          <div>
                            <E2GTextField name="failureMissingPOF" style={style} value={results.failureMissingPOF} label="Component Missing Jacketing POF at Analysis Date" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureSealantPOF" style={style} value={results.failureSealantPOF} label="Component Jacketing Sealant POF at Analysis Date" type="number" textWidth="95%" disabled />
                            <E2GTextField name="failureCorrosionPOF" style={style} value={results.failureCorrosionPOF} label="Component Jacketing Corrosion POF at Analysis Date" type="number" textWidth="95%" disabled />
                            {results.returnCondensationBool[0] === 1
                              ? (
                                <div>
                                  <E2GTextField name="failureMissingVaporBarrierPOF" style={style} value={results.failureMissingVaporBarrierPOF} label="Component Missing Vapor Barrier POF at Analysis Date" type="number" textWidth="95%" disabled />
                                  <E2GTextField name="failureCondensationSeamsPOF" style={style} value={results.failureCondensationSeamsPOF} label="Component Vapor Barrier Sealant POF at Analysis Date" type="number" textWidth="95%" disabled />
                                </div>
                              )
                              : ''}
                            <E2GTextField name="finalComponentPOF" style={style} value={results.finalComponentPOF} label="Component POF at Analysis Date" type="number" textWidth="95%" disabled />
                          </div>
                        )
                        : ''}
                    </div>
                    <div className="fl w-50-l w-50-m w-100">
                      <div className="fl w-100-l w-100-m w-100">
                        {graphs
                          ? <DataGraphDisplay plotClass="data-graph-75" graphs={graphsToDisplay} />
                          : ''}
                      </div>
                    </div>
                  </div>
                )
                : ''}
            </div>
          )
          : ('')}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  results: state.workflow.response ? state.workflow.response.results : undefined,
  style: state.ui.style,
  activeUnitSystem: state.workflow.activeUnitSystem,
  priorNonIntrusiveVisualInspection: state.workflow.fields.priorNonIntrusiveVisualInspection,
  priorIntrusiveVisualInspection: state.workflow.fields.priorIntrusiveVisualInspection,
  priorNdeInspection: state.workflow.fields.priorNdeInspection,
  graphs: state.workflow.response.results ? state.workflow.response.results.graphs : undefined,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  componentAgeVsInstallDateBool: state.workflow.fields.componentAgeVsInstallDateBool,
  runCondensation: state.workflow.fields.runCondensation,
  failureTimeXInterpProb: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.failureTimeXInterpProb : undefined,
  failureTimeXInterpProbAfterInspections: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.failureTimeXInterpProbAfterInspections : undefined,
})

export default connect(mapStateToProps)(Step7)
