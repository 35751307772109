/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

const DecisionsResults = ({ results, decisionsTable }) => {
  return (
    <div className="cf w-100">
      <p>Return = Benefit - Costs</p>
      <DataTableDisplay
        id="decisionsTable"
        table={decisionsTable}
        colHeaders={{
          USCustomary: [
            'Strategy',
            'Recoat',
            'Recoat Time (yrs)',
            'Rejacket',
            'Rejacket Time (yrs)',
            'Reseal',
            'Reseal Time (yrs)',
            'Replace Time (yrs)',
            'Total Return ($)',
          ],
          Metric: [
            'Strategy',
            'Recoat',
            'Recoat Time (yrs)',
            'Rejacket',
            'Rejacket Time (yrs)',
            'Reseal',
            'Reseal Time (yrs)',
            'Replace Time (yrs)',
            'Total Return ($)',
          ],
        }}
      />
    </div>
  )
}

DecisionsResults.propTypes = {
  results: PropTypes.object,
  decisionsTable: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  decisionsTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.decisionsTable : undefined,
})
export default connect(mapStateToProps, null)(DecisionsResults)
