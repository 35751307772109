import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import BayesViewer from '@e2grnd/ui-core/dist/components/bayes/BayesViewer'
import BeliefTable from '@e2grnd/ui-core/dist/components/bayes/BeliefTable'
import Dialog from '@e2grnd/ui-core/dist/components/ImageDialog'
import Close from '@e2grnd/ui-core/dist/components/ImageDialogClose'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'
import TextArea from '@e2grnd/ui-core/dist/containers/TextArea'

function BayesNetBuilderProb2({ results, decisionsStrategy, problemType }) {
  let bayesNetNetwork012
  let bayesNetNetwork034
  let bayesNetNetwork05
  let bayesNetNetwork06
  let bayesNetNetwork07
  let bayesNetNetwork09
  let bayesNetNetwork10
  let bayesNetNetwork11
  let bayesNetNetwork12
  let bayesNetNetwork13
  let bayesNetNetwork14
  let bayesNetNetwork15
  let bayesNetNetwork16
  let bayesNetNetwork17
  let bayesNetNetwork18
  let bayesNetReplaceDecision01
  let bayesNetReplaceDecision02
  let bayesNetReplaceDecision03
  let bayesNetReplaceDecision04
  let bayesNetReplaceDecision05
  let bayesNetActualThicknessProb21101
  let bayesNetActualThicknessProb21102
  let bayesNetActualThicknessProb21103
  let bayesNetActualThicknessProb21401
  let bayesNetActualThicknessProb21402
  let bayesNetActualThicknessProb21403
  let bayesNetActualThicknessProb21701
  let bayesNetActualThicknessProb21702
  let bayesNetActualThicknessProb21703
  let bayesNetNetworkCondensation19
  let bayesNetNetworkCondensation20
  let bayesNetNetworkCondensation23
  let bayesNetNetworkCondensation12
  let bayesNetNetworkCondensation21
  let bayesNetNetworkCondensation22
  let bayesNetNetworkCondensation24
  let bayesNetNetworkCondensation25
  let bayesNetNetworkCondensation26
  let bayesNetNetworkCondensation27
  let bayesNetActualThicknessProb2CondensationSeams01
  let bayesNetActualThicknessProb2CondensationSeams02
  let bayesNetActualThicknessProb2CondensationSeams03
  let bayesNetActualThicknessProb2MissingVP01
  let bayesNetActualThicknessProb2MissingVP02
  let bayesNetActualThicknessProb2MissingVP03
  let whichString
  if(problemType === 'PROB2') {
    if(typeof results.poppedJsonOutReplaceDecision !== 'undefined' || typeof results.poppedJsonOutMaintenanceStrat01 !== 'undefined' || typeof results.poppedJsonOutMaintenanceStrat02 !== 'undefined' || typeof results.poppedJsonOutMaintenanceStrat03 !== 'undefined' || typeof results.poppedJsonOutMaintenanceStrat04 !== 'undefined') {
      bayesNetNetwork012 = JSON.parse(results.poppedJsonOutProblemType2_012)
      bayesNetNetwork034 = JSON.parse(results.poppedJsonOutProblemType2_034)
      bayesNetNetwork05 = JSON.parse(results.poppedJsonOutProblemType2_05)
      bayesNetNetwork06 = JSON.parse(results.poppedJsonOutProblemType2_06)
      bayesNetNetwork07 = JSON.parse(results.poppedJsonOutProblemType2_07)
      bayesNetNetwork09 = JSON.parse(results.poppedJsonOutProblemType2_09)
      bayesNetNetwork10 = JSON.parse(results.poppedJsonOutProblemType2_10)
      bayesNetNetwork11 = JSON.parse(results.poppedJsonOutProblemType2_11)
      bayesNetNetwork12 = JSON.parse(results.poppedJsonOutProblemType2_12)
      bayesNetNetwork13 = JSON.parse(results.poppedJsonOutProblemType2_13)
      bayesNetNetwork14 = JSON.parse(results.poppedJsonOutProblemType2_14)
      bayesNetNetwork15 = JSON.parse(results.poppedJsonOutProblemType2_15)
      bayesNetNetwork16 = JSON.parse(results.poppedJsonOutProblemType2_16)
      bayesNetNetwork17 = JSON.parse(results.poppedJsonOutProblemType2_17)
      bayesNetNetwork18 = JSON.parse(results.poppedJsonOutProblemType2_18)
      if(typeof results.poppedJsonOutReplaceDecision !== 'undefined') {
        bayesNetReplaceDecision01 = JSON.parse(results.poppedJsonOutReplaceDecision)
        bayesNetReplaceDecision02 = JSON.parse(results.poppedJsonOutMaintenanceStrat01)
        bayesNetReplaceDecision03 = JSON.parse(results.poppedJsonOutMaintenanceStrat02)
        bayesNetReplaceDecision04 = JSON.parse(results.poppedJsonOutMaintenanceStrat03)
        bayesNetReplaceDecision05 = JSON.parse(results.poppedJsonOutMaintenanceStrat04)
      }
      if(results.returnCondensationBool[0] === 1) {
        bayesNetNetworkCondensation19 = JSON.parse(results.poppedJsonOutProblemType2Condensation19)
        bayesNetNetworkCondensation20 = JSON.parse(results.poppedJsonOutProblemType2Condensation20)
        bayesNetNetworkCondensation23 = JSON.parse(results.poppedJsonOutProblemType2Condensation23)
        bayesNetNetworkCondensation12 = JSON.parse(results.poppedJsonOutProblemType2Condensation12)
        bayesNetNetworkCondensation21 = JSON.parse(results.poppedJsonOutProblemType2Condensation21)
        bayesNetNetworkCondensation22 = JSON.parse(results.poppedJsonOutProblemType2Condensation22)
        bayesNetNetworkCondensation24 = JSON.parse(results.poppedJsonOutProblemType2Condensation24)
        bayesNetNetworkCondensation25 = JSON.parse(results.poppedJsonOutProblemType2Condensation25)
        bayesNetNetworkCondensation26 = JSON.parse(results.poppedJsonOutProblemType2Condensation26)
        bayesNetNetworkCondensation27 = JSON.parse(results.poppedJsonOutProblemType2Condensation27)
      }
      if(typeof results.poppedJsonOutActualThicknessProb2_11_1 !== 'undefined') {
        bayesNetActualThicknessProb21101 = JSON.parse(results.poppedJsonOutActualThicknessProb2_11_1)
        bayesNetActualThicknessProb21401 = JSON.parse(results.poppedJsonOutActualThicknessProb2_14_1)
        bayesNetActualThicknessProb21701 = JSON.parse(results.poppedJsonOutActualThicknessProb2_17_1)
        if(results.returnCondensationBool[0] === 1) {
          bayesNetActualThicknessProb2CondensationSeams01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_1)
          bayesNetActualThicknessProb2MissingVP01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_1)
        }
      } if(typeof results.poppedJsonOutActualThicknessProb2_11_2 !== 'undefined') {
        bayesNetActualThicknessProb21102 = JSON.parse(results.poppedJsonOutActualThicknessProb2_11_2)
        bayesNetActualThicknessProb21402 = JSON.parse(results.poppedJsonOutActualThicknessProb2_14_2)
        bayesNetActualThicknessProb21702 = JSON.parse(results.poppedJsonOutActualThicknessProb2_17_2)
        if(results.returnCondensationBool[0] === 1) {
          bayesNetActualThicknessProb2CondensationSeams01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_1)
          bayesNetActualThicknessProb2CondensationSeams02 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_2)
          bayesNetActualThicknessProb2MissingVP01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_1)
          bayesNetActualThicknessProb2MissingVP02 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_2)
        }
      } if(typeof results.poppedJsonOutActualThicknessProb2_11_3 !== 'undefined') {
        bayesNetActualThicknessProb21103 = JSON.parse(results.poppedJsonOutActualThicknessProb2_11_3)
        bayesNetActualThicknessProb21403 = JSON.parse(results.poppedJsonOutActualThicknessProb2_14_3)
        bayesNetActualThicknessProb21703 = JSON.parse(results.poppedJsonOutActualThicknessProb2_17_3)
        if(results.returnCondensationBool[0] === 1) {
          bayesNetActualThicknessProb2CondensationSeams01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_1)
          bayesNetActualThicknessProb2CondensationSeams02 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_2)
          bayesNetActualThicknessProb2CondensationSeams03 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_3)
          bayesNetActualThicknessProb2MissingVP01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_1)
          bayesNetActualThicknessProb2MissingVP02 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_2)
          bayesNetActualThicknessProb2MissingVP03 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_3)
        }
      }
      whichString = results.whichNetwork[0]
    } else {
      bayesNetNetwork012 = JSON.parse(results.poppedJsonOutProblemType2_012)
      bayesNetNetwork034 = JSON.parse(results.poppedJsonOutProblemType2_034)
      bayesNetNetwork05 = JSON.parse(results.poppedJsonOutProblemType2_05)
      bayesNetNetwork06 = JSON.parse(results.poppedJsonOutProblemType2_06)
      bayesNetNetwork07 = JSON.parse(results.poppedJsonOutProblemType2_07)
      bayesNetNetwork09 = JSON.parse(results.poppedJsonOutProblemType2_09)
      bayesNetNetwork10 = JSON.parse(results.poppedJsonOutProblemType2_10)
      bayesNetNetwork11 = JSON.parse(results.poppedJsonOutProblemType2_11)
      bayesNetNetwork12 = JSON.parse(results.poppedJsonOutProblemType2_12)
      bayesNetNetwork13 = JSON.parse(results.poppedJsonOutProblemType2_13)
      bayesNetNetwork14 = JSON.parse(results.poppedJsonOutProblemType2_14)
      bayesNetNetwork15 = JSON.parse(results.poppedJsonOutProblemType2_15)
      bayesNetNetwork16 = JSON.parse(results.poppedJsonOutProblemType2_16)
      bayesNetNetwork17 = JSON.parse(results.poppedJsonOutProblemType2_17)
      bayesNetNetwork18 = JSON.parse(results.poppedJsonOutProblemType2_18)
      if(results.returnCondensationBool[0] === 1) {
        bayesNetNetworkCondensation19 = JSON.parse(results.poppedJsonOutProblemType2Condensation19)
        bayesNetNetworkCondensation20 = JSON.parse(results.poppedJsonOutProblemType2Condensation20)
        bayesNetNetworkCondensation23 = JSON.parse(results.poppedJsonOutProblemType2Condensation23)
        bayesNetNetworkCondensation12 = JSON.parse(results.poppedJsonOutProblemType2Condensation12)
        bayesNetNetworkCondensation21 = JSON.parse(results.poppedJsonOutProblemType2Condensation21)
        bayesNetNetworkCondensation22 = JSON.parse(results.poppedJsonOutProblemType2Condensation22)
        bayesNetNetworkCondensation24 = JSON.parse(results.poppedJsonOutProblemType2Condensation24)
        bayesNetNetworkCondensation25 = JSON.parse(results.poppedJsonOutProblemType2Condensation25)
        bayesNetNetworkCondensation26 = JSON.parse(results.poppedJsonOutProblemType2Condensation26)
        bayesNetNetworkCondensation27 = JSON.parse(results.poppedJsonOutProblemType2Condensation27)
      }
      if(typeof results.poppedJsonOutActualThicknessProb2_11_1 !== 'undefined') {
        bayesNetActualThicknessProb21101 = JSON.parse(results.poppedJsonOutActualThicknessProb2_11_1)
        bayesNetActualThicknessProb21401 = JSON.parse(results.poppedJsonOutActualThicknessProb2_14_1)
        bayesNetActualThicknessProb21701 = JSON.parse(results.poppedJsonOutActualThicknessProb2_17_1)
        if(results.returnCondensationBool[0] === 1) {
          bayesNetActualThicknessProb2CondensationSeams01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_1)
          bayesNetActualThicknessProb2MissingVP01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_1)
        }
      } if(typeof results.poppedJsonOutActualThicknessProb2_11_2 !== 'undefined') {
        bayesNetActualThicknessProb21102 = JSON.parse(results.poppedJsonOutActualThicknessProb2_11_2)
        bayesNetActualThicknessProb21402 = JSON.parse(results.poppedJsonOutActualThicknessProb2_14_2)
        bayesNetActualThicknessProb21702 = JSON.parse(results.poppedJsonOutActualThicknessProb2_17_2)
        if(results.returnCondensationBool[0] === 1) {
          bayesNetActualThicknessProb2CondensationSeams01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_1)
          bayesNetActualThicknessProb2CondensationSeams02 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_2)
          bayesNetActualThicknessProb2MissingVP01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_1)
          bayesNetActualThicknessProb2MissingVP02 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_2)
        }
      } if(typeof results.poppedJsonOutActualThicknessProb2_11_3 !== 'undefined') {
        bayesNetActualThicknessProb21103 = JSON.parse(results.poppedJsonOutActualThicknessProb2_11_3)
        bayesNetActualThicknessProb21403 = JSON.parse(results.poppedJsonOutActualThicknessProb2_14_3)
        bayesNetActualThicknessProb21703 = JSON.parse(results.poppedJsonOutActualThicknessProb2_17_3)
        if(results.returnCondensationBool[0] === 1) {
          bayesNetActualThicknessProb2CondensationSeams01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_1)
          bayesNetActualThicknessProb2CondensationSeams02 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_2)
          bayesNetActualThicknessProb2CondensationSeams03 = JSON.parse(results.poppedJsonOutActualThicknessProb2_CondensationSeams_3)
          bayesNetActualThicknessProb2MissingVP01 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_1)
          bayesNetActualThicknessProb2MissingVP02 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_2)
          bayesNetActualThicknessProb2MissingVP03 = JSON.parse(results.poppedJsonOutActualThicknessProb2_MissingVP_3)
        }
      }
      whichString = results.whichNetwork[0]
    }
  }
  let nodeNew
  const [states, setStates] = useState([])
  const [beliefs, setBeliefs] = useState([])
  const [netIndex, setNetIndex] = useState('5')

  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const onNodeClick = (node) => {
    if(node) {
      if(problemType === 'PROB2') {
        if(results.whichNetwork[0] === '1') {
          nodeNew = bayesNetNetwork012.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '2') {
          nodeNew = bayesNetNetwork034.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '3') {
          nodeNew = bayesNetNetwork05.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '4') {
          nodeNew = bayesNetNetwork06.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '5') {
          nodeNew = bayesNetNetwork07.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '6') {
          nodeNew = bayesNetNetwork09.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '7') {
          nodeNew = bayesNetNetwork10.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '8') {
          nodeNew = bayesNetNetwork11.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '9') {
          nodeNew = bayesNetNetwork12.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '10') {
          nodeNew = bayesNetNetwork13.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '11') {
          nodeNew = bayesNetNetwork14.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '12') {
          nodeNew = bayesNetNetwork15.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '13') {
          nodeNew = bayesNetNetwork16.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '14') {
          nodeNew = bayesNetNetwork17.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '15') {
          nodeNew = bayesNetNetwork18.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '16a') {
          nodeNew = bayesNetReplaceDecision01.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '16b') {
          nodeNew = bayesNetReplaceDecision02.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '16c') {
          nodeNew = bayesNetReplaceDecision03.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '16d') {
          nodeNew = bayesNetReplaceDecision04.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '16e') {
          nodeNew = bayesNetReplaceDecision05.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '17') {
          nodeNew = bayesNetActualThicknessProb21101.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '18') {
          nodeNew = bayesNetActualThicknessProb21401.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '19') {
          nodeNew = bayesNetActualThicknessProb21701.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '20') {
          nodeNew = bayesNetActualThicknessProb21102.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '21') {
          nodeNew = bayesNetActualThicknessProb21402.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '22') {
          nodeNew = bayesNetActualThicknessProb21702.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '23') {
          nodeNew = bayesNetActualThicknessProb21103.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '24') {
          nodeNew = bayesNetActualThicknessProb21403.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '25') {
          nodeNew = bayesNetActualThicknessProb21703.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '26') {
          nodeNew = bayesNetNetworkCondensation19.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '27') {
          nodeNew = bayesNetNetworkCondensation20.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '28') {
          nodeNew = bayesNetNetworkCondensation23.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '29') {
          nodeNew = bayesNetNetworkCondensation12.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '30') {
          nodeNew = bayesNetNetworkCondensation21.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '31') {
          nodeNew = bayesNetNetworkCondensation22.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '32') {
          nodeNew = bayesNetNetworkCondensation24.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '33') {
          nodeNew = bayesNetNetworkCondensation25.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '34') {
          nodeNew = bayesNetNetworkCondensation26.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '35') {
          nodeNew = bayesNetNetworkCondensation27.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '36') {
          nodeNew = bayesNetActualThicknessProb2CondensationSeams01.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '37') {
          nodeNew = bayesNetActualThicknessProb2CondensationSeams02.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '38') {
          nodeNew = bayesNetActualThicknessProb2CondensationSeams03.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '39') {
          nodeNew = bayesNetActualThicknessProb2MissingVP01.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '40') {
          nodeNew = bayesNetActualThicknessProb2MissingVP02.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '41') {
          nodeNew = bayesNetActualThicknessProb2MissingVP03.nodes.find((n) => n.name === node)
        }
      }
      if(typeof nodeNew.states !== 'undefined' && typeof nodeNew.belief !== 'undefined') {
        setStates(nodeNew.states.states || [])
        setBeliefs(nodeNew.belief || [])
      } else {
        setStates([])
        setBeliefs([])
      }
    }
  }

  useEffect(() => {
    setStates([])
    setBeliefs([])
  }, [whichString])
  return (
    <div>
      {problemType === 'PROB2'
        ? (
          <div>
            {decisionsStrategy === '1'
              ? (
                <div>
                  {bayesNetActualThicknessProb21101 === undefined && bayesNetActualThicknessProb21102 === undefined && bayesNetActualThicknessProb21103 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="16a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="16b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="16c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="16d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="16e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="16a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="16b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="16c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="16d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="16e" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="26" />
                              <E2GSelectFieldOption label="Condensation 2 - Vapor Barrier Failure" value="27" />
                              <E2GSelectFieldOption label="Condensation 3 - Vapor Barrier Missing Failure" value="28" />
                              <E2GSelectFieldOption label="Condensation 4 - Effective Max Corrosion Rate - Vapor Barrier Seams" value="29" />
                              <E2GSelectFieldOption label="Condensation 5 - Vapor Barrier Upper Transition Time Condensation" value="30" />
                              <E2GSelectFieldOption label="Condensation 6 - Component Failure Time - Vapor Barrier Sealant" value="31" />
                              <E2GSelectFieldOption label="Condensation 7 - Effective Corrosion Rate - Missing Vapor Barrier" value="32" />
                              <E2GSelectFieldOption label="Condensation 8 - Component Failure Time - Missing Vapor Barrier" value="33" />
                              <E2GSelectFieldOption label="Condensation 9 - Multiple Failure Times of Condensation Networks" value="34" />
                              <E2GSelectFieldOption label="Condensation 10 - Multiple Failure Times of Final Condensation and Jacketing Networks" value="35" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>

                    )
                    : '' }
                  {bayesNetActualThicknessProb21101 !== undefined && bayesNetActualThicknessProb21102 === undefined && bayesNetActualThicknessProb21103 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="16a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="16b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="16c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="16d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="16e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="16a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="16b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="16c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="16d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="16e" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="26" />
                              <E2GSelectFieldOption label="Condensation 2 - Vapor Barrier Failure" value="27" />
                              <E2GSelectFieldOption label="Condensation 3 - Vapor Barrier Missing Failure" value="28" />
                              <E2GSelectFieldOption label="Condensation 4 - Effective Max Corrosion Rate - Vapor Barrier Seams" value="29" />
                              <E2GSelectFieldOption label="Condensation 5 - Vapor Barrier Upper Transition Time Condensation" value="30" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 1" value="36" />
                              <E2GSelectFieldOption label="Condensation 6 - Component Failure Time - Vapor Barrier Sealant" value="31" />
                              <E2GSelectFieldOption label="Condensation 7 - Effective Corrosion Rate - Missing Vapor Barrier" value="32" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 1" value="39" />
                              <E2GSelectFieldOption label="Condensation 8 - Component Failure Time - Missing Vapor Barrier" value="33" />
                              <E2GSelectFieldOption label="Condensation 9 - Multiple Failure Times of Condensation Networks" value="34" />
                              <E2GSelectFieldOption label="Condensation 10 - Multiple Failure Times of Final Condensation and Jacketing Networks" value="35" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb21101 !== undefined && bayesNetActualThicknessProb21102 !== undefined && bayesNetActualThicknessProb21103 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 2  - Missing Jacketing" value="20" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 2 - Sealant Failure" value="21" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 2 - Corrosion Failure" value="22" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="16a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="16b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="16c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="16d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="16e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 2  - Missing Jacketing" value="20" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 2 - Sealant Failure" value="21" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 2 - Corrosion Failure" value="22" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="16a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="16b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="16c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="16d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="16e" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="26" />
                              <E2GSelectFieldOption label="Condensation 2 - Vapor Barrier Failure" value="27" />
                              <E2GSelectFieldOption label="Condensation 3 - Vapor Barrier Missing Failure" value="28" />
                              <E2GSelectFieldOption label="Condensation 4 - Effective Max Corrosion Rate - Vapor Barrier Seams" value="29" />
                              <E2GSelectFieldOption label="Condensation 5 - Vapor Barrier Upper Transition Time Condensation" value="30" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 1" value="36" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 2" value="37" />
                              <E2GSelectFieldOption label="Condensation 6 - Component Failure Time - Vapor Barrier Sealant" value="31" />
                              <E2GSelectFieldOption label="Condensation 7 - Effective Corrosion Rate - Missing Vapor Barrier" value="32" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 1" value="39" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 2" value="40" />
                              <E2GSelectFieldOption label="Condensation 8 - Component Failure Time - Missing Vapor Barrier" value="33" />
                              <E2GSelectFieldOption label="Condensation 9 - Multiple Failure Times of Condensation Networks" value="34" />
                              <E2GSelectFieldOption label="Condensation 10 - Multiple Failure Times of Final Condensation and Jacketing Networks" value="35" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb21101 !== undefined && bayesNetActualThicknessProb21102 !== undefined && bayesNetActualThicknessProb21103 !== undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 2  - Missing Jacketing" value="20" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 3  - Missing Jacketing" value="23" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 2 - Sealant Failure" value="21" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 3 - Sealant Failure" value="24" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 2 - Corrosion Failure" value="22" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 3 - Corrosion Failure" value="25" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="16a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="16b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="16c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="16d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="16e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 2  - Missing Jacketing" value="20" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 3  - Missing Jacketing" value="23" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 2 - Sealant Failure" value="21" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 3 - Sealant Failure" value="24" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 2 - Corrosion Failure" value="22" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 3 - Corrosion Failure" value="25" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="16a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="16b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="16c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="16d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="16e" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="26" />
                              <E2GSelectFieldOption label="Condensation 2 - Vapor Barrier Failure" value="27" />
                              <E2GSelectFieldOption label="Condensation 3 - Vapor Barrier Missing Failure" value="28" />
                              <E2GSelectFieldOption label="Condensation 4 - Effective Max Corrosion Rate - Vapor Barrier Seams" value="29" />
                              <E2GSelectFieldOption label="Condensation 5 - Vapor Barrier Upper Transition Time Condensation" value="30" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 1" value="36" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 2" value="37" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 3" value="38" />
                              <E2GSelectFieldOption label="Condensation 6 - Component Failure Time - Vapor Barrier Sealant" value="31" />
                              <E2GSelectFieldOption label="Condensation 7 - Effective Corrosion Rate - Missing Vapor Barrier" value="32" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 1" value="39" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 2" value="40" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 3" value="41" />
                              <E2GSelectFieldOption label="Condensation 8 - Component Failure Time - Missing Vapor Barrier" value="33" />
                              <E2GSelectFieldOption label="Condensation 9 - Multiple Failure Times of Condensation Networks" value="34" />
                              <E2GSelectFieldOption label="Condensation 10 - Multiple Failure Times of Final Condensation and Jacketing Networks" value="35" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                </div>
              )
              : '' }
            {decisionsStrategy === '0'
              ? (
                <div>
                  {bayesNetActualThicknessProb21101 === undefined && bayesNetActualThicknessProb21102 === undefined && bayesNetActualThicknessProb21103 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="26" />
                              <E2GSelectFieldOption label="Condensation 2 - Vapor Barrier Failure" value="27" />
                              <E2GSelectFieldOption label="Condensation 3 - Vapor Barrier Missing Failure" value="28" />
                              <E2GSelectFieldOption label="Condensation 4 - Effective Max Corrosion Rate - Vapor Barrier Seams" value="29" />
                              <E2GSelectFieldOption label="Condensation 5 - Vapor Barrier Upper Transition Time Condensation" value="30" />
                              <E2GSelectFieldOption label="Condensation 6 - Component Failure Time - Vapor Barrier Sealant" value="31" />
                              <E2GSelectFieldOption label="Condensation 7 - Effective Corrosion Rate - Missing Vapor Barrier" value="32" />
                              <E2GSelectFieldOption label="Condensation 8 - Component Failure Time - Missing Vapor Barrier" value="33" />
                              <E2GSelectFieldOption label="Condensation 9 - Multiple Failure Times of Condensation Networks" value="34" />
                              <E2GSelectFieldOption label="Condensation 10 - Multiple Failure Times of Final Condensation and Jacketing Networks" value="35" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>

                    )
                    : '' }
                  {bayesNetActualThicknessProb21101 !== undefined && bayesNetActualThicknessProb21102 === undefined && bayesNetActualThicknessProb21103 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="26" />
                              <E2GSelectFieldOption label="Condensation 2 - Vapor Barrier Failure" value="27" />
                              <E2GSelectFieldOption label="Condensation 3 - Vapor Barrier Missing Failure" value="28" />
                              <E2GSelectFieldOption label="Condensation 4 - Effective Max Corrosion Rate - Vapor Barrier Seams" value="29" />
                              <E2GSelectFieldOption label="Condensation 5 - Vapor Barrier Upper Transition Time Condensation" value="30" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 1" value="36" />
                              <E2GSelectFieldOption label="Condensation 6 - Component Failure Time - Vapor Barrier Sealant" value="31" />
                              <E2GSelectFieldOption label="Condensation 7 - Effective Corrosion Rate - Missing Vapor Barrier" value="32" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 1" value="39" />
                              <E2GSelectFieldOption label="Condensation 8 - Component Failure Time - Missing Vapor Barrier" value="33" />
                              <E2GSelectFieldOption label="Condensation 9 - Multiple Failure Times of Condensation Networks" value="34" />
                              <E2GSelectFieldOption label="Condensation 10 - Multiple Failure Times of Final Condensation and Jacketing Networks" value="35" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb21101 !== undefined && bayesNetActualThicknessProb21102 !== undefined && bayesNetActualThicknessProb21103 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 2  - Missing Jacketing" value="20" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 2 - Sealant Failure" value="21" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 2 - Corrosion Failure" value="22" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 1  - Missing Jacketing" value="17" />
                              <E2GSelectFieldOption label="11 - Actual Thickness Unique Time Inspection 2  - Missing Jacketing" value="20" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 1 - Sealant Failure" value="18" />
                              <E2GSelectFieldOption label="14 - Actual Thickness Unique Time Inspection 2 - Sealant Failure" value="21" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 1 - Corrosion Failure" value="19" />
                              <E2GSelectFieldOption label="17 - Actual Thickness Unique Time Inspection 2 - Corrosion Failure" value="22" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="26" />
                              <E2GSelectFieldOption label="Condensation 2 - Vapor Barrier Failure" value="27" />
                              <E2GSelectFieldOption label="Condensation 3 - Vapor Barrier Missing Failure" value="28" />
                              <E2GSelectFieldOption label="Condensation 4 - Effective Max Corrosion Rate - Vapor Barrier Seams" value="29" />
                              <E2GSelectFieldOption label="Condensation 5 - Vapor Barrier Upper Transition Time Condensation" value="30" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 1" value="36" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 2" value="37" />
                              <E2GSelectFieldOption label="Condensation 6 - Component Failure Time - Vapor Barrier Sealant" value="31" />
                              <E2GSelectFieldOption label="Condensation 7 - Effective Corrosion Rate - Missing Vapor Barrier" value="32" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 1" value="39" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 2" value="40" />
                              <E2GSelectFieldOption label="Condensation 8 - Component Failure Time - Missing Vapor Barrier" value="33" />
                              <E2GSelectFieldOption label="Condensation 9 - Multiple Failure Times of Condensation Networks" value="34" />
                              <E2GSelectFieldOption label="Condensation 10 - Multiple Failure Times of Final Condensation and Jacketing Networks" value="35" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb21101 !== undefined && bayesNetActualThicknessProb21102 !== undefined && bayesNetActualThicknessProb21103 !== undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Missing Jacketing Actual Thickness Unique Time Inspection 1" value="17" />
                              <E2GSelectFieldOption label="11 - Missing Jacketing Actual Thickness Unique Time Inspection 2" value="20" />
                              <E2GSelectFieldOption label="11 - Missing Jacketing Actual Thickness Unique Time Inspection 3" value="23" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Jacketing Sealant Actual Thickness Unique Time Inspection 1" value="18" />
                              <E2GSelectFieldOption label="14 - Jacketing Sealant Actual Thickness Unique Time Inspection 2" value="21" />
                              <E2GSelectFieldOption label="14 - Jacketing Sealant Actual Thickness Unique Time Inspection 3" value="24" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Jacketing Actual Thickness Unique Time Inspection 1" value="19" />
                              <E2GSelectFieldOption label="17 - Jacketing Actual Thickness Unique Time Inspection 2" value="22" />
                              <E2GSelectFieldOption label="17 - Jacketing Actual Thickness Unique Time Inspection 3" value="25" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="1+2 - Instant Rate and External Fraction" value="1" />
                              <E2GSelectFieldOption label="3+4 - Coating Failure Time and Damage Rate" value="2" />
                              <E2GSelectFieldOption label="5 - Jacketing Sealant Failure" value="3" />
                              <E2GSelectFieldOption label="6 - Jacketing Missing Failure" value="4" />
                              <E2GSelectFieldOption label="7 - Jacketing Corrosion Failure" value="5" />
                              <E2GSelectFieldOption label="9 - Internal Exposure Fraction - Flux" value="6" />
                              <E2GSelectFieldOption label="10 - Effective Corrosion Rate - Missing Jacketing" value="7" />
                              <E2GSelectFieldOption label="11 - Missing Jacketing Actual Thickness Unique Time Inspection 1" value="17" />
                              <E2GSelectFieldOption label="11 - Missing Jacketing Actual Thickness Unique Time Inspection 2" value="20" />
                              <E2GSelectFieldOption label="11 - Missing Jacketing Actual Thickness Unique Time Inspection 3" value="23" />
                              <E2GSelectFieldOption label="11 - Component Failure Time - Missing Jacketing" value="8" />
                              <E2GSelectFieldOption label="12 - Effective Max Corrosion Rate - Sealant Failure" value="9" />
                              <E2GSelectFieldOption label="13 - Jacketing Upper Transition Time Sealant" value="10" />
                              <E2GSelectFieldOption label="14 - Jacketing Sealant Actual Thickness Unique Time Inspection 1" value="18" />
                              <E2GSelectFieldOption label="14 - Jacketing Sealant Actual Thickness Unique Time Inspection 2" value="21" />
                              <E2GSelectFieldOption label="14 - Jacketing Sealant Actual Thickness Unique Time Inspection 3" value="24" />
                              <E2GSelectFieldOption label="14 - Component Failure Time - Sealant Failure" value="11" />
                              <E2GSelectFieldOption label="15 - Effective Max Corrosion Rate - Corrosion Failure" value="12" />
                              <E2GSelectFieldOption label="16 - Jacketing Upper Transition Time Corrosion" value="13" />
                              <E2GSelectFieldOption label="17 - Jacketing Actual Thickness Unique Time Inspection 1" value="19" />
                              <E2GSelectFieldOption label="17 - Jacketing Actual Thickness Unique Time Inspection 2" value="22" />
                              <E2GSelectFieldOption label="17 - Jacketing Actual Thickness Unique Time Inspection 3" value="25" />
                              <E2GSelectFieldOption label="17 - Component Failure Time - Corrosion Failure" value="14" />
                              <E2GSelectFieldOption label="18 - Multiple Failure Times" value="15" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="26" />
                              <E2GSelectFieldOption label="Condensation 2 - Vapor Barrier Failure" value="27" />
                              <E2GSelectFieldOption label="Condensation 3 - Vapor Barrier Missing Failure" value="28" />
                              <E2GSelectFieldOption label="Condensation 4 - Effective Max Corrosion Rate - Vapor Barrier Seams" value="29" />
                              <E2GSelectFieldOption label="Condensation 5 - Vapor Barrier Upper Transition Time Condensation" value="30" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 1" value="36" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 2" value="37" />
                              <E2GSelectFieldOption label="Condensation 6 - Vapor Barrier Sealant Actual Thickness Unique Time Inspection 3" value="38" />
                              <E2GSelectFieldOption label="Condensation 6 - Component Failure Time - Vapor Barrier Sealant" value="31" />
                              <E2GSelectFieldOption label="Condensation 7 - Effective Corrosion Rate - Missing Vapor Barrier" value="32" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 1" value="39" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 2" value="40" />
                              <E2GSelectFieldOption label="Condensation 8 - Missing Vapor Barrier Actual Thickness Unique Time Inspection 3" value="41" />
                              <E2GSelectFieldOption label="Condensation 8 - Component Failure Time - Missing Vapor Barrier" value="33" />
                              <E2GSelectFieldOption label="Condensation 9 - Multiple Failure Times of Condensation Networks" value="34" />
                              <E2GSelectFieldOption label="Condensation 10 - Multiple Failure Times of Final Condensation and Jacketing Networks" value="35" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                </div>
              )
              : '' }
            <Stack>
              <div className="cf w-100">
                {results.whichNetwork[0] === '1'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Instant Rate and External Fraction
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Instant Rate and External Fraction" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork012, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '2'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Coating Failure Time and Damage Rate
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Coating Failure Time and Damage Rate" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork034, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '3'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Sealant Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Sealant Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork05, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '4'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Missing Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Missing Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork06, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '5'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Corrosion Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Corrosion Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork07, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Internal Exposure Fraction - Flux
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Internal Exposure Fraction - Flux" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork09, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '7'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Effective Corrosion Rate - Missing Jacketing
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Effective Corrosion Rate - Missing Jacketing" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork10, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '8'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Component Failure Time - Missing Jacketing
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Component Failure Time - Missing Jacketing" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork11, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '9'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Effective Max Corrosion Rate - Sealant Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Effective Max Corrosion Rate - Sealant Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork12, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '10'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Upper Transition Time Sealant
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Upper Transition Time Sealant" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork13, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '11'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Component Failure Time - Sealant Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Component Failure Time - Sealant Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork14, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '12'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Effective Max Corrosion Rate - Corrosion Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Effective Max Corrosion Rate - Corrosion Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork15, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '13'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Upper Transition Time Corrosion
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Upper Transition Time Corrosion" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork16, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '14'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Component Failure Time - Corrosion Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Component Failure Time - Corrosion Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork17, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '15'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Multiple Failure Times
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Multiple Failure Times" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetwork18, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16a'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Replace Only
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Replace Only" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision01, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16b'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Recoat Only
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Recoat Only" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision02, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16c'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Rejacket Only
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Rejacket Only" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision03, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16d'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Reseal Only
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Reseal Only" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision04, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16e'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Recoat and Rejacket
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Recoat and Rejacket" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision05, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '17'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Missing Jacketing Actual Thickness Unique Time Inspection 1
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Missing Jacketing Actual Thickness Unique Time Inspection 1" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21101, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '18'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Sealant Actual Thickness Unique Time Inspection 1
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Sealant Actual Thickness Unique Time Inspection 1" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21401, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '19'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Actual Thickness Unique Time Inspection 1
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Actual Thickness Unique Time Inspection 1" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21701, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '20'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Missing Jacketing Actual Thickness Unique Time Inspection 2
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Missing Jacketing Actual Thickness Unique Time Inspection 2" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21102, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '21'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Sealant Actual Thickness Unique Time Inspection 2
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Sealant Actual Thickness Unique Time Inspection 2" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21402, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '22'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Actual Thickness Unique Time Inspection 2
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Actual Thickness Unique Time Inspection 2" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21702, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '23'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Missing Jacketing Actual Thickness Unique Time Inspection 3
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Missing Jacketing Actual Thickness Unique Time Inspection 3" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21103, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '24'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Sealant Actual Thickness Unique Time Inspection 3
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Sealant Actual Thickness Unique Time Inspection 3" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21403, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '25'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Actual Thickness Unique Time Inspection 3
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Actual Thickness Unique Time Inspection 3" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb21703, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}

                {results.whichNetwork[0] === '26'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Dew Point
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Dew Point" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation19, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '27'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Vapor Barrier Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Vapor Barrier Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation20, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '28'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Vapor Barrier Missing Failure
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Vapor Barrier Missing Failure" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation23, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '29'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Effective Max Corrosion Rate - Vapor Barrier Seams
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Effective Max Corrosion Rate - Vapor Barrier Seams" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation12, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '30'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Vapor Barrier Upper Transition Time Condensation
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Vapor Barrier Upper Transition Time Condensation" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation21, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '31'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Component Failure Time - Vapor Barrier Sealant
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Component Failure Time - Vapor Barrier Sealant" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation22, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '32'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Effective Corrosion Rate - Missing Vapor Barrier
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Effective Corrosion Rate - Missing Vapor Barrier" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation24, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '33'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Component Failure Time - Missing Vapor Barrier
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Component Failure Time - Missing Vapor Barrier" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation25, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '34'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Multiple Failure Times of Condensation Networks
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Multiple Failure Times of Condensation Networks" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation26, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '35'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Multiple Failure Times of Final Condensation and Jacketing Networks
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Multiple Failure Times of Final Condensation and Jacketing Networks" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation27, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '36'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Vapor Barrier Sealant Actual Thickness Unique Time Inspection 1
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Vapor Barrier Sealant Actual Thickness Unique Time Inspection 1" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb2CondensationSeams01, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '37'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Vapor Barrier Sealant Actual Thickness Unique Time Inspection 2
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Vapor Barrier Sealant Actual Thickness Unique Time Inspection 2" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb2CondensationSeams02, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '38'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Vapor Barrier Sealant Actual Thickness Unique Time Inspection 3
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Vapor Barrier Sealant Actual Thickness Unique Time Inspection 3" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb2CondensationSeams03, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '39'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Missing Vapor Barrier Actual Thickness Unique Time Inspection 1
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Missing Vapor Barrier Actual Thickness Unique Time Inspection 1" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb2MissingVP01, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '40'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Missing Vapor Barrier Actual Thickness Unique Time Inspection 2
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Missing Vapor Barrier Actual Thickness Unique Time Inspection 2" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb2MissingVP02, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '41'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Missing Vapor Barrier Actual Thickness Unique Time Inspection 3
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Missing Vapor Barrier Actual Thickness Unique Time Inspection 3" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb2MissingVP03, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                <div className="pa5">
                  <p>First, click GENERATE LAYOUT to view the network. Then click any nature node (green) to view the corrosponding belief table. Note, there are no belief tables for decision (blue) and utility (orange) nodes.</p>
                </div>
                {results.whichNetwork[0] === '1'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork012} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '2'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork034} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '3'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork05} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '4'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork06} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '5'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork07} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork09} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '7'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork10} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '8'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork11} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '9'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork12} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '10'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork13} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '11'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork14} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '12'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork15} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '13'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork16} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '14'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork17} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '15'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetwork18} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16a'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision01} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16b'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision02} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16c'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision03} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16d'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision04} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '16e'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision05} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '17'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21101} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '18'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21401} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '19'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21701} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '20'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21102} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '21'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21402} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '22'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21702} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '23'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21103} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '24'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21403} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '25'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb21703} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '26'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation19} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '27'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation20} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '28'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation23} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '29'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation12} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '30'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation21} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '31'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation22} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '32'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation24} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '33'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation25} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '34'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation26} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '35'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation27} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '36'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb2CondensationSeams01} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '37'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb2CondensationSeams02} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '38'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb2CondensationSeams03} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '39'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb2MissingVP01} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '40'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb2MissingVP02} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '41'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb2MissingVP03} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
              </div>
            </Stack>
          </div>
        )
        : ''}
    </div>
  )
}

BayesNetBuilderProb2.propTypes = {
  results: PropTypes.object,
  decisionsStrategy: PropTypes.string,
  problemType: PropTypes.string,
}

const mapStateToProps = (state) => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  decisionsStrategy: state.workflow.fields.decisionsStrategy,
  problemType: state.workflow.fields.problemType,
})

export default connect(mapStateToProps, null)(BayesNetBuilderProb2)
