import React from 'react'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

export default [
  {
    name: 'Step1',
    title: 'Piping/Equipment Components',
    content: (
      <Step1 />
    ),
  },
  {
    name: 'Step2',
    title: 'Insulation System',
    content: (
      <Step2 />
    ),
  },
  
  {
    name: 'Step3',
    title: 'Jacketing',
    content: (
      <Step3 />
    ),
  },
  {
    name: 'Step4',
    title: 'Coating',
    content: (
      <Step4 />
    ),
  },
]
