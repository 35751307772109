/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step3({ problemType, runCondensation, seamsAmount, properSeamAdhesian, componentType, verticalTelescoping, endCapType, protrusionsCovered, horizontalLapJoints, insulationType, lowPointWaterTrap, pooling, highTempSilicone, runQualMethodsBool, insulationMaintanceInsectionQuality, properDrainage, waterRepellent, jacketInstallQuality, jacketingMaterial, jacketPostInstallQAQC, moistureAccumulationSites, sealantAmount, highTempSealant, sealantPostInstallQAQC, vaporBarrierPostInstallQaQc, vaporBarrierInstallQuality, sealantInstallQuality, jacketMetallic, probabilisticInputFieldFlag, insulationTypeProb, properDrainageProb, moistureAccumulationSitesProb, lowPointWaterTrapProb, protrusionsCoveredProb, horizontalLapJointsProb, verticalTelescopingProb, endCapTypeProb, jacketingMaterialProb, jacketMetallicProb, jacketInstallQualityProb, jacketPostInstallQAQCProb, sealantAmountProb, highTempSealantProb, sealantPostInstallQAQCProb, sealantInstallQualityProb }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB3'
        ? (
          <div>
            {probabilisticInputFieldFlag === false
              ? (
                <div>
                  <div className="fl w-third-l w-50-m w-100">
                    <E2GSelectField name="jacketingMaterial" value={jacketingMaterial} label="Jacketing Material">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Non-Metallic" value="Non-Metallic" />
                      <E2GSelectFieldOption label="Stainless Steel" value="Stainless Steel" />
                      <E2GSelectFieldOption label="Aluminum" value="Aluminum" />
                      <E2GSelectFieldOption label="Galvanized Steel" value="Galvanized Steel" />
                    </E2GSelectField>
                    <E2GSelectField name="jacketInstallQuality" value={jacketInstallQuality} label="Jacket Install Quality">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Low" value="Low" />
                      <E2GSelectFieldOption label="Moderate" value="Moderate" />
                      <E2GSelectFieldOption label="High" value="High" />
                    </E2GSelectField>
                    <E2GSelectField name="jacketPostInstallQAQC" value={jacketPostInstallQAQC} label="Jacket Post Install QA/QC">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="sealantAmount" value={sealantAmount} label="Jacket Sealant Amount">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Few" value="Few" />
                      <E2GSelectFieldOption label="Some" value="Some" />
                      <E2GSelectFieldOption label="Many" value="Many" />
                    </E2GSelectField>
                    <E2GSelectField name="highTempSealant" value={highTempSealant} label="High Temperature Sealant">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="sealantInstallQuality" value={sealantInstallQuality} label="Jacket Sealant Install Quality">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Poor" value="Poor" />
                      <E2GSelectFieldOption label="Average" value="Average" />
                      <E2GSelectFieldOption label="Quality" value="Quality" />
                    </E2GSelectField>
                    <E2GSelectField name="sealantPostInstallQAQC" value={sealantPostInstallQAQC} label="Jacket Sealant Post Install QA/QC">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                  </div>
                </div>
              )
              : <div>
                <div className="fl w-50-l w-100">
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="jacketingMaterialProb"
                      jsonEditable
                      value={jacketingMaterialProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Jacketing Material',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="jacketInstallQualityProb"
                      jsonEditable
                      value={jacketInstallQualityProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Jacket Install Quality',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="jacketPostInstallQAQCProb"
                      jsonEditable
                      value={jacketPostInstallQAQCProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Jacket Post Install QA/QC',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="sealantAmountProb"
                      jsonEditable
                      value={sealantAmountProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Jacket Sealant Amount',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="highTempSealantProb"
                      jsonEditable
                      value={highTempSealantProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'High Temperature Sealant',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="sealantInstallQualityProb"
                      jsonEditable
                      value={sealantInstallQualityProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Jacket Sealant Install Quality',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="sealantPostInstallQAQCProb"
                      jsonEditable
                      value={sealantPostInstallQAQCProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Jacket Sealant Post Install QA/QC',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                </div>
              </div>}
          </div>
        )
        : ''}
      <div className="fl w-100">
        <Submit label="Next" />
      </div>
    </div>
  )
}

Step3.propTypes = {
  problemType: PropTypes.string,
  properDrainage: PropTypes.string,
  waterRepellent: PropTypes.string,
  jacketInstallQuality: PropTypes.string,
  jacketingMaterial: PropTypes.string,
  jacketPostInstallQAQC: PropTypes.string,
  runQualMethodsBool: PropTypes.bool,
  insulationMaintanceInsectionQuality: PropTypes.string,
  highTempSilicone: PropTypes.string,
  pooling: PropTypes.string,
  lowPointWaterTrap: PropTypes.string,
  insulationType: PropTypes.string,
  protrusionsCovered: PropTypes.string,
  horizontalLapJoints: PropTypes.string,
  verticalTelescoping: PropTypes.string,
  endCapType: PropTypes.string,
  moistureAccumulationSites: PropTypes.string,
  sealantAmount: PropTypes.string,
  componentType: PropTypes.string,
  runCondensation: PropTypes.bool,
  seamsAmount: PropTypes.string,
  properSeamAdhesian: PropTypes.string,
  highTempSealant: PropTypes.string,
  sealantPostInstallQAQC: PropTypes.string,
  vaporBarrierPostInstallQaQc: PropTypes.string,
  vaporBarrierInstallQuality: PropTypes.string,
  sealantInstallQuality: PropTypes.string,
  jacketMetallic: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  insulationTypeProb: PropTypes.string,
  properDrainageProb: PropTypes.string,
  moistureAccumulationSitesProb: PropTypes.string,
  lowPointWaterTrapProb: PropTypes.string,
  protrusionsCoveredProb: PropTypes.string,
  horizontalLapJointsProb: PropTypes.string,
  verticalTelescopingProb: PropTypes.string,
  endCapTypeProb: PropTypes.string,
  jacketingMaterialProb: PropTypes.string,
  jacketMetallicProb: PropTypes.string,
  jacketInstallQualityProb: PropTypes.string,
  jacketPostInstallQAQCProb: PropTypes.string,
  sealantAmountProb: PropTypes.string,
  highTempSealantProb: PropTypes.string,
  sealantPostInstallQAQCProb: PropTypes.string,
  sealantInstallQualityProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  properDrainage: state.workflow.fields.properDrainage,
  waterRepellent: state.workflow.fields.waterRepellent,
  jacketInstallQuality: state.workflow.fields.jacketInstallQuality,
  jacketingMaterial: state.workflow.fields.jacketingMaterial,
  jacketPostInstallQAQC: state.workflow.fields.jacketPostInstallQAQC,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  insulationMaintanceInsectionQuality: state.workflow.fields.insulationMaintanceInsectionQuality,
  highTempSilicone: state.workflow.fields.highTempSilicone,
  pooling: state.workflow.fields.pooling,
  lowPointWaterTrap: state.workflow.fields.lowPointWaterTrap,
  insulationType: state.workflow.fields.insulationType,
  protrusionsCovered: state.workflow.fields.protrusionsCovered,
  horizontalLapJoints: state.workflow.fields.horizontalLapJoints,
  verticalTelescoping: state.workflow.fields.verticalTelescoping,
  endCapType: state.workflow.fields.endCapType,
  moistureAccumulationSites: state.workflow.fields.moistureAccumulationSites,
  sealantAmount: state.workflow.fields.sealantAmount,
  componentType: state.workflow.fields.componentType,
  runCondensation: state.workflow.fields.runCondensation,
  seamsAmount: state.workflow.fields.seamsAmount,
  properSeamAdhesian: state.workflow.fields.properSeamAdhesian,
  highTempSealant: state.workflow.fields.highTempSealant,
  sealantPostInstallQAQC: state.workflow.fields.sealantPostInstallQAQC,
  vaporBarrierPostInstallQaQc: state.workflow.fields.vaporBarrierPostInstallQaQc,
  vaporBarrierInstallQuality: state.workflow.fields.vaporBarrierInstallQuality,
  sealantInstallQuality: state.workflow.fields.sealantInstallQuality,
  jacketMetallic: state.workflow.fields.jacketMetallic,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  insulationTypeProb: state.workflow.fields.insulationTypeProb,
  properDrainageProb: state.workflow.fields.properDrainageProb,
  moistureAccumulationSitesProb: state.workflow.fields.moistureAccumulationSitesProb,
  lowPointWaterTrapProb: state.workflow.fields.lowPointWaterTrapProb,
  protrusionsCoveredProb: state.workflow.fields.protrusionsCoveredProb,
  horizontalLapJointsProb: state.workflow.fields.horizontalLapJointsProb,
  verticalTelescopingProb: state.workflow.fields.verticalTelescopingProb,
  endCapTypeProb: state.workflow.fields.endCapTypeProb,
  jacketingMaterialProb: state.workflow.fields.jacketingMaterialProb,
  jacketMetallicProb: state.workflow.fields.jacketMetallicProb,
  jacketInstallQualityProb: state.workflow.fields.jacketInstallQualityProb,
  jacketPostInstallQAQCProb: state.workflow.fields.jacketPostInstallQAQCProb,
  sealantAmountProb: state.workflow.fields.sealantAmountProb,
  highTempSealantProb: state.workflow.fields.highTempSealantProb,
  sealantPostInstallQAQCProb: state.workflow.fields.sealantPostInstallQAQCProb,
  sealantInstallQualityProb: state.workflow.fields.sealantInstallQualityProb,
})

export default connect(mapStateToProps)(Step3)
