export function plotBarResults(results, activeUnitSystem, decisionsStrategy) {
  let thicknessUnits = 'in'
  let damageRateUnits = 'mil/yr'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  if(activeUnitSystem === 'Metric') {
    damageRateUnits = 'mm/yr'
  }
  const startingThicknessBeliefPlot = results.dataTables.startingThicknessBelief.keywords[0].data
  const startingThicknessStatesPlot = results.startingThicknessStates
  const coatingFirstFailureTimeBeliefPlot = results.dataTables.coatingFirstFailureTimeBeliefOutput.keywords[0].data
  const coatingFirstFailureTimeStatesPlot = results.coatingFirstFailureTimeStatesOutput
  const failureTimeMissingJacketingBeliefPlot = results.dataTables.failureTimeMissingJacketingBeliefOutput.keywords[0].data
  const failureTimeMissingJacketingStatesPlot = results.failureTimeMissingJacketingStatesOutput
  const sealantFailureTimeBeliefPlot = results.dataTables.sealantFailureTimeBeliefOutput.keywords[0].data
  const sealantFailureTimeStatesPlot = results.sealantFailureTimeStatesOutput
  const corrosionFailureTimeBeliefPlot = results.dataTables.corrosionFailureTimeBeliefOutput.keywords[0].data
  const corrosionFailureTimeStatesPlot = results.corrosionFailureTimeStatesOutput
  const coatingDamageRateBeliefPlot = results.dataTables.coatingDamageRateBeliefOutput.keywords[0].data
  const coatingDamageRateStatesPlot = results.coatingDamageRateStatesOutput
  const sealantDamageRateBeliefPlot = results.dataTables.sealantDamageRateBeliefOutput.keywords[0].data
  const sealantDamageRateStatesPlot = results.sealantDamageRateStatesOutput
  const corrosionJacketDamageRateBeliefPlot = results.dataTables.corrosionJacketDamageRateBeliefOutput.keywords[0].data
  const corrosionJacketDamageRateStatesPlot = results.corrosionJacketDamageRateStatesOutput
  const failureTimeBeliefPlot = results.dataTables.failureTimeBelief.keywords[0].data
  const failureTimeStatesPlot = results.failureTimeStates
  const missingComponentFailureTimeBeliefPlot = results.dataTables.missingJacketingComponentFailureTimeBeliefOutput.keywords[0].data
  const missingComponentFailureTimeStatesPlot = results.missingJacketingComponentFailureTimeStateLevelsReturn
  const sealantComponentFailureTimeBeliefPlot = results.dataTables.sealantJacketingComponentFailureTimeBeliefOutput.keywords[0].data
  const sealantComponentFailureTimeStatesPlot = results.sealantJacketingComponentFailureTimeStateLevelsReturn
  const corrosionComponentFailureTimeBeliefPlot = results.dataTables.corrosionComponentFailureTimeBeliefOutput.keywords[0].data
  const corrosionComponentFailureTimeStatesPlot = results.corrosionComponentFailureTimeLevelsReturn

  let missingVaporBarrierComponentFailureTimeBeliefPlot
  let sealantVaporBarrierComponentFailureTimeBeliefPlot
  let condensationComponentFailureTimeBeliefPlot
  let missingVaporBarrierComponentFailureTimeStatesPlot
  let sealantVaporBarrierComponentFailureTimeStatesPlot
  let condensationComponentFailureTimeStatesPlot
  if(results.returnCondensationBool[0] === 1) {
    missingVaporBarrierComponentFailureTimeBeliefPlot = results.dataTables.missingVaporBarrierComponentFailureTimeBeliefOutput.keywords[0].data
    missingVaporBarrierComponentFailureTimeStatesPlot = results.missingVaporBarrierComponentFailureTimeStateLevelsReturn
    sealantVaporBarrierComponentFailureTimeBeliefPlot = results.dataTables.condensationJacketingComponentFailureTimeBeliefOutput.keywords[0].data
    sealantVaporBarrierComponentFailureTimeStatesPlot = results.condensationJacketingComponentFailureTimeStateLevelsReturn
    condensationComponentFailureTimeBeliefPlot = results.dataTables.condensationFailureTimeBelief.keywords[0].data
    condensationComponentFailureTimeStatesPlot = results.condensationFailureTimeStates
  }

  const tracesProfileData01 = []
  const tracesProfileData02 = []
  const tracesProfileData03 = []
  const tracesProfileData04 = []
  const tracesProfileData05 = []
  const tracesProfileData06 = []
  const tracesProfileData07 = []
  const tracesProfileData08 = []
  const tracesProfileData10 = []
  const tracesProfileData12 = []
  const tracesProfileData13 = []
  const tracesProfileData14 = []
  const tracesProfileData15 = []
  const tracesProfileData16 = []
  const tracesProfileData17 = []
  tracesProfileData01.push({
    x: startingThicknessStatesPlot,
    y: startingThicknessBeliefPlot,
    type: 'bar',
  })
  tracesProfileData02.push({
    x: coatingFirstFailureTimeStatesPlot,
    y: coatingFirstFailureTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData03.push({
    x: failureTimeMissingJacketingStatesPlot,
    y: failureTimeMissingJacketingBeliefPlot,
    type: 'bar',
  })
  tracesProfileData04.push({
    x: sealantFailureTimeStatesPlot,
    y: sealantFailureTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData05.push({
    x: corrosionFailureTimeStatesPlot,
    y: corrosionFailureTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData06.push({
    x: coatingDamageRateStatesPlot,
    y: coatingDamageRateBeliefPlot,
    type: 'bar',
  })
  tracesProfileData07.push({
    x: sealantDamageRateStatesPlot,
    y: sealantDamageRateBeliefPlot,
    type: 'bar',
  })
  tracesProfileData08.push({
    x: corrosionJacketDamageRateStatesPlot,
    y: corrosionJacketDamageRateBeliefPlot,
    type: 'bar',
  })
  tracesProfileData10.push({
    x: failureTimeStatesPlot,
    y: failureTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData12.push({
    x: missingComponentFailureTimeStatesPlot,
    y: missingComponentFailureTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData13.push({
    x: sealantComponentFailureTimeStatesPlot,
    y: sealantComponentFailureTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData14.push({
    x: corrosionComponentFailureTimeStatesPlot,
    y: corrosionComponentFailureTimeBeliefPlot,
    type: 'bar',
  })
  if(results.returnCondensationBool[0] === 1) {
    tracesProfileData15.push({
      x: missingVaporBarrierComponentFailureTimeStatesPlot,
      y: missingVaporBarrierComponentFailureTimeBeliefPlot,
      type: 'bar',
    })
    tracesProfileData16.push({
      x: sealantVaporBarrierComponentFailureTimeStatesPlot,
      y: sealantVaporBarrierComponentFailureTimeBeliefPlot,
      type: 'bar',
    })
    tracesProfileData17.push({
      x: condensationComponentFailureTimeStatesPlot,
      y: condensationComponentFailureTimeBeliefPlot,
      type: 'bar',
    })
  }
  const graphs = {}
  graphs.Graph1 = {
    traces: tracesProfileData01,
    layout: {
      title: 'Starting Thickness',
      xaxis: {
        title: `Thickness (${thicknessUnits})`,
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph2 = {
    traces: tracesProfileData02,
    layout: {
      title: 'Coating First Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph3 = {
    traces: tracesProfileData03,
    layout: {
      title: 'Missing Jacketing First Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph4 = {
    traces: tracesProfileData04,
    layout: {
      title: 'Sealant Jacketing First Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph5 = {
    traces: tracesProfileData05,
    layout: {
      title: 'Corrosion Jacketing First Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph6 = {
    traces: tracesProfileData06,
    layout: {
      title: 'Coating Damage Rate',
      xaxis: {
        title: `Rate (${damageRateUnits})`, // title: 'Rate (%/yr)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph7 = {
    traces: tracesProfileData07,
    layout: {
      title: 'Sealant Jacketing Damage Rate',
      xaxis: {
        title: `Rate (${damageRateUnits})`,
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph8 = {
    traces: tracesProfileData08,
    layout: {
      title: 'Corrosion Jacketing Damage Rate',
      xaxis: {
        title: `Rate (${damageRateUnits})`,
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph9 = {
    traces: tracesProfileData12,
    layout: {
      title: 'Component Missing Jacketing Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph10 = {
    traces: tracesProfileData13,
    layout: {
      title: 'Component Jacketing Sealant Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph11 = {
    traces: tracesProfileData14,
    layout: {
      title: 'Component Jacketing Corrosion Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  if(results.returnCondensationBool[0] === 1) {
    graphs.Graph12 = {
      traces: tracesProfileData15,
      layout: {
        title: 'Component Missing Vapor Barrier Failure Time',
        xaxis: {
          title: 'Time (yrs)',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
    graphs.Graph13 = {
      traces: tracesProfileData16,
      layout: {
        title: 'Component Vapor Barrier Sealant Failure Time',
        xaxis: {
          title: 'Time (yrs)',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
    graphs.Graph14 = {
      traces: tracesProfileData17,
      layout: {
        title: 'Component Condensation Failure Time',
        xaxis: {
          title: 'Time (yrs)',
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  graphs.Graph15 = {
    traces: tracesProfileData10,
    layout: {
      title: 'Component Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  if(decisionsStrategy === '1') {
    const failureDecisionsBelief = results.dataTables.failureDecisionsBelief.keywords[0].data
    const failureDecisionsStates = results.dataTables.failureDecisionsStates.keywords[0].data
    const tracesProfileData11 = []
    tracesProfileData11.push({
      x: ['Yes', 'No'],
      y: failureDecisionsBelief,
      type: 'bar',
    })
    graphs.Graph16 = {
      traces: tracesProfileData11,
      layout: {
        title: 'Failure',
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  return graphs
}

