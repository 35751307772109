/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step1({ problemType, accessibility, runQualMethodsBool, attachments, pipingComplexity, supportRings, airSoilInterface, steamTracing, branchesGeometryChanges, steamTracingVariance, steamTracingAge, steamTracingQuality, probabilisticInputFieldFlag, attachmentsProb, pipingComplexityProb, accessibilityProb, steamTracingVarianceProb, steamTracingQualityProb, steamTracingProb }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB1'
        ? (
          <div className="cf w-100">
            <div className="fl w-third-l w-50-m w-100">
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Attachments refer to various fittings, connectors, and auxiliary devices that are used to join, support, or enhance the functionality of the pipeline system.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="attachments" value={attachments} label="Attachments?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Few" value="Few" />
                <E2GSelectFieldOption label="Some" value="Some" />
                <E2GSelectFieldOption label="Many" value="Many" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>In piping systems, branches and geometry changes impact fluid flow, pressure loss, and overall system efficiency. These changes can include bends, expansions, contractions, and various fittings that alter the flow path.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="branchesGeometryChanges" value={branchesGeometryChanges} label="Branches or Geometry Changes?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Piping complexity in a piping system refers to the intricacy and sophistication of the network of pipes, fittings, valves, and other components used to transport fluids. This complexity can be influenced by several factors, including the design, layout, and operational requirements of the system.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="pipingComplexity" value={pipingComplexity} label="Piping Complexity?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Simple" value="Simple" />
                <E2GSelectFieldOption label="Average" value="Average" />
                <E2GSelectFieldOption label="Complex" value="Complex" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Piping accessibility in a piping system refers to the ease with which the system can be inspected, maintained, and operated.</p>
                  <li>Note that conjested refers to a dense network of pipes with limited space, whereas elevated refers to pipes that are installed above ground level at a higher level.</li>
                </div>}
                dialog
              > */}
              <E2GSelectField name="accessibility" value={accessibility} label="Piping Accessibility?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Accessible" value="Accessible" />
                <E2GSelectFieldOption label="Congested" value="Congested" />
                <E2GSelectFieldOption label="Elevated" value="Elevated" />
                <E2GSelectFieldOption label="Congested and Elevated" value="Congested and Elevated" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Steam tracing is a method used to maintain or raise the temperature of process fluids in piping systems by circulating steam through tracer lines that run alongside the pipes.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="steamTracing" value={steamTracing} label="Steam Tracing?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes with Leaks" value="Yes with Leaks" />
                <E2GSelectFieldOption label="Yes without Leaks" value="Yes without Leaks" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {runQualMethodsBool === true
                ? (
                  <div>

                    {/* <E2GInfoButton
                      tooltip={<div>
                        <p>Support rings in a piping system are devices used to provide structural support and stability to pipes.</p>
                      </div>}
                      dialog
                    > */}
                    <E2GSelectField name="supportRings" value={supportRings} label="Support Rings?">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Horizontal and Protruding" value="Horizontal and Protruding" />
                      <E2GSelectFieldOption label="Vertical and Protruding" value="Vertical and Protruding" />
                      <E2GSelectFieldOption label="Not Protruding" value="Not Protruding" />
                      <E2GSelectFieldOption label="None" value="None" />
                    </E2GSelectField>
                    {/* </E2GInfoButton> */}
                    {/* <E2GInfoButton
                      tooltip={<div>
                        <p>The air to soil interface in a piping system refers to the area where a pipeline transitions from being buried underground to being exposed above ground.</p>
                      </div>}
                      dialog
                    > */}
                    <E2GSelectField name="airSoilInterface" value={airSoilInterface} label="Air to Soil Interface?">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="No" value="No" />
                      <E2GSelectFieldOption label="Yes without Puddling" value="Yes without Puddling" />
                      <E2GSelectFieldOption label="Yes with Puddling" value="Yes with Puddling" />
                    </E2GSelectField>
                    {/* </E2GInfoButton> */}
                  </div>
                )
                : ''}
            </div>
          </div>
        )
        : ''}
      {problemType === 'PROB2'
        ? (
          <div className="cf w-100">
            <E2GSelectField name="attachments" value={attachments} label="Attachments?">
              <E2GSelectFieldOption label="Unknown" value="unknown" />
              <E2GSelectFieldOption label="Few" value="Few" />
              <E2GSelectFieldOption label="Some" value="Some" />
              <E2GSelectFieldOption label="Many" value="Many" />
            </E2GSelectField>
            <E2GSelectField name="pipingComplexity" value={pipingComplexity} label="Piping Complexity?">
              <E2GSelectFieldOption label="Unknown" value="unknown" />
              <E2GSelectFieldOption label="Simple" value="Simple" />
              <E2GSelectFieldOption label="Average" value="Average" />
              <E2GSelectFieldOption label="Complex" value="Complex" />
            </E2GSelectField>
            <E2GSelectField name="accessibility" value={accessibility} label="Piping Accessibility?">
              <E2GSelectFieldOption label="Unknown" value="unknown" />
              <E2GSelectFieldOption label="Accessible" value="Accessible" />
              <E2GSelectFieldOption label="Congested" value="Congested" />
              <E2GSelectFieldOption label="Elevated" value="Elevated" />
              <E2GSelectFieldOption label="Congested and Elevated" value="Congested and Elevated" />
            </E2GSelectField>
            <E2GSelectField name="steamTracing" value={steamTracing} label="Steam Tracing?">
              <E2GSelectFieldOption label="Unknown" value="unknown" />
              <E2GSelectFieldOption label="Yes with Leaks" value="Yes with Leaks" />
              <E2GSelectFieldOption label="Yes without Leaks" value="Yes without Leaks" />
              <E2GSelectFieldOption label="No" value="No" />
            </E2GSelectField>
          </div>
        )
        : ''}
      <Submit label="Next" />
    </div>
  )
}

Step1.propTypes = {
  problemType: PropTypes.string,
  accessibility: PropTypes.string,
  attachments: PropTypes.string,
  pipingComplexity: PropTypes.string,
  supportRings: PropTypes.string,
  airSoilInterface: PropTypes.string,
  steamTracing: PropTypes.string,
  branchesGeometryChanges: PropTypes.string,
  runQualMethodsBool: PropTypes.bool,
  steamTracingVariance: PropTypes.string,
  steamTracingAge: PropTypes.number,
  steamTracingQuality: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  attachmentsProb: PropTypes.string,
  pipingComplexityProb: PropTypes.string,
  accessibilityProb: PropTypes.string,
  steamTracingVarianceProb: PropTypes.string,
  steamTracingQualityProb: PropTypes.string,
  steamTracingProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  accessibility: state.workflow.fields.accessibility,
  attachments: state.workflow.fields.attachments,
  pipingComplexity: state.workflow.fields.pipingComplexity,
  supportRings: state.workflow.fields.supportRings,
  airSoilInterface: state.workflow.fields.airSoilInterface,
  steamTracing: state.workflow.fields.steamTracing,
  branchesGeometryChanges: state.workflow.fields.branchesGeometryChanges,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  steamTracingVariance: state.workflow.fields.steamTracingVariance,
  steamTracingAge: state.workflow.fields.steamTracingAge,
  steamTracingQuality: state.workflow.fields.steamTracingQuality,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  attachmentsProb: state.workflow.fields.attachmentsProb,
  pipingComplexityProb: state.workflow.fields.pipingComplexityProb,
  accessibilityProb: state.workflow.fields.accessibilityProb,
  steamTracingVarianceProb: state.workflow.fields.steamTracingVarianceProb,
  steamTracingQualityProb: state.workflow.fields.steamTracingQualityProb,
  steamTracingProb: state.workflow.fields.steamTracingProb,
})

export default connect(mapStateToProps)(Step1)
