/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
// import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'

import * as histogram from '../lib/histogram'
import * as histogramProb2 from '../lib/histogramProb2'

const Graphs = ({ results, graphs, activeUnitSystem, decisionsFlag, problemType, decisionsStrategy }) => {
  let graphsToDisplay
  let graphsToDisplay01
  let graphsToDisplay02
  if(problemType === 'PROB1') {
    graphsToDisplay = histogram.plotBarResults(results, 0, activeUnitSystem, decisionsStrategy)
  } else if(problemType === 'PROB2') {
    graphsToDisplay = histogramProb2.plotBarResults(results, 0, activeUnitSystem, decisionsStrategy)
  } else if(problemType === 'PROB3') {
    if(results.inspections[0] === 0) {
      graphsToDisplay = histogram.plotBarResults(results, 0, activeUnitSystem, decisionsStrategy)
    } else if(results.inspections[0] === 1) {
      graphsToDisplay01 = histogram.plotBarResults(results, 1, activeUnitSystem, decisionsStrategy)
      graphsToDisplay02 = histogram.plotBarResults(results, 2, activeUnitSystem, decisionsStrategy)
    }
  }
  return (
    <div className="cf w-100">
      {problemType === 'PROB1' || problemType === 'PROB2'
        ? (
          <div className="cf w-100">
            <DataGraphDisplay graphs={graphsToDisplay} />
          </div>
        )
        : ''}
      {problemType === 'PROB3'
        ? (
          <div className="cf w-100">
            {results.inspections[0] === 1
              ? (
                <div>
                  <E2GSelectFieldIndexer name="beforeOrAfterInspectionsPlot" value={results.beforeOrAfterInspectionsPlot[0]} label="Select Plot to View">
                    <E2GSelectFieldOption label="Before Inspections" value="1" />
                    <E2GSelectFieldOption label="After Inspections" value="2" />
                  </E2GSelectFieldIndexer >
                  {results.beforeOrAfterInspectionsPlot[0] === '1'
                    ? (
                      <div>
                        <DataGraphDisplay graphs={graphsToDisplay01} />
                      </div>
                    )
                    : ''}
                  {results.beforeOrAfterInspectionsPlot[0] === '2'
                    ? (
                      <div>
                        <DataGraphDisplay graphs={graphsToDisplay02} />
                      </div>
                    )
                    : ''}
                </div>
              )
              : <div>
                <DataGraphDisplay graphs={graphsToDisplay} />
              </div>}
          </div>
        )
        : ''}
    </div>
  )
}

Graphs.propTypes = {
  results: PropTypes.object,
  graphs: PropTypes.object,
  activeUnitSystem: PropTypes.string,
  decisionsFlag: PropTypes.string,
  problemType: PropTypes.string,
  decisionsStrategy: PropTypes.string,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  graphs: state.workflow.response ? state.workflow.response.results.graphs : undefined,
  activeUnitSystem: state.workflow.activeUnitSystem,
  decisionsFlag: state.workflow.fields.decisionsFlag,
  problemType: state.workflow.fields.problemType,
  decisionsStrategy: state.workflow.fields.decisionsStrategy,
})
export default connect(mapStateToProps, null)(Graphs)
