/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GInfoButton from '@e2grnd/ui-core/dist/containers/E2GInfoButton'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

import * as images from '../../images'

function Step3({ problemType, coatingInstallQuality, coatingType, coatingMaterial, coatingPostInstallQAQC, coatingDesignedForCui, coatingInstalledInTheField, probabilisticInputFieldFlag, coatingMaterialProb, coatingInstallQualityProb, coatingInstalledInTheFieldProb, coatingPostInstallQAQCProb, coatingDesignedForCuiProb }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB1'
        ? (
          <div className="cf w-100">
            {/* <E2GInfoButton
              tooltip={<div>
                <p>In a coating system, coating material is a substance applied to the surface of an object to enhance its appearance, protect it from environmental factors, or provide specific functional properties. When applied, it forms a solid, continuous, and adherent film. This film can serve various purposes, such as corrosion resistance, wear resistance, or aesthetic enhancement. Thus, the quality of the coating material refers to the standards and characteristics that determine the effectiveness and durability of the coating. Note that high-quality coating materials are essential for protecting surfaces from corrosion in order to extend the life of the coating system.</p>
              </div>}
              dialog
            > */}
            <E2GSelectField name="coatingType" value={coatingType} label="Coating Material Quality">
              <E2GSelectFieldOption label="Unknown" value="unknown" />
              <E2GSelectFieldOption label="None (0-1 yr)" value="None" />
              <E2GSelectFieldOption label="Poor (1-5 yr)" value="Poor" />
              <E2GSelectFieldOption label="Moderate (5-10 yr)" value="Moderate" />
              <E2GSelectFieldOption label="Good (10-20 yr)" value="Good" />
            </E2GSelectField>
            {/* </E2GInfoButton> */}
            {coatingType === 'unknown'
              ? (
                <E2GInfoButton
                  tooltip={<div>
                    <img src={images.coatingMaterialTable} alt="" />
                  </div>}
                  dialog
                >
                  <E2GSelectField name="coatingMaterial" value={coatingMaterial} label="Coating Material">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="None" value="None" />
                    <E2GSelectFieldOption label="Primer" value="Primer" />
                    <E2GSelectFieldOption label="Hot Dip Galvanizing" value="Hot Dip Galvanizing" />
                    <E2GSelectFieldOption label="Zinc Silicate w/ Top Seal" value="Zinc Silicate w/ Top Seal" />
                    <E2GSelectFieldOption label="2 Layer with Zinc Rich Primer as 1st Layer" value="2 Layer with Zinc Rich Primer as 1st Layer" />
                    <E2GSelectFieldOption label="3 Layer with Zinc Rich Primer as 1st Layer" value="3 Layer with Zinc Rich Primer as 1st Layer" />
                    <E2GSelectFieldOption label="Polyster Based Coating" value="Polyster Based Coating" />
                    <E2GSelectFieldOption label="2 Component Epoxy" value="2 Component Epoxy" />
                    <E2GSelectFieldOption label="3 Layer on Epoxy Primer" value="3 Layer on Epoxy Primer" />
                    <E2GSelectFieldOption label="2 Layer Epoxy" value="2 Layer Epoxy" />
                    <E2GSelectFieldOption label="2 Layer Phenolic/Novolac" value="2 Layer Phenolic/Novolac" />
                    <E2GSelectFieldOption label="Fusion Bonded Epoxy" value="Fusion Bonded Epoxy" />
                    <E2GSelectFieldOption label="Thermal Spray Aluminum w/ Top Seal" value="Thermal Spray Aluminum w/ Top Seal" />
                    <E2GSelectFieldOption label="Air Dried or Modified Silicone" value="Air Dried or Modified Silicone" />
                    <E2GSelectFieldOption label="Inorganic Polymer" value="Inorganic Polymer" />
                  </E2GSelectField>
                </E2GInfoButton>
              )
              : ''}
            {coatingType !== 'None' && coatingMaterial !== 'None'
              ? (
                <div>
                  {/* <E2GInfoButton
                    tooltip={<div>
                      <p>Coating install quality refers to the standards and procedures used to ensure that a coating is applied correctly and meets the specified requirements.</p>
                    </div>}
                    dialog
                  > */}
                  <E2GSelectField name="coatingInstallQuality" value={coatingInstallQuality} label="Coating Install Quality">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Low" value="Low" />
                    <E2GSelectFieldOption label="Moderate" value="Moderate" />
                    <E2GSelectFieldOption label="High" value="High" />
                  </E2GSelectField>
                  {/* </E2GInfoButton> */}
                  {coatingInstallQuality === 'unknown'
                    ? (
                      // <E2GInfoButton
                      //   tooltip={<div>
                      //     <p>Coating installed in the field refers to whether the coating (or other parts of the system) was install in the field or somewhere else.</p>
                      //   </div>}
                      //   dialog
                      // >
                      <E2GSelectField name="coatingInstalledInTheField" value={coatingInstalledInTheField} label="Coating Installed in the Field?">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Yes" value="Yes" />
                        <E2GSelectFieldOption label="Weld Joints Only" value="Weld Joints Only" />
                        <E2GSelectFieldOption label="No" value="No" />
                      </E2GSelectField>
                      // </E2GInfoButton>
                    )
                    : ''}
                  {/* <E2GInfoButton
                    tooltip={<div>
                      <p>Coating post install QA/QC (Quality Assurance/Quality Control) involves a series of checks and procedures to ensure that the coating material has been installed correctly and meets all required standards.</p>
                    </div>}
                    dialog
                  > */}
                  <E2GSelectField name="coatingPostInstallQAQC" value={coatingPostInstallQAQC} label="Coating Install QA/QC">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Yes" value="Yes" />
                    <E2GSelectFieldOption label="No" value="No" />
                  </E2GSelectField>
                  {/* </E2GInfoButton> */}
                  {/* <E2GInfoButton
                    tooltip={<div>
                      <p>A coating designed for CUI is specifically formulated to protect metal surfaces that are covered by insulation from corrosion.</p>
                    </div>}
                    dialog
                  > */}
                  <E2GSelectField name="coatingDesignedForCui" value={coatingDesignedForCui} label="Coating Designed for CUI?">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Yes" value="Yes" />
                    <E2GSelectFieldOption label="No" value="No" />
                  </E2GSelectField>
                  {/* </E2GInfoButton> */}
                </div>
              )
              : ''}
          </div>
        )
        : ''}
      {problemType === 'PROB2'
        ? (
          <div className="cf w-100">
            <E2GInfoButton
              tooltip={<div>
                <img src={images.coatingMaterialTable} alt="" />
              </div>}
              dialog
            >
              <E2GSelectField name="coatingMaterial" value={coatingMaterial} label="Coating Material">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="None" value="None" />
                <E2GSelectFieldOption label="Primer" value="Primer" />
                <E2GSelectFieldOption label="Hot Dip Galvanizing" value="Hot Dip Galvanizing" />
                <E2GSelectFieldOption label="Zinc Silicate w/ Top Seal" value="Zinc Silicate w/ Top Seal" />
                <E2GSelectFieldOption label="2 Layer with Zinc Rich Primer as 1st Layer" value="2 Layer with Zinc Rich Primer as 1st Layer" />
                <E2GSelectFieldOption label="3 Layer with Zinc Rich Primer as 1st Layer" value="3 Layer with Zinc Rich Primer as 1st Layer" />
                <E2GSelectFieldOption label="Polyster Based Coating" value="Polyster Based Coating" />
                <E2GSelectFieldOption label="2 Component Epoxy" value="2 Component Epoxy" />
                <E2GSelectFieldOption label="3 Layer on Epoxy Primer" value="3 Layer on Epoxy Primer" />
                <E2GSelectFieldOption label="2 Layer Epoxy" value="2 Layer Epoxy" />
                <E2GSelectFieldOption label="2 Layer Phenolic/Novolac" value="2 Layer Phenolic/Novolac" />
                <E2GSelectFieldOption label="Fusion Bonded Epoxy" value="Fusion Bonded Epoxy" />
                <E2GSelectFieldOption label="Thermal Spray Aluminum w/ Top Seal" value="Thermal Spray Aluminum w/ Top Seal" />
                <E2GSelectFieldOption label="Air Dried or Modified Silicone" value="Air Dried or Modified Silicone" />
                <E2GSelectFieldOption label="Inorganic Polymer" value="Inorganic Polymer" />
              </E2GSelectField>
            </E2GInfoButton>
            {coatingMaterial !== 'None'
              ? (
                <div>
                  <E2GSelectField name="coatingInstallQuality" value={coatingInstallQuality} label="Coating Install Quality">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Low" value="Low" />
                    <E2GSelectFieldOption label="Moderate" value="Moderate" />
                    <E2GSelectFieldOption label="High" value="High" />
                  </E2GSelectField>
                  {coatingInstallQuality === 'unknown'
                    ? (
                      <E2GSelectField name="coatingInstalledInTheField" value={coatingInstalledInTheField} label="Coating Installed in the Field?">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Yes" value="Yes" />
                        <E2GSelectFieldOption label="Weld Joints Only" value="Weld Joints Only" />
                        <E2GSelectFieldOption label="No" value="No" />
                      </E2GSelectField>
                    )
                    : ''}
                  <E2GSelectField name="coatingPostInstallQAQC" value={coatingPostInstallQAQC} label="Coating Install QA/QC">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Yes" value="Yes" />
                    <E2GSelectFieldOption label="No" value="No" />
                  </E2GSelectField>
                  <E2GSelectField name="coatingDesignedForCui" value={coatingDesignedForCui} label="Coating Designed for CUI?">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Yes" value="Yes" />
                    <E2GSelectFieldOption label="No" value="No" />
                  </E2GSelectField>
                </div>
              )
              : ''}
          </div>
        )
        : ''}
      <Submit label="Next" />
    </div>
  )
}

Step3.propTypes = {
  problemType: PropTypes.string,
  coatingInstallQuality: PropTypes.string,
  coatingType: PropTypes.string,
  coatingMaterial: PropTypes.string,
  coatingPostInstallQAQC: PropTypes.string,
  coatingDesignedForCui: PropTypes.string,
  coatingInstalledInTheField: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  coatingMaterialProb: PropTypes.string,
  coatingInstallQualityProb: PropTypes.string,
  coatingInstalledInTheFieldProb: PropTypes.string,
  coatingPostInstallQAQCProb: PropTypes.string,
  coatingDesignedForCuiProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  coatingInstallQuality: state.workflow.fields.coatingInstallQuality,
  coatingType: state.workflow.fields.coatingType,
  coatingMaterial: state.workflow.fields.coatingMaterial,
  coatingPostInstallQAQC: state.workflow.fields.coatingPostInstallQAQC,
  coatingDesignedForCui: state.workflow.fields.coatingDesignedForCui,
  coatingInstalledInTheField: state.workflow.fields.coatingInstalledInTheField,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  coatingMaterialProb: state.workflow.fields.coatingMaterialProb,
  coatingInstallQualityProb: state.workflow.fields.coatingInstallQualityProb,
  coatingInstalledInTheFieldProb: state.workflow.fields.coatingInstalledInTheFieldProb,
  coatingPostInstallQAQCProb: state.workflow.fields.coatingPostInstallQAQCProb,
  coatingDesignedForCuiProb: state.workflow.fields.coatingDesignedForCuiProb,
})

export default connect(mapStateToProps)(Step3)
