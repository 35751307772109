export function plotBarResults(results, includeInspections, activeUnitSystem, decisionsStrategy) {
  let thicknessUnits = 'in'
  let damageRateUnits = 'mil/yr'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  if(activeUnitSystem === 'Metric') {
    damageRateUnits = 'mm/yr'
  }
  let actualThicknessBeliefPlot
  let actualThicknessStatesPlot
  let startingThicknessBeliefPlot
  let startingThicknessStatesPlot
  let coatingFailureTimeBeliefPlot
  let coatingFailureTimeStatesPlot
  let jacketingFailureTimeBeliefPlot
  let jacketingFailureTimeStatesPlot
  let initiationTimeBeliefPlot
  let initiationTimeStatesPlot
  let damageRateBeliefPlot
  let damageRateStatesPlot
  let failureTimeBeliefPlot
  let failureTimeStatesPlot
  if(includeInspections === 0) {
    startingThicknessBeliefPlot = results.dataTables.startingThicknessBelief.keywords[0].data
    startingThicknessStatesPlot = results.startingThicknessStates
    coatingFailureTimeBeliefPlot = results.dataTables.coatingFailureTimeBelief.keywords[0].data
    coatingFailureTimeStatesPlot = results.coatingFailureTimeStates
    jacketingFailureTimeBeliefPlot = results.dataTables.jacketingFailureTimeBelief.keywords[0].data
    jacketingFailureTimeStatesPlot = results.jacketingFailureTimeStates
    initiationTimeBeliefPlot = results.dataTables.initiationTimeBelief.keywords[0].data
    initiationTimeStatesPlot = results.initiationTimeStates
    damageRateBeliefPlot = results.dataTables.damageRateBelief.keywords[0].data
    damageRateStatesPlot = results.damageRateStates
    failureTimeBeliefPlot = results.dataTables.failureTimeBelief.keywords[0].data
    failureTimeStatesPlot = results.failureTimeStates
  } else if(includeInspections === 1) {
    startingThicknessBeliefPlot = results.dataTables.startingThicknessBeliefPrior.keywords[0].data
    startingThicknessStatesPlot = results.startingThicknessStatesPrior
    coatingFailureTimeBeliefPlot = results.dataTables.coatingFailureTimeBeliefPrior.keywords[0].data
    coatingFailureTimeStatesPlot = results.coatingFailureTimeStatesPrior
    jacketingFailureTimeBeliefPlot = results.dataTables.jacketingFailureTimeBeliefPrior.keywords[0].data
    jacketingFailureTimeStatesPlot = results.jacketingFailureTimeStatesPrior
    initiationTimeBeliefPlot = results.dataTables.initiationTimeBeliefPrior.keywords[0].data
    initiationTimeStatesPlot = results.initiationTimeStatesPrior
    damageRateBeliefPlot = results.dataTables.damageRateBeliefPrior.keywords[0].data
    damageRateStatesPlot = results.damageRateStatesPrior
    failureTimeBeliefPlot = results.dataTables.failureTimeBeliefPrior.keywords[0].data
    failureTimeStatesPlot = results.failureTimeStatesPrior
  } else if(includeInspections === 2) {
    actualThicknessBeliefPlot = results.dataTables.actualThicknessBelief.keywords[0].data
    actualThicknessStatesPlot = results.actualThicknessStates
    coatingFailureTimeBeliefPlot = results.dataTables.coatingFailureTimeBelief.keywords[0].data
    coatingFailureTimeStatesPlot = results.coatingFailureTimeStates
    jacketingFailureTimeBeliefPlot = results.dataTables.jacketingFailureTimeBelief.keywords[0].data
    jacketingFailureTimeStatesPlot = results.jacketingFailureTimeStates
    initiationTimeBeliefPlot = results.dataTables.initiationTimeBelief.keywords[0].data
    initiationTimeStatesPlot = results.initiationTimeStates
    damageRateBeliefPlot = results.dataTables.damageRateBelief.keywords[0].data
    damageRateStatesPlot = results.damageRateStates
    failureTimeBeliefPlot = results.dataTables.failureTimeBelief.keywords[0].data
    failureTimeStatesPlot = results.failureTimeStates
  }
  const tracesProfileData1 = []
  const tracesProfileData2 = []
  const tracesProfileData3 = []
  const tracesProfileData4 = []
  const tracesProfileData5 = []
  const tracesProfileData6 = []
  if(includeInspections !== 2) {
    tracesProfileData1.push({
      x: startingThicknessStatesPlot,
      y: startingThicknessBeliefPlot,
      type: 'bar',
    })
  } else {
    tracesProfileData1.push({
      x: actualThicknessStatesPlot,
      y: actualThicknessBeliefPlot,
      type: 'bar',
    })
  }
  tracesProfileData2.push({
    x: coatingFailureTimeStatesPlot,
    y: coatingFailureTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData3.push({
    x: jacketingFailureTimeStatesPlot,
    y: jacketingFailureTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData4.push({
    x: initiationTimeStatesPlot,
    y: initiationTimeBeliefPlot,
    type: 'bar',
  })
  tracesProfileData5.push({
    x: damageRateStatesPlot,
    y: damageRateBeliefPlot,
    type: 'bar',
  })
  tracesProfileData6.push({
    x: failureTimeStatesPlot,
    y: failureTimeBeliefPlot,
    type: 'bar',
  })
  const graphs = {}
  if(includeInspections !== 2) {
    graphs.Graph1 = {
      traces: tracesProfileData1,
      layout: {
        title: 'Starting Thickness',
        xaxis: {
          title: `Thickness (${thicknessUnits})`,
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  } else {
    graphs.Graph1 = {
      traces: tracesProfileData1,
      layout: {
        title: 'Actual Thickness',
        xaxis: {
          title: `Thickness (${thicknessUnits})`,
        },
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  graphs.Graph2 = {
    traces: tracesProfileData2,
    layout: {
      title: 'Coating Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph3 = {
    traces: tracesProfileData3,
    layout: {
      title: 'Jacketing Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph4 = {
    traces: tracesProfileData4,
    layout: {
      title: 'Component Initiation Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph5 = {
    traces: tracesProfileData5,
    layout: {
      title: 'Component Damage Rate',
      xaxis: {
        title: `Rate (${damageRateUnits})`,
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph6 = {
    traces: tracesProfileData6,
    layout: {
      title: 'Component Failure Time',
      xaxis: {
        title: 'Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  if(decisionsStrategy === '1') {
    const failureDecisionsBelief = results.dataTables.failureDecisionsBelief.keywords[0].data
    const failureDecisionsStates = results.dataTables.failureDecisionsStates.keywords[0].data
    const tracesProfileData7 = []
    tracesProfileData7.push({
      x: ['Yes', 'No'],
      y: failureDecisionsBelief,
      type: 'bar',
    })
    graphs.Graph7 = {
      traces: tracesProfileData7,
      layout: {
        title: 'Failure',
        yaxis: {
          title: 'Probability',
        },
      },
    }
  }
  return graphs
}

