/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

import { getNextTableDataNDE } from '../../lib/tableFunctions'

function Step5({ problemType, ndeTable, ndeTableSS, inspectionMaintenanceTable, inspectionInputType, ndeMeasurementErrorType, measurementErrorThickness, materialOfConstruction, ndeCrackSeverity, measuredFlawDepth, coatingConditionNDE, ndeCorrosionSeverity, priorNdeInspection, lastNdeInspectionTime, measuredThickness, measurementError, measurementErrorConfidence, coatingFailureNDE, materialOfConstructionHybrid, probabilisticInputFieldFlag, ndeCorrosionSeverityProb, coatingConditionNDEProb, measurementErrorProb, measurementErrorThicknessProb }) {
  let valCheck
  if(inspectionInputType === 'manyTable') {
    valCheck = getNextTableDataNDE(inspectionMaintenanceTable, ndeTable, true, false)
  }
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        {inspectionInputType === 'manyTable'
          ? (
            <div>
              {valCheck !== 0
                ? (
                  <div>
                    {problemType === 'PROB1'
                      ? (
                        <div>
                          {materialOfConstruction !== 'SS'
                            ? (
                              <div>
                                <DataTableField
                                  id="ndeTable"
                                  name="ndeTable"
                                  value={getNextTableDataNDE(inspectionMaintenanceTable, ndeTable, false, false)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'NDE Component<br/>Corrosion<br/>Severity?',
                                      'NDE Coating<br/>Damage<br/>Severity?',
                                      'Measured<br/>Thickness (in)',
                                      'NDE Measurement<br/>Error Type',
                                      'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mil)',
                                      'NDE Measurement<br/>Error Confidence',
                                    ],
                                    Metric: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'NDE Component<br/>Corrosion<br/>Severity?',
                                      'NDE Coating<br/>Damage<br/>Severity?',
                                      'Measured<br/>Thickness (mm)',
                                      'NDE Measurement<br/>Error Type',
                                      'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mm)',
                                      'NDE Measurement<br/>Error Confidence',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                          {materialOfConstruction === 'SS'
                            ? (
                              <div>
                                <DataTableField
                                  id="ndeTableSS"
                                  name="ndeTableSS"
                                  value={getNextTableDataNDE(inspectionMaintenanceTable, ndeTableSS, false, true)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'NDE Component<br/>Corrosion<br/>Severity?',
                                      'NDE Coating<br/>Damage<br/>Severity?',
                                      'NDE Crack<br/>Severity',
                                      'NDE Measured<br/>Flaw Depth (in)',
                                      'Measured<br/>Thickness (in)',
                                      'NDE Measurement<br/>Error Type',
                                      'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mil)',
                                      'NDE Measurement<br/>Error Confidence',
                                    ],
                                    Metric: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'NDE Component<br/>Corrosion<br/>Severity?',
                                      'NDE Coating<br/>Damage<br/>Severity?',
                                      'NDE Crack<br/>Severity',
                                      'NDE Measured<br/>Flaw Depth (mm)',
                                      'Measured<br/>Thickness (mm)',
                                      'NDE Measurement<br/>Error Type',
                                      'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mm)',
                                      'NDE Measurement<br/>Error Confidence',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB3'
                      ? (
                        <div>
                          {materialOfConstructionHybrid !== 'SS'
                            ? (
                              <div>
                                <DataTableField
                                  id="ndeTable"
                                  name="ndeTable"
                                  value={getNextTableDataNDE(inspectionMaintenanceTable, ndeTable, false, false)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'NDE Component<br/>Corrosion<br/>Severity?',
                                      'NDE Coating<br/>Damage<br/>Severity?',
                                      'Measured<br/>Thickness (in)',
                                      'NDE Measurement<br/>Error Type',
                                      'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mil)',
                                      'NDE Measurement<br/>Error Confidence',
                                    ],
                                    Metric: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'NDE Component<br/>Corrosion<br/>Severity?',
                                      'NDE Coating<br/>Damage<br/>Severity?',
                                      'Measured<br/>Thickness (mm)',
                                      'NDE Measurement<br/>Error Type',
                                      'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mm)',
                                      'NDE Measurement<br/>Error Confidence',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                          {materialOfConstructionHybrid === 'SS'
                            ? (
                              <div>
                                <DataTableField
                                  id="ndeTableSS"
                                  name="ndeTableSS"
                                  value={getNextTableDataNDE(inspectionMaintenanceTable, ndeTableSS, false, true)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'NDE Component<br/>Corrosion<br/>Severity?',
                                      'NDE Coating<br/>Damage<br/>Severity?',
                                      'NDE Crack<br/>Severity',
                                      'NDE Measured<br/>Flaw Depth (in)',
                                      'Measured<br/>Thickness (in)',
                                      'NDE Measurement<br/>Error Type',
                                      'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mil)',
                                      'NDE Measurement<br/>Error Confidence',
                                    ],
                                    Metric: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'NDE Component<br/>Corrosion<br/>Severity?',
                                      'NDE Coating<br/>Damage<br/>Severity?',
                                      'NDE Crack<br/>Severity',
                                      'NDE Measured<br/>Flaw Depth (mm)',
                                      'Measured<br/>Thickness (mm)',
                                      'NDE Measurement<br/>Error Type',
                                      'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mm)',
                                      'NDE Measurement<br/>Error Confidence',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB2'
                      ? (
                        <div>
                          <DataTableField
                            id="ndeTable"
                            name="ndeTable"
                            value={getNextTableDataNDE(inspectionMaintenanceTable, ndeTable, false, false)}
                            startRows={4}
                            rowHeaders={false}
                            colHeaders={{
                              USCustomary: [
                                'Inspection Date<br/>(MM/DD/YYYY) ',
                                'NDE Component<br/>Corrosion<br/>Severity?',
                                'NDE Coating<br/>Damage<br/>Severity?',
                                'Measured<br/>Thickness (in)',
                                'NDE Measurement<br/>Error Type',
                                'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mil)',
                                'NDE Measurement<br/>Error Confidence',
                              ],
                              Metric: [
                                'Inspection Date<br/>(MM/DD/YYYY) ',
                                'NDE Component<br/>Corrosion<br/>Severity?',
                                'NDE Coating<br/>Damage<br/>Severity?',
                                'Measured<br/>Thickness (mm)',
                                'NDE Measurement<br/>Error Type',
                                'NDE Measurement<br/>Error Percent (%)<br/>or Thickness(mm)',
                                'NDE Measurement<br/>Error Confidence',
                              ],
                            }}
                          />
                        </div>
                      )
                      : ''}
                  </div>
                )
                : 'No NDE inspection performed based on prior main inspection table'}
            </div>
          )
          : ''}
        {inspectionInputType === 'lastSingle'
          ? (
            <div>
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>NDE involves a variety of techniques used to evaluate the properties of a material, component, or system without causing any damage. These methods are crucial for ensuring the integrity and reliability of the component.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="priorNdeInspection" value={priorNdeInspection} label="Prior NDE Inspection?">
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {priorNdeInspection === 'Yes' // NDE
                ? (
                  <div>
                    {problemType === 'PROB1'
                      ? (
                        <div>
                          <div className="fl w-third-l w-50-m w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Time at which the NDE inspection was performed since the analysis date.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="lastNdeInspectionTime" value={lastNdeInspectionTime} label="Last NDE Inspection Time" type="number" required />
                            {/* </E2GInfoButton> */}
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The degree of corrosion found at the component level after performing the NDE inspection.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="ndeCorrosionSeverity" value={ndeCorrosionSeverity} label="NDE Component Corrosion Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                            {materialOfConstruction === 'SS'
                              ? (
                                // <E2GInfoButton
                                //   tooltip={<div>
                                //     <p>The degree of cracking/pitting found after performing the NDE inspection.</p>
                                //   </div>}
                                //   dialog
                                // >
                                <E2GSelectField name="ndeCrackSeverity" value={ndeCrackSeverity} label="NDE Crack Severity?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                  <E2GSelectFieldOption label="Minor" value="Minor" />
                                  <E2GSelectFieldOption label="Major" value="Major" />
                                  <E2GSelectFieldOption label="Severe" value="Severe" />
                                </E2GSelectField>
                                // </E2GInfoButton>
                              )
                              : ''}
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The degree of corrosion found at the coating level after performing the NDE inspection.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="coatingConditionNDE" value={coatingConditionNDE} label="NDE Coating Damage Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>
                          <div className="fl w-third-l w-50-m w-100">
                            {materialOfConstruction === 'SS'
                              ? (
                                // <E2GInfoButton
                                //   tooltip={<div>
                                //     <p>Measured flaw depth refers to the depth of the defect or imperfection within a material found during the NDE inspection.</p>
                                //   </div>}
                                //   dialog
                                // >
                                <E2GTextField name="measuredFlawDepth" value={measuredFlawDepth} label="Measured Flaw Depth" type="number" required />
                                // </E2GInfoButton>
                              )
                              : ''}
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The measured thickness is the thickness identified during the NDE inspection. Note that a measured thickness that is the same as the nominal thickness indicates no metal loss (i.e., corrosion).</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="measuredThickness" value={measuredThickness} label="Measured Thickness" type="number" required />
                            {/* </E2GInfoButton> */}
                            <E2GSelectField name="ndeMeasurementErrorType" value={ndeMeasurementErrorType} label="NDE Measurement Error Type">
                              <E2GSelectFieldOption label="Percent of Thickness" value="percent" />
                              <E2GSelectFieldOption label="Specified Thickness" value="specified" />
                            </E2GSelectField>
                            {ndeMeasurementErrorType === 'percent'
                              ? (
                                <E2GTextField name="measurementError" value={measurementError} label="NDE Measurement Error" type="number" required />
                              )
                              : ''}
                            {ndeMeasurementErrorType === 'specified'
                              ? (
                                <E2GTextField name="measurementErrorThickness" value={measurementErrorThickness} label="NDE Measurement Error" type="number" required />
                              )
                              : ''}
                            <E2GSelectField name="measurementErrorConfidence" value={measurementErrorConfidence} label="NDE Measurement Error Confidence">
                              <E2GSelectFieldOption label="Low" value="Low" />
                              <E2GSelectFieldOption label="Moderate" value="Moderate" />
                              <E2GSelectFieldOption label="High" value="High" />
                            </E2GSelectField>
                          </div>
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB3'
                      ? (
                        <div>
                          {probabilisticInputFieldFlag === false
                            ? (
                              <div>
                                <div className="fl w-third-l w-50-m w-100">
                                  {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>Time at which the NDE inspection was performed since the analysis date.</p>
                                    </div>}
                                    dialog
                                  > */}
                                  <E2GTextField name="lastNdeInspectionTime" value={lastNdeInspectionTime} label="Last NDE Inspection Time" type="number" required />
                                  {/* </E2GInfoButton> */}
                                  {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>The degree of corrosion found at the component level after performing the NDE inspection.</p>
                                    </div>}
                                    dialog
                                  > */}
                                  <E2GSelectField name="ndeCorrosionSeverity" value={ndeCorrosionSeverity} label="NDE Component Corrosion Severity?">
                                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                                    <E2GSelectFieldOption label="None" value="None" />
                                    <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                    <E2GSelectFieldOption label="Minor" value="Minor" />
                                    <E2GSelectFieldOption label="Major" value="Major" />
                                    <E2GSelectFieldOption label="Severe" value="Severe" />
                                  </E2GSelectField>
                                  {/* </E2GInfoButton> */}
                                  {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>The degree of corrosion found at the coating level after performing the NDE inspection.</p>
                                    </div>}
                                    dialog
                                  > */}
                                  <E2GSelectField name="coatingConditionNDE" value={coatingConditionNDE} label="NDE Coating Damage Severity?">
                                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                                    <E2GSelectFieldOption label="None" value="None" />
                                    <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                    <E2GSelectFieldOption label="Minor" value="Minor" />
                                    <E2GSelectFieldOption label="Major" value="Major" />
                                    <E2GSelectFieldOption label="Severe" value="Severe" />
                                  </E2GSelectField>
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="fl w-third-l w-50-m w-100">
                                  {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>The measured thickness is the thickness identified during the NDE inspection. Note that a measured thickness that is the same as the nominal thickness indicates no metal loss (i.e., corrosion).</p>
                                    </div>}
                                    dialog
                                  > */}
                                  <E2GTextField name="measuredThickness" value={measuredThickness} label="Measured Thickness" type="number" required />
                                  {/* </E2GInfoButton> */}
                                  <E2GSelectField name="ndeMeasurementErrorType" value={ndeMeasurementErrorType} label="NDE Measurement Error Type">
                                    <E2GSelectFieldOption label="Percent of Thickness" value="percent" />
                                    <E2GSelectFieldOption label="Specified Thickness" value="specified" />
                                  </E2GSelectField>
                                  {ndeMeasurementErrorType === 'percent'
                                    ? (
                                      <E2GTextField name="measurementError" value={measurementError} label="NDE Measurement Error" type="number" required />
                                    )
                                    : ''}
                                  {ndeMeasurementErrorType === 'specified'
                                    ? (
                                      <E2GTextField name="measurementErrorThickness" value={measurementErrorThickness} label="NDE Measurement Error" type="number" required />
                                    )
                                    : ''}
                                  <E2GSelectField name="measurementErrorConfidence" value={measurementErrorConfidence} label="NDE Measurement Error Confidence">
                                    <E2GSelectFieldOption label="Low" value="Low" />
                                    <E2GSelectFieldOption label="Moderate" value="Moderate" />
                                    <E2GSelectFieldOption label="High" value="High" />
                                  </E2GSelectField>
                                </div>
                              </div>
                            )
                            : ''}
                          {probabilisticInputFieldFlag === true
                            ? (
                              <div>
                                <div className="fl w-third-l w-50-m w-100">
                                  {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>Time at which the NDE inspection was performed since the analysis date.</p>
                                    </div>}
                                    dialog
                                  > */}
                                  <E2GTextField name="lastNdeInspectionTime" value={lastNdeInspectionTime} label="Last NDE Inspection Time" type="number" required />
                                  {/* </E2GInfoButton> */}
                                  {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>The measured thickness is the thickness identified during the NDE inspection. Note that a measured thickness that is the same as the nominal thickness indicates no metal loss (i.e., corrosion).</p>
                                    </div>}
                                    dialog
                                  > */}
                                  <E2GTextField name="measuredThickness" value={measuredThickness} label="Measured Thickness" type="number" required />
                                  {/* </E2GInfoButton> */}
                                  <E2GSelectField name="ndeMeasurementErrorType" value={ndeMeasurementErrorType} label="NDE Measurement Error Type">
                                    <E2GSelectFieldOption label="Percent of Thickness" value="percent" />
                                    <E2GSelectFieldOption label="Specified Thickness" value="specified" />
                                  </E2GSelectField>
                                  {ndeMeasurementErrorType === 'percent'
                                    ? (
                                      <div className="pt3">
                                        <ProbabilisticInputField
                                          name="measurementErrorProb"
                                          jsonEditable
                                          value={measurementErrorProb}
                                          variableType="continuous"
                                          InputProps={{
                                            label: 'NDE Measurement Error (50th Percentile)',
                                            jsonKeyPath: 'median',
                                            units: {
                                              USCustomary: '%',
                                              Metric: '%',
                                            },
                                            isNumber: false,
                                            required: true,
                                          }}
                                          DialogProps={{
                                            inputFields: [{
                                              label: 'Distribution Type',
                                              jsonKeyPath: 'distributionType',
                                              select: true,
                                              options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                            }, {
                                              label: 'Variance Type',
                                              jsonKeyPath: 'varianceType',
                                              select: true,
                                              options(parsedJson) {
                                                if(parsedJson.distributionType === 'normal') {
                                                  return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                                }
                                                return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                              },
                                            }, {
                                              label: 'Standard Deviation',
                                              jsonKeyPath: 'standardDeviation',
                                              isNumber: true,
                                              units: {
                                                USCustomary: '%',
                                                Metric: '%',
                                              },
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'standardDeviation'
                                              },
                                            }, {
                                              label: 'Upper Bound',
                                              jsonKeyPath: 'upperBound',
                                              isNumber: true,
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'upperBound'
                                              },
                                              units: {
                                                USCustomary: '%',
                                                Metric: '%',
                                              },
                                            }, {
                                              label: 'Upper Bound Probability',
                                              jsonKeyPath: 'upperBoundProbability',
                                              isNumber: true,
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'upperBound'
                                              },
                                              units: {
                                                USCustomary: '%',
                                                Metric: '%',
                                              },
                                            }, {
                                              label: 'Scale Parameter',
                                              jsonKeyPath: 'scale',
                                              isNumber: true,
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'scale'
                                              },
                                            }, {
                                              label: 'CV (SD/Mean)',
                                              jsonKeyPath: 'cv',
                                              isNumber: true,
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'cv'
                                              },
                                            }],
                                          }}
                                        />
                                      </div>
                                    )
                                    : ''}
                                  {ndeMeasurementErrorType === 'specified'
                                    ? (
                                      <div className="pt3">
                                        <ProbabilisticInputField
                                          name="measurementErrorThicknessProb"
                                          jsonEditable
                                          value={measurementErrorThicknessProb}
                                          variableType="continuous"
                                          InputProps={{
                                            label: 'NDE Measurement Error (50th Percentile)',
                                            jsonKeyPath: 'median',
                                            units: {
                                              USCustomary: 'mil',
                                              Metric: 'mm',
                                            },
                                            isNumber: false,
                                            required: true,
                                          }}
                                          DialogProps={{
                                            inputFields: [{
                                              label: 'Distribution Type',
                                              jsonKeyPath: 'distributionType',
                                              select: true,
                                              options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                            }, {
                                              label: 'Variance Type',
                                              jsonKeyPath: 'varianceType',
                                              select: true,
                                              options(parsedJson) {
                                                if(parsedJson.distributionType === 'normal') {
                                                  return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                                }
                                                return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                              },
                                            }, {
                                              label: 'Standard Deviation',
                                              jsonKeyPath: 'standardDeviation',
                                              isNumber: true,
                                              units: {
                                                USCustomary: 'mil',
                                                Metric: 'mm',
                                              },
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'standardDeviation'
                                              },
                                            }, {
                                              label: 'Upper Bound',
                                              jsonKeyPath: 'upperBound',
                                              isNumber: true,
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'upperBound'
                                              },
                                              units: {
                                                USCustomary: 'mil',
                                                Metric: 'mm',
                                              },
                                            }, {
                                              label: 'Upper Bound Probability',
                                              jsonKeyPath: 'upperBoundProbability',
                                              isNumber: true,
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'upperBound'
                                              },
                                              units: {
                                                USCustomary: '%',
                                                Metric: '%',
                                              },
                                            }, {
                                              label: 'Scale Parameter',
                                              jsonKeyPath: 'scale',
                                              isNumber: true,
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'scale'
                                              },
                                            }, {
                                              label: 'CV (SD/Mean)',
                                              jsonKeyPath: 'cv',
                                              isNumber: true,
                                              hide(parsedJson) {
                                                return parsedJson.varianceType !== 'cv'
                                              },
                                            }],
                                          }}
                                        />
                                      </div>
                                    )
                                    : ''}
                                  <div className="pt3">
                                    {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>The degree of corrosion found at the component level after performing the NDE inspection.</p>
                                    </div>}
                                    dialog
                                  > */}
                                    <ProbabilisticInputField
                                      name="ndeCorrosionSeverityProb"
                                      jsonEditable
                                      value={ndeCorrosionSeverityProb}
                                      variableType="discrete"
                                      InputProps={{
                                        label: 'NDE Component Corrosion Severity?',
                                        jsonKeyPath: 'median',
                                        isNumber: false,
                                        required: true,
                                      }}
                                    />
                                    {/* </E2GInfoButton> */}
                                  </div>
                                  <div className="pt3">
                                    {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>The degree of corrosion found at the coating level after performing the NDE inspection.</p>
                                    </div>}
                                    dialog
                                  > */}
                                    <ProbabilisticInputField
                                      name="coatingConditionNDEProb"
                                      jsonEditable
                                      value={coatingConditionNDEProb}
                                      variableType="discrete"
                                      InputProps={{
                                        label: 'NDE Coating Damage Severity?',
                                        jsonKeyPath: 'median',
                                        isNumber: false,
                                        required: true,
                                      }}
                                    />
                                    {/* </E2GInfoButton> */}
                                  </div>
                                </div>
                              </div>
                            )
                            : ''}
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB2'
                      ? (
                        <div>
                          <div className="fl w-third-l w-50-m w-100">
                            <E2GTextField name="lastNdeInspectionTime" value={lastNdeInspectionTime} label="Last NDE Inspection Time" type="number" required />
                            <E2GSelectField name="ndeCorrosionSeverity" value={ndeCorrosionSeverity} label="NDE Component Corrosion Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                            <E2GSelectField name="coatingConditionNDE" value={coatingConditionNDE} label="NDE Coating Damage Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                          </div>
                          <div className="fl w-third-l w-50-m w-100">
                            <E2GTextField name="measuredThickness" value={measuredThickness} label="Measured Thickness" type="number" required />
                            <E2GSelectField name="ndeMeasurementErrorType" value={ndeMeasurementErrorType} label="NDE Measurement Error Type">
                              <E2GSelectFieldOption label="Percent of Thickness" value="percent" />
                              <E2GSelectFieldOption label="Specified Thickness" value="specified" />
                            </E2GSelectField>
                            {ndeMeasurementErrorType === 'percent'
                              ? (
                                <E2GTextField name="measurementError" value={measurementError} label="NDE Measurement Error" type="number" required />
                              )
                              : ''}
                            {ndeMeasurementErrorType === 'specified'
                              ? (
                                <E2GTextField name="measurementErrorThickness" value={measurementErrorThickness} label="NDE Measurement Error" type="number" required />
                              )
                              : ''}
                            <E2GSelectField name="measurementErrorConfidence" value={measurementErrorConfidence} label="NDE Measurement Error Confidence">
                              <E2GSelectFieldOption label="Low" value="Low" />
                              <E2GSelectFieldOption label="Moderate" value="Moderate" />
                              <E2GSelectFieldOption label="High" value="High" />
                            </E2GSelectField>
                          </div>
                        </div>
                      )
                      : ''}
                  </div>
                )
                : ''}
            </div>
          )
          : ''}
      </div>
      <Submit label="Next" />
    </div>
  )
}

Step5.propTypes = {
  problemType: PropTypes.string,
  priorNdeInspection: PropTypes.string,
  lastNdeInspectionTime: PropTypes.number,
  ndeCorrosionSeverity: PropTypes.string,
  measuredThickness: PropTypes.number,
  measurementError: PropTypes.number,
  measurementErrorConfidence: PropTypes.string,
  coatingFailureNDE: PropTypes.string,
  coatingConditionNDE: PropTypes.string,
  ndeCrackSeverity: PropTypes.string,
  measuredFlawDepth: PropTypes.number,
  materialOfConstruction: PropTypes.string,
  materialOfConstructionHybrid: PropTypes.string,
  ndeMeasurementErrorType: PropTypes.string,
  measurementErrorThickness: PropTypes.number,
  inspectionInputType: PropTypes.string,
  inspectionMaintenanceTable: PropTypes.array,
  ndeTable: PropTypes.array,
  ndeTableSS: PropTypes.array,
  probabilisticInputFieldFlag: PropTypes.bool,
  ndeCorrosionSeverityProb: PropTypes.string,
  coatingConditionNDEProb: PropTypes.string,
  measurementErrorProb: PropTypes.string,
  measurementErrorThicknessProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  priorNdeInspection: state.workflow.fields.priorNdeInspection,
  lastNdeInspectionTime: state.workflow.fields.lastNdeInspectionTime,
  ndeCorrosionSeverity: state.workflow.fields.ndeCorrosionSeverity,
  measuredThickness: state.workflow.fields.measuredThickness,
  measurementError: state.workflow.fields.measurementError,
  measurementErrorConfidence: state.workflow.fields.measurementErrorConfidence,
  coatingFailureNDE: state.workflow.fields.coatingFailureNDE,
  coatingConditionNDE: state.workflow.fields.coatingConditionNDE,
  ndeCrackSeverity: state.workflow.fields.ndeCrackSeverity,
  measuredFlawDepth: state.workflow.fields.measuredFlawDepth,
  materialOfConstruction: state.workflow.fields.materialOfConstruction,
  materialOfConstructionHybrid: state.workflow.fields.materialOfConstructionHybrid,
  ndeMeasurementErrorType: state.workflow.fields.ndeMeasurementErrorType,
  measurementErrorThickness: state.workflow.fields.measurementErrorThickness,
  inspectionInputType: state.workflow.fields.inspectionInputType,
  inspectionMaintenanceTable: state.workflow.fields.inspectionMaintenanceTable,
  ndeTable: state.workflow.fields.ndeTable,
  ndeTableSS: state.workflow.fields.ndeTableSS,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  ndeCorrosionSeverityProb: state.workflow.fields.ndeCorrosionSeverityProb,
  coatingConditionNDEProb: state.workflow.fields.coatingConditionNDEProb,
  measurementErrorProb: state.workflow.fields.measurementErrorProb,
  measurementErrorThicknessProb: state.workflow.fields.measurementErrorThicknessProb,
})

export default connect(mapStateToProps)(Step5)
