import React from 'react'

import Step1 from './Step1'
import Step2 from './Step2'

export default [
  {
    name: 'Step1',
    title: 'Jacketing and Coating Failure Time',
    content: (
      <Step1 />
    ),
  },
  {
    name: 'Step2',
    title: 'Damage Rate',
    content: (
      <Step2 />
    ),
  },
]
