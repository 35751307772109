import React from 'react'
import { connect } from 'react-redux'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'

const BeforeInspectionMainResults = ({ results, style, problemType, componentAgeVsInstallDateBool, failureTimeXInterpProbAfterInspections, graphs }) => {
  let graphsToDisplay
  if(typeof results.poppedJsonOutComponentFailureTime !== 'undefined') {
    graphsToDisplay = pick(graphs, ['graphTemp1', 'failureTimeCDF'])
  }
  return (
    <div className="cf w-100">
      <div className="fl w-25-l w-50-m w-100">
        <E2GTextField name="coatingFailureTimeEVPrior" style={style} value={results.coatingFailureTimeEVPrior} label="Coating Failure Time Mean" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingMissingFailureTimeEVPrior" style={style} value={results.jacketingMissingFailureTimeEVPrior} label="Missing Jacketing Failure Time Mean" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingSealantFailureTimeEVPrior" style={style} value={results.jacketingSealantFailureTimeEVPrior} label="Jacketing Sealant Failure Time Mean" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingCorrosionFailureTimeEVPrior" style={style} value={results.jacketingCorrosionFailureTimeEVPrior} label="Jacketing Corrosion Failure Time Mean" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingDesignFailureTimeEVPrior" style={style} value={results.jacketingDesignFailureTimeEVPrior} label="Jacketing Design Failure Time Mean" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingFailureTimeEVPrior" style={style} value={results.jacketingFailureTimeEVPrior} label="Jacketing Failure Time Mean" type="number" textWidth="90%" disabled />
        <E2GTextField name="initiationTimeEVPrior" style={style} value={results.initiationTimeEVPrior} label="Component Initiation Time Mean" type="number" textWidth="90%" disabled />
        <E2GTextField name="damageRateEVPrior" style={style} value={results.damageRateEVPrior} label="Component Damage Rate Mean" type="number" textWidth="90%" disabled />
        <E2GTextField name="failureTimeEVPrior" style={style} value={results.failureTimeEVPrior} label="Component Failure Time Mean" type="number" textWidth="90%" disabled />
        {componentAgeVsInstallDateBool === true
          ? (
            <div>
              <E2GTextField name="pofBeforeJacketing" style={style} value={results.pofBeforeJacketing} label="Jacketing POF at Component Age" type="number" textWidth="90%" disabled />
              <E2GTextField name="pofBefore" style={style} value={results.pofBefore} label="Component POF at Component Age" type="number" textWidth="90%" disabled />
            </div>
          )
          : <div>
            <E2GTextField name="pofBeforeJacketing" style={style} value={results.pofBeforeJacketing} label="Jacketing POF at Analysis Date" type="number" textWidth="90%" disabled />
            <E2GTextField name="pofBefore" style={style} value={results.pofBefore} label="Component POF at Analysis Date" type="number" textWidth="90%" disabled />
          </div>}
      </div>
      <div className="fl w-25-l w-50-m w-100">
        <E2GTextField name="coatingFailureTimeSDPrior" style={style} value={results.coatingFailureTimeSDPrior} label="Coating Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingMissingFailureTimeSDPrior" style={style} value={results.jacketingMissingFailureTimeSDPrior} label="Missing Jacketing Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingSealantFailureTimeSDPrior" style={style} value={results.jacketingSealantFailureTimeSDPrior} label="Jacketing Sealant Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingCorrosionFailureTimeSDPrior" style={style} value={results.jacketingCorrosionFailureTimeSDPrior} label="Jacketing Corrosion Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingDesignFailureTimeSDPrior" style={style} value={results.jacketingDesignFailureTimeSDPrior} label="Jacketing Design Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
        <E2GTextField name="jacketingFailureTimeSDPrior" style={style} value={results.jacketingFailureTimeSDPrior} label="Jacketing Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
        <E2GTextField name="initiationTimeSDPrior" style={style} value={results.initiationTimeSDPrior} label="Component Initiation Time Standard Deviation" type="number" textWidth="90%" disabled />
        <E2GTextField name="damageRateSDPrior" style={style} value={results.damageRateSDPrior} label="Component Damage Rate Standard Deviation" type="number" textWidth="90%" disabled />
        <E2GTextField name="failureTimeSDPrior" style={style} value={results.failureTimeSDPrior} label="Component Failure Time Standard Deviation" type="number" textWidth="90%" disabled />
        {componentAgeVsInstallDateBool === true
          ? (
            <E2GTextField name="pofBeforeCoating" style={style} value={results.pofBeforeCoating} label="Coating POF at Component Age" type="number" textWidth="90%" disabled />
          )
          : <E2GTextField name="pofBeforeCoating" style={style} value={results.pofBeforeCoating} label="Coating POF at Analysis Date" type="number" textWidth="90%" disabled />}
      </div>
      <div className="fl w-50-l w-50-m w-100">
        <div className="fl w-100-l w-100-m w-100">
          {graphs
            ? <DataGraphDisplay plotClass="data-graph-75" graphs={graphsToDisplay} />
            : ''}
        </div>
      </div>
      <div className="fl w-100-l w-100-m w-100">
        <div className="fl w-50-nl w-100">
          {results.whichTable[0] === 'afterInspections'
            ? (
              <div>
                <DataTableDisplay
                  id="failureTimeXInterpProbAfterInspections"
                  table={failureTimeXInterpProbAfterInspections}
                  colHeaders={{
                    USCustomary: [
                      'Inspection',
                      '50th</br>Percentile</br>Failure</br>Time (yrs)',
                      '90th</br>Percentile</br>Failure</br>Time (yrs)',
                      '95th</br>Percentile</br>Failure</br>Time (yrs)',
                      '99th</br>Percentile</br>Failure</br>Time (yrs)',
                    ],
                    Metric: [
                      'Inspection',
                      '50th</br>Percentile</br>Failure</br>Time (yrs)',
                      '90th</br>Percentile</br>Failure</br>Time (yrs)',
                      '95th</br>Percentile</br>Failure</br>Time (yrs)',
                      '99th</br>Percentile</br>Failure</br>Time (yrs)',
                    ],
                  }}
                />
              </div>
            )
            : ''}
        </div>
      </div>

    </div>
  )
}

BeforeInspectionMainResults.propTypes = {
  results: PropTypes.object,
  style: PropTypes.object,
  problemType: PropTypes.string,
  componentAgeVsInstallDateBool: PropTypes.bool,
  failureTimeXInterpProbAfterInspections: PropTypes.object,
  graphs: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  style: state.ui.style,
  problemType: state.workflow.fields.problemType,
  componentAgeVsInstallDateBool: state.workflow.fields.componentAgeVsInstallDateBool,
  failureTimeXInterpProbAfterInspections: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.failureTimeXInterpProbAfterInspections : undefined,
  graphs: state.workflow.response.results ? state.workflow.response.results.graphs : undefined,
})
export default connect(mapStateToProps, null)(BeforeInspectionMainResults)
