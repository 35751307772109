import React from 'react'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'
import Step6 from './Step6'
import Step7 from './Step7'
import subSteps0 from './subSteps0'
import subSteps0b from './subSteps0b'
import subSteps1 from './subSteps1'
import subSteps1b from './subSteps1b'
import subSteps2 from './subSteps2'
import subSteps3 from './subSteps3'

export default [
  {
    name: 'Step1',
    title: 'Component Design',
    content: (
      <Step1 />
    ),
  },
  {
    name: 'Step2',
    title: 'Component Operating Conditions',
    content: (
      <Step2 />
    ),
    subSteps: [subSteps0, subSteps0b],
  },
  {
    name: 'Step3',
    title: 'Insulation System Design',
    content: (
      <Step3 />
    ),
    subSteps: [subSteps1, subSteps1b],
  },
  {
    name: 'Step4',
    title: 'Expert Priors',
    content: (
      <Step4 />
    ),
    subSteps: [subSteps2],
  },
  {
    name: 'Step5',
    title: ' Inspections',
    content: (
      <Step5 />
    ),
    subSteps: [subSteps3],
  },
  {
    name: 'Step6',
    title: ' Decision Parameters',
    content: (
      <Step6 />
    ),
  },
  {
    name: 'Step7',
    title: 'Calculation Results',
    content: (
      <Step7 />
    ),
  },
]
