import React from 'react'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'
import Step5 from './Step5'

export default [
  {
    name: 'Step1',
    title: 'Single or Multiple',
    content: (
      <Step1 />
    ),
  },
  {
    name: 'Step2',
    title: 'Past Maintenance',
    content: (
      <Step2 />
    ),
  },
  {
    name: 'Step3',
    title: 'VTE',
    content: (
      <Step3 />
    ),
  },
  {
    name: 'Step4',
    title: 'VTI',
    content: (
      <Step4 />
    ),
  },
  {
    name: 'Step5',
    title: 'NDE',
    content: (
      <Step5 />
    ),
  },
]
