export function plotResultsCDF(results) {
  const inspectionTimesPlot = results.dataTables.inspectionTimes.keywords[0].data
  const failureCDFTracePlot = results.dataTables.failureCDFTraceBefore.keywords[0].data
  const cdfTimePlot = results.dataTables.cdfTime.keywords[0].data
  const cdfTimeMinMaxPlot = results.dataTables.cdfTimeMinMax.keywords[0].data

  const cdfTraceJsonAll = JSON.parse(results.cdfTraceJson[0])
  const cdfTimeJsonAll = JSON.parse(results.cdfTimeJson[0])
  const cdfTimeMiMaxJsonAll = JSON.parse(results.cdfTimeMiMaxJson[0])
  const cdfTraceJsonKeys = Object.keys(cdfTraceJsonAll)
  const cdfTimeJsonKeys = Object.keys(cdfTimeJsonAll)
  const cdfTimeMiMaxJsonKeys = Object.keys(cdfTimeMiMaxJsonAll)
  let cdfTraceJson = []
  let cdfTimeJson = []
  let cdfTimeMiMaxJson = []
  cdfTraceJsonKeys.forEach((key) => {
    if(key === 'data') {
      cdfTraceJson = cdfTraceJsonAll[key]
    }
  })
  cdfTimeJsonKeys.forEach((key) => {
    if(key === 'data') {
      cdfTimeJson = cdfTimeJsonAll[key]
    }
  })
  cdfTimeMiMaxJsonKeys.forEach((key) => {
    if(key === 'data') {
      cdfTimeMiMaxJson = cdfTimeMiMaxJsonAll[key]
    }
  })
  const graphs = {}
  const allTraces = []
  for(let i = 0; i < inspectionTimesPlot.length; i += 1) {
    const traces = []
    if(i === 0) {
      const traceTMin = {
        x: cdfTimePlot,
        y: failureCDFTracePlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Failure Time CDF',
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: cdfTimeMinMaxPlot,
        y: [0.5, 0.5],
        mode: 'lines',
        type: 'scatter',
        name: '50th Percentile',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: cdfTimeMinMaxPlot,
        y: [0.1, 0.1],
        mode: 'lines',
        type: 'scatter',
        name: '90th Percentile',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceLower1)
      const traceLower = {
        x: cdfTimeMinMaxPlot,
        y: [0.05, 0.05],
        mode: 'lines',
        type: 'scatter',
        name: '95th Percentile',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceLower)
      const traceMedian = {
        x: cdfTimeMinMaxPlot,
        y: [0.01, 0.01],
        mode: 'lines',
        type: 'scatter',
        name: '99th Percentile',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `cdfFailureTime${i}`,
          title: 'Failure Time CDF Before Inspections',
          showlegend: true,
          xaxis: {
            title: 'Failure Time (yrs)',
          },
          yaxis: {
            title: 'Cumulative Probability',
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    } else {
      const traceTMin = {
        x: cdfTimeJson[i - 1],
        y: cdfTraceJson[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Failure Time CDF',
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: cdfTimeMiMaxJson[i - 1],
        y: [0.5, 0.5],
        mode: 'lines',
        type: 'scatter',
        name: '50th Percentile',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: cdfTimeMiMaxJson[i - 1],
        y: [0.1, 0.1],
        mode: 'lines',
        type: 'scatter',
        name: '90th Percentile',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceLower1)
      const traceLower = {
        x: cdfTimeMiMaxJson[i - 1],
        y: [0.05, 0.05],
        mode: 'lines',
        type: 'scatter',
        name: '95th Percentile',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceLower)
      const traceMedian = {
        x: cdfTimeMiMaxJson[i - 1],
        y: [0.01, 0.01],
        mode: 'lines',
        type: 'scatter',
        name: '99th Percentile',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `cdfFailureTime${i}`,
          title: `Failure Time CDF After Inspection Time ${inspectionTimesPlot[i]}`,
          showlegend: true,
          xaxis: {
            title: 'Failure Time (yrs)',
          },
          yaxis: {
            title: 'Cumulative Probability',
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    }
  }
  return graphs
}

export function plotResults(results, activeUnitSystem) {
  let thicknessUnits = 'in'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  const inspectionTimesPlot = results.dataTables.inspectionTimes.keywords[0].data
  const lb01MultTableBeforeInspectionsPlot = results.dataTables.lbMultTableBeforeInspections_01.keywords[0].data
  const lb1MultTableBeforeInspectionsPlot = results.dataTables.lbMultTableBeforeInspections_1.keywords[0].data
  const lbMultTableBeforeInspectionsPlot = results.dataTables.lbMultTableBeforeInspections.keywords[0].data
  const medMultTableBeforeInspectionsPlot = results.dataTables.medMultTableBeforeInspections.keywords[0].data
  const upMultTableBeforeInspectionsPlot = results.dataTables.upMultTableBeforeInspections.keywords[0].data
  const up99MultTableBeforeInspectionsPlot = results.dataTables.upMultTableBeforeInspections_99.keywords[0].data
  const timeMultTableBeforeInspectionsPlot = results.dataTables.timeMultTableBeforeInspections.keywords[0].data
  const tminTraceCol0Plot = results.dataTables.tminTraceCol0.keywords[0].data
  const timeMultTablesAll = JSON.parse(results.timeMultTablesJson[0])
  const multTableLowerBoundAll01 = JSON.parse(results.multTableLowerBound_01Json[0])
  const multTableLowerBoundAll1 = JSON.parse(results.multTableLowerBound_1Json[0])
  const multTableLowerBoundAll = JSON.parse(results.multTableLowerBoundJson[0])
  const multTableMedianAll = JSON.parse(results.multTableMedianJson[0])
  const multTableUpperBoundAll = JSON.parse(results.multTableUpperBoundJson[0])
  const multTableUpperBoundAll99 = JSON.parse(results.multTableUpperBound_99Json[0])
  const tMinMultTablesAll = JSON.parse(results.tMinMultTablesJson[0])
  const timePlotKeys = Object.keys(timeMultTablesAll)
  const multTableLowerBound01PlotKeys = Object.keys(multTableLowerBoundAll01)
  const multTableLowerBound1PlotKeys = Object.keys(multTableLowerBoundAll1)
  const multTableLowerBoundPlotKeys = Object.keys(multTableLowerBoundAll)
  const multTableMedianPlotKeys = Object.keys(multTableMedianAll)
  const multTableUpperBoundPlotKeys = Object.keys(multTableUpperBoundAll)
  const multTableUpperBound99PlotKeys = Object.keys(multTableUpperBoundAll99)
  const tMinPlotKeys = Object.keys(tMinMultTablesAll)
  let timePlot = []
  let tminPlot = []
  let multTableLowerBound01Plot = []
  let multTableLowerBound1Plot = []
  let multTableLowerBoundPlot = []
  let multTableMedianPlot = []
  let multTableUpperBoundPlot = []
  let multTableUpperBound99Plot = []
  timePlotKeys.forEach((key) => {
    if(key === 'data') {
      timePlot = timeMultTablesAll[key]
    }
  })
  tMinPlotKeys.forEach((key) => {
    if(key === 'data') {
      tminPlot = tMinMultTablesAll[key]
    }
  })
  multTableLowerBound01PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound01Plot = multTableLowerBoundAll01[key]
    }
  })
  multTableLowerBound1PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound1Plot = multTableLowerBoundAll1[key]
    }
  })
  multTableLowerBoundPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBoundPlot = multTableLowerBoundAll[key]
    }
  })
  multTableMedianPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableMedianPlot = multTableMedianAll[key]
    }
  })
  multTableUpperBoundPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableUpperBoundPlot = multTableUpperBoundAll[key]
    }
  })
  multTableUpperBound99PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableUpperBound99Plot = multTableUpperBoundAll99[key]
    }
  })
  const graphs = {}
  const allTraces = []
  for(let i = 0; i < inspectionTimesPlot.length; i += 1) {
    const traces = []
    if(i === 0) {
      const traceTMin = {
        x: timeMultTableBeforeInspectionsPlot,
        y: tminTraceCol0Plot,
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb01MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb1MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lbMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timeMultTableBeforeInspectionsPlot,
        y: medMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: 'Component Thickness Projection Before Inspections',
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    } else {
      const traceTMin = {
        x: timePlot[i - 1],
        y: tminPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timePlot[i - 1],
        y: multTableLowerBound01Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timePlot[i - 1],
        y: multTableLowerBound1Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timePlot[i - 1],
        y: multTableLowerBoundPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timePlot[i - 1],
        y: multTableMedianPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: `Component Thickness Projection After Inspection Time ${inspectionTimesPlot[i]}`,
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    }
  }
  return graphs
}

export function plotResultsMissingJacketing(results, activeUnitSystem) {
  let thicknessUnits = 'in'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  const inspectionTimesPlot = results.dataTables.inspectionTimes.keywords[0].data
  const tminTraceCol0Plot = results.dataTables.tminTraceACol0.keywords[0].data
  const lbMultTableBeforeInspectionsPlot = results.dataTables.lbMultTableBeforeInspectionsMissingJacketing.keywords[0].data
  const lb01MultTableBeforeInspectionsPlot = results.dataTables.lb_01MultTableBeforeInspectionsMissingJacketing.keywords[0].data
  const lb1MultTableBeforeInspectionsPlot = results.dataTables.lb_1MultTableBeforeInspectionsMissingJacketing.keywords[0].data
  const medMultTableBeforeInspectionsPlot = results.dataTables.medMultTableBeforeInspectionsMissingJacketing.keywords[0].data
  const timeMultTableBeforeInspectionsPlot = results.dataTables.timeMultTableBeforeInspections.keywords[0].data
  const timeMultTablesAll = JSON.parse(results.timeMultTablesJson[0])
  const tMinMultTablesAll = JSON.parse(results.tMinMultTablesMissingJson[0])
  const multTableLower01BoundAll = JSON.parse(results.multTableLower_01BoundJsonMissingJacketing[0])
  const multTableLower1BoundAll = JSON.parse(results.multTableLower_1BoundJsonMissingJacketing[0])
  const multTableLowerBoundAll = JSON.parse(results.multTableLowerBoundJsonMissingJacketing[0])
  const multTableMedianAll = JSON.parse(results.multTableMedianJsonMissingJacketing[0])
  const timePlotKeys = Object.keys(timeMultTablesAll)
  const tMinPlotKeys = Object.keys(tMinMultTablesAll)
  const multTableLowerBound01PlotKeys = Object.keys(multTableLower01BoundAll)
  const multTableLowerBound1PlotKeys = Object.keys(multTableLower1BoundAll)
  const multTableLowerBoundPlotKeys = Object.keys(multTableLowerBoundAll)
  const multTableMedianPlotKeys = Object.keys(multTableMedianAll)
  let timePlot = []
  let tminPlot = []
  let multTableLowerBound01Plot = []
  let multTableLowerBound1Plot = []
  let multTableLowerBoundPlot = []
  let multTableMedianPlot = []
  timePlotKeys.forEach((key) => {
    if(key === 'data') {
      timePlot = timeMultTablesAll[key]
    }
  })
  tMinPlotKeys.forEach((key) => {
    if(key === 'data') {
      tminPlot = tMinMultTablesAll[key]
    }
  })
  multTableLowerBound01PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound01Plot = multTableLower01BoundAll[key]
    }
  })
  multTableLowerBound1PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound1Plot = multTableLower1BoundAll[key]
    }
  })
  multTableLowerBoundPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBoundPlot = multTableLowerBoundAll[key]
    }
  })
  multTableMedianPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableMedianPlot = multTableMedianAll[key]
    }
  })
  const graphs = {}
  const allTraces = []
  for(let i = 0; i < inspectionTimesPlot.length; i += 1) {
    const traces = []
    if(i === 0) {
      const traceTMin = {
        x: timeMultTableBeforeInspectionsPlot,
        y: tminTraceCol0Plot,
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb01MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb1MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lbMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timeMultTableBeforeInspectionsPlot,
        y: medMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: 'Component Failure due to Missing Jacketing<br>Thickness Projections Before Inspections</br>',
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    } else {
      const traceTMin = {
        x: timePlot[i - 1],
        y: tminPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timePlot[i - 1],
        y: multTableLowerBound01Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timePlot[i - 1],
        y: multTableLowerBound1Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timePlot[i - 1],
        y: multTableLowerBoundPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timePlot[i - 1],
        y: multTableMedianPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: `Component Failure due to Missing Jacketing<br>Thickness Projections After Inspection Time ${inspectionTimesPlot[i]}</br>`,
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    }
  }
  return graphs
}

export function plotResultsSealantJacketing(results, activeUnitSystem) {
  let thicknessUnits = 'in'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  const tminTraceCol0Plot = results.dataTables.tminTraceBCol0.keywords[0].data
  const lb01MultTableBeforeInspectionsPlot = results.dataTables.lb_01MultTableBeforeInspectionsSealantJacketing.keywords[0].data
  const lb1MultTableBeforeInspectionsPlot = results.dataTables.lb_1MultTableBeforeInspectionsSealantJacketing.keywords[0].data
  const tMinMultTablesAll = JSON.parse(results.tMinMultTablesSealantJson[0])
  const multTableLower01BoundAll = JSON.parse(results.multTableLower_01BoundJsonSealantJacketing[0])
  const multTableLower1BoundAll = JSON.parse(results.multTableLower_1BoundJsonSealantJacketing[0])
  const tMinPlotKeys = Object.keys(tMinMultTablesAll)
  const multTableLowerBound01PlotKeys = Object.keys(multTableLower01BoundAll)
  const multTableLowerBound1PlotKeys = Object.keys(multTableLower1BoundAll)

  const inspectionTimesPlot = results.dataTables.inspectionTimes.keywords[0].data
  const lbMultTableBeforeInspectionsPlot = results.dataTables.lbMultTableBeforeInspectionsSealantJacketing.keywords[0].data
  const medMultTableBeforeInspectionsPlot = results.dataTables.medMultTableBeforeInspectionsSealantJacketing.keywords[0].data
  const timeMultTableBeforeInspectionsPlot = results.dataTables.timeMultTableBeforeInspections.keywords[0].data
  const timeMultTablesAll = JSON.parse(results.timeMultTablesJson[0])
  const multTableLowerBoundAll = JSON.parse(results.multTableLowerBoundJsonSealantJacketing[0])
  const multTableMedianAll = JSON.parse(results.multTableMedianJsonSealantJacketing[0])
  const timePlotKeys = Object.keys(timeMultTablesAll)
  const multTableLowerBoundPlotKeys = Object.keys(multTableLowerBoundAll)
  const multTableMedianPlotKeys = Object.keys(multTableMedianAll)
  let timePlot = []
  let tminPlot = []
  let multTableLowerBound01Plot = []
  let multTableLowerBound1Plot = []
  let multTableLowerBoundPlot = []
  let multTableMedianPlot = []
  timePlotKeys.forEach((key) => {
    if(key === 'data') {
      timePlot = timeMultTablesAll[key]
    }
  })
  tMinPlotKeys.forEach((key) => {
    if(key === 'data') {
      tminPlot = tMinMultTablesAll[key]
    }
  })
  multTableLowerBound01PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound01Plot = multTableLower01BoundAll[key]
    }
  })
  multTableLowerBound1PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound1Plot = multTableLower1BoundAll[key]
    }
  })
  multTableLowerBoundPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBoundPlot = multTableLowerBoundAll[key]
    }
  })
  multTableMedianPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableMedianPlot = multTableMedianAll[key]
    }
  })
  const graphs = {}
  const allTraces = []
  for(let i = 0; i < inspectionTimesPlot.length; i += 1) {
    const traces = []
    if(i === 0) {
      const traceTMin = {
        x: timeMultTableBeforeInspectionsPlot,
        y: tminTraceCol0Plot,
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb01MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb1MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lbMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timeMultTableBeforeInspectionsPlot,
        y: medMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: 'Component Failure due to Jacket Sealant<br>Thickness Projections Before Inspections</br>',
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    } else {
      const traceTMin = {
        x: timePlot[i - 1],
        y: tminPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timePlot[i - 1],
        y: multTableLowerBound01Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timePlot[i - 1],
        y: multTableLowerBound1Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timePlot[i - 1],
        y: multTableLowerBoundPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timePlot[i - 1],
        y: multTableMedianPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: `Component Failure due to Jacket Sealant<br>Thickness Projections After Inspection Time ${inspectionTimesPlot[i]}</br>`,
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    }
  }
  return graphs
}

export function plotResultsCorrosionJacketing(results, activeUnitSystem) {
  let thicknessUnits = 'in'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  const tminTraceCol0Plot = results.dataTables.tminTraceCCol0.keywords[0].data
  const lb01MultTableBeforeInspectionsPlot = results.dataTables.lb_01MultTableBeforeInspectionsCorrosionJacketing.keywords[0].data
  const lb1MultTableBeforeInspectionsPlot = results.dataTables.lb_1MultTableBeforeInspectionsCorrosionJacketing.keywords[0].data
  const tMinMultTablesAll = JSON.parse(results.tMinMultTablesCorrosionJson[0])
  const multTableLower01BoundAll = JSON.parse(results.multTableLower_01BoundJsonCorrosionJacketing[0])
  const multTableLower1BoundAll = JSON.parse(results.multTableLower_1BoundJsonCorrosionJacketing[0])
  const tMinPlotKeys = Object.keys(tMinMultTablesAll)
  const multTableLowerBound01PlotKeys = Object.keys(multTableLower01BoundAll)
  const multTableLowerBound1PlotKeys = Object.keys(multTableLower1BoundAll)

  const inspectionTimesPlot = results.dataTables.inspectionTimes.keywords[0].data
  const lbMultTableBeforeInspectionsPlot = results.dataTables.lbMultTableBeforeInspectionsCorrosionJacketing.keywords[0].data
  const medMultTableBeforeInspectionsPlot = results.dataTables.medMultTableBeforeInspectionsCorrosionJacketing.keywords[0].data
  const upMultTableBeforeInspectionsPlot = results.dataTables.upMultTableBeforeInspectionsCorrosionJacketing.keywords[0].data
  const timeMultTableBeforeInspectionsPlot = results.dataTables.timeMultTableBeforeInspections.keywords[0].data
  const timeMultTablesAll = JSON.parse(results.timeMultTablesJson[0])
  const multTableLowerBoundAll = JSON.parse(results.multTableLowerBoundJsonCorrosionJacketing[0])
  const multTableMedianAll = JSON.parse(results.multTableMedianJsonCorrosionJacketing[0])
  const timePlotKeys = Object.keys(timeMultTablesAll)
  const multTableLowerBoundPlotKeys = Object.keys(multTableLowerBoundAll)
  const multTableMedianPlotKeys = Object.keys(multTableMedianAll)
  let timePlot = []
  let tminPlot = []
  let multTableLowerBound01Plot = []
  let multTableLowerBound1Plot = []
  let multTableLowerBoundPlot = []
  let multTableMedianPlot = []
  timePlotKeys.forEach((key) => {
    if(key === 'data') {
      timePlot = timeMultTablesAll[key]
    }
  })
  tMinPlotKeys.forEach((key) => {
    if(key === 'data') {
      tminPlot = tMinMultTablesAll[key]
    }
  })
  multTableLowerBound01PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound01Plot = multTableLower01BoundAll[key]
    }
  })
  multTableLowerBound1PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound1Plot = multTableLower1BoundAll[key]
    }
  })
  multTableLowerBoundPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBoundPlot = multTableLowerBoundAll[key]
    }
  })
  multTableMedianPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableMedianPlot = multTableMedianAll[key]
    }
  })
  const graphs = {}
  const allTraces = []
  for(let i = 0; i < inspectionTimesPlot.length; i += 1) {
    const traces = []
    if(i === 0) {
      const traceTMin = {
        x: timeMultTableBeforeInspectionsPlot,
        y: tminTraceCol0Plot,
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb01MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb1MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lbMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timeMultTableBeforeInspectionsPlot,
        y: medMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: 'Component Failure due to Jacketing Corrosion<br>Thickness Projections Before Inspections</br>',
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    } else {
      const traceTMin = {
        x: timePlot[i - 1],
        y: tminPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timePlot[i - 1],
        y: multTableLowerBound01Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timePlot[i - 1],
        y: multTableLowerBound1Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timePlot[i - 1],
        y: multTableLowerBoundPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timePlot[i - 1],
        y: multTableMedianPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: `Component Failure due to Jacketing Corrosion<br>Thickness Projections After Inspection Time ${inspectionTimesPlot[i]}</br>`,
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    }
  }
  return graphs
}

export function plotResultsMissingVaporBarrier(results, activeUnitSystem) {
  let thicknessUnits = 'in'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }

  const tminTraceCol0Plot = results.dataTables.tminTraceECol0.keywords[0].data
  const lb01MultTableBeforeInspectionsPlot = results.dataTables.lb_01MultTableBeforeInspectionsMissingVaporBarrier.keywords[0].data
  const lb1MultTableBeforeInspectionsPlot = results.dataTables.lb_1MultTableBeforeInspectionsMissingVaporBarrier.keywords[0].data
  const tMinMultTablesAll = JSON.parse(results.tMinMultTablesMissingVaporBarrierJson[0])
  const multTableLower01BoundAll = JSON.parse(results.multTableLower_01BoundJsonMissingVaporBarrier[0])
  const multTableLower1BoundAll = JSON.parse(results.multTableLower_1BoundJsonMissingVaporBarrier[0])
  const tMinPlotKeys = Object.keys(tMinMultTablesAll)
  const multTableLowerBound01PlotKeys = Object.keys(multTableLower01BoundAll)
  const multTableLowerBound1PlotKeys = Object.keys(multTableLower1BoundAll)

  const inspectionTimesPlot = results.dataTables.inspectionTimes.keywords[0].data
  const lbMultTableBeforeInspectionsPlot = results.dataTables.lbMultTableBeforeInspectionsMissingVaporBarrier.keywords[0].data
  const medMultTableBeforeInspectionsPlot = results.dataTables.medMultTableBeforeInspectionsMissingVaporBarrier.keywords[0].data
  const upMultTableBeforeInspectionsPlot = results.dataTables.upMultTableBeforeInspectionsMissingVaporBarrier.keywords[0].data
  const timeMultTableBeforeInspectionsPlot = results.dataTables.timeMultTableBeforeInspections.keywords[0].data
  const timeMultTablesAll = JSON.parse(results.timeMultTablesJson[0])
  const multTableLowerBoundAll = JSON.parse(results.multTableLowerBoundJsonMissingVaporBarrier[0])
  const multTableMedianAll = JSON.parse(results.multTableMedianJsonMissingVaporBarrier[0])
  const timePlotKeys = Object.keys(timeMultTablesAll)
  const multTableLowerBoundPlotKeys = Object.keys(multTableLowerBoundAll)
  const multTableMedianPlotKeys = Object.keys(multTableMedianAll)
  let timePlot = []
  let tminPlot = []
  let multTableLowerBound01Plot = []
  let multTableLowerBound1Plot = []
  let multTableLowerBoundPlot = []
  let multTableMedianPlot = []
  timePlotKeys.forEach((key) => {
    if(key === 'data') {
      timePlot = timeMultTablesAll[key]
    }
  })
  tMinPlotKeys.forEach((key) => {
    if(key === 'data') {
      tminPlot = tMinMultTablesAll[key]
    }
  })
  multTableLowerBound01PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound01Plot = multTableLower01BoundAll[key]
    }
  })
  multTableLowerBound1PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound1Plot = multTableLower1BoundAll[key]
    }
  })
  multTableLowerBoundPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBoundPlot = multTableLowerBoundAll[key]
    }
  })
  multTableMedianPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableMedianPlot = multTableMedianAll[key]
    }
  })
  const graphs = {}
  const allTraces = []
  for(let i = 0; i < inspectionTimesPlot.length; i += 1) {
    const traces = []
    if(i === 0) {
      const traceTMin = {
        x: timeMultTableBeforeInspectionsPlot,
        y: tminTraceCol0Plot,
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb01MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb1MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lbMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timeMultTableBeforeInspectionsPlot,
        y: medMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: 'Component Failure due to Missing Vapor Barrier<br>Thickness Projections Before Inspections</br>',
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    } else {
      const traceTMin = {
        x: timePlot[i - 1],
        y: tminPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timePlot[i - 1],
        y: multTableLowerBound01Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timePlot[i - 1],
        y: multTableLowerBound1Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timePlot[i - 1],
        y: multTableLowerBoundPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timePlot[i - 1],
        y: multTableMedianPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: `Component Failure due to Missing Vapor Barrier<br>Thickness Projections After Inspection Time ${inspectionTimesPlot[i]}</br>`,
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    }
  }
  return graphs
}

export function plotResultsSealantVaporBarrier(results, activeUnitSystem) {
  let thicknessUnits = 'in'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  const tminTraceCol0Plot = results.dataTables.tminTraceDCol0.keywords[0].data
  const lb01MultTableBeforeInspectionsPlot = results.dataTables.lb_01MultTableBeforeInspectionsSealantVaporBarrier.keywords[0].data
  const lb1MultTableBeforeInspectionsPlot = results.dataTables.lb_1MultTableBeforeInspectionsSealantVaporBarrier.keywords[0].data
  const tMinMultTablesAll = JSON.parse(results.tMinMultTablesSealantVaporBarrierJson[0])
  const multTableLower01BoundAll = JSON.parse(results.multTableLower_01BoundJsonSealantVaporBarrier[0])
  const multTableLower1BoundAll = JSON.parse(results.multTableLower_1BoundJsonSealantVaporBarrier[0])
  const tMinPlotKeys = Object.keys(tMinMultTablesAll)
  const multTableLowerBound01PlotKeys = Object.keys(multTableLower01BoundAll)
  const multTableLowerBound1PlotKeys = Object.keys(multTableLower1BoundAll)

  const inspectionTimesPlot = results.dataTables.inspectionTimes.keywords[0].data
  const lbMultTableBeforeInspectionsPlot = results.dataTables.lbMultTableBeforeInspectionsSealantVaporBarrier.keywords[0].data
  const medMultTableBeforeInspectionsPlot = results.dataTables.medMultTableBeforeInspectionsSealantVaporBarrier.keywords[0].data
  const upMultTableBeforeInspectionsPlot = results.dataTables.upMultTableBeforeInspectionsSealantVaporBarrier.keywords[0].data
  const timeMultTableBeforeInspectionsPlot = results.dataTables.timeMultTableBeforeInspections.keywords[0].data
  const timeMultTablesAll = JSON.parse(results.timeMultTablesJson[0])
  const multTableLowerBoundAll = JSON.parse(results.multTableLowerBoundJsonSealantVaporBarrier[0])
  const multTableMedianAll = JSON.parse(results.multTableMedianJsonSealantVaporBarrier[0])
  const timePlotKeys = Object.keys(timeMultTablesAll)
  const multTableLowerBoundPlotKeys = Object.keys(multTableLowerBoundAll)
  const multTableMedianPlotKeys = Object.keys(multTableMedianAll)
  let timePlot = []
  let tminPlot = []
  let multTableLowerBound01Plot = []
  let multTableLowerBound1Plot = []
  let multTableLowerBoundPlot = []
  let multTableMedianPlot = []
  timePlotKeys.forEach((key) => {
    if(key === 'data') {
      timePlot = timeMultTablesAll[key]
    }
  })
  tMinPlotKeys.forEach((key) => {
    if(key === 'data') {
      tminPlot = tMinMultTablesAll[key]
    }
  })
  multTableLowerBound01PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound01Plot = multTableLower01BoundAll[key]
    }
  })
  multTableLowerBound1PlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBound1Plot = multTableLower1BoundAll[key]
    }
  })
  multTableLowerBoundPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableLowerBoundPlot = multTableLowerBoundAll[key]
    }
  })
  multTableMedianPlotKeys.forEach((key) => {
    if(key === 'data') {
      multTableMedianPlot = multTableMedianAll[key]
    }
  })
  const graphs = {}
  const allTraces = []
  for(let i = 0; i < inspectionTimesPlot.length; i += 1) {
    const traces = []
    if(i === 0) {
      const traceTMin = {
        x: timeMultTableBeforeInspectionsPlot,
        y: tminTraceCol0Plot,
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb01MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lb1MultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timeMultTableBeforeInspectionsPlot,
        y: lbMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timeMultTableBeforeInspectionsPlot,
        y: medMultTableBeforeInspectionsPlot,
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: 'Component Failure due to Vapor Barrier Sealant Failure<br>Thickness Projections Before Inspections</br>',
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    } else {
      const traceTMin = {
        x: timePlot[i - 1],
        y: tminPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Mean Failure Thickness',
        line: {
          dash: 'dash',
          width: 4,
        },
      }
      traces.push(traceTMin)
      const traceLower01 = {
        x: timePlot[i - 1],
        y: multTableLowerBound01Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (0.1%)',
      }
      traces.push(traceLower01)
      const traceLower1 = {
        x: timePlot[i - 1],
        y: multTableLowerBound1Plot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (1%)',
      }
      traces.push(traceLower1)
      const traceLower = {
        x: timePlot[i - 1],
        y: multTableLowerBoundPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Lower Bound (10%)',
      }
      traces.push(traceLower)
      const traceMedian = {
        x: timePlot[i - 1],
        y: multTableMedianPlot[i - 1],
        mode: 'lines',
        type: 'scatter',
        name: 'Median (50%)',
      }
      traces.push(traceMedian)
      allTraces.push(traces)
      graphs[`${i.toString()}`] = {
        traces: allTraces[i],
        layout: {
          name: `thicknessProjection${i}`,
          title: `Component Failure due to Vapor Barrier Sealant Failure<br>Thickness Projections After Inspection Time ${inspectionTimesPlot[i]}</br>`,
          showlegend: true,
          xaxis: {
            title: 'Time (yr)',
          },
          yaxis: {
            title: `Thickness (${thicknessUnits})`,
          },
          legend: {
            orientation: 'v',
            bgcolor: 'rgba(0,0,0,0)',
            x: 1.1,
            xanchor: 'right',
            y: 1.1,
          },
        },
      }
    }
  }
  return graphs
}
