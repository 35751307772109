import React from 'react'

import BayesNetBuilderProb1 from './BayesNetBuilderProb1'
import BayesNetBuilderProb2 from './BayesNetBuilderProb2'
import BayesNetBuilderProb3 from './BayesNetBuilderProb3'
import BayesNetBuilderQualProp from './BayesNetBuilderQual'
import BeforeInspectionMainResults from './BeforeInspectionMainResults'
import DecisionsPlot from './DecisionsPlot'
import DecisionsResults from './DecisionsResults'
import Histograms from './Histograms'
import MultTableThickProj from './MultTableThickProj'
import PerformanceMetrics from './PerformanceMetrics'
import POFAtTimes from './POFAtTimes'
import QualMethodsProp from './QualMethods'

export default [
  {
    name: 'Prop0',
    title: 'Before Inspection Main Results',
    component: <BeforeInspectionMainResults/>,
    predicate: (fields, response) => (response.results.inspections[0] === 1 && fields.problemType === 'PROB3'),
  },
  {
    name: 'Prop1',
    title: 'Histograms',
    component: <Histograms/>,
  },
  {
    name: 'Prop2',
    title: 'POFs at Various Times',
    component: <POFAtTimes/>,
  },
  {
    name: 'Prop3',
    title: 'Performance Metrics',
    component: <PerformanceMetrics/>,
    predicate: fields => fields.problemType !== 'PROB2',
  },
  {
    name: 'Prop3',
    title: 'Thickness Projection per Inspection',
    component: <MultTableThickProj/>,
    predicate: fields => fields.inspectionInputType === 'manyTable',
  },
  {
    name: 'Prop4',
    title: 'Decisions Results',
    component: <DecisionsResults/>,
    predicate: fields => fields.decisionsStrategy === '1',
  },
  {
    name: 'Prop5',
    title: 'Decisions Plotting Results',
    component: <DecisionsPlot/>,
    predicate: fields => fields.decisionsStrategy === '1',
  },
  {
    name: 'Prop6',
    title: 'Qualitative Methods Results',
    component: <QualMethodsProp/>,
    predicate: fields => (fields.runQualMethodsBool === true && fields.problemType === 'PROB1'),
  },
  {
    name: 'Prop7',
    title: 'Bayes Net Builder',
    component: <BayesNetBuilderProb1/>,
    predicate: fields => fields.problemType === 'PROB1',
  },
  {
    name: 'Prop8',
    title: 'Bayes Net Builder',
    component: <BayesNetBuilderProb2/>,
    predicate: fields => fields.problemType === 'PROB2',
  },
  {
    name: 'Prop9',
    title: 'Bayes Net Builder',
    component: <BayesNetBuilderProb3/>,
    predicate: fields => fields.problemType === 'PROB3',
  },
  {
    name: 'Prop10',
    title: 'Bayes Net Builder Qualitative Methods',
    component: <BayesNetBuilderQualProp/>,
    predicate: fields => (fields.runQualMethodsBool === true && fields.problemType === 'PROB1'),
  },
]
