import { ReactNode } from 'react'

import { CalculatorExports, DataTable, PlantManagerAsset, PlantManagerComponent } from '@e2grnd/ui-core/dist/types'

import { problemTypeLup } from './sequence/Problems'
import dataTables from './dataTables'
import examples from './examples'
import graphs from './graphs'
import optionsPages from './optionsPages'
import params from './params'
import propertyPages from './propertyPages'
import report from './report'
import sequence from './sequence'
import steps from './steps'

const config: CalculatorExports = {
  params,
  steps,
  dataTables: dataTables as DataTable[],
  examples,
  propertyPages,
  sequence,
  problemTypeLup,
  optionsPages,
  graphs,
  report,

  /**
   * For calculators that operate on a single component, use this function to
   * map the component data to the calculator's parameters. The asset data is
   * also provided in case it is needed.
   */
  assetComponentParamsMapFn: (pmAsset: PlantManagerAsset, pmComponent: PlantManagerComponent) => {
    if(!pmAsset) {
      return { fieldValues: {} }
    }

    // eslint-disable-next-line no-console
    console.log('Mapping asset data:\n\tAsset:', pmAsset, '\n\tComponent:', pmComponent)
    const warnings: ReactNode[] = []
    const errors: ReactNode[] = []

    let innerDiameter
    if(!pmComponent.innerDiameter) {
      warnings.push('The selected component does not have an inner diameter defined. Thus, we calculate the inner diameter from the outer diameter and the nominal thickness.')
      if(!pmComponent.outerDiameter) {
        warnings.push('The selected component does not have an outer diameter defined.')
      } else {
        if(!pmComponent.nominalThickness) {
          warnings.push('The selected component does not have a nominal thickness defined.')
        } else {
          innerDiameter = pmComponent.outerDiameter - 2.0 * pmComponent.nominalThickness
        }
      }
    } else {
      innerDiameter = pmComponent.innerDiameter
    }

    let nominalThickness
    let nominalThicknessProb
    if(!pmComponent.nominalThickness) {
      warnings.push('The selected component does not have a nominal thickness defined.')
    } else {
      nominalThickness = pmComponent.nominalThickness
      nominalThicknessProb = JSON.stringify({
        name: 'Starting Thickness',
        kind: 'nature',
        type: 'continuous',
        parents: null,
        states: {
          levels: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        },
        CPT: {
          neticaEquation: 'p(StartingThickness|)=NormalDist(StartingThickness, 0.5, 0.1)',
          sampleSize: 100,
        },
        distributionType: 'normal',
        median: pmComponent.nominalThickness,
        varianceType: 'cv',
        cv: 0.1,
      }, null, 4)
    }

    let temperature
    let operatingTemperatureProb
    if(!pmComponent.circuit) {
      warnings.push('The selected component does not have a circuit defined.')
      if(!pmComponent.designTemperature) {
        warnings.push('The selected component does not have a design temperature defined.')
      }
      temperature = pmComponent.designTemperature
      operatingTemperatureProb = JSON.stringify({
        name: 'temperature',
        kind: 'nature',
        type: 'continuous',
        parents: null,
        states: {
          levels: [-100, -80, -60, -40, -20, 0, 32, 45, 55, 65, 75, 85, 95, 105, 115, 125, 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245, 255, 265, 275, 350],
        },
        CPT: {
          neticaEquation: 'p(temperature|)=NormalDist(temperature, 70, 10)',
          sampleSize: 100,
        },
        distributionType: 'normal',
        median: pmComponent.designTemperature,
        varianceType: 'cv',
        cv: 0.1,
      }, null, 4)
    } else {
      if(!pmComponent.circuit.operatingTemperature) {
        warnings.push('The selected component does not have a circuit operating temperature defined.')
      }
      temperature = pmComponent.circuit.operatingTemperature
      operatingTemperatureProb = JSON.stringify({
        name: 'temperature',
        kind: 'nature',
        type: 'continuous',
        parents: null,
        states: {
          levels: [-100, -80, -60, -40, -20, 0, 32, 45, 55, 65, 75, 85, 95, 105, 115, 125, 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245, 255, 265, 275, 350],
        },
        CPT: {
          neticaEquation: 'p(temperature|)=NormalDist(temperature, 70, 10)',
          sampleSize: 100,
        },
        distributionType: 'normal',
        median: pmComponent.circuit.operatingTemperature,
        varianceType: 'cv',
        cv: 10,
      }, null, 4)
    }

    let pressure
    if(!pmComponent.circuit) {
      warnings.push('The selected component does not have a circuit defined.')
      if(!pmComponent.designPressure) {
        warnings.push('The selected component does not have a design pressure defined.')
      }
      pressure = pmComponent.designPressure
    } else {
      if(!pmComponent.circuit.operatingPressure) {
        warnings.push('The selected component does not have a circuit operating pressure defined.')
      }
      pressure = pmComponent.circuit.operatingPressure
    }

    let minimumThickness
    let failureThicknessProb
    if(!pmComponent.minimumThickness) {
      warnings.push('The selected component does not have a minimum thickness defined.')
    } else {
      minimumThickness = pmComponent.minimumThickness
      failureThicknessProb = JSON.stringify({
        name: 'Failure Thickness',
        kind: 'nature',
        type: 'continuous',
        parents: null,
        states: {
          levels: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        },
        CPT: {
          neticaEquation: 'p(FailureThickness|)=NormalDist(FailureThickness, 0.1, 0.025)',
          sampleSize: 100,
        },
        distributionType: 'normal',
        median: pmComponent.minimumThickness,
        varianceType: 'standardDeviation',
        standardDeviation: 0.025,
      }, null, 4)
    }

    let inServiceDate
    if(!pmComponent.inServiceDate) {
      warnings.push('The selected component does not have a minimum thickness defined.')
    } else {
      inServiceDate = pmComponent.inServiceDate
      // const inServiceDateTemp = pmComponent.inServiceDate
      // const dateArray = inServiceDateTemp.split("-")
      // inServiceDate = dateArray[0].concat('-',dateArray[1],'-',dateArray[2])
    }

    return {
      errors,
      warnings,
      fieldValues: {
        innerDiameter,
        innerDiameterFE: innerDiameter,
        nominalThickness,
        nominalThicknessProb,
        operatingTemperature: temperature,
        operatingTemperatureProb,
        internalPressure: pressure,
        failureThickness: minimumThickness,
        failureThicknessProb,
        installReplaceDate: inServiceDate,
      },
    }
  },
}

export default config
