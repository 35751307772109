/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GInfoButton from '@e2grnd/ui-core/dist/containers/E2GInfoButton'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'
import ToggleField from '@e2grnd/ui-core/dist/containers/ToggleField'

import inspectionGuid from '../images/inspectionGuid.png'

function Step1({ problemType, innerDiameterFE, runCondensation, criticalFlawDepthVarType, criticalFlawDepth, criticalFlawDepthSd, criticalFlawDepthCv, criticalFlawDepthConfidence, materialOfConstruction, stressState, acidicEnvironment, maxTime, nominalThickness, undertolerance, failureThickness, failureThicknessConfidence, failureThicknessMethod, internalPressure, flowStress, innerDiameter, runQualMethodsBool, componentAge, coatingAge, insulationAge, componentType, installReplaceDate, componentAgeVsInstallDateBool, failureThicknessVarianceType, failureThicknessSD, failureThicknessCV, probabilisticInputFieldFlag, materialOfConstructionHybrid, nominalThicknessProb, failureThicknessProb, componentTypeProb }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB1'
        ? (
          <div className="cf w-100">
            <E2GInfoButton
              tooltip={<div>
                <img src={inspectionGuid} alt="" />
              </div>}
              dialog
            >
              <p>See best practice guidelines for identifying vulnerable locations</p>
            </E2GInfoButton>
            {materialOfConstruction !== 'SS'
              ? (
                <ToggleField name="runQualMethodsBool" value={runQualMethodsBool} label="Enable Qualitative Methods Procedure" />
              )
              : <input type="hidden" name="runQualMethodsBool" value={false} />}

            <ToggleField name="runCondensation" value={runCondensation} label="Enable Condensation Networks" />
            <div className="fl w-third-l w-50-m w-100">
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Piping components are mechanical parts that are used to join or assemble piping systems that contain fluid and are under pressure.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="componentType" value={componentType} label="Piping Component Type">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Horizontal Run" value="Horizontal Run" />
                <E2GSelectFieldOption label="Vertical Run" value="Vertical Run" />
                <E2GSelectFieldOption label="Elbow on Horizontal Run" value="Horizontal Elbow" />
                <E2GSelectFieldOption label="Elbow at Top of Vertical Run" value="Top of Vertical Elbow" />
                <E2GSelectFieldOption label="Elbow at Bottom of Vertical Run" value="Bottom of Vertical Elbow" />
                <E2GSelectFieldOption label="Horizontal Run after Vertical Run" value="Horizontal Run after Vertical Run" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>The material of construction refers to the specific materials used to fabricate the pipes and related components.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="materialOfConstruction" value={materialOfConstruction} label="Material of Construction">
                <E2GSelectFieldOption label="Carbon Steel" value="CS" />
                <E2GSelectFieldOption label="Stainless Steel" value="SS" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Component design life refers to the expected operational lifespan of a pipeline component under specified conditions.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="maxTime" value={maxTime} label="Component Design Life" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>The inner diameter refers to the measurement across the inside of the pipe.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="innerDiameter" value={innerDiameter} label="Inner Diameter" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Component nominal thickness refers to the specified thickness of a pipe wall or other pipeline component as defined by industry standards.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="nominalThickness" value={nominalThickness} label="Component Nominal Thickness" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Component undertolerance refers to the allowable deviation below the specified nominal thickness of a pipeline component.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="undertolerance" value={undertolerance} label="Component Undertolerance" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>The component install Date refers to the specific date when a pipeline component, such as a pipe, valve, or fitting, is installed into the pipeline system.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="installReplaceDate" value={installReplaceDate} label="Component Install Date (MM/DD/YYYY)" type="date" required />
              {/* </E2GInfoButton> */}
            </div>
            <div className="fl w-third-l w-50-m w-100">
              {materialOfConstruction !== 'SS'
                ? (
                  <div>
                    <E2GSelectField name="failureThicknessMethod" value={failureThicknessMethod} label="Failure Thickness Method">
                      <E2GSelectFieldOption label="Specify" value="specify" />
                      <E2GSelectFieldOption label="Calculate" value="calculate" />
                    </E2GSelectField>
                    {failureThicknessMethod === 'calculate'
                      ? (
                        <div>
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>The internal pressure refers to the pressure exerted by the fluid inside the pipeline.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GTextField name="internalPressure" value={internalPressure} label="Internal Pressure" type="number" required />
                          {/* </E2GInfoButton> */}
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>The flow stress refers to the stress exerted on the pipeline walls due to the dynamic pressure of the fluid flowing through it.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GTextField name="flowStress" value={flowStress} label="Flow Stress" type="number" required />
                          {/* </E2GInfoButton> */}
                        </div>
                      )
                      : <div>
                        <E2GInfoButton
                          tooltip={<div>
                            <p>Failure thickness is the thickness at which the component will fail. When calculating the failure thickness, consider the following:</p>
                            <li>Best pressure/cylinder failure pressure is using method in Sage PBUSRT Module, this method is also in API 579.</li>
                            <li>Material spec (most IDMS will have this) and use PBURST directly</li>
                            <p>Other ways include internal pressure (psi) and flow stress (ksi), and by using the following equation can then derive failure thickness:</p>
                            <li>failureThickness = (internalPressure / 1000) * innerDiameter / 2.0 / flowStress</li>
                          </div>}
                          dialog
                        >
                          <E2GTextField name="failureThickness" value={failureThickness} label="Component Failure Thickness" type="number" required />
                        </E2GInfoButton>
                      </div>}
                    <E2GSelectField name="failureThicknessVarianceType" value={failureThicknessVarianceType} label="Failure Thickness Variance Type">
                      <E2GSelectFieldOption label="Confidence" value="confidence" />
                      <E2GSelectFieldOption label="Standard Deviation" value="standardDeviation" />
                      <E2GSelectFieldOption label="CV" value="cv" />
                    </E2GSelectField>
                    {failureThicknessVarianceType === 'confidence'
                      ? (
                        <E2GSelectField name="failureThicknessConfidence" value={failureThicknessConfidence} label="Failure Thickness Confidence">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Low (CV=0.2)" value="Low" />
                          <E2GSelectFieldOption label="Moderate (CV=0.1)" value="Moderate" />
                          <E2GSelectFieldOption label="High (CV=0.05)" value="High" />
                        </E2GSelectField>
                      )
                      : ''}
                    {failureThicknessVarianceType === 'standardDeviation'
                      ? (
                        <E2GTextField name="failureThicknessSD" value={failureThicknessSD} label="Failure Thickness SD" type="number" required />
                      )
                      : ''}
                    {failureThicknessVarianceType === 'cv'
                      ? (
                        <E2GTextField name="failureThicknessCV" value={failureThicknessCV} label="Failure Thickness CV" type="number" required />
                      )
                      : ''}
                  </div>
                )
                : ''}
              {runQualMethodsBool === true && materialOfConstruction === 'CS'
                ? (
                  <div>
                    {/* <E2GInfoButton
                      tooltip={<div>
                        <p>Coating age refers to the length of time a protective coating has been in service on a pipeline.</p>
                        <p>Note that component age (i.e., time between the analysis date and the component install date) must be greater than or equal to coating age.</p>
                      </div>}
                      dialog
                    > */}
                    <E2GTextField name="coatingAge" value={coatingAge} label="Coating Age" type="number" required />
                    {/* </E2GInfoButton> */}
                    {/* <E2GInfoButton
                      tooltip={<div>
                        <p>The insulation system age refers to the duration that an insulation system has been in service on a pipeline.</p>
                      </div>}
                      dialog
                    > */}
                    <E2GTextField name="insulationAge" value={insulationAge} label="Insulation System Age" type="number" required />
                    {/* </E2GInfoButton> */}
                  </div>
                )
                : ''}
            </div>
            <div className="fl w-third-l w-50-m w-100">
              {materialOfConstruction === 'SS'
                ? (
                  <div>
                    {/* <E2GInfoButton
                      tooltip={<div>
                        <p>The stress state refers to the distribution of internal forces within the pipeline material due to various loads and operating conditions.</p>
                      </div>}
                      dialog
                    > */}
                    <E2GSelectField name="stressState" value={stressState} label="Stress State">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Near Yield" value="Near Yield" />
                      <E2GSelectFieldOption label="Allowable to Yield" value="Allowable to Yield" />
                      <E2GSelectFieldOption label="Zero to Allowable" value="Zero to Allowable" />
                      <E2GSelectFieldOption label="Compressive" value="Compressive" />
                    </E2GSelectField>
                    {/* </E2GInfoButton> */}
                    <E2GInfoButton
                      tooltip={<div>
                        <p>An acidic environment refers to conditions where the pH level is below 7.0, indicating a higher concentration of hydrogen ions.</p>
                      </div>}
                      dialog
                    >
                      <E2GSelectField name="acidicEnvironment" value={acidicEnvironment} label="Acidic Environment?">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Yes" value="Yes" />
                        <E2GSelectFieldOption label="No" value="No" />
                      </E2GSelectField>
                    </E2GInfoButton>
                    {/* <E2GInfoButton
                      tooltip={<div>
                        <p>Critical flaw depth refers to the maximum depth of a defect, such as a crack or corrosion pit, that a pipeline can tolerate before it is at risk of failure under operating conditions.</p>
                      </div>}
                      dialog
                    > */}
                    <E2GTextField name="criticalFlawDepth" value={criticalFlawDepth} label="Critical Flaw Depth" type="number" required />
                    {/* </E2GInfoButton> */}
                    <E2GSelectField name="criticalFlawDepthVarType" value={criticalFlawDepthVarType} label="Critical Flaw Depth Type">
                      <E2GSelectFieldOption label="SD" value="sd" />
                      <E2GSelectFieldOption label="CV" value="cv" />
                      <E2GSelectFieldOption label="Confidence" value="Confidence" />
                    </E2GSelectField>
                    {criticalFlawDepthVarType === 'sd'
                      ? (
                        <E2GTextField name="criticalFlawDepthSd" value={criticalFlawDepthSd} label="criticalFlawDepthSd" type="number" required />
                      )
                      : ''}
                    {criticalFlawDepthVarType === 'cv'
                      ? (
                        <E2GTextField name="criticalFlawDepthCv" value={criticalFlawDepthCv} label="criticalFlawDepthCv" type="number" required />
                      )
                      : ''}
                    {criticalFlawDepthVarType === 'Confidence'
                      ? (
                        <E2GSelectField name="criticalFlawDepthConfidence" value={criticalFlawDepthConfidence} label="Critical Flaw Depth Confidence">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Low (SD=0.02)" value="Low" />
                          <E2GSelectFieldOption label="Moderate (SD=0.01)" value="Moderate" />
                          <E2GSelectFieldOption label="High (SD=0.005)" value="High" />
                        </E2GSelectField>
                      )
                      : ''}

                  </div>
                )
                : ''}
            </div>
          </div>
        )
        : ''}
      {problemType === 'PROB3'
        ? (
          <div className="cf w-100">
            <E2GInfoButton tooltip={<div> <img src={inspectionGuid} alt="" /> </div>} dialog>
              <p>See best practice guidelines for identifying vulnerable locations</p>
            </E2GInfoButton>
            <ToggleField name="runCondensation" value={runCondensation} label="Enable Condensation Networks" />
            <div className="fl w-50-l w-100">
              {probabilisticInputFieldFlag === false
                ? (
                  <div>
                    <E2GTextField name="nominalThickness" value={nominalThickness} label="Component Nominal Thickness" type="number" required />
                    <E2GTextField name="undertolerance" value={undertolerance} label="Component Undertolerance" type="number" required />
                  </div>
                )
                : <div >
                  <div>
                    <ProbabilisticInputField
                      name="nominalThicknessProb"
                      jsonEditable
                      value={nominalThicknessProb}
                      variableType="continuous"
                      InputProps={{
                        label: 'Nominal Thickness (50th Percentile)',
                        jsonKeyPath: 'median',
                        units: {
                          USCustomary: 'in',
                          Metric: 'mm',
                        },
                        isNumber: false,
                        required: true,
                      }}
                      DialogProps={{
                        inputFields: [{
                          label: 'Distribution Type',
                          jsonKeyPath: 'distributionType',
                          select: true,
                          options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                        }, {
                          label: 'Variance Type',
                          jsonKeyPath: 'varianceType',
                          select: true,
                          options(parsedJson) {
                            if(parsedJson.distributionType === 'normal') {
                              return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }, { value: 'underTolerance', label: 'Component Undertolerance' }]
                            }
                            return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                          },
                        }, {
                          label: 'Standard Deviation',
                          jsonKeyPath: 'standardDeviation',
                          isNumber: true,
                          units: {
                            USCustomary: 'in',
                            Metric: 'mm',
                          },
                          hide(parsedJson) {
                            return parsedJson.varianceType !== 'standardDeviation'
                          },
                        }, {
                          label: 'Upper Bound',
                          jsonKeyPath: 'upperBound',
                          isNumber: true,
                          hide(parsedJson) {
                            return parsedJson.varianceType !== 'upperBound'
                          },
                          units: {
                            USCustomary: 'in',
                            Metric: 'mm',
                          },
                        }, {
                          label: 'Upper Bound Probability',
                          jsonKeyPath: 'upperBoundProbability',
                          isNumber: true,
                          hide(parsedJson) {
                            return parsedJson.varianceType !== 'upperBound'
                          },
                          units: {
                            USCustomary: '%',
                            Metric: '%',
                          },
                        }, {
                          label: 'Component Undertolerance',
                          jsonKeyPath: 'underTolerance',
                          isNumber: true,
                          hide(parsedJson) {
                            return parsedJson.varianceType !== 'underTolerance'
                          },
                          units: {
                            USCustomary: '%',
                            Metric: '%',
                          },
                        }, {
                          label: 'Scale Parameter',
                          jsonKeyPath: 'scale',
                          isNumber: true,
                          hide(parsedJson) {
                            return parsedJson.varianceType !== 'scale'
                          },
                        }, {
                          label: 'CV (SD/Mean)',
                          jsonKeyPath: 'cv',
                          isNumber: true,
                          hide(parsedJson) {
                            return parsedJson.varianceType !== 'cv'
                          },
                        }],
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <E2GInfoButton
                      tooltip={<div>
                        <p>Failure thickness is the thickness at which the component will fail. When calculating the failure thickness, consider the following:</p>
                        <li>Best pressure/cylinder failure pressure is using method in Sage PBUSRT Module, this method is also in API 579.</li>
                        <li>Material spec (most IDMS will have this) and use PBURST directly</li>
                        <p>Other ways include internal pressure (psi) and flow stress (ksi), and by using the following equation can then derive failure thickness:</p>
                        <li>failureThickness = (internalPressure / 1000) * innerDiameter / 2.0 / flowStress</li>
                      </div>}
                      dialog
                    >
                      <ProbabilisticInputField
                        name="failureThicknessProb"
                        jsonEditable
                        value={failureThicknessProb}
                        variableType="continuous"
                        InputProps={{
                          label: 'Failure Thickness (50th Percentile)',
                          jsonKeyPath: 'median',
                          units: {
                            USCustomary: 'in',
                            Metric: 'mm',
                          },
                          isNumber: false,
                          required: true,
                        }}
                        DialogProps={{
                          inputFields: [{
                            label: 'Distribution Type',
                            jsonKeyPath: 'distributionType',
                            select: true,
                            options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                          }, {
                            label: 'Variance Type',
                            jsonKeyPath: 'varianceType',
                            select: true,
                            options(parsedJson) {
                              if(parsedJson.distributionType === 'normal') {
                                return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                              }
                              return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                            },
                          }, {
                            label: 'Standard Deviation',
                            jsonKeyPath: 'standardDeviation',
                            isNumber: true,
                            units: {
                              USCustomary: 'in',
                              Metric: 'mm',
                            },
                            hide(parsedJson) {
                              return parsedJson.varianceType !== 'standardDeviation'
                            },
                          }, {
                            label: 'Upper Bound',
                            jsonKeyPath: 'upperBound',
                            isNumber: true,
                            hide(parsedJson) {
                              return parsedJson.varianceType !== 'upperBound'
                            },
                            units: {
                              USCustomary: 'in',
                              Metric: 'mm',
                            },
                          }, {
                            label: 'Upper Bound Probability',
                            jsonKeyPath: 'upperBoundProbability',
                            isNumber: true,
                            hide(parsedJson) {
                              return parsedJson.varianceType !== 'upperBound'
                            },
                            units: {
                              USCustomary: '%',
                              Metric: '%',
                            },
                          }, {
                            label: 'Scale Parameter',
                            jsonKeyPath: 'scale',
                            isNumber: true,
                            hide(parsedJson) {
                              return parsedJson.varianceType !== 'scale'
                            },
                          }, {
                            label: 'CV (SD/Mean)',
                            jsonKeyPath: 'cv',
                            isNumber: true,
                            hide(parsedJson) {
                              return parsedJson.varianceType !== 'cv'
                            },
                          }],
                        }}
                      />
                    </E2GInfoButton>
                  </div>
                </div>}
              <div className="pt2">
                <E2GTextField name="installReplaceDate" value={installReplaceDate} label="Component Install Date (MM/DD/YYYY)" type="date" required />
                <E2GSelectField name="componentType" value={componentType} label="Component Type">
                  <E2GSelectFieldOption label="Piping Horizontal Run" value="Horizontal Run" />
                  <E2GSelectFieldOption label="Piping Vertical Run" value="Vertical Run" />
                  <E2GSelectFieldOption label="Piping Horizontal Run Elbow" value="Horizontal Elbow" />
                  <E2GSelectFieldOption label="Piping Elbow at Top of Vertical Run" value="Elbow on Top of Vertical Run" />
                  <E2GSelectFieldOption label="Piping Elbow at Bottom of Vertical Run" value="Elbow on Bottom of Vertical Run" />
                  <E2GSelectFieldOption label="Piping Horizontal Run after Vertical Run" value="Horizontal Run after Vertical Run" />
                  <E2GSelectFieldOption label="Horizontal Vessel" value="Horizontal Vessel" />
                  <E2GSelectFieldOption label="Vertical Vessel" value="Vertical Vessel" />
                  <E2GSelectFieldOption label="Tank" value="Tank" />
                  <E2GSelectFieldOption label="Vent" value="Vent" />
                  <E2GSelectFieldOption label="Tee" value="Tee" />
                  <E2GSelectFieldOption label="Drain" value="Drain" />
                </E2GSelectField>
                <input type="hidden" name="materialOfConstructionHybrid" value={'CS'} />
                {componentType === 'Horizontal Vessel' || componentType === 'Vertical Vessle' || componentType === 'Tank'
                  ? (
                    <E2GTextField name="innerDiameterFE" value={innerDiameterFE} label="Inner Diameter" type="number" required />
                  )
                  : <E2GTextField name="innerDiameter" value={innerDiameter} label="Inner Diameter" type="number" required />}
              </div>
            </div>
            <div className="fl w-50-l w-100">
              {probabilisticInputFieldFlag === false
                ? (
                  <div>
                    <E2GSelectField name="failureThicknessMethod" value={failureThicknessMethod} label="Failure Thickness Method">
                      <E2GSelectFieldOption label="Specify" value="specify" />
                      <E2GSelectFieldOption label="Calculate" value="calculate" />
                    </E2GSelectField>
                    {failureThicknessMethod === 'calculate'
                      ? (
                        <div>
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>The internal pressure refers to the pressure exerted by the fluid (liquid or gas) inside the pipeline.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GTextField name="internalPressure" value={internalPressure} label="Internal Pressure" type="number" required />
                          {/* </E2GInfoButton> */}
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>The flow stress refers to the stress exerted on the pipeline walls due to the dynamic pressure of the fluid flowing through it.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GTextField name="flowStress" value={flowStress} label="Flow Stress" type="number" required />
                          {/* </E2GInfoButton> */}
                        </div>
                      )
                      : <div>
                        <E2GInfoButton
                          tooltip={<div>
                            <p>Failure thickness is the thickness at which the component will fail. When calculating the failure thickness, consider the following:</p>
                            <li>Best pressure/cylinder failure pressure is using method in Sage PBUSRT Module, this method is also in API 579.</li>
                            <li>Material spec (most IDMS will have this) and use PBURST directly</li>
                            <p>Other ways include internal pressure (psi) and flow stress (ksi), and by using the following equation can then derive failure thickness:</p>
                            <li>failureThickness = (internalPressure / 1000) * innerDiameter / 2.0 / flowStress</li>
                          </div>}
                          dialog
                        >
                          <E2GTextField name="failureThickness" value={failureThickness} label="Component Failure Thickness" type="number" required />
                        </E2GInfoButton>

                      </div>}
                    <E2GSelectField name="failureThicknessVarianceType" value={failureThicknessVarianceType} label="Failure Thickness Variance Type">
                      <E2GSelectFieldOption label="Confidence" value="confidence" />
                      <E2GSelectFieldOption label="Standard Deviation" value="standardDeviation" />
                      <E2GSelectFieldOption label="CV" value="cv" />
                    </E2GSelectField>
                    {failureThicknessVarianceType === 'confidence'
                      ? (
                        <E2GSelectField name="failureThicknessConfidence" value={failureThicknessConfidence} label="Failure Thickness Confidence">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Low (CV=0.2)" value="Low" />
                          <E2GSelectFieldOption label="Moderate (CV=0.1)" value="Moderate" />
                          <E2GSelectFieldOption label="High (CV=0.05)" value="High" />
                        </E2GSelectField>
                      )
                      : ''}
                    {failureThicknessVarianceType === 'standardDeviation'
                      ? (
                        <E2GTextField name="failureThicknessSD" value={failureThicknessSD} label="Failure Thickness SD" type="number" required />
                      )
                      : ''}
                    {failureThicknessVarianceType === 'cv'
                      ? (
                        <E2GTextField name="failureThicknessCV" value={failureThicknessCV} label="Failure Thickness CV" type="number" required />
                      )
                      : ''}
                  </div>
                )
                : ''}
            </div>
          </div>
        )
        : ''}
      {problemType === 'PROB2'
        ? (
          <div className="cf w-100">
            <E2GInfoButton
              tooltip={<div>
                <img src={inspectionGuid} alt="" />
              </div>}
              dialog
            >
              <p>See best practice guidelines for identifying vulnerable locations</p>
            </E2GInfoButton>
            <ToggleField name="runCondensation" value={runCondensation} label="Enable Condensation Networks" />
            <div className="fl w-third-l w-50-m w-100">
              <E2GSelectField name="componentType" value={componentType} label="Piping Component Type">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Horizontal Run" value="Horizontal Run" />
                <E2GSelectFieldOption label="Vertical Run" value="Vertical Run" />
                <E2GSelectFieldOption label="Elbow on Horizontal Run" value="Horizontal Elbow" />
                <E2GSelectFieldOption label="Elbow at Top of Vertical Run" value="Top of Vertical Elbow" />
                <E2GSelectFieldOption label="Elbow at Bottom of Vertical Run" value="Bottom of Vertical Elbow" />
                <E2GSelectFieldOption label="Horizontal Run after Vertical Run" value="Horizontal Run after Vertical Run" />
              </E2GSelectField>
              <E2GTextField name="maxTime" value={maxTime} label="Component Design Life" type="number" required />
              <E2GTextField name="innerDiameter" value={innerDiameter} label="Inner Diameter" type="number" required />
              <E2GTextField name="nominalThickness" value={nominalThickness} label="Component Nominal Thickness" type="number" required />
              <E2GTextField name="undertolerance" value={undertolerance} label="Component Undertolerance" type="number" required />
              <E2GTextField name="installReplaceDate" value={installReplaceDate} label="Component Install Date (MM/DD/YYYY)" type="date" required />
            </div>
            <div className="fl w-third-l w-50-m w-100">
              <E2GSelectField name="failureThicknessMethod" value={failureThicknessMethod} label="Failure Thickness Method">
                <E2GSelectFieldOption label="Specify" value="specify" />
                <E2GSelectFieldOption label="Calculate" value="calculate" />
              </E2GSelectField>
              {failureThicknessMethod === 'calculate'
                ? (
                  <div>
                    <E2GTextField name="internalPressure" value={internalPressure} label="Internal Pressure" type="number" required />
                    <E2GTextField name="flowStress" value={flowStress} label="Flow Stress" type="number" required />
                  </div>
                )
                : ''}
              {failureThicknessMethod !== 'calculate'
                ? (
                  <div>
                    <E2GInfoButton
                      tooltip={<div>
                        <p>Consider the following when deriving failure thickness:</p>
                        <li>Best pressure/cylinder failure pressure is using method in Sage PBUSRT Module, this method is also in API 579.</li>
                        <li>Material spec (most IDMS will have this) and use PBURST directly</li>
                        <p>Other ways include internal pressure (psi) and flow stress (ksi), and by using the following equation can then derive failure thickness:</p>
                        <li>failureThickness = (internalPressure / 1000) * innerDiameter / 2.0 / flowStress</li>
                      </div>}
                      dialog
                    >
                      <E2GTextField name="failureThickness" value={failureThickness} label="Component Failure Thickness" type="number" required />
                    </E2GInfoButton>
                  </div>
                )
                : ''}
              <E2GSelectField name="failureThicknessVarianceType" value={failureThicknessVarianceType} label="Failure Thickness Variance Type">
                <E2GSelectFieldOption label="Confidence" value="confidence" />
                <E2GSelectFieldOption label="Standard Deviation" value="standardDeviation" />
                <E2GSelectFieldOption label="CV" value="cv" />
              </E2GSelectField>
              {failureThicknessVarianceType === 'confidence'
                ? (
                  <E2GSelectField name="failureThicknessConfidence" value={failureThicknessConfidence} label="Failure Thickness Confidence">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Low (CV=0.2)" value="Low" />
                    <E2GSelectFieldOption label="Moderate (CV=0.1)" value="Moderate" />
                    <E2GSelectFieldOption label="High (CV=0.05)" value="High" />
                  </E2GSelectField>
                )
                : ''}
              {failureThicknessVarianceType === 'standardDeviation'
                ? (
                  <E2GTextField name="failureThicknessSD" value={failureThicknessSD} label="Failure Thickness SD" type="number" required />
                )
                : ''}
              {failureThicknessVarianceType === 'cv'
                ? (
                  <E2GTextField name="failureThicknessCV" value={failureThicknessCV} label="Failure Thickness CV" type="number" required />
                )
                : ''}
            </div>
          </div>
        )
        : ''}
      <Submit label="Next" />
    </div>
  )
}

Step1.propTypes = {
  problemType: PropTypes.string,
  maxTime: PropTypes.number,
  innerDiameterFE: PropTypes.number,
  nominalThickness: PropTypes.number,
  undertolerance: PropTypes.number,
  failureThickness: PropTypes.number,
  failureThicknessConfidence: PropTypes.string,
  failureThicknessMethod: PropTypes.string,
  failureThicknessVarianceType: PropTypes.string,
  failureThicknessSD: PropTypes.number,
  failureThicknessCV: PropTypes.number,
  internalPressure: PropTypes.number,
  flowStress: PropTypes.number,
  innerDiameter: PropTypes.number,
  runQualMethodsBool: PropTypes.bool,
  componentAge: PropTypes.number,
  coatingAge: PropTypes.number,
  insulationAge: PropTypes.number,
  componentType: PropTypes.string,
  stressState: PropTypes.string,
  acidicEnvironment: PropTypes.string,
  materialOfConstruction: PropTypes.string,
  materialOfConstructionHybrid: PropTypes.string,
  criticalFlawDepthVarType: PropTypes.string,
  criticalFlawDepth: PropTypes.number,
  criticalFlawDepthSd: PropTypes.number,
  criticalFlawDepthCv: PropTypes.number,
  criticalFlawDepthConfidence: PropTypes.string,
  installReplaceDate: PropTypes.string,
  componentAgeVsInstallDateBool: PropTypes.bool,
  runCondensation: PropTypes.bool,
  probabilisticInputFieldFlag: PropTypes.bool,
  nominalThicknessProb: PropTypes.string,
  failureThicknessProb: PropTypes.string,
  componentTypeProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  runCondensation: state.workflow.fields.runCondensation,
  maxTime: state.workflow.fields.maxTime,
  innerDiameterFE: state.workflow.fields.innerDiameterFE,
  nominalThickness: state.workflow.fields.nominalThickness,
  undertolerance: state.workflow.fields.undertolerance,
  failureThickness: state.workflow.fields.failureThickness,
  failureThicknessConfidence: state.workflow.fields.failureThicknessConfidence,
  failureThicknessMethod: state.workflow.fields.failureThicknessMethod,
  failureThicknessVarianceType: state.workflow.fields.failureThicknessVarianceType,
  failureThicknessSD: state.workflow.fields.failureThicknessSD,
  failureThicknessCV: state.workflow.fields.failureThicknessCV,
  internalPressure: state.workflow.fields.internalPressure,
  flowStress: state.workflow.fields.flowStress,
  innerDiameter: state.workflow.fields.innerDiameter,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  componentAge: state.workflow.fields.componentAge,
  coatingAge: state.workflow.fields.coatingAge,
  insulationAge: state.workflow.fields.insulationAge,
  componentType: state.workflow.fields.componentType,
  stressState: state.workflow.fields.stressState,
  acidicEnvironment: state.workflow.fields.acidicEnvironment,
  materialOfConstruction: state.workflow.fields.materialOfConstruction,
  materialOfConstructionHybrid: state.workflow.fields.materialOfConstructionHybrid,
  criticalFlawDepthVarType: state.workflow.fields.criticalFlawDepthVarType,
  criticalFlawDepth: state.workflow.fields.criticalFlawDepth,
  criticalFlawDepthSd: state.workflow.fields.criticalFlawDepthSd,
  criticalFlawDepthCv: state.workflow.fields.criticalFlawDepthCv,
  criticalFlawDepthConfidence: state.workflow.fields.criticalFlawDepthConfidence,
  installReplaceDate: state.workflow.fields.installReplaceDate,
  componentAgeVsInstallDateBool: state.workflow.fields.componentAgeVsInstallDateBool,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  nominalThicknessProb: state.workflow.fields.nominalThicknessProb,
  failureThicknessProb: state.workflow.fields.failureThicknessProb,
  componentTypeProb: state.workflow.fields.componentTypeProb,
})

export default connect(mapStateToProps)(Step1)
