import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import BayesViewer from '@e2grnd/ui-core/dist/components/bayes/BayesViewer'
import BeliefTable from '@e2grnd/ui-core/dist/components/bayes/BeliefTable'
import Dialog from '@e2grnd/ui-core/dist/components/ImageDialog'
import Close from '@e2grnd/ui-core/dist/components/ImageDialogClose'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'
import TextArea from '@e2grnd/ui-core/dist/containers/TextArea'

function BayesNetBuilderProb3({ results, decisionsStrategy, problemType }) {
  let bayesNetAll
  let poppedJsonLabels
  if(results.inspections[0] === 1) {
    if(results.beforeOrAfterInspections[0] === '2') {
      bayesNetAll = JSON.parse(results.poppedJsonOutsAll[0])
      poppedJsonLabels = results.poppedJsonOutAll_Labels
    } else if(results.beforeOrAfterInspections[0] === '1') {
      bayesNetAll = JSON.parse(results.poppedJsonOutsAllBefore[0])
      poppedJsonLabels = results.poppedJsonOutAll_LabelsBefore
    }
  } else if(results.inspections[0] === 0) {
    bayesNetAll = JSON.parse(results.poppedJsonOutsAll[0])
    poppedJsonLabels = results.poppedJsonOutAll_Labels
  }
  const networkNames = []
  const networkNamesBayes = []
  const networkIndices = []
  const items = []
  let bayesNet
  let indexEval = 0
  let j = 0
  for(let i = 0; i < poppedJsonLabels.length; i += 1) {
    items.push(<E2GSelectFieldOption label={poppedJsonLabels[i]} value={j.toString()} key={j}/>)
    networkNames.push(poppedJsonLabels[i])
    networkNamesBayes.push('Bayes Net Output File for ' + poppedJsonLabels[i])
    networkIndices.push(i)
    j = j + 1
  }

  let nodeNew
  const [whichNetwork, setWhichNetwork] = useState('0')
  const [states, setStates] = useState([])
  const [beliefs, setBeliefs] = useState([])

  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  if(typeof results.poppedJsonOutsAll[0] !== 'undefined') {
    indexEval = parseInt(whichNetwork)
    const networkIndex = networkIndices[indexEval]
    bayesNet = bayesNetAll[poppedJsonLabels[networkIndex]]
  }

  const onNodeClick = (node) => {
    if(node) {
      nodeNew = bayesNet.nodes.find((n) => n.name === node)
    }
    if(typeof nodeNew.states !== 'undefined' && typeof nodeNew.belief !== 'undefined') {
      setStates(nodeNew.states.states || [])
      setBeliefs(nodeNew.belief || [])
    } else {
      setStates([])
      setBeliefs([])
    }
  }

  useEffect(() => {
    setWhichNetwork('0')
  }, [results.beforeOrAfterInspections])

  useEffect(() => {
    setStates([])
    setBeliefs([])
    setWhichNetwork(results.whichNetwork)
  }, [results.whichNetwork])
  return (
    <div>
      {results.inspections[0] === 1
        ? (
          <E2GSelectFieldIndexer name="beforeOrAfterInspections" value={results.beforeOrAfterInspections[0]} label="Select Plot to View">
            <E2GSelectFieldOption label="Before Inspections" value="1" />
            <E2GSelectFieldOption label="After Inspections" value="2" />
          </E2GSelectFieldIndexer >
        )
        : '' }
      <E2GSelectFieldIndexer name="whichNetwork" value={whichNetwork} label="Select Network to View">
        {items}
      </E2GSelectFieldIndexer >
      <div>
        <div className="fl w-100">
          <Button
            color="primary"
            variant="contained"
            onClick={toggleOpen}
          >
            {networkNamesBayes[indexEval]}
          </Button>
          <Dialog
            title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
            modal={false}
            open={open}
            fullWidth
          >
            <TextArea label={networkNamesBayes[indexEval]} style={{ height: '50vh' }} value={JSON.stringify(bayesNet, null, 2)} disabled/>
          </Dialog>
        </div>
        <div className="pa5">
          <p>First, click GENERATE LAYOUT to view the network. Then click any nature node (green) to view the corrosponding belief table. Note, there are no belief tables for decision (blue) and utility (orange) nodes.</p>
        </div>
        <div className="fl w-third-l w-50-m w-100">
          <BeliefTable states={states} beliefs={beliefs} />
        </div>
        <div className="fl w-two-thirds-l w-50-m w-100">
          <Box height={500}> <BayesViewer bayesNet={bayesNet} onNodeClick={onNodeClick} /> </Box>
        </div>
      </div>
    </div>
  )
}

BayesNetBuilderProb3.propTypes = {
  results: PropTypes.object,
  decisionsStrategy: PropTypes.string,
  problemType: PropTypes.string,
}

const mapStateToProps = (state) => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  decisionsStrategy: state.workflow.fields.decisionsStrategy,
  problemType: state.workflow.fields.problemType,
})

export default connect(mapStateToProps, null)(BayesNetBuilderProb3)
