export default [
  {
    traces: [
      // {
      //   keyword: 'trace01',
      //   mode: 'lines',
      //   type: 'scatter',
      //   title: 'Jacketing Failure Time',
      //   hoverinfo: 'all',
      //   units: {
      //     USCustomary: ['DimensionlessEnglish'],
      //     Metric: ['DimensionlessMetric'],
      //   },
      // },
      // {
      //   keyword: 'trace02',
      //   mode: 'lines',
      //   type: 'scatter',
      //   title: 'Coating Failure Time',
      //   hoverinfo: 'all',
      //   units: {
      //     USCustomary: ['DimensionlessEnglish'],
      //     Metric: ['DimensionlessMetric'],
      //   },
      // },
      // {
      //   keyword: 'trace03',
      //   mode: 'lines',
      //   type: 'scatter',
      //   title: 'Component Initiation Time',
      //   hoverinfo: 'all',
      //   units: {
      //     USCustomary: ['DimensionlessEnglish'],
      //     Metric: ['DimensionlessMetric'],
      //   },
      // },
      // {
      //   keyword: 'trace04',
      //   mode: 'lines',
      //   type: 'scatter',
      //   title: 'Failure Thickness',
      //   hoverinfo: 'all',
      //   units: {
      //     USCustomary: ['DimensionlessEnglish'],
      //     Metric: ['DimensionlessMetric'],
      //   },
      // },
      {
        keyword: 'trace11_01',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11_1',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace12',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      // {
      //   keyword: 'trace06',
      //   mode: 'lines+markers',
      //   type: 'scatter',
      //   title: 'Updated CT',
      //   hoverinfo: 'all',
      //   units: {
      //     USCustomary: ['DimensionlessEnglish'],
      //     Metric: ['DimensionlessMetric'],
      //   },
      // },
    ],
    name: 'graphTemp1',
    title: 'Component Thickness Projection Before Inspections',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'failureCDFTrace',
        mode: 'lines',
        type: 'scatter',
        title: 'Failure Time CDF',
        hoverinfo: 'all',
      },
      {
        keyword: 'fiftyPercentile',
        mode: 'lines',
        type: 'scatter',
        title: '50th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyPercentile',
        mode: 'lines',
        type: 'scatter',
        title: '90th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyFivePercentile',
        mode: 'lines',
        type: 'scatter',
        title: '95th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyNinePercentile',
        mode: 'lines',
        type: 'scatter',
        title: '99th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'failureTimeCDF',
    title: 'Failure Time CDF Before Inspections',
    xAxis: {
      title: {
        USCustomary: 'Failure Time (yrs)',
        Metric: 'Failure Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Cumulative Probability',
        Metric: 'Cumulative Probability',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'failureCDFTraceAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: 'Failure Time CDF',
        hoverinfo: 'all',
      },
      {
        keyword: 'fiftyPercentileAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: '50th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyPercentileAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: '90th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyFivePercentileAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: '95th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
      {
        keyword: 'ninetyNinePercentileAfterInspections',
        mode: 'lines',
        type: 'scatter',
        title: '99th Percentile',
        hoverinfo: 'all',
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'failureTimeCDFAfterInspections',
    title: 'Failure Time CDF After Inspections',
    xAxis: {
      title: {
        USCustomary: 'Failure Time (yrs)',
        Metric: 'Failure Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Cumulative Probability',
        Metric: 'Cumulative Probability',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace07',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities for Replace Only Network',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp2',
    title: 'Total Expected Utilities for Replace Only Network',
    xAxis: {
      title: {
        USCustomary: 'Replacement Time (yrs)',
        Metric: 'Replacement Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace001RecoatTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp001RecoatTime',
    title: 'Total Expected Utilities',
    xAxis: {
      title: {
        USCustomary: 'Recoat Time (yrs)',
        Metric: 'Recoat Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace001ReplaceTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities for Recoat Only Network',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp001ReplaceTime',
    title: 'Total Expected Utilities for Recoat Only Network',
    xAxis: {
      title: {
        USCustomary: 'Replacement Time (yrs)',
        Metric: 'Replacement Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace002RejacketTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp002RejacketTime',
    title: 'Total Expected Utilities',
    xAxis: {
      title: {
        USCustomary: 'Rejacket Time (yrs)',
        Metric: 'Rejacket Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace002ReplaceTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities for Rejacket Only Network',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp002ReplaceTime',
    title: 'Total Expected Utilities for Rejacket Only Network',
    xAxis: {
      title: {
        USCustomary: 'Replacement Time (yrs)',
        Metric: 'Replacement Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace003ResealTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp003ResealTime',
    title: 'Total Expected Utilities',
    xAxis: {
      title: {
        USCustomary: 'Reseal Time (yrs)',
        Metric: 'Reseal Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace003ReplaceTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities for Reseal Only Network Network',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp003ReplaceTime',
    title: 'Total Expected Utilities for Reseal Only Network Network',
    xAxis: {
      title: {
        USCustomary: 'Replacement Time (yrs)',
        Metric: 'Replacement Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace004RecoatAndRejacketTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp004RecoatAndRejacketTime',
    title: 'Total Expected Utilities',
    xAxis: {
      title: {
        USCustomary: 'Recoat and Rejacket Time (yrs)',
        Metric: 'Recoat and Rejacket Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace004ReplaceTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities for Recoat and Rejacket Network',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp004ReplaceTime',
    title: 'Total Expected Utilities for Recoat and Rejacket Network',
    xAxis: {
      title: {
        USCustomary: 'Replacement Time (yrs)',
        Metric: 'Replacement Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace005RecoatAndResealTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp005RecoatAndResealTime',
    title: 'Total Expected Utilities',
    xAxis: {
      title: {
        USCustomary: 'Recoat and Reseal Time (yrs)',
        Metric: 'Recoat and Reseal Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace005ReplaceTime',
        mode: 'lines+markers',
        type: 'scatter',
        title: 'Total Expected Utilities for Recoat and Reseal Network',
        hoverinfo: 'all',
        units: {
          USCustomary: ['DimensionlessEnglish'],
          Metric: ['DimensionlessMetric'],
        },
      },
    ],
    name: 'graphTemp005ReplaceTime',
    title: 'Total Expected Utilities for Recoat and Reseal Network',
    xAxis: {
      title: {
        USCustomary: 'Replacement Time (yrs)',
        Metric: 'Replacement Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Total Expected Utility ($)',
        Metric: 'Total Expected Utility ($)',
      },
    },
  },
  {
    traces: [
      {
        keyword: 'trace08_01',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08_1',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace09',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTrace2',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp3',
    title: 'Component Thickness Projections After Inspections',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace11_01a',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11_1a',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11a',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace12a',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTraceA',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp1a',
    title: 'Component Failure due to Missing Jacketing<br>Thickness Projection Before Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace11_01b',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11_1b',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11b',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace12b',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTraceB',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp1b',
    title: 'Component Failure due to Jacket Sealant Failure<br>Thickness Projection Before Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.0,
    },
  },
  {
    traces: [
      {
        keyword: 'trace11_01c',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11_1c',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11c',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace12c',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTraceC',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp1c',
    title: 'Component Failure due to Jacketing Corrosion<br>Thickness Projection Before Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace11_01d',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11_1d',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11d',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace12d',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTraceD',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp1d',
    title: 'Component Failure due to Vapor Barrier Sealant Failure<br>Thickness Projection Before Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace11_01e',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11_1e',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace11e',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace12e',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTraceE',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp1e',
    title: 'Component Failure due to Missing Vapor Barrier<br>Thickness Projections Before Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace08_01a',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08_1a',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08a',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace09a',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTrace2A',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp3a',
    title: 'Component Failure due to Missing Jacketing<br>Thickness Projections After Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace08_01b',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08_1b',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08b',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace09b',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTrace2B',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp3b',
    title: 'Component Failure due to Jacket Sealant Failure<br>Thickness Projections After Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace08_01c',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08_1c',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08c',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace09c',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTrace2C',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp3c',
    title: 'Component Failure due to Jacketing Corrosion <br>Thickness Projections After Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace08_01d',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08_1d',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08d',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace09d',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTrace2D',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp3d',
    title: 'Component Failure due to Vapor Barrier Sealant Failure<br>Thickness Projection After Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
  {
    traces: [
      {
        keyword: 'trace08_01e',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (0.1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08_1e',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (1%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace08e',
        mode: 'lines',
        type: 'scatter',
        title: 'Lower Bound (10%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'trace09e',
        mode: 'lines',
        type: 'scatter',
        title: 'Median (50%)',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
      },
      {
        keyword: 'tminTrace2E',
        mode: 'lines',
        type: 'scatter',
        title: 'Mean Failure Thickness',
        hoverinfo: 'all',
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        line: {
          dash: 'dash',
          width: 4,
        },
      },
    ],
    name: 'graphTemp3e',
    title: 'Component Failure due to Missing Vapor Barrier<br>Thickness Projections After Inspections</br>',
    xAxis: {
      title: {
        USCustomary: 'Time (yrs)',
        Metric: 'Time (yrs)',
      },
    },
    yAxis: {
      title: {
        USCustomary: 'Thickness (in)',
        Metric: 'Thickness (mm)',
      },
    },
    legend: {
      orientation: 'v',
      bgcolor: 'rgba(0,0,0,0)',
      x: 1.1,
      xanchor: 'right',
      y: 1.1,
    },
  },
]
