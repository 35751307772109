/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
// import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'

import { plotResults, plotResultsCDF, plotResultsCorrosionJacketing, plotResultsMissingJacketing, plotResultsMissingVaporBarrier, plotResultsSealantJacketing, plotResultsSealantVaporBarrier } from '../lib/timeThickness'

const MultTableThickProj = ({ results, style, graphs, problemType, activeUnitSystem, failureTimeXInterpMult }) => {
  let graphsToDisplay01
  let graphsToDisplay02
  let graphsToDisplay1
  let graphsToDisplay2
  let graphsToDisplay3
  let graphsToDisplay4
  let graphsToDisplay5
  let graphsToDisplay6
  if(results.whichManyTablePlot[0] !== '0') {
    if(problemType === 'PROB1' || problemType === 'PROB3') {
      if(results.whichManyTablePlot[0] === '11') {
        graphsToDisplay01 = plotResults(results, activeUnitSystem)
      } else if(results.whichManyTablePlot[0] === '22') {
        graphsToDisplay02 = plotResultsCDF(results)
      }
    } else if(problemType === 'PROB2') {
      if(results.whichManyTablePlot[0] === '1') {
        graphsToDisplay1 = plotResultsMissingJacketing(results, activeUnitSystem)
      } else if(results.whichManyTablePlot[0] === '2') {
        graphsToDisplay2 = plotResultsSealantJacketing(results, activeUnitSystem)
      } else if(results.whichManyTablePlot[0] === '3') {
        graphsToDisplay3 = plotResultsCorrosionJacketing(results, activeUnitSystem)
      } else if(results.whichManyTablePlot[0] === '4') {
        graphsToDisplay4 = plotResultsMissingVaporBarrier(results, activeUnitSystem)
      } else if(results.whichManyTablePlot[0] === '5') {
        graphsToDisplay5 = plotResultsSealantVaporBarrier(results, activeUnitSystem)
      } else if(results.whichManyTablePlot[0] === '6') {
        graphsToDisplay6 = plotResultsCDF(results)
      }
    }
  }
  return (
    <div className="cf w-100">
      {results.whichManyTablePlot[0] !== '0'
        ? (
          <div>
            {problemType === 'PROB1' || problemType === 'PROB3'
              ? (
                <div className="cf w-100">
                  <div className="fl w-100">
                    <E2GSelectFieldIndexer name="whichManyTablePlot" value={results.whichManyTablePlot[0]} label="Select Plot to View">
                      <E2GSelectFieldOption label="Thickness Projection Plots" value="11" />
                      <E2GSelectFieldOption label="Failure Time CDF Plots" value="22" />
                    </E2GSelectFieldIndexer >
                  </div>
                  <p>Thickness Projection and Failure Time CDF Per Inspection Plots</p>
                  {results.whichManyTablePlot[0] === '11'
                    ? (
                      <div>
                        <DataGraphDisplay graphs={graphsToDisplay01} />
                      </div>
                    )
                    : ''}
                  {results.whichManyTablePlot[0] === '22'
                    ? (
                      <div>
                        <div>
                          <DataTableDisplay
                            id="failureTimeXInterpMult"
                            table={failureTimeXInterpMult}
                            colHeaders={{
                              USCustomary: [
                                'Inspection',
                                '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                '99th</br>Percentile</br>Failure</br>Time (yrs)',
                              ],
                              Metric: [
                                'Inspection',
                                '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                '99th</br>Percentile</br>Failure</br>Time (yrs)',
                              ],
                            }}
                          />
                        </div>
                        <DataGraphDisplay graphs={graphsToDisplay02} />
                      </div>
                    )
                    : ''}
                </div>
              )
              : ''}
            {problemType === 'PROB2'
              ? (
                <div className="cf w-100">
                  <div className="fl w-100">
                    {results.returnCondensationBool[0] === 0
                      ? (
                        <E2GSelectFieldIndexer name="whichManyTablePlot" value={results.whichManyTablePlot[0]} label="Select Plot to View">
                          <E2GSelectFieldOption label="Thickness Projection - Missing Jacketing" value="1" />
                          <E2GSelectFieldOption label="Thickness Projection - Sealant Jacketing" value="2" />
                          <E2GSelectFieldOption label="Thickness Projection - Corrosion Jacketing" value="3" />
                          <E2GSelectFieldOption label="Component Failure Time CDF" value="6" />
                        </E2GSelectFieldIndexer >
                      )
                      : ''}
                    {results.returnCondensationBool[0] === 1
                      ? (
                        <E2GSelectFieldIndexer name="whichManyTablePlot" value={results.whichManyTablePlot[0]} label="Select Plot to View">
                          <E2GSelectFieldOption label="Thickness Projection - Missing Jacketing" value="1" />
                          <E2GSelectFieldOption label="Thickness Projection - Sealant Jacketing" value="2" />
                          <E2GSelectFieldOption label="Thickness Projection - Corrosion Jacketing" value="3" />
                          <E2GSelectFieldOption label="Thickness Projection - Missing Vapor Barrier" value="4" />
                          <E2GSelectFieldOption label="Thickness Projection - Sealant Vapor Barrier" value="5" />
                          <E2GSelectFieldOption label="Component Failure Time CDF" value="6" />
                        </E2GSelectFieldIndexer >
                      )
                      : ''}
                  </div>
                  <div className="fl w-100">
                    {results.whichManyTablePlot[0] === '1'
                      ? (
                        <DataGraphDisplay graphs={graphsToDisplay1} />
                      )
                      : ''}
                    {results.whichManyTablePlot[0] === '2'
                      ? (
                        <DataGraphDisplay graphs={graphsToDisplay2} />
                      )
                      : ''}
                    {results.whichManyTablePlot[0] === '3'
                      ? (
                        <DataGraphDisplay graphs={graphsToDisplay3} />
                      )
                      : ''}
                    {results.whichManyTablePlot[0] === '4'
                      ? (
                        <DataGraphDisplay graphs={graphsToDisplay4} />
                      )
                      : ''}
                    {results.whichManyTablePlot[0] === '5'
                      ? (
                        <DataGraphDisplay graphs={graphsToDisplay5} />
                      )
                      : ''}
                    {results.whichManyTablePlot[0] === '6'
                      ? (
                        <div>
                          <div>
                            <DataTableDisplay
                              id="failureTimeXInterpMult"
                              table={failureTimeXInterpMult}
                              colHeaders={{
                                USCustomary: [
                                  'Inspection',
                                  '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                  '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                  '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                  '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                ],
                                Metric: [
                                  'Inspection',
                                  '50th</br>Percentile</br>Failure</br>Time (yrs)',
                                  '90th</br>Percentile</br>Failure</br>Time (yrs)',
                                  '95th</br>Percentile</br>Failure</br>Time (yrs)',
                                  '99th</br>Percentile</br>Failure</br>Time (yrs)',
                                ],
                              }}
                            />
                          </div>
                          <DataGraphDisplay graphs={graphsToDisplay6} />
                        </div>
                      )
                      : ''}
                  </div>
                </div>
              )
              : ''}
          </div>
        )
        : 'Only maintenance was performed'}
    </div>
  )
}

MultTableThickProj.propTypes = {
  results: PropTypes.object,
  graphs: PropTypes.object,
  problemType: PropTypes.string,
  style: PropTypes.object,
  activeUnitSystem: PropTypes.string,
  failureTimeXInterpMult: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  graphs: state.workflow.response ? state.workflow.response.results.graphs : undefined,
  problemType: state.workflow.fields.problemType,
  style: state.ui.style,
  activeUnitSystem: state.workflow.activeUnitSystem,
  failureTimeXInterpMult: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.failureTimeXInterpMult : undefined,

})
export default connect(mapStateToProps, null)(MultTableThickProj)
