/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

import { getNextTableDataVTE, getNextTableDataVTEPROB3 } from '../../lib/tableFunctions'

function Step3({ problemType, vteTable, vteTableSS, inspectionMaintenanceTable, inspectionInputType, materialOfConstruction, coatingConditionVTE, sealantFailure, vteCorrosionSeverity, priorNonIntrusiveVisualInspection, lastNonIntrusiveVisualInspectionTime, jacketingFailure, coatingFailureVTE, jacketCondition, missingAtVTE, jacketConditionGeneral, vteTablePROB3, vteTableSSPROB3, materialOfConstructionHybrid, probabilisticInputFieldFlag, vteCorrosionSeverityProb, coatingConditionVTEProb, sealantFailureProb, jacketConditionProb, jacketConditionGeneralProb, missingAtVTEProb }) {
  let valCheck
  if(inspectionInputType === 'manyTable') {
    if(problemType !== 'PROB3') {
      valCheck = getNextTableDataVTE(inspectionMaintenanceTable, vteTable, true, false)
    } else {
      valCheck = getNextTableDataVTEPROB3(inspectionMaintenanceTable, vteTablePROB3, true, false)
    }
  }
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        {inspectionInputType === 'manyTable'
          ? (
            <div>
              {valCheck !== 0
                ? (
                  <div>
                    {problemType === 'PROB1'
                      ? (
                        <div>
                          {materialOfConstruction !== 'SS'
                            ? (
                              <div>
                                <DataTableField
                                  id="vteTable"
                                  name="vteTable"
                                  value={getNextTableDataVTE(inspectionMaintenanceTable, vteTable, false, false)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'VTE Jacketing<br/>Sealant Condition?',
                                      'Jacketing Missing<br/>at VTE?',
                                      'VTE Jacketing<br/>Damage Severity?',
                                      'VTE Component<br/>Corrosion Severity?',
                                      'VTE Coating<br/>Damage Severity?',
                                    ],
                                    Metric: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'VTE Jacketing<br/>Sealant Condition?',
                                      'Jacketing Missing<br/>at VTE?',
                                      'VTE Jacketing<br/>Damage Severity?',
                                      'VTE Component<br/>Corrosion Severity?',
                                      'VTE Coating<br/>Damage Severity?',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                          {materialOfConstruction === 'SS'
                            ? (
                              <div>
                                <DataTableField
                                  id="vteTableSS"
                                  name="vteTableSS"
                                  value={getNextTableDataVTE(inspectionMaintenanceTable, vteTableSS, false, true)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'VTE Jacketing<br/>Sealant Condition?',
                                      'Jacketing Missing<br/>at VTE?',
                                      'VTE Jacketing<br/>Damage Severity?',
                                      'VTE Coating<br/>Damage Severity?',
                                    ],
                                    Metric: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'VTE Jacketing<br/>Sealant Condition?',
                                      'Jacketing Missing<br/>at VTE?',
                                      'VTE Jacketing<br/>Damage Severity?',
                                      'VTE Coating<br/>Damage Severity?',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB3'
                      ? (
                        <div>
                          {materialOfConstructionHybrid !== 'SS'
                            ? (
                              <div>
                                <DataTableField
                                  id="vteTablePROB3"
                                  name="vteTablePROB3"
                                  value={getNextTableDataVTEPROB3(inspectionMaintenanceTable, vteTablePROB3, false, false)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'VTE Jacketing<br/>Sealant Condition?',
                                      'Jacketing Missing<br/>at VTE?',
                                      'VTE Corrosion Jacketing<br/>Damage Severity?',
                                      'VTE General Jacketing<br/>Damage Severity?',
                                      'VTE Component<br/>Corrosion Severity?',
                                      'VTE Coating<br/>Damage Severity?',
                                    ],
                                    Metric: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'VTE Jacketing<br/>Sealant Condition?',
                                      'Jacketing Missing<br/>at VTE?',
                                      'VTE Corrosion Jacketing<br/>Damage Severity?',
                                      'VTE General Jacketing<br/>Damage Severity?',
                                      'VTE Component<br/>Corrosion Severity?',
                                      'VTE Coating<br/>Damage Severity?',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                          {materialOfConstructionHybrid === 'SS'
                            ? (
                              <div>
                                <DataTableField
                                  id="vteTableSSPROB3"
                                  name="vteTableSSPROB3"
                                  value={getNextTableDataVTEPROB3(inspectionMaintenanceTable, vteTableSSPROB3, false, true)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'VTE Jacketing<br/>Sealant Condition?',
                                      'Jacketing Missing<br/>at VTE?',
                                      'VTE Corrosion Jacketing<br/>Damage Severity?',
                                      'VTE General Jacketing<br/>Damage Severity?',
                                      'VTE Coating<br/>Damage Severity?',
                                    ],
                                    Metric: [
                                      'Inspection Date<br/>(MM/DD/YYYY) ',
                                      'VTE Jacketing<br/>Sealant Condition?',
                                      'Jacketing Missing<br/>at VTE?',
                                      'VTE Corrosion Jacketing<br/>Damage Severity?',
                                      'VTE General Jacketing<br/>Damage Severity?',
                                      'VTE Coating<br/>Damage Severity?',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB2'
                      ? (
                        <div>
                          <DataTableField
                            id="vteTable"
                            name="vteTable"
                            value={getNextTableDataVTE(inspectionMaintenanceTable, vteTable, false, false)}
                            startRows={4}
                            rowHeaders={false}
                            colHeaders={{
                              USCustomary: [
                                'Inspection Date<br/>(MM/DD/YYYY) ',
                                'VTE Jacketing<br/>Sealant Condition?',
                                'Jacketing Missing<br/>at VTE?',
                                'VTE Jacketing<br/>Damage Severity?',
                                'VTE Component<br/>Corrosion Severity?',
                                'VTE Coating<br/>Damage Severity?',
                              ],
                              Metric: [
                                'Inspection Date<br/>(MM/DD/YYYY) ',
                                'VTE Jacketing<br/>Sealant Condition?',
                                'Jacketing Missing<br/>at VTE?',
                                'VTE Jacketing<br/>Damage Severity?',
                                'VTE Component<br/>Corrosion Severity?',
                                'VTE Coating<br/>Damage Severity?',
                              ],
                            }}
                          />
                        </div>
                      )
                      : ''}
                  </div>
                )
                : 'No VTE inspection performed based on prior main inspection table'}
            </div>
          )
          : ''}
        {inspectionInputType === 'lastSingle'
          ? (
            <div>
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>VTE involves examining equipment or structures without opening or dismantling them. This type of inspection is aimed at replacing or deferring VTI by using external techniques to assess the condition and integrity of the equipment.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="priorNonIntrusiveVisualInspection" value={priorNonIntrusiveVisualInspection} label="Prior Non-Intrusive Visual Inspection?">
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {priorNonIntrusiveVisualInspection === 'Yes' // VTE
                ? (
                  <div>
                    {problemType === 'PROB1'
                      ? (
                        <div>
                          <div className="fl w-third-l w-50-m w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Time at which the VTE inspection was performed since the analysis date.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="lastNonIntrusiveVisualInspectionTime" value={lastNonIntrusiveVisualInspectionTime} label="Last Non-Intrusive Visual Inspection Time" type="number" required />
                            {/* </E2GInfoButton> */}
                            {materialOfConstruction !== 'SS'
                              ? (
                                // <E2GInfoButton
                                //   tooltip={<div>
                                //     <p>The degree of corrosion found at the component level after performing the VTE inspection.</p>
                                //   </div>}
                                //   dialog
                                // >
                                <E2GSelectField name="vteCorrosionSeverity" value={vteCorrosionSeverity} label="VTE Component Corrosion Severity?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="None" value="None" />
                                  <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                  <E2GSelectFieldOption label="Minor" value="Minor" />
                                  <E2GSelectFieldOption label="Major" value="Major" />
                                  <E2GSelectFieldOption label="Severe" value="Severe" />
                                </E2GSelectField>
                                // </E2GInfoButton>
                              )
                              : ''}
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The degree of corrosion found at the coating level after performing the VTE inspection.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="coatingConditionVTE" value={coatingConditionVTE} label="VTE Coating Damage Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>
                          <div className="fl w-third-l w-50-m w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The degree of corrosion found at the jacketing sealant level after performing the VTE inspection.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="sealantFailure" value={sealantFailure} label="VTE Jacketing Sealant Condition?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="No Sealant" value="None" />
                              <E2GSelectFieldOption label="Sealed" value="Sealed" />
                              <E2GSelectFieldOption label="Cracks no Gaps" value="Cracks no Gaps" />
                              <E2GSelectFieldOption label="Open Gaps" value="Open Gaps" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The degree of corrosion found at the jacketing level after performing the VTE inspection.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="jacketCondition" value={jacketCondition} label="VTE Jacketing Damage Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Indicate whether the jacketing has been stripped (i.e., now missing) after the VTE inspection.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="missingAtVTE" value={missingAtVTE} label="Jacketing Missing at VTE?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="Yes" value="Yes" />
                              <E2GSelectFieldOption label="No" value="No" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB3'
                      ? (
                        <div className="fl w-third-l w-50-m w-100">
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>Time at which the VTE inspection was performed since the analysis date.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GTextField name="lastNonIntrusiveVisualInspectionTime" value={lastNonIntrusiveVisualInspectionTime} label="Last Non-Intrusive Visual Inspection Time" type="number" required />
                          {/* </E2GInfoButton> */}
                          {probabilisticInputFieldFlag === false
                            ? (
                              <div>
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the component level after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="vteCorrosionSeverity" value={vteCorrosionSeverity} label="VTE Component Corrosion Severity?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="None" value="None" />
                                  <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                  <E2GSelectFieldOption label="Minor" value="Minor" />
                                  <E2GSelectFieldOption label="Major" value="Major" />
                                  <E2GSelectFieldOption label="Severe" value="Severe" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the coating level after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="coatingConditionVTE" value={coatingConditionVTE} label="VTE Coating Damage Severity?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="None" value="None" />
                                  <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                  <E2GSelectFieldOption label="Minor" value="Minor" />
                                  <E2GSelectFieldOption label="Major" value="Major" />
                                  <E2GSelectFieldOption label="Severe" value="Severe" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the jacketing sealant level after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="sealantFailure" value={sealantFailure} label="VTE Jacketing Sealant Condition?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="No Sealant" value="None" />
                                  <E2GSelectFieldOption label="Sealed" value="Sealed" />
                                  <E2GSelectFieldOption label="Cracks no Gaps" value="Cracks no Gaps" />
                                  <E2GSelectFieldOption label="Open Gaps" value="Open Gaps" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the jacketing level after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="jacketCondition" value={jacketCondition} label="VTE Corrosion Jacketing Damage Severity?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="None" value="None" />
                                  <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                  <E2GSelectFieldOption label="Minor" value="Minor" />
                                  <E2GSelectFieldOption label="Major" value="Major" />
                                  <E2GSelectFieldOption label="Severe" value="Severe" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found in general as a whole around the jacketing after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="jacketConditionGeneral" value={jacketConditionGeneral} label="VTE Other Jacketing Damage Severity?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="None" value="None" />
                                  <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                  <E2GSelectFieldOption label="Minor" value="Minor" />
                                  <E2GSelectFieldOption label="Major" value="Major" />
                                  <E2GSelectFieldOption label="Severe" value="Severe" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>Indicate whether the jacketing has been stripped (i.e., now missing) after the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="missingAtVTE" value={missingAtVTE} label="Jacketing Missing at VTE?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="Yes" value="Yes" />
                                  <E2GSelectFieldOption label="No" value="No" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                              </div>
                            )
                            : ''}
                          {probabilisticInputFieldFlag === true
                            ? (
                              <div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the component level after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="vteCorrosionSeverityProb"
                                    jsonEditable
                                    value={vteCorrosionSeverityProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'VTE Component Corrosion Severity?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the coating level after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="coatingConditionVTEProb"
                                    jsonEditable
                                    value={coatingConditionVTEProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'VTE Coating Damage Severity?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the jacketing sealant level after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="sealantFailureProb"
                                    jsonEditable
                                    value={sealantFailureProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'VTE Jacketing Sealant Condition?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the jacketing level after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="jacketConditionProb"
                                    jsonEditable
                                    value={jacketConditionProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'VTE Corrosion Jacketing Damage Severity?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found in general as a whole around the jacketing after performing the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="jacketConditionGeneralProb"
                                    jsonEditable
                                    value={jacketConditionGeneralProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'VTE Other Jacketing Damage Severity?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>Indicate whether the jacketing has been stripped (i.e., now missing) after the VTE inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="missingAtVTEProb"
                                    jsonEditable
                                    value={missingAtVTEProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'Jacketing Missing at VTE?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                              </div>
                            )
                            : ''}
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB2'
                      ? (
                        <div>
                          <div className="fl w-third-l w-50-m w-100">
                            <E2GTextField name="lastNonIntrusiveVisualInspectionTime" value={lastNonIntrusiveVisualInspectionTime} label="Last Non-Intrusive Visual Inspection Time" type="number" required />
                            <E2GSelectField name="vteCorrosionSeverity" value={vteCorrosionSeverity} label="VTE Component Corrosion Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                            <E2GSelectField name="coatingConditionVTE" value={coatingConditionVTE} label="VTE Coating Damage Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                          </div>
                          <div className="fl w-third-l w-50-m w-100">
                            <E2GSelectField name="sealantFailure" value={sealantFailure} label="VTE Jacketing Sealant Condition?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="No Sealant" value="None" />
                              <E2GSelectFieldOption label="Sealed" value="Sealed" />
                              <E2GSelectFieldOption label="Cracks no Gaps" value="Cracks no Gaps" />
                              <E2GSelectFieldOption label="Open Gaps" value="Open Gaps" />
                            </E2GSelectField>
                            <E2GSelectField name="jacketCondition" value={jacketCondition} label="VTE Jacketing Damage Severity?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None" value="None" />
                              <E2GSelectFieldOption label="Minimal" value="Minimal" />
                              <E2GSelectFieldOption label="Minor" value="Minor" />
                              <E2GSelectFieldOption label="Major" value="Major" />
                              <E2GSelectFieldOption label="Severe" value="Severe" />
                            </E2GSelectField>
                            <E2GSelectField name="missingAtVTE" value={missingAtVTE} label="Jacketing Missing at VTE?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="Yes" value="Yes" />
                              <E2GSelectFieldOption label="No" value="No" />
                            </E2GSelectField>
                          </div>
                        </div>
                      )
                      : ''}
                  </div>
                )
                : ''}
            </div>
          )
          : ''}
      </div>
      <Submit label="Next" />
    </div>
  )
}

Step3.propTypes = {
  problemType: PropTypes.string,
  priorNonIntrusiveVisualInspection: PropTypes.string,
  lastNonIntrusiveVisualInspectionTime: PropTypes.number,
  vteCorrosionSeverity: PropTypes.string,
  sealantFailure: PropTypes.string,
  jacketingFailure: PropTypes.string,
  coatingFailureVTE: PropTypes.string,
  jacketCondition: PropTypes.string,
  coatingConditionVTE: PropTypes.string,
  missingAtVTE: PropTypes.string,
  materialOfConstruction: PropTypes.string,
  materialOfConstructionHybrid: PropTypes.string,
  inspectionInputType: PropTypes.string,
  jacketConditionGeneral: PropTypes.string,
  inspectionMaintenanceTable: PropTypes.array,
  vteTable: PropTypes.array,
  vteTableSS: PropTypes.array,
  vteTablePROB3: PropTypes.array,
  vteTableSSPROB3: PropTypes.array,
  probabilisticInputFieldFlag: PropTypes.bool,
  vteCorrosionSeverityProb: PropTypes.string,
  coatingConditionVTEProb: PropTypes.string,
  sealantFailureProb: PropTypes.string,
  jacketConditionProb: PropTypes.string,
  jacketConditionGeneralProb: PropTypes.string,
  missingAtVTEProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  priorNonIntrusiveVisualInspection: state.workflow.fields.priorNonIntrusiveVisualInspection,
  lastNonIntrusiveVisualInspectionTime: state.workflow.fields.lastNonIntrusiveVisualInspectionTime,
  vteCorrosionSeverity: state.workflow.fields.vteCorrosionSeverity,
  sealantFailure: state.workflow.fields.sealantFailure,
  jacketingFailure: state.workflow.fields.jacketingFailure,
  coatingFailureVTE: state.workflow.fields.coatingFailureVTE,
  jacketCondition: state.workflow.fields.jacketCondition,
  coatingConditionVTE: state.workflow.fields.coatingConditionVTE,
  missingAtVTE: state.workflow.fields.missingAtVTE,
  jacketConditionGeneral: state.workflow.fields.jacketConditionGeneral,
  materialOfConstruction: state.workflow.fields.materialOfConstruction,
  materialOfConstructionHybrid: state.workflow.fields.materialOfConstructionHybrid,
  inspectionInputType: state.workflow.fields.inspectionInputType,
  inspectionMaintenanceTable: state.workflow.fields.inspectionMaintenanceTable,
  vteTable: state.workflow.fields.vteTable,
  vteTableSS: state.workflow.fields.vteTableSS,
  vteTablePROB3: state.workflow.fields.vteTablePROB3,
  vteTableSSPROB3: state.workflow.fields.vteTableSSPROB3,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  vteCorrosionSeverityProb: state.workflow.fields.vteCorrosionSeverityProb,
  coatingConditionVTEProb: state.workflow.fields.coatingConditionVTEProb,
  sealantFailureProb: state.workflow.fields.sealantFailureProb,
  jacketConditionProb: state.workflow.fields.jacketConditionProb,
  jacketConditionGeneralProb: state.workflow.fields.jacketConditionGeneralProb,
  missingAtVTEProb: state.workflow.fields.missingAtVTEProb,
})

export default connect(mapStateToProps)(Step3)
