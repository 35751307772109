/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

const POFAtTimes = ({ results, problemType, v0ComponentPOFsFromAnalysisDateReg, v0ComponentPOFsFromAnalysisDateCond, v1ComponentPOFsFromAnalysisDateReg, v1ComponentPOFsFromAnalysisDateCond, v2ComponentPOFsFromAnalysisDate, v2ComponentPOFsFromAnalysisDatePrior, convergenceTable }) => {
  return (
    <div className="cf w-100">
      {problemType === 'PROB1'
        ? (
          <div className="cf w-100">
            {results.returnCondensationBool[0] === 0
              ? (
                <div>
                  <DataTableDisplay
                    id="v0ComponentPOFsFromAnalysisDateReg"
                    table={v0ComponentPOFsFromAnalysisDateReg}
                    colHeaders={{
                      USCustomary: [
                        'POF Times',
                        'Component POF (%)',
                        'Component Jacketing POF (%)',
                        'Component Coating POF (%)',
                      ],
                      Metric: [
                        'POF Times',
                        'Component POF (%)',
                        'Component Jacketing POF (%)',
                        'Component Coating POF (%)',
                      ],
                    }}
                  />
                </div>
              )
              : ''}
            {results.returnCondensationBool[0] === 1
              ? (
                <div>
                  <DataTableDisplay
                    id="v0ComponentPOFsFromAnalysisDateCond"
                    table={v0ComponentPOFsFromAnalysisDateCond}
                    colHeaders={{
                      USCustomary: [
                        'POF Times',
                        'Component POF (%)',
                        'Component Jacketing POF (%)',
                        'Component Coating POF (%)',
                        'Component Missing Vapor Barrier POF (%)',
                        'Component Sealant Vapor Barrier POF (%)',
                      ],
                      Metric: [
                        'POF Times',
                        'Component Failure POF (%)',
                        'Component Jacketing POF (%)',
                        'Component Coating POF (%)',
                        'Component Missing Vapor Barrier POF (%)',
                        'Component Sealant Vapor Barrier POF (%)',
                      ],
                    }}
                  />
                </div>
              )
              : ''}
            <div className="fl w-100">
              <p>Failure Time Convergence Table (Failure Time Tolerance Criterion = 0.1)</p>
              <DataTableDisplay
                id="convergenceTable"
                table={convergenceTable}
                colHeaders={{
                  USCustomary: [
                    'Cycle Information',
                    'Failure Time (yrs)<br>at Target Min POF',
                    'Failure Time (yrs)<br>Difference Between Cycles',
                    'POF (%) Now',
                    'POF (%) Difference Between Cycles',
                  ],
                  Metric: [
                    'Cycle Information',
                    'Failure Time (yrs)<br>at Target Min POF',
                    'Failure Time (yrs)<br>Difference Between Cycles',
                    'POF (%) Now',
                    'POF (%) Difference Between Cycles',
                  ],
                }}
              />
            </div>
          </div>
        )
        : ''}
      {problemType === 'PROB3'
        ? (
          <div className="cf w-100">
            {results.inspections[0] === 1
              ? (
                <div className="cf w-100">
                  <div className="fl w-50-l w-50-m w-100">
                    <p>Before Inspections Results</p>
                    <DataTableDisplay
                      id="v2ComponentPOFsFromAnalysisDatePrior"
                      table={v2ComponentPOFsFromAnalysisDatePrior}
                      colHeaders={{
                        USCustomary: [
                          'POF Times',
                          'Component POF (%)',
                          'Component Jacketing POF (%)',
                          'Component Coating POF (%)',
                        ],
                        Metric: [
                          'POF Times',
                          'Component POF (%)',
                          'Component Jacketing POF (%)',
                          'Component Coating POF (%)',
                        ],
                      }}
                    />
                  </div>
                  <div className="fl w-50-l w-50-m w-100">
                    <p>After Inspections Results</p>
                    <DataTableDisplay
                      id="v2ComponentPOFsFromAnalysisDate"
                      table={v2ComponentPOFsFromAnalysisDate}
                      colHeaders={{
                        USCustomary: [
                          'POF Times',
                          'Component POF (%)',
                          'Component Jacketing POF (%)',
                          'Component Coating POF (%)',
                        ],
                        Metric: [
                          'POF Times',
                          'Component POF (%)',
                          'Component Jacketing POF (%)',
                          'Component Coating POF (%)',
                        ],
                      }}
                    />
                  </div>
                </div>
              )
              : <div className="fl w-50-l w-50-m w-100">
                <DataTableDisplay
                  id="v2ComponentPOFsFromAnalysisDate"
                  table={v2ComponentPOFsFromAnalysisDate}
                  colHeaders={{
                    USCustomary: [
                      'POF Times',
                      'Component POF (%)',
                      'Component Jacketing POF (%)',
                      'Component Coating POF (%)',
                    ],
                    Metric: [
                      'POF Times',
                      'Component POF (%)',
                      'Component Jacketing POF (%)',
                      'Component Coating POF (%)',
                    ],
                  }}
                />
              </div>}
            <div className="fl w-100">
              <p>Failure Time Convergence Table (Failure Time Tolerance Criterion = 0.1)</p>
              <DataTableDisplay
                id="convergenceTable"
                table={convergenceTable}
                colHeaders={{
                  USCustomary: [
                    'Cycle Information',
                    'Failure Time (yrs)<br>at Target Min POF',
                    'Failure Time (yrs)<br>Difference Between Cycles',
                    'POF (%) Now',
                    'POF (%) Difference Between Cycles',
                  ],
                  Metric: [
                    'Cycle Information',
                    'Failure Time (yrs)<br>at Target Min POF',
                    'Failure Time (yrs)<br>Difference Between Cycles',
                    'POF (%) Now',
                    'POF (%) Difference Between Cycles',
                  ],
                }}
              />
            </div>
          </div>
        )
        : ''}
      {problemType === 'PROB2'
        ? (
          <div className="cf w-100">
            {results.returnCondensationBool[0] === 0
              ? (
                <div>
                  <DataTableDisplay
                    id="v1ComponentPOFsFromAnalysisDateReg"
                    table={v1ComponentPOFsFromAnalysisDateReg}
                    colHeaders={{
                      USCustomary: [
                        'POF Times',
                        'Component POF (%)',
                        'Coating POF (%)',
                        'Missing Jacketing POF (%)',
                        'Sealant Jacketing POF (%)',
                        'Corrosion Jacketing POF (%)',
                      ],
                      Metric: [
                        'POF Times',
                        'Component POF (%)',
                        'Coating POF (%)',
                        'Missing Jacketing POF (%)',
                        'Sealant Jacketing POF (%)',
                        'Corrosion Jacketing POF (%)',
                      ],
                    }}
                  />
                </div>
              )
              : ''}
            {results.returnCondensationBool[0] === 1
              ? (
                <div>
                  <DataTableDisplay
                    id="v1ComponentPOFsFromAnalysisDateCond"
                    table={v1ComponentPOFsFromAnalysisDateCond}
                    colHeaders={{
                      USCustomary: [
                        'POF Times',
                        'Component POF (%)',
                        'Coating POF (%)',
                        'Missing Jacketing POF (%)',
                        'Sealant Jacketing POF (%)',
                        'Corrosion Jacketing POF (%)',
                        'Missing Vapor Barrier POF (%)',
                        'Sealant Vapor Barrier POF (%)',
                      ],
                      Metric: [
                        'POF Times',
                        'Component POF (%)',
                        'Coating POF (%)',
                        'Missing Jacketing POF (%)',
                        'Sealant Jacketing POF (%)',
                        'Corrosion Jacketing POF (%)',
                        'Missing Vapor Barrier POF (%)',
                        'Sealant Vapor Barrier POF (%)',
                      ],
                    }}
                  />
                </div>
              )
              : ''}
          </div>
        )
        : ''}
    </div>
  )
}

POFAtTimes.propTypes = {
  results: PropTypes.object,
  problemType: PropTypes.string,
  v0ComponentPOFsFromAnalysisDateReg: PropTypes.object,
  v0ComponentPOFsFromAnalysisDateCond: PropTypes.object,
  v1ComponentPOFsFromAnalysisDateReg: PropTypes.object,
  v1ComponentPOFsFromAnalysisDateCond: PropTypes.object,
  v2ComponentPOFsFromAnalysisDate: PropTypes.object,
  v2ComponentPOFsFromAnalysisDatePrior: PropTypes.object,
  convergenceTable: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  problemType: state.workflow.fields.problemType,
  v0ComponentPOFsFromAnalysisDateReg: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.v0ComponentPOFsFromAnalysisDateReg : undefined,
  v0ComponentPOFsFromAnalysisDateCond: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.v0ComponentPOFsFromAnalysisDateCond : undefined,
  v1ComponentPOFsFromAnalysisDateReg: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.v1ComponentPOFsFromAnalysisDateReg : undefined,
  v1ComponentPOFsFromAnalysisDateCond: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.v1ComponentPOFsFromAnalysisDateCond : undefined,
  v2ComponentPOFsFromAnalysisDate: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.v2ComponentPOFsFromAnalysisDate : undefined,
  v2ComponentPOFsFromAnalysisDatePrior: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.v2ComponentPOFsFromAnalysisDatePrior : undefined,
  convergenceTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.convergenceTable : undefined,
})
export default connect(mapStateToProps, null)(POFAtTimes)
