/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
// import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'

import * as decisionsProjections from '../lib/decisionsProjections'

const DecisionsPlot = ({ results, graphs }) => {
  const graphsToDisplay = decisionsProjections.getDecisionsPlot(results)
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        <div className="cf w-100">
          <DataGraphDisplay graphs={graphsToDisplay} />
        </div>
      </div>
    </div>
  )
}

DecisionsPlot.propTypes = {
  results: PropTypes.object,
  graphs: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  graphs: state.workflow.response ? state.workflow.response.results.graphs : undefined,
})
export default connect(mapStateToProps, null)(DecisionsPlot)
