export function plotBarResults(results, activeUnitSystem) {
  let thicknessUnits = 'in'
  let damageRateUnits = 'mil/yr'
  if(activeUnitSystem === 'Metric') {
    thicknessUnits = 'mm'
  }
  if(activeUnitSystem === 'Metric') {
    damageRateUnits = 'mm/yr'
  }
  const method0AdjustedCRHistX = results.api581MethodAdjustedCRStates
  const method0AdjustedCRHistY = results.dataTables.api581MethodAdjustedCRBelief.keywords[0].data
  const method0FailureHistX = results.dataTables.api581MethodFailureStates.keywords[0].data
  const method0FailureHistY = results.dataTables.api581MethodFailureBelief.keywords[0].data
  const method0FailureTimeHistX = results.api581MethodFailureTimeStates
  const method0FailureTimeHistY = results.dataTables.api581MethodFailureTimeBelief.keywords[0].data
  const method1PrimaryScoreHistX = results.bpMethodPrimaryScoreStates
  const method1PrimaryScoreHistY = results.dataTables.bpMethodPrimaryScoreBelief.keywords[0].data
  const method1QualitativeLikelihoodHistX = results.dataTables.bpMethodQualitativeLikelihoodStates.keywords[0].data
  const method1QualitativeLikelihoodHistY = results.dataTables.bpMethodQualitativeLikelihoodBelief.keywords[0].data
  const method2POFTotalHistX = results.dataTables.dnvglMethodPOFTotalStates.keywords[0].data
  const method2POFTotalHistY = results.dataTables.dnvglMethodPOFTotalBelief.keywords[0].data
  const method3PrimaryScoreHistX = results.ineosMethodPrimaryScoreStates
  const method3PrimaryScoreHistY = results.dataTables.ineosMethodPrimaryScoreBelief.keywords[0].data
  const method3QualitativePOFHistX = results.dataTables.ineosMethodQualitativePOFStates.keywords[0].data
  const method3QualitativePOFHistY = results.dataTables.ineosMethodQualitativePOFBelief.keywords[0].data
  const method4PrimaryScoreHistX = results.McVayMethodPrimaryScoreStates
  const method4PrimaryScoreHistY = results.dataTables.McVayMethodPrimaryScoreBelief.keywords[0].data
  const method4QualitativeLikelihoodHistX = results.dataTables.McVayMethodQualitativeLikelihoodStates.keywords[0].data
  const method4QualitativeLikelihoodHistY = results.dataTables.McVayMethodQualitativeLikelihoodBelief.keywords[0].data
  const method5LikelihoodHistX = results.dataTables.api583LikelihoodStates.keywords[0].data
  const method5LikelihoodHistY = results.dataTables.api583LikelihoodBelief.keywords[0].data
  const method5TotalScoreHistX = results.api583TotalScoreStates
  const method5TotalScoreHistY = results.dataTables.api583TotalScoreBelief.keywords[0].data
  const method6PrimaryScoreHistX = results.euroMethodPrimaryScoreStates
  const method6PrimaryScoreHistY = results.dataTables.euroMethodPrimaryScoreBelief.keywords[0].data
  const method6LikelihoodHistX = results.dataTables.euroMethodLikelihoodStates.keywords[0].data
  const method6LikelihoodHistY = results.dataTables.euroMethodLikelihoodBelief.keywords[0].data
  const tracesProfileData01 = []
  const tracesProfileData02 = []
  const tracesProfileData03 = []
  const tracesProfileData04 = []
  const tracesProfileData05 = []
  const tracesProfileData06 = []
  const tracesProfileData07 = []
  const tracesProfileData08 = []
  const tracesProfileData09 = []
  const tracesProfileData10 = []
  const tracesProfileData11 = []
  const tracesProfileData12 = []
  const tracesProfileData13 = []
  const tracesProfileData14 = []
  tracesProfileData01.push({
    x: method0AdjustedCRHistX,
    y: method0AdjustedCRHistY,
    type: 'bar',
  })
  tracesProfileData02.push({
    x: ['Yes', 'No'],
    y: method0FailureHistY,
    type: 'bar',
  })
  tracesProfileData03.push({
    x: method0FailureTimeHistX,
    y: method0FailureTimeHistY,
    type: 'bar',
  })
  tracesProfileData04.push({
    x: method1PrimaryScoreHistX,
    y: method1PrimaryScoreHistY,
    type: 'bar',
  })
  tracesProfileData05.push({
    x: ['Low', 'Moderate', 'High'],
    y: method1QualitativeLikelihoodHistY,
    type: 'bar',
  })
  tracesProfileData06.push({
    x: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
    y: method2POFTotalHistY,
    type: 'bar',
  })
  tracesProfileData07.push({
    x: method3PrimaryScoreHistX,
    y: method3PrimaryScoreHistY,
    type: 'bar',
  })
  tracesProfileData08.push({
    x: ['Low', 'Medium', 'High'],
    y: method3QualitativePOFHistY,
    type: 'bar',
  })
  tracesProfileData09.push({
    x: method4PrimaryScoreHistX,
    y: method4PrimaryScoreHistY,
    type: 'bar',
  })
  tracesProfileData10.push({
    x: ['Low', 'Moderate', 'High'],
    y: method4QualitativeLikelihoodHistY,
    type: 'bar',
  })
  tracesProfileData11.push({
    x: ['Very Low', 'Low', 'Moderate', 'High', 'Very High'],
    y: method5LikelihoodHistY,
    type: 'bar',
  })
  tracesProfileData12.push({
    x: method5TotalScoreHistX,
    y: method5TotalScoreHistY,
    type: 'bar',
  })
  tracesProfileData13.push({
    x: method6PrimaryScoreHistX,
    y: method6PrimaryScoreHistY,
    type: 'bar',
  })
  tracesProfileData14.push({
    x: ['Very Low', 'Low', 'Moderate', 'High', 'Very High'],
    y: method6LikelihoodHistY,
    type: 'bar',
  })
  const graphs = {}
  graphs.Graph1 = {
    traces: tracesProfileData01,
    layout: {
      title: 'API 581 Method',
      xaxis: {
        title: `Adjusted Corrosion Rate (${damageRateUnits})`,
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph2 = {
    traces: tracesProfileData02,
    layout: {
      title: 'API 581 Method',
      xaxis: {
        title: 'Failure',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph3 = {
    traces: tracesProfileData03,
    layout: {
      title: 'API 581 Method',
      xaxis: {
        title: 'Failure Time (yrs)',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph4 = {
    traces: tracesProfileData04,
    layout: {
      title: 'BP Method',
      xaxis: {
        title: 'Primary Score',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph5 = {
    traces: tracesProfileData05,
    layout: {
      title: 'BP Method',
      xaxis: {
        title: 'Qualitative Likelihood',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph6 = {
    traces: tracesProfileData06,
    layout: {
      title: 'DNVGL Method',
      xaxis: {
        title: 'PoF Total',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph7 = {
    traces: tracesProfileData07,
    layout: {
      title: 'Ineos Method',
      xaxis: {
        title: 'Primary Score',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph8 = {
    traces: tracesProfileData08,
    layout: {
      title: 'Ineos Method',
      xaxis: {
        title: 'Qualitative PoF',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph9 = {
    traces: tracesProfileData09,
    layout: {
      title: 'McVay Method',
      xaxis: {
        title: 'Primary Score',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph10 = {
    traces: tracesProfileData10,
    layout: {
      title: 'McVay Method',
      xaxis: {
        title: 'Qualitative Likelihood',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph11 = {
    traces: tracesProfileData11,
    layout: {
      title: 'API 583 Method',
      xaxis: {
        title: 'Likelihood',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph12 = {
    traces: tracesProfileData12,
    layout: {
      title: 'API 583 Method',
      xaxis: {
        title: 'Total Score',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph13 = {
    traces: tracesProfileData13,
    layout: {
      title: 'Euro Method',
      xaxis: {
        title: 'Primary Score',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  graphs.Graph14 = {
    traces: tracesProfileData14,
    layout: {
      title: 'Euro Method',
      xaxis: {
        title: 'Likelihood',
      },
      yaxis: {
        title: 'Probability',
      },
    },
  }
  return graphs
}

