export function getDecisionsPlot(results) {
  const dataX = results.dataTables.xAllArrayTimes.keywords[0].data
  const dataY = results.dataTables.yAllArrayExpectedUtilities.keywords[0].data
  const labelTitle = results.labelTitle
  const labelX = results.labelX
  const labelY = results.labelY
  const dataOrgX = results.dataTables.xAllArrayTimesOrg.keywords[0].data
  const dataOrgY = results.dataTables.yAllArrayExpectedUtilitiesOrg.keywords[0].data
  const labelTitleOrg = results.labelTitleOrg
  const labelXOrg = results.labelXOrg
  const labelYOrg = results.labelYOrg
  const dataXReplacement = results.dataTables.xAllArrayTimesReplacement.keywords[0].data
  const dataYReplacement = results.dataTables.yAllArrayExpectedUtilitiesReplacement.keywords[0].data
  const labelTitleReplacement = results.labelTitleReplacement
  const labelXReplacement = results.labelXReplacement
  const labelYReplacement = results.labelYReplacement
  const traces1 = []
  const traces2 = []
  const traces3 = []
  let val
  let val1
  const graphs = {}
  if(labelTitle[0] !== '0') {
    for(let i = 0; i < labelTitle.length; i += 1) {
      const trace1 = {
        x: dataX[i],
        y: dataY[i],
        type: 'scatter',
        mode: 'lines+markers',
      }
      traces1.push(trace1)
      graphs[`${i.toString()}`] = {
        traces: [traces1[i]],
        layout: {
          name: `decisionsProjections ${i.toString()}`,
          title: labelTitle[i],
          showlegend: false,
          xaxis: {
            title: labelX[i],
          },
          yaxis: {
            title: labelY[i],
          },
        },
      }
    }
  }
  for(let j = 0; j < labelTitleOrg.length; j += 1) {
    const trace2 = {
      x: dataOrgX[j],
      y: dataOrgY[j],
      type: 'scatter',
      mode: 'lines+markers',
    }
    traces2.push(trace2)
    val = labelTitle.length + j
    graphs[`${val.toString()}`] = {
      traces: [traces2[j]],
      layout: {
        name: `decisionsProjections ${val.toString()}`,
        title: labelTitleOrg[j],
        showlegend: false,
        xaxis: {
          title: labelXOrg[j],
        },
        yaxis: {
          title: labelYOrg[j],
        },
      },
    }
  }
  for(let p = 0; p < labelTitleReplacement.length; p += 1) {
    const trace3 = {
      x: dataXReplacement[p],
      y: dataYReplacement[p],
      type: 'scatter',
      mode: 'lines+markers',
    }
    traces3.push(trace3)
    val1 = labelTitle.length + labelTitleOrg.length + p
    graphs[`${val1.toString()}`] = {
      traces: [traces3[p]],
      layout: {
        name: `decisionsProjections ${val1.toString()}`,
        title: labelTitleReplacement[p],
        showlegend: false,
        xaxis: {
          title: labelXReplacement[p],
        },
        yaxis: {
          title: labelYReplacement[p],
        },
      },
    }
  }
  return graphs
}
