/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'
import ToggleField from '@e2grnd/ui-core/dist/containers/ToggleField'

function Step3({ problemType, internalDamageRate, internalDamageRateConfidence, style, internalDamageRateBool, operatingTemperatureKnown, tempConfOrCV, operatingTemperatureCV, activeUnitSystem, operatingTemperature, operatingTemperatureConfidence, operatingTemperatureVarianceType, operatingTemperatureSD, normalOperatingTempCyclic, tempHistory, operatingTemperatureInputType, operatingTemperature1, probabilisticInputFieldFlag, operatingTemperatureProb, operatingTemperatureInputTypeProb, internalDamageRateProb }) {
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        {problemType === 'PROB3'
          ? (
            <div>
              <div className="fl w-50-l w-100">
                <E2GSelectField name="operatingTemperatureKnown" value={operatingTemperatureKnown} label="Is Operating Temperature Known?">
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {operatingTemperatureKnown === 'Yes' && probabilisticInputFieldFlag === false
                  ? (
                    <div>
                      <E2GSelectField name="operatingTemperatureInputType" value={operatingTemperatureInputType} label="Operating Temperature Input Type">
                        <E2GSelectFieldOption label="Constant" value="constant" />
                        <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                      </E2GSelectField>
                      {operatingTemperatureInputType === 'constant'
                        ? (
                          <div>
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The operating temperature of a pipeline refers to the range of temperatures within which the pipeline can safely and effectively function (i.e., temperature at which the pipeline is operating at).</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="operatingTemperature" value={operatingTemperature} label="Operating Temperature" type="number" required />
                            {/* </E2GInfoButton> */}
                            <E2GSelectField name="operatingTemperatureVarianceType" value={operatingTemperatureVarianceType} label="Operating Temperature Variance Type">
                              <E2GSelectFieldOption label="Confidence" value="confidence" />
                              <E2GSelectFieldOption label="Standard Deviation" value="standardDeviation" />
                              <E2GSelectFieldOption label="CV" value="cv" />
                            </E2GSelectField>
                            {operatingTemperatureVarianceType === 'confidence'
                              ? (
                                <div>
                                  {activeUnitSystem !== 'Metric'
                                    ? (
                                      <E2GSelectField name="operatingTemperatureConfidence" value={operatingTemperatureConfidence} label="Operating Temperature Confidence">
                                        <E2GSelectFieldOption label="Low (SD=20F)" value="Low" />
                                        <E2GSelectFieldOption label="Moderate (SD=10F)" value="Moderate" />
                                        <E2GSelectFieldOption label="High (SD=5F)" value="High" />
                                      </E2GSelectField>
                                    )
                                    : <E2GSelectField name="operatingTemperatureConfidence" value={operatingTemperatureConfidence} label="Operating Temperature Confidence">
                                      <E2GSelectFieldOption label="Low (SD=11C)" value="Low" />
                                      <E2GSelectFieldOption label="Moderate (SD=5.5C)" value="Moderate" />
                                      <E2GSelectFieldOption label="High (SD=3C)" value="High" />
                                    </E2GSelectField>}
                                </div>
                              )
                              : ''}
                            {operatingTemperatureVarianceType === 'standardDeviation'
                              ? (
                                <E2GTextField name="operatingTemperatureSD" value={operatingTemperatureSD} label="Operating Temperature SD" type="number" required />
                              )
                              : ''}
                            {operatingTemperatureVarianceType === 'cv'
                              ? (
                                <E2GTextField name="operatingTemperatureCV" value={operatingTemperatureCV} label="Operating Temperature CV" type="number" required />
                              )
                              : ''}

                          </div>
                        )
                        : <div>
                          <DataTableField
                            id="tempHistory"
                            name="tempHistory"
                            startRows={2}
                            value={tempHistory}
                            rowHeaders={['1', '2']}
                            colHeaders={{ USCustomary: ['Hourly Temperature History (F)'], Metric: ['Hourly Temperature History (C)'] }}
                          />
                        </div>}
                    </div>
                  )
                  : ''}
                {operatingTemperatureKnown === 'Yes' && probabilisticInputFieldFlag === true
                  ? (
                    <div>
                      {operatingTemperatureKnown === 'Yes'
                        ? (
                          <div>
                            <E2GSelectField name="operatingTemperatureInputTypeProb" value={operatingTemperatureInputTypeProb} label="Operating Temperature Input Type">
                              <E2GSelectFieldOption label="Probabilistic" value="probabilistic" />
                              <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                            </E2GSelectField>
                            {operatingTemperatureInputTypeProb === 'probabilistic'
                              ? (
                                <div>
                                  <div className="pt3">
                                    {/* <E2GInfoButton
                                    tooltip={<div>
                                      <p>The operating temperature of a pipeline refers to the range of temperatures within which the pipeline can safely and effectively function (i.e., temperature at which the pipeline is operating at).</p>
                                    </div>}
                                    dialog
                                  > */}
                                    <ProbabilisticInputField
                                      name="operatingTemperatureProb"
                                      jsonEditable
                                      value={operatingTemperatureProb}
                                      variableType="continuous"
                                      InputProps={{
                                        label: 'Temperature (50th Percentile)',
                                        jsonKeyPath: 'median',
                                        units: {
                                          USCustomary: 'F',
                                          Metric: 'C',
                                        },
                                        isNumber: false,
                                        required: true,
                                      }}
                                      DialogProps={{
                                        inputFields: [{
                                          label: 'Distribution Type',
                                          jsonKeyPath: 'distributionType',
                                          select: true,
                                          options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                        }, {
                                          label: 'Variance Type',
                                          jsonKeyPath: 'varianceType',
                                          select: true,
                                          options(parsedJson) {
                                            if(parsedJson.distributionType === 'normal') {
                                              return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                            }
                                            return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                          },
                                        }, {
                                          label: 'Standard Deviation',
                                          jsonKeyPath: 'standardDeviation',
                                          isNumber: true,
                                          units: {
                                            USCustomary: 'F',
                                            Metric: 'C',
                                          },
                                          hide(parsedJson) {
                                            return parsedJson.varianceType !== 'standardDeviation'
                                          },
                                        }, {
                                          label: 'Upper Bound',
                                          jsonKeyPath: 'upperBound',
                                          isNumber: true,
                                          hide(parsedJson) {
                                            return parsedJson.varianceType !== 'upperBound'
                                          },
                                          units: {
                                            USCustomary: 'F',
                                            Metric: 'C',
                                          },
                                        }, {
                                          label: 'Upper Bound Probability',
                                          jsonKeyPath: 'upperBoundProbability',
                                          isNumber: true,
                                          hide(parsedJson) {
                                            return parsedJson.varianceType !== 'upperBound'
                                          },
                                          units: {
                                            USCustomary: '%',
                                            Metric: '%',
                                          },
                                        }, {
                                          label: 'Scale Parameter',
                                          jsonKeyPath: 'scale',
                                          isNumber: true,
                                          hide(parsedJson) {
                                            return parsedJson.varianceType !== 'scale'
                                          },
                                        }, {
                                          label: 'CV (SD/Mean)',
                                          jsonKeyPath: 'cv',
                                          isNumber: true,
                                          hide(parsedJson) {
                                            return parsedJson.varianceType !== 'cv'
                                          },
                                        }],
                                      }}
                                    />
                                    {/* </E2GInfoButton> */}
                                  </div>
                                </div>
                              )
                              : ''}
                            {operatingTemperatureInputTypeProb === 'temporal'
                              ? (
                                <DataTableField
                                  id="tempHistory"
                                  name="tempHistory"
                                  startRows={2}
                                  value={tempHistory}
                                  rowHeaders={['1', '2']}
                                  colHeaders={{ USCustomary: ['Hourly Temperature History (F)'], Metric: ['Hourly Temperature History (C)'] }}
                                />
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
              <div className="fl w-50-l w-100">
                <ToggleField name="internalDamageRateBool" value={internalDamageRateBool} label="Enable Internal Damage Rate" />
                {internalDamageRateBool === true
                  ? (
                    <div>
                      {probabilisticInputFieldFlag === false
                        ? (
                          <div className="fl w-third-l w-50-m w-100">
                            <E2GTextField name="internalDamageRate" style={style} value={internalDamageRate} label="Internal Damage Rate Mean" type="number" required />
                            <E2GSelectField name="internalDamageRateConfidence" style={style} value={internalDamageRateConfidence} label="Internal Damage Rate Confidence">
                              <E2GSelectFieldOption label="Low (SD=5)" value="Low" />
                              <E2GSelectFieldOption label="Moderate (SD=3)" value="Moderate" />
                              <E2GSelectFieldOption label="High (SD=1)" value="High" />
                            </E2GSelectField>
                          </div>
                        )
                        : <div className="pt3">
                          <ProbabilisticInputField
                            name="internalDamageRateProb"
                            jsonEditable
                            value={internalDamageRateProb}
                            variableType="continuous"
                            InputProps={{
                              label: 'Internal Damage Rate (50th Percentile)',
                              jsonKeyPath: 'median',
                              units: {
                                USCustomary: 'mil/yr',
                                Metric: 'mm/yr',
                              },
                              isNumber: false,
                              required: true,
                            }}
                            DialogProps={{
                              inputFields: [{
                                label: 'Distribution Type',
                                jsonKeyPath: 'distributionType',
                                select: true,
                                options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                              }, {
                                label: 'Variance Type',
                                jsonKeyPath: 'varianceType',
                                select: true,
                                options(parsedJson) {
                                  if(parsedJson.distributionType === 'normal') {
                                    return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                  }
                                  return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                },
                              }, {
                                label: 'Standard Deviation',
                                jsonKeyPath: 'standardDeviation',
                                isNumber: true,
                                units: {
                                  USCustomary: 'mil/yr',
                                  Metric: 'mm/yr',
                                },
                                hide(parsedJson) {
                                  return parsedJson.varianceType !== 'standardDeviation'
                                },
                              }, {
                                label: 'Upper Bound',
                                jsonKeyPath: 'upperBound',
                                isNumber: true,
                                hide(parsedJson) {
                                  return parsedJson.varianceType !== 'upperBound'
                                },
                                units: {
                                  USCustomary: 'mil/yr',
                                  Metric: 'mm/yr',
                                },
                              }, {
                                label: 'Upper Bound Probability',
                                jsonKeyPath: 'upperBoundProbability',
                                isNumber: true,
                                hide(parsedJson) {
                                  return parsedJson.varianceType !== 'upperBound'
                                },
                                units: {
                                  USCustomary: '%',
                                  Metric: '%',
                                },
                              }, {
                                label: 'Scale Parameter',
                                jsonKeyPath: 'scale',
                                isNumber: true,
                                hide(parsedJson) {
                                  return parsedJson.varianceType !== 'scale'
                                },
                              }, {
                                label: 'CV (SD/Mean)',
                                jsonKeyPath: 'cv',
                                isNumber: true,
                                hide(parsedJson) {
                                  return parsedJson.varianceType !== 'cv'
                                },
                              }],
                            }}
                          />
                        </div>}
                    </div>
                  )
                  : ''}
              </div>
            </div>
          )
          : ''}
      </div>
      <Submit label="Next" />
    </div>
  )
}

Step3.propTypes = {
  tempHistory: PropTypes.array,
  operatingTemperatureInputType: PropTypes.string,
  internalDamageRate: PropTypes.number,
  internalDamageRateConfidence: PropTypes.string,
  style: PropTypes.object,
  problemType: PropTypes.string,
  activeUnitSystem: PropTypes.string,
  operatingTemperature: PropTypes.number,
  operatingTemperatureConfidence: PropTypes.string,
  normalOperatingTempCyclic: PropTypes.string,
  tempConfOrCV: PropTypes.string,
  operatingTemperatureCV: PropTypes.number,
  operatingTemperatureVarianceType: PropTypes.string,
  operatingTemperatureSD: PropTypes.number,
  operatingTemperatureKnown: PropTypes.string,
  internalDamageRateBool: PropTypes.bool,
  operatingTemperature1: PropTypes.number,
  probabilisticInputFieldFlag: PropTypes.bool,
  operatingTemperatureProb: PropTypes.string,
  operatingTemperatureInputTypeProb: PropTypes.string,
  internalDamageRateProb: PropTypes.string,
}

const mapStateToProps = state => ({
  operatingTemperatureInputType: state.workflow.fields.operatingTemperatureInputType,
  tempHistory: state.workflow.fields.tempHistory,
  internalDamageRate: state.workflow.fields.internalDamageRate,
  internalDamageRateConfidence: state.workflow.fields.internalDamageRateConfidence,
  style: state.ui.style,
  problemType: state.workflow.fields.problemType,
  activeUnitSystem: state.workflow.activeUnitSystem,
  operatingTemperature: state.workflow.fields.operatingTemperature,
  operatingTemperatureConfidence: state.workflow.fields.operatingTemperatureConfidence,
  operatingTemperatureVarianceType: state.workflow.fields.operatingTemperatureVarianceType,
  operatingTemperatureSD: state.workflow.fields.operatingTemperatureSD,
  normalOperatingTempCyclic: state.workflow.fields.normalOperatingTempCyclic,
  tempConfOrCV: state.workflow.fields.tempConfOrCV,
  operatingTemperatureCV: state.workflow.fields.operatingTemperatureCV,
  operatingTemperatureKnown: state.workflow.fields.operatingTemperatureKnown,
  internalDamageRateBool: state.workflow.fields.internalDamageRateBool,
  operatingTemperature1: state.workflow.fields.operatingTemperature1,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  operatingTemperatureProb: state.workflow.fields.operatingTemperatureProb,
  operatingTemperatureInputTypeProb: state.workflow.fields.operatingTemperatureInputTypeProb,
  internalDamageRateProb: state.workflow.fields.internalDamageRateProb,
})

export default connect(mapStateToProps)(Step3)
