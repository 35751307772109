/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// import E2GInfoButton from '@e2grnd/ui-core/dist/containers/E2GInfoButton'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step6({ operatingProfit, replacementCost, failureCost, decisionsStrategy, recoat, recoatCosts, rejacket, rejacketCosts, reseal, resealCosts, caseNum }) {
  return (
    <div className="cf w-100">
      <div className="fl w-third-l w-50-m w-100">
        <E2GSelectField name="decisionsStrategy" value={decisionsStrategy} label="Run Inspection and Maintenance Decision Strategy Analyses?">
          <E2GSelectFieldOption label="No" value="0" />
          <E2GSelectFieldOption label="Yes" value="1" />
        </E2GSelectField>
        {/* <E2GTextField name="caseNum" value={caseNum} label="Case Study Number" type="number" required /> */}
        {decisionsStrategy === '1'
          ? (
            <div>
              <font color="black">Note, all costs can be normalized with the lowest cost of the three for result interpretation convenience.</font>
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>Annual operating profit is the annual profit made from the core business operations.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="operatingProfit" value={operatingProfit} label="Annual Operating Profit ($)" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>The replacement cost is the cost to replace the component.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="replacementCost" value={replacementCost} label="Replacement Cost ($)" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>The failure cost is the cost to shutdown production if the component fails.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="failureCost" value={failureCost} label="Failure Cost ($)" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>The recoat cost is the cost to recoat the component.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="recoatCosts" value={recoatCosts} label="Recoat Cost ($)" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>The reseal cost is the cost to reseal the component.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="resealCosts" value={resealCosts} label="Reseal Cost ($)" type="number" required />
              {/* </E2GInfoButton> */}
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>The rejacket cost is the cost to rejacket the component.</p>
                </div>}
                dialog
              > */}
              <E2GTextField name="rejacketCosts" value={rejacketCosts} label="Rejacket Cost ($)" type="number" required />
              {/* </E2GInfoButton> */}
            </div>
          )
          : ''}
        <Submit label="Calculate" />
      </div>
    </div>
  )
}

Step6.propTypes = {
  operatingProfit: PropTypes.number,
  replacementCost: PropTypes.number,
  failureCost: PropTypes.number,
  decisionsStrategy: PropTypes.string,
  recoatCosts: PropTypes.number,
  rejacketCosts: PropTypes.number,
  resealCosts: PropTypes.number,
  caseNum: PropTypes.number,
}

const mapStateToProps = state => ({
  operatingProfit: state.workflow.fields.operatingProfit,
  replacementCost: state.workflow.fields.replacementCost,
  failureCost: state.workflow.fields.failureCost,
  decisionsStrategy: state.workflow.fields.decisionsStrategy,
  recoatCosts: state.workflow.fields.recoatCosts,
  rejacketCosts: state.workflow.fields.rejacketCosts,
  resealCosts: state.workflow.fields.resealCosts,
  caseNum: state.workflow.fields.caseNum,
})

export default connect(mapStateToProps)(Step6)
