/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step2({ problemType, temperatureSummer, temperatureFall, temperatureSpring, temperatureWinter, relativeHumidity, runCondensation, expertExposureConfidenceKnown, internalPh, externalEnvironment, windSpeedFraction1, elevation, steamVentExposure, otherDirectExposure, ambientTemperature, activeUnitSystem, highWindLikelihood, environment, environmentConfidence, annualRainfall, annualRainfall1, environmentCorrosivity, uvExposure, ozoneExposure, exposureConfidence, expertExposureConfidence, expertExposureFractionMean, ambientTemperatureInputType, ambientTempHistory, annualRainfall2, temperatureAm, temperaturePm, relativeHumidityAm, relativeHumidityPm, externalEnvironment1, temperatureSummerConfidence, temperatureFallConfidence, temperatureWinterConfidence, temperatureSpringConfidence, relativeHumidityConfidence, temperatureAmConfidence, temperaturePmConfidence, relativeHumidityAmConfidence, relativeHumidityPmConfidence, condensationParametersVarianceType, temperatureAmSD, temperaturePmSD, relativeHumidityAmSD, relativeHumidityPmSD, temperatureAmCV, temperaturePmCV, relativeHumidityAmCV, relativeHumidityPmCV, temperatureSummerSD, temperatureFallSD, temperatureSpringSD, temperatureWinterSD, relativeHumiditySD, temperatureSummerCV, temperatureFallCV, temperatureSpringCV, temperatureWinterCV, relativeHumidityCV, relativeHumidityInputType, relativeHumidityHistory, probabilisticInputFieldFlag, externalEnvironment1Prob, ambientTemperatureInputTypeProb, relativeHumidityInputTypeProb, temperatureAmProb, temperaturePmProb, relativeHumidityAmProb, relativeHumidityPmProb, annualRainfall2Prob, windSpeedFraction1Prob, elevationProb, internalPhProb, steamVentExposureProb, otherDirectExposureProb, uvExposureProb }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB3'
        ? (
          <div className="fl w-100">
            <div>
              {probabilisticInputFieldFlag === false
                ? (
                  <div>
                    <div className="fl pa2 w-50-l w-100">
                      <E2GSelectField name="externalEnvironment1" value={externalEnvironment1} label="External Environment">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Water" value="Water" />
                        <E2GSelectFieldOption label="Non-Saline Cooling Tower" value="Non-Saline Cooling Tower" />
                        <E2GSelectFieldOption label="Low % Marine Salt" value="Low % Marine Salt" />
                        <E2GSelectFieldOption label="Low % Acid Rain" value="Low % Acid Rain" />
                        <E2GSelectFieldOption label="Saline Cooling Tower" value="Saline Cooling Tower" />
                        <E2GSelectFieldOption label="High % Marine Salt" value="High % Marine Salt" />
                        <E2GSelectFieldOption label="High % Acid Rain" value="High % Acid Rain" />
                      </E2GSelectField>
                      {/* </E2GInfoButton> */}
                      {activeUnitSystem !== 'Metric'
                        ? (
                          <div>
                            <E2GSelectField name="annualRainfall2" value={annualRainfall2} label="Annual Rainfall (in)">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None (In Building)" value="None (In Building)" />
                              <E2GSelectFieldOption label="Indirect (Sheltered)" value="Indirect (Sheltered)" />
                              <E2GSelectFieldOption label="<15in" value="<15in" />
                              <E2GSelectFieldOption label="15-30in" value="15-30in" />
                              <E2GSelectFieldOption label="30-60in" value="30-60in" />
                              <E2GSelectFieldOption label=">60in" value=">60in" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>
                        )
                        : <div>
                          <E2GSelectField name="annualRainfall2" value={annualRainfall2} label="Annual Rainfall (in)">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="None (In Building)" value="None (In Building)" />
                            <E2GSelectFieldOption label="Indirect (Sheltered)" value="Indirect (Sheltered)" />
                            <E2GSelectFieldOption label="<381mm" value="<15in" />
                            <E2GSelectFieldOption label="381-762mm" value="15-30in" />
                            <E2GSelectFieldOption label="762-1524mm" value="30-60in" />
                            <E2GSelectFieldOption label=">1524mm" value=">60in" />
                          </E2GSelectField>
                          {/* </E2GInfoButton> */}
                        </div>}
                      <E2GSelectField name="windSpeedFraction1" value={windSpeedFraction1} label="Average Annual Wind Speed (mph)">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="0 to 30" value="0 to 30" />
                        <E2GSelectFieldOption label="30 to 60" value="30 to 60" />
                        <E2GSelectFieldOption label="60 to 100" value="60 to 100" />
                      </E2GSelectField>
                      {activeUnitSystem !== 'Metric'
                        ? (
                          <div>
                            {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>Elevation refers to the height above sea level that the pipeline resides.</p>
                                </div>}
                                dialog
                              > */}
                            <E2GSelectField name="elevation" value={elevation} label="Elevation (ft)">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="0 to 50" value="0 to 50" />
                              <E2GSelectFieldOption label="50 to 100" value="50 to 100" />
                              <E2GSelectFieldOption label="100 to 150" value="100 to 150" />
                              <E2GSelectFieldOption label="150 to 200" value="150 to 200" />
                              <E2GSelectFieldOption label="200 to 250" value="200 to 250" />
                              <E2GSelectFieldOption label="250 to 300" value="250 to 300" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>
                        )
                        : <div>
                          {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>Elevation refers to the height above sea level that the pipeline resides.</p>
                                </div>}
                                dialog
                              > */}
                          <E2GSelectField name="elevation" value={elevation} label="Elevation (m)">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="0 to 15.24" value="0 to 50" />
                            <E2GSelectFieldOption label="15.24 to 30.48" value="50 to 100" />
                            <E2GSelectFieldOption label="30.48 to 45.72" value="100 to 150" />
                            <E2GSelectFieldOption label="45.72 to 60.96" value="150 to 200" />
                            <E2GSelectFieldOption label="60.96 to 76.2" value="200 to 250" />
                            <E2GSelectFieldOption label="76.2 to 91.44" value="250 to 300" />
                          </E2GSelectField>
                          {/* </E2GInfoButton> */}
                        </div>}
                    </div>
                    <div className="fl pa2 w-50-l w-100">
                      {/* <E2GSelectField name="internalPh" value={internalPh} label="Insulation System pH">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Alkaline (<6)" value="Alkaline)" />
                          <E2GSelectFieldOption label="Neutral (6-8)" value="Neutral)" />
                          <E2GSelectFieldOption label="Acidic (>8)" value="Acidic" />
                        </E2GSelectField>  */}
                      <E2GSelectField name="steamVentExposure" value={steamVentExposure} label="Steam Vent Exposure">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Yes" value="Yes" />
                        <E2GSelectFieldOption label="No" value="No" />
                      </E2GSelectField>
                      <E2GSelectField name="otherDirectExposure" value={otherDirectExposure} label="Other Direct Exposure">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Yes" value="Yes" />
                        <E2GSelectFieldOption label="No" value="No" />
                      </E2GSelectField>
                      <E2GSelectField name="uvExposure" value={uvExposure} label="UV Exposure (Index)">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Low (0-2)" value="Low (0-2)" />
                        <E2GSelectFieldOption label="Moderate (3-8)" value="Moderate (3-8)" />
                        <E2GSelectFieldOption label="High (>8)" value="High (>8)" />
                      </E2GSelectField>
                    </div>
                  </div>
                )
                : ''}
              {probabilisticInputFieldFlag === true
                ? (
                  <div>
                    <div className="fl pa2 w-50-l w-100">
                      <div className="pt3">
                        <ProbabilisticInputField
                          name="externalEnvironment1Prob"
                          jsonEditable
                          value={externalEnvironment1Prob}
                          variableType="discrete"
                          InputProps={{
                            label: 'External Environment',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                      </div>
                      <div className="pt3">
                        <ProbabilisticInputField
                          name="annualRainfall2Prob"
                          jsonEditable
                          value={annualRainfall2Prob}
                          variableType="discrete"
                          InputProps={{
                            label: 'Average Annual Rainfall (in)',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                      </div>
                      <div className="pt3">
                        <ProbabilisticInputField
                          name="windSpeedFraction1Prob"
                          jsonEditable
                          value={windSpeedFraction1Prob}
                          variableType="discrete"
                          InputProps={{
                            label: 'Average Annual Wind Speed (mph)',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                      </div>
                      <div className="pt3">
                        <ProbabilisticInputField
                          name="elevationProb"
                          jsonEditable
                          value={elevationProb}
                          variableType="discrete"
                          InputProps={{
                            label: 'Elevation (ft)',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                      </div>
                    </div>
                    <div className="fl pa2 w-50-l w-100">
                      {/* <div className="pt3">
                        <ProbabilisticInputField
                          name="internalPhProb"
                          jsonEditable
                          value={internalPhProb}
                          variableType="discrete"
                          InputProps={{
                            label: 'Insulation System pH',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                      </div> */}
                      <div className="pt3">
                        <ProbabilisticInputField
                          name="steamVentExposureProb"
                          jsonEditable
                          value={steamVentExposureProb}
                          variableType="discrete"
                          InputProps={{
                            label: 'Steam Vent Exposure',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                      </div>
                      <div className="pt3">
                        <ProbabilisticInputField
                          name="otherDirectExposureProb"
                          jsonEditable
                          value={otherDirectExposureProb}
                          variableType="discrete"
                          InputProps={{
                            label: 'Other Direct Exposure',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                      </div>
                      <div className="pt3">
                        <ProbabilisticInputField
                          name="uvExposureProb"
                          jsonEditable
                          value={uvExposureProb}
                          variableType="discrete"
                          InputProps={{
                            label: 'UV Exposure (Index)',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
                : ''}
            </div>
          </div>
        )
        : ''}
      <div className="fl w-100">
        <Submit label="Next" />
      </div>
    </div>
  )
}

Step2.propTypes = {
  problemType: PropTypes.string,
  activeUnitSystem: PropTypes.string,
  environment: PropTypes.string,
  environmentConfidence: PropTypes.string,
  annualRainfall: PropTypes.string,
  annualRainfall1: PropTypes.string,
  environmentCorrosivity: PropTypes.string,
  uvExposure: PropTypes.string,
  ozoneExposure: PropTypes.string,
  highWindLikelihood: PropTypes.string,
  ambientTemperature: PropTypes.string,
  steamVentExposure: PropTypes.string,
  otherDirectExposure: PropTypes.string,
  elevation: PropTypes.string,
  windSpeedFraction1: PropTypes.string,
  externalEnvironment: PropTypes.string,
  externalEnvironment1: PropTypes.string,
  internalPh: PropTypes.string,
  exposureConfidence: PropTypes.string,
  expertExposureConfidence: PropTypes.string,
  expertExposureFractionMean: PropTypes.number,
  expertExposureConfidenceKnown: PropTypes.string,
  runCondensation: PropTypes.bool,
  temperatureSummer: PropTypes.number,
  temperatureFall: PropTypes.number,
  temperatureSpring: PropTypes.number,
  temperatureWinter: PropTypes.number,
  relativeHumidity: PropTypes.number,
  temperatureSummerSD: PropTypes.number,
  temperatureFallSD: PropTypes.number,
  temperatureSpringSD: PropTypes.number,
  temperatureWinterSD: PropTypes.number,
  relativeHumiditySD: PropTypes.number,
  temperatureSummerCV: PropTypes.number,
  temperatureFallCV: PropTypes.number,
  temperatureSpringCV: PropTypes.number,
  temperatureWinterCV: PropTypes.number,
  relativeHumidityCV: PropTypes.number,
  ambientTemperatureInputType: PropTypes.string,
  ambientTempHistory: PropTypes.array,
  relativeHumidityHistory: PropTypes.array,
  annualRainfall2: PropTypes.string,
  temperatureAm: PropTypes.number,
  temperaturePm: PropTypes.number,
  relativeHumidityAm: PropTypes.number,
  relativeHumidityPm: PropTypes.number,
  temperatureSummerConfidence: PropTypes.string,
  temperatureFallConfidence: PropTypes.string,
  temperatureWinterConfidence: PropTypes.string,
  temperatureSpringConfidence: PropTypes.string,
  relativeHumidityConfidence: PropTypes.string,
  temperatureAmConfidence: PropTypes.string,
  temperaturePmConfidence: PropTypes.string,
  relativeHumidityAmConfidence: PropTypes.string,
  relativeHumidityPmConfidence: PropTypes.string,
  condensationParametersVarianceType: PropTypes.string,
  temperatureAmSD: PropTypes.number,
  temperaturePmSD: PropTypes.number,
  relativeHumidityAmSD: PropTypes.number,
  relativeHumidityPmSD: PropTypes.number,
  temperatureAmCV: PropTypes.number,
  temperaturePmCV: PropTypes.number,
  relativeHumidityAmCV: PropTypes.number,
  relativeHumidityPmCV: PropTypes.number,
  relativeHumidityInputType: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  externalEnvironment1Prob: PropTypes.string,
  ambientTemperatureInputTypeProb: PropTypes.string,
  relativeHumidityInputTypeProb: PropTypes.string,
  temperatureAmProb: PropTypes.string,
  temperaturePmProb: PropTypes.string,
  relativeHumidityAmProb: PropTypes.string,
  relativeHumidityPmProb: PropTypes.string,
  annualRainfall2Prob: PropTypes.string,
  windSpeedFraction1Prob: PropTypes.string,
  elevationProb: PropTypes.string,
  internalPhProb: PropTypes.string,
  steamVentExposureProb: PropTypes.string,
  otherDirectExposureProb: PropTypes.string,
  uvExposureProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  activeUnitSystem: state.workflow.activeUnitSystem,
  environment: state.workflow.fields.environment,
  environmentConfidence: state.workflow.fields.environmentConfidence,
  annualRainfall: state.workflow.fields.annualRainfall,
  annualRainfall1: state.workflow.fields.annualRainfall1,
  environmentCorrosivity: state.workflow.fields.environmentCorrosivity,
  uvExposure: state.workflow.fields.uvExposure,
  ozoneExposure: state.workflow.fields.ozoneExposure,
  highWindLikelihood: state.workflow.fields.highWindLikelihood,
  ambientTemperature: state.workflow.fields.ambientTemperature,
  steamVentExposure: state.workflow.fields.steamVentExposure,
  otherDirectExposure: state.workflow.fields.otherDirectExposure,
  elevation: state.workflow.fields.elevation,
  windSpeedFraction1: state.workflow.fields.windSpeedFraction1,
  externalEnvironment: state.workflow.fields.externalEnvironment,
  externalEnvironment1: state.workflow.fields.externalEnvironment1,
  internalPh: state.workflow.fields.internalPh,
  exposureConfidence: state.workflow.fields.exposureConfidence,
  expertExposureConfidence: state.workflow.fields.expertExposureConfidence,
  expertExposureFractionMean: state.workflow.fields.expertExposureFractionMean,
  expertExposureConfidenceKnown: state.workflow.fields.expertExposureConfidenceKnown,
  runCondensation: state.workflow.fields.runCondensation,
  temperatureSummer: state.workflow.fields.temperatureSummer,
  temperatureFall: state.workflow.fields.temperatureFall,
  temperatureSpring: state.workflow.fields.temperatureSpring,
  temperatureWinter: state.workflow.fields.temperatureWinter,
  relativeHumidity: state.workflow.fields.relativeHumidity,
  temperatureSummerSD: state.workflow.fields.temperatureSummerSD,
  temperatureFallSD: state.workflow.fields.temperatureFallSD,
  temperatureSpringSD: state.workflow.fields.temperatureSpringSD,
  temperatureWinterSD: state.workflow.fields.temperatureWinterSD,
  relativeHumiditySD: state.workflow.fields.relativeHumiditySD,
  temperatureSummerCV: state.workflow.fields.temperatureSummerCV,
  temperatureFallCV: state.workflow.fields.temperatureFallCV,
  temperatureSpringCV: state.workflow.fields.temperatureSpringCV,
  temperatureWinterCV: state.workflow.fields.temperatureWinterCV,
  relativeHumidityCV: state.workflow.fields.relativeHumidityCV,
  ambientTemperatureInputType: state.workflow.fields.ambientTemperatureInputType,
  ambientTempHistory: state.workflow.fields.ambientTempHistory,
  relativeHumidityHistory: state.workflow.fields.relativeHumidityHistory,
  annualRainfall2: state.workflow.fields.annualRainfall2,
  temperatureAm: state.workflow.fields.temperatureAm,
  temperaturePm: state.workflow.fields.temperaturePm,
  relativeHumidityAm: state.workflow.fields.relativeHumidityAm,
  relativeHumidityPm: state.workflow.fields.relativeHumidityPm,
  temperatureAmSD: state.workflow.fields.temperatureAmSD,
  temperaturePmSD: state.workflow.fields.temperaturePmSD,
  relativeHumidityAmSD: state.workflow.fields.relativeHumidityAmSD,
  relativeHumidityPmSD: state.workflow.fields.relativeHumidityPmSD,
  temperatureAmCV: state.workflow.fields.temperatureAmCV,
  temperaturePmCV: state.workflow.fields.temperaturePmCV,
  relativeHumidityAmCV: state.workflow.fields.relativeHumidityAmCV,
  relativeHumidityPmCV: state.workflow.fields.relativeHumidityPmCV,
  temperatureSummerConfidence: state.workflow.fields.temperatureSummerConfidence,
  temperatureFallConfidence: state.workflow.fields.temperatureFallConfidence,
  temperatureWinterConfidence: state.workflow.fields.temperatureWinterConfidence,
  temperatureSpringConfidence: state.workflow.fields.temperatureSpringConfidence,
  relativeHumidityConfidence: state.workflow.fields.relativeHumidityConfidence,
  temperatureAmConfidence: state.workflow.fields.temperatureAmConfidence,
  temperaturePmConfidence: state.workflow.fields.temperaturePmConfidence,
  relativeHumidityAmConfidence: state.workflow.fields.relativeHumidityAmConfidence,
  relativeHumidityPmConfidence: state.workflow.fields.relativeHumidityPmConfidence,
  condensationParametersVarianceType: state.workflow.fields.condensationParametersVarianceType,
  relativeHumidityInputType: state.workflow.fields.relativeHumidityInputType,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  externalEnvironment1Prob: state.workflow.fields.externalEnvironment1Prob,
  ambientTemperatureInputTypeProb: state.workflow.fields.ambientTemperatureInputTypeProb,
  relativeHumidityInputTypeProb: state.workflow.fields.relativeHumidityInputTypeProb,
  temperatureAmProb: state.workflow.fields.temperatureAmProb,
  temperaturePmProb: state.workflow.fields.temperaturePmProb,
  relativeHumidityAmProb: state.workflow.fields.relativeHumidityAmProb,
  relativeHumidityPmProb: state.workflow.fields.relativeHumidityPmProb,
  annualRainfall2Prob: state.workflow.fields.annualRainfall2Prob,
  windSpeedFraction1Prob: state.workflow.fields.windSpeedFraction1Prob,
  elevationProb: state.workflow.fields.elevationProb,
  internalPhProb: state.workflow.fields.internalPhProb,
  steamVentExposureProb: state.workflow.fields.steamVentExposureProb,
  otherDirectExposureProb: state.workflow.fields.otherDirectExposureProb,
  uvExposureProb: state.workflow.fields.uvExposureProb,
})

export default connect(mapStateToProps)(Step2)
