import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import BayesViewer from '@e2grnd/ui-core/dist/components/bayes/BayesViewer'
import BeliefTable from '@e2grnd/ui-core/dist/components/bayes/BeliefTable'
import Dialog from '@e2grnd/ui-core/dist/components/ImageDialog'
import Close from '@e2grnd/ui-core/dist/components/ImageDialogClose'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'
import TextArea from '@e2grnd/ui-core/dist/containers/TextArea'

function BayesNetBuilderQualProp({ results, runQualMethodsBool, includeClientMethods }) {
  let bayesNetAPI581
  let bayesNetBPMethod
  let bayesNetDNVGLMethod
  let bayesNetIneosMethod
  let bayesNetMcVayMethod
  let bayesNetAPI583
  let bayesNetEuroMethod
  let whichQualString
  if(typeof results.poppedJsonQualMethod_API581 !== 'undefined') {
    if(typeof results.poppedJsonQualMethod_BPMethod !== 'undefined') {
      bayesNetAPI581 = JSON.parse(results.poppedJsonQualMethod_API581)
      bayesNetBPMethod = JSON.parse(results.poppedJsonQualMethod_BPMethod)
      bayesNetDNVGLMethod = JSON.parse(results.poppedJsonQualMethod_DNVGLMethod)
      bayesNetIneosMethod = JSON.parse(results.poppedJsonQualMethod_IneosMethod)
      bayesNetMcVayMethod = JSON.parse(results.poppedJsonQualMethod_McVayMethod)
      bayesNetAPI583 = JSON.parse(results.poppedJsonQualMethod_API583)
      bayesNetEuroMethod = JSON.parse(results.poppedJsonQualMethod_EuroMethod)
      whichQualString = results.whichNetworkQual[0]
    } else {
      bayesNetAPI581 = JSON.parse(results.poppedJsonQualMethod_API581)
      bayesNetDNVGLMethod = JSON.parse(results.poppedJsonQualMethod_DNVGLMethod)
      bayesNetMcVayMethod = JSON.parse(results.poppedJsonQualMethod_McVayMethod)
      bayesNetAPI583 = JSON.parse(results.poppedJsonQualMethod_API583)
      bayesNetEuroMethod = JSON.parse(results.poppedJsonQualMethod_EuroMethod)
      whichQualString = results.whichNetworkQual[0]
    }
  }

  let nodeNew
  const [states, setStates] = useState([])
  const [beliefs, setBeliefs] = useState([])
  const [netIndex, setNetIndex] = useState('1')

  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const onNodeClick = (node) => {
    if(node) {
      if(includeClientMethods === true) {
        if(results.whichNetworkQual[0] === '1') {
          nodeNew = bayesNetAPI581.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '2') {
          nodeNew = bayesNetBPMethod.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '3') {
          nodeNew = bayesNetDNVGLMethod.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '4') {
          nodeNew = bayesNetIneosMethod.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '5') {
          nodeNew = bayesNetMcVayMethod.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '6') {
          nodeNew = bayesNetAPI583.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '7') {
          nodeNew = bayesNetEuroMethod.nodes.find((n) => n.name === node)
        }
      } else {
        if(results.whichNetworkQual[0] === '1') {
          nodeNew = bayesNetAPI581.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '2') {
          nodeNew = bayesNetDNVGLMethod.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '3') {
          nodeNew = bayesNetMcVayMethod.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '4') {
          nodeNew = bayesNetAPI583.nodes.find((n) => n.name === node)
        } else if(results.whichNetworkQual[0] === '5') {
          nodeNew = bayesNetEuroMethod.nodes.find((n) => n.name === node)
        }
      }
      if(typeof nodeNew.states !== 'undefined' && typeof nodeNew.belief !== 'undefined') {
        setStates(nodeNew.states.states || [])
        setBeliefs(nodeNew.belief || [])
      } else {
        setStates([])
        setBeliefs([])
      }
    }
  }

  useEffect(() => {
    setStates([])
    setBeliefs([])
  }, [whichQualString])

  return (
    <div>
      {runQualMethodsBool === true
        ? (
          <div>
            {includeClientMethods === true
              ? (
                <E2GSelectFieldIndexer name="whichNetworkQual" value={results.whichNetworkQual[0]} label="Select Network to View">
                  <E2GSelectFieldOption label="API 581 Method" value="1" />
                  <E2GSelectFieldOption label="BP Method" value="2" />
                  <E2GSelectFieldOption label="DNVGL Method" value="3" />
                  <E2GSelectFieldOption label="Ineos Method" value="4" />
                  <E2GSelectFieldOption label="McVay Method" value="5" />
                  <E2GSelectFieldOption label="API 583 Method" value="6" />
                  <E2GSelectFieldOption label="Euro Method" value="7" />
                </E2GSelectFieldIndexer >
              )
              : ''}
            {includeClientMethods === false
              ? (
                <E2GSelectFieldIndexer name="whichNetworkQual" value={results.whichNetworkQual[0]} label="Select Network to View">
                  <E2GSelectFieldOption label="API 581 Method" value="1" />
                  <E2GSelectFieldOption label="DNVGL Method" value="2" />
                  <E2GSelectFieldOption label="McVay Method" value="3" />
                  <E2GSelectFieldOption label="API 583 Method" value="4" />
                  <E2GSelectFieldOption label="Euro Method" value="5" />
                </E2GSelectFieldIndexer >
              )
              : ''}
          </div>
        )
        : ''}
      {runQualMethodsBool === true && includeClientMethods === true
        ? (
          <Stack>
            <div className="cf w-100">
              {results.whichNetworkQual[0] === '1'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for API 581 Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for API 581 Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetAPI581, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '2'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for BP Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for BP Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetBPMethod, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '3'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for DNVGL Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for DNVGL Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetDNVGLMethod, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '4'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for Ineos Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for Ineos Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetIneosMethod, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '5'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for McVay Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for McVay Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetMcVayMethod, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '6'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for API 583 Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for API 583 Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetAPI583, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '7'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for Euro Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for Euro Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetEuroMethod, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              <div className="pa5">
                <p>First, click GENERATE LAYOUT to view the network. Then click any nature node (green) to view the corrosponding belief table. Note, there are no belief tables for decision (blue) and utility (orange) nodes.</p>
              </div>
              {results.whichNetworkQual[0] === '1'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetAPI581} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '2'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetBPMethod} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '3'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetDNVGLMethod} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '4'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetIneosMethod} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '5'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetMcVayMethod} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '6'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetAPI583} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '7'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetEuroMethod} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
            </div>
          </Stack>
        )
        : ''}
      {runQualMethodsBool === true && includeClientMethods === false
        ? (
          <Stack>
            <div className="cf w-100">
              {results.whichNetworkQual[0] === '1'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for API 581 Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for API 581 Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetAPI581, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '2'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for DNVGL Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for DNVGL Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetDNVGLMethod, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '3'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for McVay Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for McVay Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetMcVayMethod, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '4'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for API 583 Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for API 583 Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetAPI583, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '5'
                ? (
                  <div className="fl w-100">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={toggleOpen}
                    >
                    Bayes Net Output File for Euro Method
                    </Button>
                    <Dialog
                      title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                      modal={false}
                      open={open}
                      fullWidth
                    >
                      <TextArea label="Bayes Net Output File for Euro Method" style={{ height: '50vh' }} value={JSON.stringify(bayesNetEuroMethod, null, 2)} disabled/>
                    </Dialog>
                  </div>
                )
                : ''}
              <div className="pa5">
                <p>First, click GENERATE LAYOUT to view the network. Then click any nature node (green) to view the corrosponding belief table. Note, there are no belief tables for decision (blue) and utility (orange) nodes.</p>
              </div>
              {results.whichNetworkQual[0] === '1'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetAPI581} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '2'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetDNVGLMethod} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '3'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetMcVayMethod} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '4'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetAPI583} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
              {results.whichNetworkQual[0] === '5'
                ? (
                  <div>
                    <div className="fl w-third-l w-50-m w-100">
                      <BeliefTable states={states} beliefs={beliefs} />
                    </div>
                    <div className="fl w-two-thirds-l w-50-m w-100">
                      <Box height={500}> <BayesViewer bayesNet={bayesNetEuroMethod} onNodeClick={onNodeClick} /> </Box>
                    </div>
                  </div>
                )
                : ''}
            </div>
          </Stack>
        )
        : ''}
    </div>
  )
}

BayesNetBuilderQualProp.propTypes = {
  results: PropTypes.object,
  runQualMethodsBool: PropTypes.bool,
  includeClientMethods: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  includeClientMethods: state.workflow.fields.includeClientMethods,
})

export default connect(mapStateToProps, null)(BayesNetBuilderQualProp)
