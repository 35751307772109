/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

const PerformanceMetrics = ({ results, style, performanceMetricTable }) => {
  return (
    <div className="cf w-100">
      <div className="fl w-100">
        <p>Note that performance metric results are sorted on Core CPT Combinations column</p>
        <DataTableDisplay
          id="performanceMetricTable"
          table={performanceMetricTable}
          colHeaders={{
            USCustomary: [
              'Network Name',
              // 'Compute Time (Sec)',
              // 'CPU (%)',
              // 'RAM Usage (%)',
              // 'RAM Usage (GB)',
              // 'RAM Usage Calculated (GB)',
              // 'Delta RAM (%)',
              // 'Delta RAM Usage (GB)',
              // 'Sum of All CPTs Shape',
              // 'All CPTs Combinations',
              'Max CPT Size',
              // 'Sum of All CPT Size (GB)',
              // 'Core Node Sum of Levels',
              // 'Length of Nodes',
              'Predicted RAM (GB)',
              'RAM (GB) Allotted',
              'CPU Allotted',
            ],
            Metric: [
              'Network Name',
              // 'Compute Time (Sec)',
              // 'CPU (%)',
              // 'RAM Usage (%)',
              // 'RAM Usage (GB)',
              // 'RAM Usage Calculated (GB)',
              // 'Delta RAM (%)',
              // 'Delta RAM Usage (GB)',
              // 'Sum of All CPTs Shape',
              // 'All CPTs Combinations',
              'Max CPT Size',
              // 'Sum of All CPT Size (GB)',
              // 'Core Node Sum of Levels',
              // 'Length of Nodes',
              'Predicted RAM (GB)',
              'RAM (GB) Allotted',
              'CPU Allotted',
            ],
          }}
        />
      </div>
    </div>
  )
}

PerformanceMetrics.propTypes = {
  results: PropTypes.object,
  style: PropTypes.object,
  performanceMetricTable: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  style: state.ui.style,
  performanceMetricTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.performanceMetricTable : undefined,
})
export default connect(mapStateToProps, null)(PerformanceMetrics)
