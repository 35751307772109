import logo from '../../../images/report'

export default {
  title: 'CUI Sight Report',
  logo,
  columnWidths: [250, 0, 260, 40],
  sections: [
    {
      type: 'heading',
      text: 'Inputs',
    },
    {
      type: 'keywords',
      heading: 'Component Design',
      params: [
        {
          keyword: 'nominalThickness',
        },
        {
          keyword: 'undertolerance',
        },
        {
          keyword: 'maxTime',
        },
        {
          keyword: 'failureThicknessConfidence',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Low') {
              return 'Low'
            } else if(value === 'Moderate') {
              return 'Moderate'
            } else if(value === 'High') {
              return 'High'
            }
            return value
          },
        },
        {
          keyword: 'failureThicknessMethod',
          transform: (value) => {
            if(value === 'specify') {
              return 'Specify'
            } else if(value === 'calculate') {
              return 'Calculate'
            }
            return value
          },
        },
        {
          keyword: 'internalPressure',
          predicate: fields => fields.failureThicknessMethod === 'calculate',
        },
        {
          keyword: 'flowStress',
          predicate: fields => fields.failureThicknessMethod === 'calculate',
        },
        {
          keyword: 'innerDiameter',
          predicate: fields => fields.failureThicknessMethod === 'calculate',
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Component Operating Conditions',
      params: [
        {
          keyword: 'operatingTemperature',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === '<25') {
              return '<25'
            } else if(value === '25 to 125') {
              return '25 to 125'
            } else if(value === '125 to 225') {
              return '125 to 225'
            } else if(value === '225 to 300') {
              return '225 to 300'
            } else if(value === '300 to 350') {
              return '300 to 350'
            } else if(value === '>350') {
              return '>350'
            }
            return value
          },
        },
        {
          keyword: 'environment',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Dry') {
              return 'Dry'
            } else if(value === 'Mild') {
              return 'Mild'
            } else if(value === 'Moderate') {
              return 'Moderate'
            } else if(value === 'Severe') {
              return 'Severe'
            }
            return value
          },
        },
        {
          keyword: 'exposureFraction',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === '0 to 0.25') {
              return '0 to 25'
            } else if(value === '0.25 to 0.5') {
              return '25 to 50'
            } else if(value === '0.5 to 0.75') {
              return '50 to 75'
            } else if(value === '0.75 to 1.0') {
              return '75 to 100'
            }
            return value
          },
        },
        {
          keyword: 'operatingTemperatureConfidence',
          predicate: fields => fields.operatingTemperature !== 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Low') {
              return 'Low'
            } else if(value === 'Moderate') {
              return 'Moderate'
            } else if(value === 'High') {
              return 'High'
            }
            return value
          },
        },
        {
          keyword: 'environmentConfidence',
          predicate: fields => fields.environment !== 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Low') {
              return 'Low'
            } else if(value === 'Moderate') {
              return 'Moderate'
            } else if(value === 'High') {
              return 'High'
            }
            return value
          },
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Piping Design',
      params: [
        {
          keyword: 'attachments',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'None') {
              return 'None'
            } else if(value === 'Few') {
              return 'Few'
            } else if(value === 'Many') {
              return 'Many'
            }
            return value
          },
        },
        {
          keyword: 'pipingComplexity',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Simple') {
              return 'Simple'
            } else if(value === 'Average') {
              return 'Average'
            } else if(value === 'Complex') {
              return 'Complex'
            }
            return value
          },
        },
        {
          keyword: 'supportRings',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Horizontal and Protruding') {
              return 'Horizontal and Protruding'
            } else if(value === 'Vertical and Protruding') {
              return 'Vertical and Protruding'
            } else if(value === 'Not Protruding') {
              return 'Not Protruding'
            } else if(value === 'None') {
              return 'None'
            }
            return value
          },
        },
        {
          keyword: 'lowPointWaterTrap',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
        {
          keyword: 'steamTracing',
          predicate: fields => fields.exposureFraction === 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes with Leaks') {
              return 'Yes with Leaks'
            } else if(value === 'Yes without Leaks') {
              return 'Yes without Leaks'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
        {
          keyword: 'airSoilInterface',
          predicate: fields => fields.exposureFraction === 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes without Puddling') {
              return 'Yes without Puddling'
            } else if(value === 'Yes with Puddling') {
              return 'Yes with Puddling'
            }
            return value
          },
        },
        {
          keyword: 'pooling',
          predicate: fields => fields.exposureFraction === 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
        {
          keyword: 'branchesGeometryChanges',
          predicate: fields => fields.exposureFraction === 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Insulation Design',
      params: [
        {
          keyword: 'jacketInstallQuality',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Low') {
              return 'Low'
            } else if(value === 'Moderate') {
              return 'Moderate'
            } else if(value === 'High') {
              return 'High'
            }
            return value
          },
        },
        {
          keyword: 'jacketingMaterial',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Non-Metallic') {
              return 'Non-Metallic'
            } else if(value === 'Galvanized Steel') {
              return 'Galvanized Steel'
            } else if(value === 'Aluminum') {
              return 'Aluminum'
            } else if(value === 'Stainless Steel') {
              return 'Stainless Steel'
            }
            return value
          },
        },
        {
          keyword: 'waterRepellent',
          predicate: fields => fields.exposureFraction === 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
        {
          keyword: 'properDrainage',
          predicate: fields => fields.exposureFraction === 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Coating Design',
      params: [
        {
          keyword: 'coatingInstallQuality',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Low') {
              return 'Low'
            } else if(value === 'Moderate') {
              return 'Moderate'
            } else if(value === 'High') {
              return 'High'
            }
            return value
          },
        },
        {
          keyword: 'coatingType',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'None') {
              return 'None'
            } else if(value === 'Poor') {
              return 'Poor'
            } else if(value === 'Moderate') {
              return 'Moderate'
            } else if(value === 'Good') {
              return 'Good'
            }
            return value
          },
        },
        {
          keyword: 'coatingMaterial',
          predicate: fields => fields.coatingType === 'unknown',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'None') {
              return 'None'
            } else if(value === 'Primer') {
              return 'Primer'
            } else if(value === 'Hot Dip Galvanizing') {
              return 'Hot Dip Galvanizing'
            } else if(value === 'Zinc Silicate w/ Top Seal') {
              return 'Zinc Silicate w/ Top Seal'
            } else if(value === '2 Layer with Zinc Rich Primer as 1st Layer') {
              return '2 Layer with Zinc Rich Primer as 1st Layer'
            } else if(value === '3 Layer with Zinc Rich Primer as 1st Layer') {
              return '3 Layer with Zinc Rich Primer as 1st Layer'
            } else if(value === '2 Component Epoxy') {
              return '2 Component Epoxy'
            } else if(value === '3 Layer on Epoxy Primer') {
              return '3 Layer on Epoxy Primer'
            } else if(value === '2 Layer Epoxy') {
              return '2 Layer Epoxy'
            } else if(value === '2 Layer Phenolic/Novolac') {
              return '2 Layer Phenolic/Novolac'
            } else if(value === 'Fusion Bonded Epoxy') {
              return 'Fusion Bonded Epoxy'
            } else if(value === 'Thermal Spray Aluminum w/ Top Seal') {
              return 'Thermal Spray Aluminum w/ Top Seal'
            } else if(value === 'Air Dried or Modified Silicone') {
              return 'Air Dried or Modified Silicone'
            } else if(value === 'Inorganic Polymer') {
              return 'Inorganic Polymer'
            }
            return value
          },
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Expert Prior Damage Rates',
      params: [
        {
          keyword: 'jacketingFailureTimeMean',
        },
        {
          keyword: 'expertJacketingConfidence',
        },
        {
          keyword: 'coatingFailureTimeMean',
        },
        {
          keyword: 'expertCoatingConfidence',
        },
        {
          keyword: 'internalDamageRate',
        },
        {
          keyword: 'internalDamageRateConfidence',
        },
        {
          keyword: 'expertDamageRate',
        },
        {
          keyword: 'expertConfidence',
        },
        {
          keyword: 'damageRateConfidence',
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'VTE Inspections',
      params: [
        {
          keyword: 'priorNonIntrusiveVisualInspection',
        },
        {
          keyword: 'lastNonIntrusiveVisualInspectionTime',
          predicate: fields => fields.priorNonIntrusiveVisualInspection === 'Yes',
        },
        {
          keyword: 'vteCorrosionSeverity',
          predicate: fields => fields.priorNonIntrusiveVisualInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Minimal') {
              return 'Minimal'
            } else if(value === 'Minor') {
              return 'Minor'
            } else if(value === 'Major') {
              return 'Major'
            } else if(value === 'Severe') {
              return 'Severe'
            }
            return value
          },
        },
        {
          keyword: 'coatingFailureVTE',
          predicate: fields => fields.priorNonIntrusiveVisualInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
        {
          keyword: 'sealantFailure',
          predicate: fields => fields.priorNonIntrusiveVisualInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'None') {
              return 'None'
            } else if(value === 'Sealed') {
              return 'Sealed'
            } else if(value === 'Cracks no Gaps') {
              return 'Cracks no Gaps'
            } else if(value === 'Open Gaps') {
              return 'Open Gaps'
            }
            return value
          },
        },
        {
          keyword: 'jacketingFailure',
          predicate: fields => fields.priorNonIntrusiveVisualInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
        {
          keyword: 'jacketCondition',
          predicate: fields => fields.priorNonIntrusiveVisualInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'None') {
              return 'None'
            } else if(value === 'Minimal') {
              return 'Minimal'
            } else if(value === 'Minor') {
              return 'Minor'
            } else if(value === 'Major') {
              return 'Major'
            } else if(value === 'Severe') {
              return 'Severe'
            }
            return value
          },
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'VTI Inspections',
      params: [
        {
          keyword: 'priorIntrusiveVisualInspection',
        },
        {
          keyword: 'lastIntrusiveVisualInspectionTime',
          predicate: fields => fields.priorIntrusiveVisualInspection === 'Yes',
        },
        {
          keyword: 'vtiCorrosionSeverity',
          predicate: fields => fields.priorIntrusiveVisualInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Minimal') {
              return 'Minimal'
            } else if(value === 'Minor') {
              return 'Minor'
            } else if(value === 'Major') {
              return 'Major'
            } else if(value === 'Severe') {
              return 'Severe'
            }
            return value
          },
        },
        {
          keyword: 'coatingFailureVTI',
          predicate: fields => fields.priorIntrusiveVisualInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'NDE Inspections',
      params: [
        {
          keyword: 'priorNdeInspection',
        },
        {
          keyword: 'lastNdeInspectionTime',
          predicate: fields => fields.priorNdeInspection === 'Yes',
        },
        {
          keyword: 'ndeCorrosionSeverity',
          predicate: fields => fields.priorNdeInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Minimal') {
              return 'Minimal'
            } else if(value === 'Minor') {
              return 'Minor'
            } else if(value === 'Major') {
              return 'Major'
            } else if(value === 'Severe') {
              return 'Severe'
            }
            return value
          },
        },
        {
          keyword: 'coatingFailureNDE',
          predicate: fields => fields.priorNdeInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Yes') {
              return 'Yes'
            } else if(value === 'No') {
              return 'No'
            }
            return value
          },
        },
        {
          keyword: 'measuredThickness',
          predicate: fields => fields.priorNdeInspection === 'Yes',
        },
        {
          keyword: 'measurementError',
          predicate: fields => fields.priorNdeInspection === 'Yes',
        },
        {
          keyword: 'measurementErrorConfidence',
          predicate: fields => fields.priorNdeInspection === 'Yes',
          transform: (value) => {
            if(value === 'unknown') {
              return 'Unknown'
            } else if(value === 'Low') {
              return 'Low'
            } else if(value === 'Moderate') {
              return 'Moderate'
            } else if(value === 'High') {
              return 'High'
            }
            return value
          },
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Decision Parameters',
      params: [
        {
          keyword: 'decisionsFlag',
        },
        {
          keyword: 'operatingProfit',
          predicate: fields => fields.decisionsFlag === 'True',
        },
        {
          keyword: 'replacementCost',
          predicate: fields => fields.decisionsFlag === 'True',
        },
        {
          keyword: 'failureCost',
          predicate: fields => fields.decisionsFlag === 'True',
        },
      ],
    },
    {
      type: 'heading',
      text: 'Results',
    },
    {
      type: 'keywords',
      heading: 'Mean Values',
      params: [
        {
          keyword: 'startingThicknessEV',
        },
        {
          keyword: 'coatingFailureTimeEV',
        },
        {
          keyword: 'jacketingFailureTimeEV',
        },
        {
          keyword: 'initiationTimeEV',
        },
        {
          keyword: 'damageRateEV',
        },
        {
          keyword: 'failureTimeEV',
        },
        {
          keyword: 'optimalReplaceTime',
          predicate: fields => fields.decisionsFlag === 'True',
        },
        {
          keyword: 'optimalUtility',
          predicate: fields => fields.decisionsFlag === 'True',
        },
      ],
    },
    {
      type: 'keywords',
      heading: 'Standard Deviation Values',
      params: [
        {
          keyword: 'startingThicknessSD',
        },
        {
          keyword: 'coatingFailureTimeSD',
        },
        {
          keyword: 'jacketingFailureTimeSD',
        },
        {
          keyword: 'initiationTimeSD',
        },
        {
          keyword: 'damageRateSD',
        },
        {
          keyword: 'failureTimeSD',
        },
      ],
    },
    {
      type: 'heading',
      text: 'Graphical Results',
    },
    {
      type: 'image',
      graphs: [
        {
          name: 'graphTemp1',
        },
      ],
    },
    {
      type: 'image',
      graphs: [
        {
          name: 'graphTemp3',
          predicate: fields => fields.priorNonIntrusiveVisualInspection === 'Yes' || fields.priorIntrusiveVisualInspection === 'Yes' || fields.priorNdeInspection === 'Yes',
        },
      ],
    },
    {
      type: 'image',
      graphs: [
        {
          name: 'graphTemp2',
          predicate: fields => fields.decisionsFlag === 'True',
        },
      ],
    },
  ],
}
