/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import SubStepper from '@e2grnd/ui-core/dist/containers/SubStepper'

function Step2({ problemType }) {
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        {problemType !== 'PROB3'
          ? (
            <SubStepper subStepIndex={0} />
          )
          : <SubStepper subStepIndex={1} />}
      </div>
    </div>
  )
}

Step2.propTypes = {
  problemType: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
})

export default connect(mapStateToProps)(Step2)
