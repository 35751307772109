/* eslint-disable react/forbid-prop-types */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import ToggleField from '@e2grnd/ui-core/dist/containers/ToggleField'

import { getNextTableDataMaintenance, getNextTableDataNDE, getNextTableDataVTEPROB3, getNextTableDataVTI } from '../lib/tableFunctions'

function AdvancedOptions({ problemType, style, runCondensation, modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime, modelConfidenceForMissingVaporBarrierFailureTime, materialOfConstruction, runQualMethodsBool, includeClientMethods, exposureConfidence, damageRateConfidence, internalExposureFractionConfidence, modelConfidenceForExternalExposureFraction, modelConfidenceForInstantaneousCorrosionRate, modelConfidenceForSealantDamageRateAndSealantFailureTime, modelConfidenceForUpdatedMissingJacketFailureTime, modelConfidenceForCorrosionInitialFailureTime, modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime, modelConfidenceForEffectiveCorrosionRate, jacketingFailureLikelihood, jacketingVaporBarrierFailureLikelihood, componentAgeVsInstallDateBool, jacketingWeightingVsOrgFlag, targtetMINPOF, discretizationSpacingDegree, spacingValue, modelConfidenceWetCorrosionRate, modelConfidenceForCoatingDesignLife, modelConfidenceForSealantFailureTime, modelConfidenceForCorrosionFailureTime, modelConfidenceForJacketingDesignFailureTime, modelConfidenceForVaporBarrierFailureTime, dewPointYearlyFractionConfidence, ramOverheadValue, spacingTypeForBoundedVariables, probabilisticInputFieldFlag, modelConfidenceForExternalExposureFractionProb, internalExposureFractionConfidenceProb, modelConfidenceWetCorrosionRateProb, modelConfidenceForEffectiveCorrosionRateProb, modelConfidenceForCoatingDesignLifeProb, dewPointYearlyFractionConfidenceProb, modelConfidenceForSealantFailureTimeProb, modelConfidenceForUpdatedMissingJacketFailureTimeProb, modelConfidenceForCorrosionFailureTimeProb, modelConfidenceForJacketingDesignFailureTimeProb, finerSpacingBetween0and1, targtetMINPOFBefore, priorNonIntrusiveVisualInspection, priorIntrusiveVisualInspection, priorNdeInspection, pastMaintenance, jacketingReplaced, coatingReplaced, jacketingSealantReplaced, inspectionInputType, inspectionMaintenanceTable, maintenanceTableNoCondensation, vteTablePROB3, ndeTable, vtiTable }) {
  let valCheckMaintenance
  let valCheckVTE
  let valCheckVTI
  let valCheckNDE
  if(inspectionInputType === 'manyTable') {
    valCheckMaintenance = getNextTableDataMaintenance(inspectionMaintenanceTable, maintenanceTableNoCondensation, true, false)
    valCheckVTE = getNextTableDataVTEPROB3(inspectionMaintenanceTable, vteTablePROB3, true, false)
    valCheckVTI = getNextTableDataVTI(inspectionMaintenanceTable, vtiTable, true)
    valCheckNDE = getNextTableDataNDE(inspectionMaintenanceTable, ndeTable, true, false)
  }

  return (
    <div>
      <div className="cf w-100">
        {problemType === 'PROB1'
          ? (
            <div className="cf w-100">
              <div className="fl pa2 w-40-l w-50-m w-100">
                <E2GSelectField name="exposureConfidence" style={style} value={exposureConfidence} label="Exposure Fraction Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                <E2GSelectField name="damageRateConfidence" style={style} value={damageRateConfidence} label="Damage Rate Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                {runCondensation === true
                  ? (
                    <E2GSelectField name="modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime" style={style} value={modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime} label="Vapor Barrier Damage Rate & Failure Time Model Confidence" fullWidth>
                      <E2GSelectFieldOption label="Low" value="Low" />
                      <E2GSelectFieldOption label="Moderate" value="Moderate" />
                      <E2GSelectFieldOption label="High" value="High" />
                    </E2GSelectField>
                  )
                  : ''}
              </div>
              <div className="fl w-100">
                {jacketingWeightingVsOrgFlag === true
                  ? (
                    <div>
                      <p>If likelihoods below do not sum to 100, they will be normalized to 100. Note that M stands for Missing, S for Sealant, and C for Corrosion.</p>
                      <DataTableField
                        id="jacketingFailureLikelihood"
                        name="jacketingFailureLikelihood"
                        startRows={3}
                        value={jacketingFailureLikelihood}
                        rowHeaders={['M', 'S', 'C']}
                        colHeaders={{
                          USCustomary: [
                            'Jacketing Failure Likelihood (%)',
                          ],
                          Metric: [
                            'Jacketing Failure Likelihood (%)',
                          ],
                        }}
                      />
                      {runCondensation === true
                        ? (
                          <div className="fl pa2 w-40-l w-50-m w-100">
                            <DataTableField
                              id="jacketingVaporBarrierFailureLikelihood"
                              name="jacketingVaporBarrierFailureLikelihood"
                              startRows={2}
                              value={jacketingVaporBarrierFailureLikelihood}
                              rowHeaders={['M', 'S']}
                              colHeaders={{
                                USCustomary: [
                                  'Vapor Barrier Jacketing<br>Failure Likelihood (%)',
                                ],
                                Metric: [
                                  'Vapor Barrier Jacketing<br>Failure Likelihood (%)',
                                ],
                              }}
                            />
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
              <div className="fl w-100">
                <div className="fl w-40-l w-50-m w-100">
                  <E2GTextField name="targtetMINPOF" style={style} value={targtetMINPOF} label="Target Minimum POF" type="number" textWidth="100%" required/>
                  <E2GSelectField name="discretizationSpacingDegree" style={style} value={discretizationSpacingDegree} label="Discretization Spacing Degree" fullWidth>
                    <E2GSelectFieldOption label="Coarse Spacing (Plus 0 Spacing)" value="Coarse Spacing" />
                    <E2GSelectFieldOption label="Average-Low Spacing (Plus 5 Spacing)" value="Average-Mild Spacing" />
                    <E2GSelectFieldOption label="Average-Mid Spacing (Plus 10 Spacing)" value="Average-Mid Spacing" />
                    <E2GSelectFieldOption label="Average-High Spacing (Plus 15 Spacing)" value="Average-High Spacing" />
                    <E2GSelectFieldOption label="Fine-Low Spacing (Plus 20 Spacing)" value="Fine-Mild Spacing" />
                    <E2GSelectFieldOption label="Fine-Mid Spacing (Plus 25 Spacing)" value="Fine-Mid Spacing" />
                    <E2GSelectFieldOption label="Maunally Set Spacing" value="Maunally Set Spacing" />
                  </E2GSelectField>
                  {discretizationSpacingDegree === 'Maunally Set Spacing'
                    ? (
                      <E2GTextField name="spacingValue" style={style} value={spacingValue} label="Spacing Value" type="number" textWidth="100%" />
                    )
                    : ''}
                  <E2GSelectField name="ramOverheadValue" style={style} value={ramOverheadValue} label="Overhead RAM (GB) Value" fullWidth>
                    <E2GSelectFieldOption label="2 GB" value="2" />
                    <E2GSelectFieldOption label="4 GB" value="4" />
                    <E2GSelectFieldOption label="8 GB" value="8" />
                    <E2GSelectFieldOption label="16 GB" value="16" />
                  </E2GSelectField>
                  {/* <ToggleField name="jacketingWeightingVsOrgFlag" value={jacketingWeightingVsOrgFlag} label="Use Jacketing Weighting Method over Mimimum of the Modes" /> */}
                  {materialOfConstruction !== 'SS'
                    ? (
                      <div className="fl w-100">
                        {runQualMethodsBool === true
                          ? (
                            <ToggleField name="includeClientMethods" value={includeClientMethods} label="Enable Qualitative Client Methods" />
                          )
                          : ''}
                      </div>
                    )
                    : <input type="hidden" name="includeClientMethods" value={false} />}
                </div>
              </div>
            </div>
          )
          : ''}
        {problemType === 'PROB3'
          ? (
            <div className="fl w-100">
              {probabilisticInputFieldFlag === false
                ? (
                  <div>
                    <div className="fl pa2 w-50-l w-100">
                      <E2GSelectField name="modelConfidenceForExternalExposureFraction" style={style} value={modelConfidenceForExternalExposureFraction} label="External Exposure Fraction Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                      <E2GSelectField name="internalExposureFractionConfidence" style={style} value={internalExposureFractionConfidence} label="Internal Exposure Fraction Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                      <E2GSelectField name="modelConfidenceWetCorrosionRate" style={style} value={modelConfidenceWetCorrosionRate} label="Wet Corrosion Rate Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                      <E2GSelectField name="modelConfidenceForEffectiveCorrosionRate" style={style} value={modelConfidenceForEffectiveCorrosionRate} label="Max Effective Corrosion Rate Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                      <E2GSelectField name="modelConfidenceForCoatingDesignLife" style={style} value={modelConfidenceForCoatingDesignLife} label="Coating Design Life Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                    </div>
                    <div className="fl pa2 w-50-l w-100">
                      <E2GSelectField name="modelConfidenceForSealantFailureTime" style={style} value={modelConfidenceForSealantFailureTime} label="Sealant Failure Time Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                      <E2GSelectField name="modelConfidenceForUpdatedMissingJacketFailureTime" style={style} value={modelConfidenceForUpdatedMissingJacketFailureTime} label="Updated Jacket Missing First Failure Time Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                      <E2GSelectField name="modelConfidenceForCorrosionFailureTime" style={style} value={modelConfidenceForCorrosionFailureTime} label="Jacket Corrosion Failure Time Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                      <E2GSelectField name="modelConfidenceForJacketingDesignFailureTime" style={style} value={modelConfidenceForJacketingDesignFailureTime} label="Jacket Design Failure Time Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                      {runCondensation === true
                        ? (
                          <div>
                            <E2GSelectField name="dewPointYearlyFractionConfidence" style={style} value={dewPointYearlyFractionConfidence} label="Dew Point Model Confidence" fullWidth>
                              <E2GSelectFieldOption label="Low" value="Low" />
                              <E2GSelectFieldOption label="Moderate" value="Moderate" />
                              <E2GSelectFieldOption label="High" value="High" />
                            </E2GSelectField>
                          </div>
                        )
                        : ''}
                    </div>
                  </div>
                )
                : <div>
                  <div className="fl w-50-l w-100">
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="modelConfidenceForExternalExposureFractionProb"
                        jsonEditable
                        value={modelConfidenceForExternalExposureFractionProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'External Exposure Fraction Model Confidence',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="internalExposureFractionConfidenceProb"
                        jsonEditable
                        value={internalExposureFractionConfidenceProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'Internal Exposure Fraction Flux Rate Model Confidences',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="modelConfidenceWetCorrosionRateProb"
                        jsonEditable
                        value={modelConfidenceWetCorrosionRateProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'Wet Corrosion Rate Model Confidence',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="modelConfidenceForEffectiveCorrosionRateProb"
                        jsonEditable
                        value={modelConfidenceForEffectiveCorrosionRateProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'Max Effective Corrosion Rate Model Confidence',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                    {runCondensation === true
                      ? (
                        <div className="pt2">
                          <ProbabilisticInputField
                            name="dewPointYearlyFractionConfidenceProb"
                            jsonEditable
                            value={dewPointYearlyFractionConfidenceProb}
                            variableType="discrete"
                            InputProps={{
                              label: 'Dew Point Model Confidence',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                              fullWidth: true,
                            }}
                          />
                        </div>
                      )
                      : ''}
                  </div>
                  <div className="fl w-50-l w-100">
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="modelConfidenceForCoatingDesignLifeProb"
                        jsonEditable
                        value={modelConfidenceForCoatingDesignLifeProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'Coating Design Life Model Confidence',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="modelConfidenceForSealantFailureTimeProb"
                        jsonEditable
                        value={modelConfidenceForSealantFailureTimeProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'Sealant Failure Time Model Confidence',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="modelConfidenceForUpdatedMissingJacketFailureTimeProb"
                        jsonEditable
                        value={modelConfidenceForUpdatedMissingJacketFailureTimeProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'Jacket Missing First Failure Time Model Confidence',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="modelConfidenceForCorrosionFailureTimeProb"
                        jsonEditable
                        value={modelConfidenceForCorrosionFailureTimeProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'Jacket Corrosion Failure Time Model Confidence',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                    <div className="pt2">
                      <ProbabilisticInputField
                        name="modelConfidenceForJacketingDesignFailureTimeProb"
                        jsonEditable
                        value={modelConfidenceForJacketingDesignFailureTimeProb}
                        variableType="discrete"
                        InputProps={{
                          label: 'Jacket Design Failure Time Model Confidence',
                          jsonKeyPath: 'median',
                          isNumber: false,
                          required: true,
                          fullWidth: true,
                        }}
                      />
                    </div>
                  </div>
                </div>}
              <div className="fl w-100">
                {inspectionInputType === 'lastSingle'
                  ? (
                    <div>
                      {priorNonIntrusiveVisualInspection === 'Yes' || priorIntrusiveVisualInspection === 'Yes' || priorNdeInspection === 'Yes' || pastMaintenance === ' Yes' || jacketingReplaced === ' Yes' || coatingReplaced === ' Yes' || jacketingSealantReplaced === ' Yes'
                        ? (
                          <div>
                            <E2GTextField name="targtetMINPOFBefore" style={style} value={targtetMINPOFBefore} label="Target Minimum POF (Before Inspections)" type="number" textWidth="100%" required />
                            <E2GTextField name="targtetMINPOF" style={style} value={targtetMINPOF} label="Target Minimum POF (After Inspections)" type="number" textWidth="100%" required />
                          </div>
                        )
                        : <E2GTextField name="targtetMINPOF" style={style} value={targtetMINPOF} label="Target Minimum POF" type="number" textWidth="100%" required />}
                    </div>
                  )
                  : ''}
                {inspectionInputType === 'manyTable'
                  ? (
                    <div>
                      {valCheckMaintenance !== 0 || valCheckVTE !== 0 || valCheckVTI !== 0 || valCheckNDE !== 0
                        ? (
                          <div>
                            <E2GTextField name="targtetMINPOFBefore" style={style} value={targtetMINPOFBefore} label="Target Minimum POF (Before Inspections)" type="number" textWidth="100%" required />
                            <E2GTextField name="targtetMINPOF" style={style} value={targtetMINPOF} label="Target Minimum POF (After Inspections)" type="number" textWidth="100%" required />
                          </div>
                        )
                        : <E2GTextField name="targtetMINPOF" style={style} value={targtetMINPOF} label="Target Minimum POF" type="number" textWidth="100%" required />}
                    </div>
                  )
                  : ''}
                <E2GSelectField name="discretizationSpacingDegree" style={style} value={discretizationSpacingDegree} label="Discretization Spacing Degree" fullWidth>
                  <E2GSelectFieldOption label="Coarse Spacing (Plus 0 Spacing)" value="Coarse Spacing" />
                  <E2GSelectFieldOption label="Average-Low Spacing (Plus 5 Spacing)" value="Average-Mild Spacing" />
                  <E2GSelectFieldOption label="Average-Mid Spacing (Plus 10 Spacing)" value="Average-Mid Spacing" />
                  <E2GSelectFieldOption label="Average-High Spacing (Plus 15 Spacing)" value="Average-High Spacing" />
                  <E2GSelectFieldOption label="Fine-Low Spacing (Plus 20 Spacing)" value="Fine-Mild Spacing" />
                  <E2GSelectFieldOption label="Fine-Mid Spacing (Plus 25 Spacing)" value="Fine-Mid Spacing" />
                  <E2GSelectFieldOption label="Maunally Set Spacing" value="Maunally Set Spacing" />
                </E2GSelectField>
                {discretizationSpacingDegree === 'Maunally Set Spacing'
                  ? (
                    <E2GTextField name="spacingValue" style={style} value={spacingValue} label="Spacing Value" type="number" textWidth="100%" />
                  )
                  : ''}
                <E2GSelectField name="spacingTypeForBoundedVariables" style={style} value={spacingTypeForBoundedVariables} label="Spacing Type for Variables Bounded by 0" fullWidth>
                  <E2GSelectFieldOption label="Linear" value="Linear" />
                  <E2GSelectFieldOption label="Logarithmic" value="Log" />
                </E2GSelectField>
                <E2GSelectField name="ramOverheadValue" style={style} value={ramOverheadValue} label="Overhead RAM (GB)" fullWidth>
                  <E2GSelectFieldOption label="2 GB" value="2" />
                  <E2GSelectFieldOption label="4 GB" value="4" />
                  <E2GSelectFieldOption label="8 GB" value="8" />
                  <E2GSelectFieldOption label="16 GB" value="16" />
                </E2GSelectField>
                <ToggleField name="probabilisticInputFieldFlag" value={probabilisticInputFieldFlag} label="Enable Probabilistic Input Fields" />
                <ToggleField name="finerSpacingBetween0and1" value={finerSpacingBetween0and1} label="Enable Finer Spacing Between 0 and 1" />
              </div>
            </div>
          )
          : ''}
        {problemType === 'PROB2'
          ? (
            <div className="fl w-100">
              <div className="fl pa2 w-40-l w-50-m w-100">
                <E2GSelectField name="modelConfidenceForExternalExposureFraction" style={style} value={modelConfidenceForExternalExposureFraction} label="External Exposure Fraction Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                <E2GSelectField name="internalExposureFractionConfidence" style={style} value={internalExposureFractionConfidence} label="Internal Exposure Fraction Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                <E2GSelectField name="modelConfidenceForInstantaneousCorrosionRate" style={style} value={modelConfidenceForInstantaneousCorrosionRate} label="Instantaneous Corrosion Rate Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                <E2GSelectField name="modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime" style={style} value={modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime} label="Base Coating Damage & First Failure Time Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                {runCondensation === true
                  ? (
                    <div>
                      <E2GSelectField name="modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime" style={style} value={modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime} label="Vapor Barrier Damage Rate & Failure Time Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                    </div>
                  )
                  : ''}
                {jacketingWeightingVsOrgFlag === true
                  ? (
                    <div>
                      <div>
                        <p>If likelihoods below do not sum to 100, they will be normalized to 100. Note that M stands for Missing, S for Sealant, and C for Corrosion.</p>
                        <DataTableField
                          id="jacketingFailureLikelihood"
                          name="jacketingFailureLikelihood"
                          startRows={3}
                          value={jacketingFailureLikelihood}
                          rowHeaders={['M', 'S', 'C']}
                          colHeaders={{
                            USCustomary: [
                              'Component Jacketing<br>Failure Likelihood (%)',
                            ],
                            Metric: [
                              'Component Jacketing<br>Failure Likelihood (%)',
                            ],
                          }}
                        />
                      </div>
                    </div>
                  )
                  : ''}
                <div>
                  {/* <ToggleField name="componentAgeVsInstallDateBool" value={componentAgeVsInstallDateBool} label="Use Component Age over Component Install Date" /> */}
                  <ToggleField name="jacketingWeightingVsOrgFlag" value={jacketingWeightingVsOrgFlag} label="Use Jacketing Weighting Method ovet Mimimum of the Modes" />
                </div>
              </div>
              <div className="fl pa2 w-40-l w-50-m w-100">
                <E2GSelectField name="modelConfidenceForSealantDamageRateAndSealantFailureTime" style={style} value={modelConfidenceForSealantDamageRateAndSealantFailureTime} label="Sealant Damage Rate & First Failure Time Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                <E2GSelectField name="modelConfidenceForUpdatedMissingJacketFailureTime" style={style} value={modelConfidenceForUpdatedMissingJacketFailureTime} label="Updated Jacket Missing First Failure Time Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                <E2GSelectField name="modelConfidenceForCorrosionInitialFailureTime" style={style} value={modelConfidenceForCorrosionInitialFailureTime} label="Jacket Corrosion Initial First Failure Time Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                <E2GSelectField name="modelConfidenceForEffectiveCorrosionRate" style={style} value={modelConfidenceForEffectiveCorrosionRate} label="Max Effective Corrosion Rate Model Confidence" fullWidth>
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                {runCondensation === true
                  ? (
                    <div>
                      <E2GSelectField name="modelConfidenceForMissingVaporBarrierFailureTime" style={style} value={modelConfidenceForMissingVaporBarrierFailureTime} label="Missing Vapor Barrier Failure Time Model Confidence" fullWidth>
                        <E2GSelectFieldOption label="Low" value="Low" />
                        <E2GSelectFieldOption label="Moderate" value="Moderate" />
                        <E2GSelectFieldOption label="High" value="High" />
                      </E2GSelectField>
                    </div>
                  )
                  : ''}
              </div>
              <div className="fl w-100">
                {jacketingWeightingVsOrgFlag === true
                  ? (
                    <div>
                      <p>If likelihoods below do not sum to 100, they will be normalized to 100. Note that M stands for Missing, S for Sealant, and C for Corrosion.</p>
                      <DataTableField
                        id="jacketingFailureLikelihood"
                        name="jacketingFailureLikelihood"
                        startRows={3}
                        value={jacketingFailureLikelihood}
                        rowHeaders={['M', 'S', 'C']}
                        colHeaders={{
                          USCustomary: [
                            'Component Jacketing<br>Failure Likelihood (%)',
                          ],
                          Metric: [
                            'Component Jacketing<br>Failure Likelihood (%)',
                          ],
                        }}
                      />
                      {runCondensation === true
                        ? (
                          <div className="fl pa2 w-40-l w-50-m w-100">
                            <DataTableField
                              id="jacketingVaporBarrierFailureLikelihood"
                              name="jacketingVaporBarrierFailureLikelihood"
                              startRows={2}
                              value={jacketingVaporBarrierFailureLikelihood}
                              rowHeaders={['M', 'S']}
                              colHeaders={{
                                USCustomary: [
                                  'Component Vapor Barrier<br>Jacketing Failure Likelihood (%)',
                                ],
                                Metric: [
                                  'Component Vapor Barrier<br>Jacketing Failure Likelihood (%)',
                                ],
                              }}
                            />
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
            </div>
          )
          : ''}
      </div>
    </div>
  )
}

AdvancedOptions.propTypes = {
  problemType: PropTypes.string,
  style: PropTypes.object,
  damageRateConfidence: PropTypes.string,
  internalExposureFractionConfidence: PropTypes.string,
  modelConfidenceForUpdatedMissingJacketFailureTime: PropTypes.string,
  modelConfidenceForCorrosionInitialFailureTime: PropTypes.string,
  modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime: PropTypes.string,
  modelConfidenceForEffectiveCorrosionRate: PropTypes.string,
  modelConfidenceForExternalExposureFraction: PropTypes.string,
  modelConfidenceForInstantaneousCorrosionRate: PropTypes.string,
  modelConfidenceForSealantDamageRateAndSealantFailureTime: PropTypes.string,
  spacingTypeForBoundedVariables: PropTypes.string,
  exposureConfidence: PropTypes.string,
  runQualMethodsBool: PropTypes.bool,
  includeClientMethods: PropTypes.bool,
  runCondensation: PropTypes.bool,
  componentAgeVsInstallDateBool: PropTypes.bool,
  materialOfConstruction: PropTypes.string,
  modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime: PropTypes.string,
  modelConfidenceForMissingVaporBarrierFailureTime: PropTypes.string,
  jacketingFailureLikelihood: PropTypes.array,
  jacketingVaporBarrierFailureLikelihood: PropTypes.array,
  jacketingWeightingVsOrgFlag: PropTypes.bool,
  targtetMINPOF: PropTypes.number,
  discretizationSpacingDegree: PropTypes.string,
  spacingValue: PropTypes.number,
  modelConfidenceWetCorrosionRate: PropTypes.string,
  modelConfidenceForCoatingDesignLife: PropTypes.string,
  modelConfidenceForSealantFailureTime: PropTypes.string,
  modelConfidenceForCorrosionFailureTime: PropTypes.string,
  modelConfidenceForJacketingDesignFailureTime: PropTypes.string,
  modelConfidenceForVaporBarrierFailureTime: PropTypes.string,
  dewPointYearlyFractionConfidence: PropTypes.string,
  ramOverheadValue: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  modelConfidenceForExternalExposureFractionProb: PropTypes.string,
  internalExposureFractionConfidenceProb: PropTypes.string,
  modelConfidenceWetCorrosionRateProb: PropTypes.string,
  modelConfidenceForEffectiveCorrosionRateProb: PropTypes.string,
  modelConfidenceForCoatingDesignLifeProb: PropTypes.string,
  dewPointYearlyFractionConfidenceProb: PropTypes.string,
  modelConfidenceForSealantFailureTimeProb: PropTypes.string,
  modelConfidenceForUpdatedMissingJacketFailureTimeProb: PropTypes.string,
  modelConfidenceForCorrosionFailureTimeProb: PropTypes.string,
  modelConfidenceForJacketingDesignFailureTimeProb: PropTypes.string,
  finerSpacingBetween0and1: PropTypes.bool,
  targtetMINPOFBefore: PropTypes.number,
  inspectionInputType: PropTypes.string,
  priorNonIntrusiveVisualInspection: PropTypes.string,
  priorIntrusiveVisualInspection: PropTypes.string,
  priorNdeInspection: PropTypes.string,
  pastMaintenance: PropTypes.string,
  jacketingReplaced: PropTypes.string,
  coatingReplaced: PropTypes.string,
  jacketingSealantReplaced: PropTypes.string,
  inspectionMaintenanceTable: PropTypes.array,
  maintenanceTableNoCondensation: PropTypes.array,
  vteTablePROB3: PropTypes.array,
  ndeTable: PropTypes.array,
  vtiTable: PropTypes.array,
}
const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  style: state.ui.style,
  damageRateConfidence: state.workflow.fields.damageRateConfidence,
  spacingTypeForBoundedVariables: state.workflow.fields.spacingTypeForBoundedVariables,
  internalExposureFractionConfidence: state.workflow.fields.internalExposureFractionConfidence,
  modelConfidenceForUpdatedMissingJacketFailureTime: state.workflow.fields.modelConfidenceForUpdatedMissingJacketFailureTime,
  modelConfidenceForCorrosionInitialFailureTime: state.workflow.fields.modelConfidenceForCorrosionInitialFailureTime,
  modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime: state.workflow.fields.modelConfidenceForBaseCoatingDamageAndCoatingFirstFailureTime,
  modelConfidenceForEffectiveCorrosionRate: state.workflow.fields.modelConfidenceForEffectiveCorrosionRate,
  modelConfidenceForExternalExposureFraction: state.workflow.fields.modelConfidenceForExternalExposureFraction,
  modelConfidenceForInstantaneousCorrosionRate: state.workflow.fields.modelConfidenceForInstantaneousCorrosionRate,
  modelConfidenceForSealantDamageRateAndSealantFailureTime: state.workflow.fields.modelConfidenceForSealantDamageRateAndSealantFailureTime,
  exposureConfidence: state.workflow.fields.exposureConfidence,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  includeClientMethods: state.workflow.fields.includeClientMethods,
  runCondensation: state.workflow.fields.runCondensation,
  materialOfConstruction: state.workflow.fields.materialOfConstruction,
  modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime: state.workflow.fields.modelConfidenceForVaporBarrierDamageRateAndVaporBarrierFailureTime,
  modelConfidenceForMissingVaporBarrierFailureTime: state.workflow.fields.modelConfidenceForMissingVaporBarrierFailureTime,
  jacketingFailureLikelihood: state.workflow.fields.jacketingFailureLikelihood,
  jacketingVaporBarrierFailureLikelihood: state.workflow.fields.jacketingVaporBarrierFailureLikelihood,
  componentAgeVsInstallDateBool: state.workflow.fields.componentAgeVsInstallDateBool,
  jacketingWeightingVsOrgFlag: state.workflow.fields.jacketingWeightingVsOrgFlag,
  targtetMINPOF: state.workflow.fields.targtetMINPOF,
  discretizationSpacingDegree: state.workflow.fields.discretizationSpacingDegree,
  spacingValue: state.workflow.fields.spacingValue,
  modelConfidenceWetCorrosionRate: state.workflow.fields.modelConfidenceWetCorrosionRate,
  modelConfidenceForCoatingDesignLife: state.workflow.fields.modelConfidenceForCoatingDesignLife,
  modelConfidenceForSealantFailureTime: state.workflow.fields.modelConfidenceForSealantFailureTime,
  modelConfidenceForCorrosionFailureTime: state.workflow.fields.modelConfidenceForCorrosionFailureTime,
  modelConfidenceForJacketingDesignFailureTime: state.workflow.fields.modelConfidenceForJacketingDesignFailureTime,
  modelConfidenceForVaporBarrierFailureTime: state.workflow.fields.modelConfidenceForVaporBarrierFailureTime,
  dewPointYearlyFractionConfidence: state.workflow.fields.dewPointYearlyFractionConfidence,
  ramOverheadValue: state.workflow.fields.ramOverheadValue,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  modelConfidenceForExternalExposureFractionProb: state.workflow.fields.modelConfidenceForExternalExposureFractionProb,
  internalExposureFractionConfidenceProb: state.workflow.fields.internalExposureFractionConfidenceProb,
  modelConfidenceWetCorrosionRateProb: state.workflow.fields.modelConfidenceWetCorrosionRateProb,
  modelConfidenceForEffectiveCorrosionRateProb: state.workflow.fields.modelConfidenceForEffectiveCorrosionRateProb,
  modelConfidenceForCoatingDesignLifeProb: state.workflow.fields.modelConfidenceForCoatingDesignLifeProb,
  dewPointYearlyFractionConfidenceProb: state.workflow.fields.dewPointYearlyFractionConfidenceProb,
  modelConfidenceForSealantFailureTimeProb: state.workflow.fields.modelConfidenceForSealantFailureTimeProb,
  modelConfidenceForUpdatedMissingJacketFailureTimeProb: state.workflow.fields.modelConfidenceForUpdatedMissingJacketFailureTimeProb,
  modelConfidenceForCorrosionFailureTimeProb: state.workflow.fields.modelConfidenceForCorrosionFailureTimeProb,
  modelConfidenceForJacketingDesignFailureTimeProb: state.workflow.fields.modelConfidenceForJacketingDesignFailureTimeProb,
  finerSpacingBetween0and1: state.workflow.fields.finerSpacingBetween0and1,
  targtetMINPOFBefore: state.workflow.fields.targtetMINPOFBefore,
  inspectionInputType: state.workflow.fields.inspectionInputType,
  priorNonIntrusiveVisualInspection: state.workflow.fields.priorNonIntrusiveVisualInspection,
  priorIntrusiveVisualInspection: state.workflow.fields.priorIntrusiveVisualInspection,
  priorNdeInspection: state.workflow.fields.priorNdeInspection,
  pastMaintenance: state.workflow.fields.pastMaintenance,
  jacketingReplaced: state.workflow.fields.jacketingReplaced,
  coatingReplaced: state.workflow.fields.coatingReplaced,
  jacketingSealantReplaced: state.workflow.fields.jacketingSealantReplaced,
  inspectionMaintenanceTable: state.workflow.fields.inspectionMaintenanceTable,
  maintenanceTableNoCondensation: state.workflow.fields.maintenanceTableNoCondensation,
  vteTablePROB3: state.workflow.fields.vteTablePROB3,
  ndeTable: state.workflow.fields.ndeTable,
  vtiTable: state.workflow.fields.vtiTable,
})
export default connect(mapStateToProps, null)(AdvancedOptions)
