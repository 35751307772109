/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step2({ problemType, runCondensation, seamsAmount, properSeamAdhesian, componentType, verticalTelescoping, endCapType, protrusionsCovered, horizontalLapJoints, insulationType, lowPointWaterTrap, pooling, highTempSilicone, runQualMethodsBool, insulationMaintanceInsectionQuality, properDrainage, waterRepellent, jacketInstallQuality, jacketingMaterial, jacketPostInstallQAQC, moistureAccumulationSites, sealantAmount, highTempSealant, sealantPostInstallQAQC, vaporBarrierPostInstallQaQc, vaporBarrierInstallQuality, sealantInstallQuality, jacketMetallic, probabilisticInputFieldFlag, insulationTypeProb, properDrainageProb, moistureAccumulationSitesProb, lowPointWaterTrapProb, protrusionsCoveredProb, horizontalLapJointsProb, verticalTelescopingProb, endCapTypeProb, jacketingMaterialProb, jacketMetallicProb, jacketInstallQualityProb, jacketPostInstallQAQCProb, sealantAmountProb, highTempSealantProb, sealantPostInstallQAQCProb, sealantInstallQualityProb }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB1'
        ? (
          <div className="cf w-100">
            <div className="cf w-100">
              <div className="fl w-third-l w-50-m w-100">
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Insulation type refers to the specific material and method used to insulate a space or component.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="insulationType" value={insulationType} label="Insulation Type?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Calcium Silicate High Chloride" value="Calcium Silicate High Chloride" />
                  <E2GSelectFieldOption label="Calcium Silicate Low Chloride" value="Calcium Silicate Low Chloride" />
                  <E2GSelectFieldOption label="Mineral Wool" value="Mineral Wool" />
                  <E2GSelectFieldOption label="Perlite" value="Perlite" />
                  <E2GSelectFieldOption label="Foam Glass" value="Foam Glass" />
                  <E2GSelectFieldOption label="Expanded Perlite" value="Expanded Perlite" />
                  <E2GSelectFieldOption label="Modern Aerogel" value="Modern Aerogel" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {runQualMethodsBool === true
                  ? (
                    // <E2GInfoButton
                    //   tooltip={<div>
                    //     <p>Insulation maintenance and inspection quality refers to the standards and practices used to ensure the insulation remains effective and safe over time</p>
                    //   </div>}
                    //   dialog
                    // >
                    <E2GSelectField name="insulationMaintanceInsectionQuality" value={insulationMaintanceInsectionQuality} label="Insulation Maintenance/Inspection Quality">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="None" value="None" />
                      <E2GSelectFieldOption label="Low" value="Low" />
                      <E2GSelectFieldOption label="High" value="High" />
                    </E2GSelectField>
                    // </E2GInfoButton>
                  )
                  : ''}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Water repellent refers to materials or treatments designed to prevent water from penetrating the insulation.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="waterRepellent" value={waterRepellent} label="Water Repellent?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Proper drainage refers to the design and implementation of measures that ensure water is effectively directed away from insulated areas.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="properDrainage" value={properDrainage} label="Proper Drainage?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>A low point or water trap refers to areas where water can accumulate due to gravity.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="lowPointWaterTrap" value={lowPointWaterTrap} label="Low Point or Water Trap?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Water pooling refers to the accumulation of water in certain areas where it cannot drain properly.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="pooling" value={pooling} label="Water Pooling?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
              </div>
              <div className="fl w-third-l w-50-m w-100">
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Jacketing material refers to the outer covering that is wrapped around insulation, such as on pipes or ducts.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="jacketingMaterial" value={jacketingMaterial} label="Jacketing Material">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Non-Metallic" value="Non-Metallic" />
                  <E2GSelectFieldOption label="Stainless Steel" value="Stainless Steel" />
                  <E2GSelectFieldOption label="Aluminum" value="Aluminum" />
                  <E2GSelectFieldOption label="Galvanized Steel" value="Galvanized Steel" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Jacket install quality refers to the standards and practices used to ensure that the jacketing material is applied correctly and effectively.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="jacketInstallQuality" value={jacketInstallQuality} label="Jacket Install Quality">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Jacket post install QA/QC (Quality Assurance/Quality Control) in an insulation system involves a series of checks and procedures to ensure that the jacketing material has been installed correctly and meets all required standards.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="jacketPostInstallQAQC" value={jacketPostInstallQAQC} label="Jacket Post Install QA/QC">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>The jacket sealant amount refers to the quantity of sealant used to ensure that the jacketing material is properly sealed.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="sealantAmount" value={sealantAmount} label="Jacket Sealant Amount">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Few" value="Few" />
                  <E2GSelectFieldOption label="Some" value="Some" />
                  <E2GSelectFieldOption label="Many" value="Many" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>High-temperature silicone is a type of silicone sealant designed to withstand extreme temperatures. </p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="highTempSilicone" value={highTempSilicone} label="High Temperature Silicone?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
              </div>
              <div className="fl w-third-l w-50-m w-100">
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>In an insulation system, protrusions covered to shed water refers to the practice of ensuring that any protruding elements, such as pipes, vents, or structural supports, are properly covered and sealed to direct water away from the insulation.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="protrusionsCovered" value={protrusionsCovered} label="Protrusions Covered to Shed Water?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="No" value="No" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Horizontal lap joints refer to the overlapping of two pieces of insulation material in a horizontal orientation. This technique is used to ensure a continuous insulation barrier and to prevent gaps that could compromise the system's thermal efficiency.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="horizontalLapJoints" value={horizontalLapJoints} label="Horizontal Lap Joints?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Poor Placement and Overlap" value="Poor Placement and Overlap" />
                  <E2GSelectFieldOption label="Poor Placement Only" value="Poor Placement Only" />
                  <E2GSelectFieldOption label="Poor Overlap Only" value="Poor Overlap Only" />
                  <E2GSelectFieldOption label="Proper Placement and Overlap" value="Proper Placement and Overlap" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {componentType === 'Top of Vertical Elbow' || componentType === 'Bottom of Vertical Elbow' || componentType === 'Horizontal Run after Vertical Run'
                  ? (
                    // <E2GInfoButton
                    //   tooltip={<div>
                    //     <p>Vertical telescoping typically refers to a design or installation feature where sections of insulation or jacketing are arranged to slide or fit into each other vertically.</p>
                    //   </div>}
                    //   dialog
                    // >
                    <E2GSelectField name="verticalTelescoping" value={verticalTelescoping} label="Vertical Telescoping?">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Up" value="Up" />
                      <E2GSelectFieldOption label="None" value="None" />
                      <E2GSelectFieldOption label="Down" value="Down" />
                    </E2GSelectField>
                    // </E2GInfoButton>
                  )
                  : ''}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>End cap quality refers to the standards and practices used to ensure that the end caps, which are used to seal the ends of insulated pipes, ducts, or other components, are installed correctly and effectively.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="endCapType" value={endCapType} label="End Cap Quality?">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Open" value="Open" />
                  <E2GSelectFieldOption label="Poor Design" value="Poor Design" />
                  <E2GSelectFieldOption label="Quality Design" value="Quality Design" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {runCondensation === true
                  ? (
                    <div>
                      {/* <E2GInfoButton
                        tooltip={<div>
                          <p>The number of seams in a vapor barrier refers to the joints or overlaps where sections of the vapor barrier material meet. The number of seams is crucial because each seam represents a potential point of failure where moisture could penetrate.</p>
                        </div>}
                        dialog
                      > */}
                      <E2GSelectField name="seamsAmount" value={seamsAmount} label="Vapor Barrier Number of Seams?">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Few" value="Few" />
                        <E2GSelectFieldOption label="Some" value="Some" />
                        <E2GSelectFieldOption label="Many" value="Many" />
                      </E2GSelectField>
                      {/* </E2GInfoButton> */}
                      {/* <E2GInfoButton
                        tooltip={<div>
                          <p>Proper seam adhesion for a vapor barrier refers to the effective bonding of the seams where sections of the vapor barrier meet.</p>
                        </div>}
                        dialog
                      > */}
                      <E2GSelectField name="properSeamAdhesian" value={properSeamAdhesian} label="Vapor Barrier Proper Seam Adhesian?">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Yes" value="Yes" />
                        <E2GSelectFieldOption label="No" value="No" />
                      </E2GSelectField>
                      {/* </E2GInfoButton> */}
                    </div>
                  )
                  : ''}
              </div>
            </div>
          </div>
        )
        : ''}
      {problemType === 'PROB2'
        ? (
          <div className="cf w-100">
            <div className="fl w-third-l w-50-m w-100">
              <E2GSelectField name="insulationType" value={insulationType} label="Insulation Type?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Calcium Silicate High Chloride" value="Calcium Silicate High Chloride" />
                <E2GSelectFieldOption label="Calcium Silicate Low Chloride" value="Calcium Silicate Low Chloride" />
                <E2GSelectFieldOption label="Mineral Wool" value="Mineral Wool" />
                <E2GSelectFieldOption label="Perlite" value="Perlite" />
                <E2GSelectFieldOption label="Foam Glass" value="Foam Glass" />
                <E2GSelectFieldOption label="Expanded Perlite" value="Expanded Perlite" />
                <E2GSelectFieldOption label="Modern Aerogel" value="Modern Aerogel" />
              </E2GSelectField>
              <E2GSelectField name="properDrainage" value={properDrainage} label="Proper Drainage?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              <E2GSelectField name="moistureAccumulationSites" value={moistureAccumulationSites} label="Moisture Accumulation Sites?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              <E2GSelectField name="lowPointWaterTrap" value={lowPointWaterTrap} label="Low Point or Water Trap?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
            </div>
            <div className="fl w-third-l w-50-m w-100">
              <E2GSelectField name="jacketingMaterial" value={jacketingMaterial} label="Jacketing Material">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Non-Metallic" value="Non-Metallic" />
                <E2GSelectFieldOption label="Stainless Steel" value="Stainless Steel" />
                <E2GSelectFieldOption label="Aluminum" value="Aluminum" />
                <E2GSelectFieldOption label="Galvanized Steel" value="Galvanized Steel" />
              </E2GSelectField>
              <E2GSelectField name="jacketInstallQuality" value={jacketInstallQuality} label="Jacket Install Quality">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Low" value="Low" />
                <E2GSelectFieldOption label="Moderate" value="Moderate" />
                <E2GSelectFieldOption label="High" value="High" />
              </E2GSelectField>
              <E2GSelectField name="jacketPostInstallQAQC" value={jacketPostInstallQAQC} label="Jacket Post Install QA/QC">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              <E2GSelectField name="sealantAmount" value={sealantAmount} label="Jacket Sealant Amount">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Few" value="Few" />
                <E2GSelectFieldOption label="Some" value="Some" />
                <E2GSelectFieldOption label="Many" value="Many" />
              </E2GSelectField>
              <E2GSelectField name="highTempSilicone" value={highTempSilicone} label="High Temperature Silicone?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
            </div>
            <div className="fl w-third-l w-50-m w-100">
              <E2GSelectField name="protrusionsCovered" value={protrusionsCovered} label="Protrusions Covered to Shed Water?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="No" value="No" />
                <E2GSelectFieldOption label="Yes" value="Yes" />
              </E2GSelectField>
              <E2GSelectField name="horizontalLapJoints" value={horizontalLapJoints} label="Horizontal Lap Joints?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Poor Placement and Overlap" value="Poor Placement and Overlap" />
                <E2GSelectFieldOption label="Poor Placement Only" value="Poor Placement Only" />
                <E2GSelectFieldOption label="Poor Overlap Only" value="Poor Overlap Only" />
                <E2GSelectFieldOption label="Proper Placement and Overlap" value="Proper Placement and Overlap" />
              </E2GSelectField>
              {componentType === 'Vertical Run' || componentType === 'Top of Vertical Elbow' || componentType === 'Bottom of Vertical Elbow' || componentType === 'Horizontal Run after Vertical Run'
                ? (
                  <E2GSelectField name="verticalTelescoping" value={verticalTelescoping} label="Vertical Telescoping?">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Up" value="Up" />
                    <E2GSelectFieldOption label="None" value="None" />
                    <E2GSelectFieldOption label="Down" value="Down" />
                  </E2GSelectField>
                )
                : ''}
              <E2GSelectField name="endCapType" value={endCapType} label="End Cap Quality?">
                <E2GSelectFieldOption label="Unknown" value="unknown" />
                <E2GSelectFieldOption label="Open" value="Open" />
                <E2GSelectFieldOption label="Poor Design" value="Poor Design" />
                <E2GSelectFieldOption label="Quality Design" value="Quality Design" />
              </E2GSelectField>
              {runCondensation === true
                ? (
                  <div>
                    <E2GSelectField name="seamsAmount" value={seamsAmount} label="Vapor Barrier Number of Seams?">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Few" value="Few" />
                      <E2GSelectFieldOption label="Some" value="Some" />
                      <E2GSelectFieldOption label="Many" value="Many" />
                    </E2GSelectField>
                    <E2GSelectField name="properSeamAdhesian" value={properSeamAdhesian} label="Vapor Barrier Proper Seam Adhesian?">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                  </div>
                )
                : ''}
            </div>
          </div>
        )
        : ''}
      <Submit label="Next" />
    </div>
  )
}

Step2.propTypes = {
  problemType: PropTypes.string,
  properDrainage: PropTypes.string,
  waterRepellent: PropTypes.string,
  jacketInstallQuality: PropTypes.string,
  jacketingMaterial: PropTypes.string,
  jacketPostInstallQAQC: PropTypes.string,
  runQualMethodsBool: PropTypes.bool,
  insulationMaintanceInsectionQuality: PropTypes.string,
  highTempSilicone: PropTypes.string,
  pooling: PropTypes.string,
  lowPointWaterTrap: PropTypes.string,
  insulationType: PropTypes.string,
  protrusionsCovered: PropTypes.string,
  horizontalLapJoints: PropTypes.string,
  verticalTelescoping: PropTypes.string,
  endCapType: PropTypes.string,
  moistureAccumulationSites: PropTypes.string,
  sealantAmount: PropTypes.string,
  componentType: PropTypes.string,
  runCondensation: PropTypes.bool,
  seamsAmount: PropTypes.string,
  properSeamAdhesian: PropTypes.string,
  highTempSealant: PropTypes.string,
  sealantPostInstallQAQC: PropTypes.string,
  vaporBarrierPostInstallQaQc: PropTypes.string,
  vaporBarrierInstallQuality: PropTypes.string,
  sealantInstallQuality: PropTypes.string,
  jacketMetallic: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  insulationTypeProb: PropTypes.string,
  properDrainageProb: PropTypes.string,
  moistureAccumulationSitesProb: PropTypes.string,
  lowPointWaterTrapProb: PropTypes.string,
  protrusionsCoveredProb: PropTypes.string,
  horizontalLapJointsProb: PropTypes.string,
  verticalTelescopingProb: PropTypes.string,
  endCapTypeProb: PropTypes.string,
  jacketingMaterialProb: PropTypes.string,
  jacketMetallicProb: PropTypes.string,
  jacketInstallQualityProb: PropTypes.string,
  jacketPostInstallQAQCProb: PropTypes.string,
  sealantAmountProb: PropTypes.string,
  highTempSealantProb: PropTypes.string,
  sealantPostInstallQAQCProb: PropTypes.string,
  sealantInstallQualityProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  properDrainage: state.workflow.fields.properDrainage,
  waterRepellent: state.workflow.fields.waterRepellent,
  jacketInstallQuality: state.workflow.fields.jacketInstallQuality,
  jacketingMaterial: state.workflow.fields.jacketingMaterial,
  jacketPostInstallQAQC: state.workflow.fields.jacketPostInstallQAQC,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  insulationMaintanceInsectionQuality: state.workflow.fields.insulationMaintanceInsectionQuality,
  highTempSilicone: state.workflow.fields.highTempSilicone,
  pooling: state.workflow.fields.pooling,
  lowPointWaterTrap: state.workflow.fields.lowPointWaterTrap,
  insulationType: state.workflow.fields.insulationType,
  protrusionsCovered: state.workflow.fields.protrusionsCovered,
  horizontalLapJoints: state.workflow.fields.horizontalLapJoints,
  verticalTelescoping: state.workflow.fields.verticalTelescoping,
  endCapType: state.workflow.fields.endCapType,
  moistureAccumulationSites: state.workflow.fields.moistureAccumulationSites,
  sealantAmount: state.workflow.fields.sealantAmount,
  componentType: state.workflow.fields.componentType,
  runCondensation: state.workflow.fields.runCondensation,
  seamsAmount: state.workflow.fields.seamsAmount,
  properSeamAdhesian: state.workflow.fields.properSeamAdhesian,
  highTempSealant: state.workflow.fields.highTempSealant,
  sealantPostInstallQAQC: state.workflow.fields.sealantPostInstallQAQC,
  vaporBarrierPostInstallQaQc: state.workflow.fields.vaporBarrierPostInstallQaQc,
  vaporBarrierInstallQuality: state.workflow.fields.vaporBarrierInstallQuality,
  sealantInstallQuality: state.workflow.fields.sealantInstallQuality,
  jacketMetallic: state.workflow.fields.jacketMetallic,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  insulationTypeProb: state.workflow.fields.insulationTypeProb,
  properDrainageProb: state.workflow.fields.properDrainageProb,
  moistureAccumulationSitesProb: state.workflow.fields.moistureAccumulationSitesProb,
  lowPointWaterTrapProb: state.workflow.fields.lowPointWaterTrapProb,
  protrusionsCoveredProb: state.workflow.fields.protrusionsCoveredProb,
  horizontalLapJointsProb: state.workflow.fields.horizontalLapJointsProb,
  verticalTelescopingProb: state.workflow.fields.verticalTelescopingProb,
  endCapTypeProb: state.workflow.fields.endCapTypeProb,
  jacketingMaterialProb: state.workflow.fields.jacketingMaterialProb,
  jacketMetallicProb: state.workflow.fields.jacketMetallicProb,
  jacketInstallQualityProb: state.workflow.fields.jacketInstallQualityProb,
  jacketPostInstallQAQCProb: state.workflow.fields.jacketPostInstallQAQCProb,
  sealantAmountProb: state.workflow.fields.sealantAmountProb,
  highTempSealantProb: state.workflow.fields.highTempSealantProb,
  sealantPostInstallQAQCProb: state.workflow.fields.sealantPostInstallQAQCProb,
  sealantInstallQualityProb: state.workflow.fields.sealantInstallQualityProb,
})

export default connect(mapStateToProps)(Step2)
