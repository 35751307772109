export default {
  USCustomary:
    [
      // {
      //   title: 'Hybrid Model - Unknown All Base Case',
      //   fileName: 'hyrbidExample.json',
      // },
      {
        title: 'Static Model - No Inspections',
        fileName: 'cuiUpdatedExample1.json',
      },
      {
        title: 'Static Model - No Inspections with Decisions',
        fileName: 'cuiUpdatedExample2.json',
      },
      {
        title: 'Static Model - NDE Inspection',
        fileName: 'cuiUpdatedExample3.json',
      },
      {
        title: 'Static Model - VTI Inspection with High Exposure Fraction Confidence',
        fileName: 'cuiUpdatedExample4.json',
      },
      {
        title: 'Static Model - VTE Inspection with Decisions',
        fileName: 'cuiUpdatedExample5.json',
      },
      {
        title: 'Static Model - Run Qualitative Methods',
        fileName: 'cuiUpdatedExample6.json',
      },
      {
        title: 'Static Model - Many Inspections',
        fileName: 'cuiManyTablesv0.json',
      },
      // {
      //   title: 'Time-dependent Model - No Inspections',
      //   fileName: 'cuiUpdatedExample7.json',
      // },
      // {
      //   title: 'Time-dependent Model - Three Inspections at Nonunique Times',
      //   fileName: 'cuiUpdatedExample8.json',
      // },
      // {
      //   title: 'Time-dependent Model - Three Inspections at Unique Times',
      //   fileName: 'cuiUpdatedExample9.json',
      // },
      // {
      //   title: 'Time-dependent Model -  Two Inspections at Unique Times with Condensation',
      //   fileName: 'cuiUpdatedExample10.json',
      // },
      // {
      //   title: 'Time-dependent Model - Many Inspections',
      //   fileName: 'cuiManyTablesv1.json',
      // },
      {
        title: 'Hybrid Model - No Inspections',
        fileName: 'hybrid_cuiUpdatedExample1.json',
      },
      {
        title: 'Hybrid Model - No Inspections - Probabilistic Input Field',
        fileName: 'hybrid_cuiUpdatedExample1Prob.json',
      },
      {
        title: 'Hybrid Model - No Inspections with Decisions',
        fileName: 'hybrid_cuiUpdatedExample2.json',
      },
      {
        title: 'Hybrid Model - NDE Inspection',
        fileName: 'hybrid_cuiUpdatedExample3.json',
      },
      {
        title: 'Hybrid Model - NDE Inspection - Probabilistic Input Field',
        fileName: 'hybrid_cuiUpdatedExample3Prob.json',
      },
      {
        title: 'Hybrid Model - VTI Inspection with High External Exposure Fraction Confidence',
        fileName: 'hybrid_cuiUpdatedExample4.json',
      },
      {
        title: 'Hybrid Model - VTE Inspection with Decisions',
        fileName: 'hybrid_cuiUpdatedExample5.json',
      },
      {
        title: 'Hybrid Model - Many Inspections',
        fileName: 'hybrid_cuiManyTables.json',
      },
    ],
  Metric: [
    {
      title: 'No Inspections',
      fileName: 'cuiUpdatedMetricExample1.json',
    },
  ],
}
