/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step1({ problemType, inspectionInputType, installDate, inspectionMaintenanceTable }) {
  return (
    <div className="cf w-100">
      <E2GSelectField name="inspectionInputType" value={inspectionInputType} label="Single or Multiple Inspections?">
        <E2GSelectFieldOption label="Single" value="lastSingle" />
        <E2GSelectFieldOption label="Multiple" value="manyTable" />
      </E2GSelectField>
      {inspectionInputType === 'manyTable'
        ? (
          <div>
            <DataTableField
              id="inspectionMaintenanceTable"
              name="inspectionMaintenanceTable"
              startRows={5}
              value={inspectionMaintenanceTable}
              containerHeight="220px"
              rowHeaders={false}
              colHeaders={{
                USCustomary: [
                  'Event Date<br/>(MM/DD/YYYY) ',
                  'VTE Inspection',
                  'VTI Inspection',
                  'NDE Inspection',
                  'Maintenance Event',
                ],
                Metric: [
                  'Event Date<br/>(MM/DD/YYYY) ',
                  'VTE Inspection',
                  'VTI Inspection',
                  'NDE Inspection',
                  'Maintenance Event',
                ],
              }}
            />
          </div>
        )
        : '' }
      <Submit label="Next" />
    </div>
  )
}

Step1.propTypes = {
  problemType: PropTypes.string,
  inspectionInputType: PropTypes.string,
  installDate: PropTypes.string,
  inspectionMaintenanceTable: PropTypes.array,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  inspectionInputType: state.workflow.fields.inspectionInputType,
  installDate: state.workflow.fields.installDate,
  inspectionMaintenanceTable: state.workflow.fields.inspectionMaintenanceTable,
})

export default connect(mapStateToProps)(Step1)
