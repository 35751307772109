/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

import { getNextTableDataVTI } from '../../lib/tableFunctions'

function Step4({ problemType, vtiTable, inspectionMaintenanceTable, inspectionInputType, insulationWetAtVTI, coatingConditionVTI, vtiCorrosionSeverity, priorIntrusiveVisualInspection, lastIntrusiveVisualInspectionTime, coatingFailureVTI, probabilisticInputFieldFlag, vtiCorrosionSeverityProb, coatingConditionVTIProb, insulationWetAtVTIProb }) {
  let valCheck
  let dataTableVTI
  if(inspectionInputType === 'manyTable') {
    valCheck = getNextTableDataVTI(inspectionMaintenanceTable, vtiTable, true)
  }
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        {inspectionInputType === 'manyTable'
          ? (
            <div>
              {valCheck !== 0
                ? (
                  <div>
                    <DataTableField
                      id="vtiTable"
                      name="vtiTable"
                      value={getNextTableDataVTI(inspectionMaintenanceTable, vtiTable, false)}
                      startRows={4}
                      rowHeaders={false}
                      colHeaders={{
                        USCustomary: [
                          'Inspection Date<br/>(MM/DD/YYYY) ',
                          'Insulation<br/>Wet at VTI?',
                          'VTI Component<br/>Corrosion Severity?',
                          'VTI Coating<br/>Damage Severity?',
                        ],
                        Metric: [
                          'Inspection Date<br/>(MM/DD/YYYY) ',
                          'Insulation<br/>Wet at VTI?',
                          'VTI Component<br/>Corrosion Severity?',
                          'VTI Coating<br/>Damage Severity?',
                        ],
                      }}
                    />

                  </div>
                )
                : 'No VTI inspection performed based on prior main inspection table'}
            </div>
          )
          : ''}
        {inspectionInputType === 'lastSingle'
          ? (
            <div>
              {/* <E2GInfoButton
                tooltip={<div>
                  <p>VTI involves a more thorough examination of equipment or structures by physically accessing and potentially removing parts of the system. This type of inspection goes beyond just looking at the exterior like VTE.</p>
                </div>}
                dialog
              > */}
              <E2GSelectField name="priorIntrusiveVisualInspection" value={priorIntrusiveVisualInspection} label="Prior Intrusive Visual Inspection?">
                <E2GSelectFieldOption label="Yes" value="Yes" />
                <E2GSelectFieldOption label="No" value="No" />
              </E2GSelectField>
              {/* </E2GInfoButton> */}
              {priorIntrusiveVisualInspection === 'Yes' // VTI
                ? (
                  <div>
                    {problemType === 'PROB1'
                      ? (
                        <div>
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>Time at which the VTI inspection was performed since the analysis date.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GTextField name="lastIntrusiveVisualInspectionTime" value={lastIntrusiveVisualInspectionTime} label="Last Intrusive Visual Inspection Time" type="number" required />
                          {/* </E2GInfoButton> */}
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>The degree of corrosion found at the component level after performing the VTI inspection.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GSelectField name="vtiCorrosionSeverity" value={vtiCorrosionSeverity} label="VTI Component Corrosion Severity?">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="None" value="None" />
                            <E2GSelectFieldOption label="Minimal" value="Minimal" />
                            <E2GSelectFieldOption label="Minor" value="Minor" />
                            <E2GSelectFieldOption label="Major" value="Major" />
                            <E2GSelectFieldOption label="Severe" value="Severe" />
                          </E2GSelectField>
                          {/* </E2GInfoButton> */}
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>The degree of corrosion found at the coating level after performing the VTI inspection.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GSelectField name="coatingConditionVTI" value={coatingConditionVTI} label="VTI Coating Damage Severity?">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="None" value="None" />
                            <E2GSelectFieldOption label="Minimal" value="Minimal" />
                            <E2GSelectFieldOption label="Minor" value="Minor" />
                            <E2GSelectFieldOption label="Major" value="Major" />
                            <E2GSelectFieldOption label="Severe" value="Severe" />
                          </E2GSelectField>
                          {/* </E2GInfoButton> */}
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>Insulation wet at VTI refers to whether there was an indication or not if the insulation was wet during the inspection.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GSelectField name="insulationWetAtVTI" value={insulationWetAtVTI} label="Insulation Wet at VTI?">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="Yes" value="Yes" />
                            <E2GSelectFieldOption label="No" value="No" />
                          </E2GSelectField>
                          {/* </E2GInfoButton> */}
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB3'
                      ? (
                        <div>
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>Time at which the VTI inspection was performed since the analysis date.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GTextField name="lastIntrusiveVisualInspectionTime" value={lastIntrusiveVisualInspectionTime} label="Last Intrusive Visual Inspection Time" type="number" required />
                          {/* </E2GInfoButton> */}
                          {probabilisticInputFieldFlag === false
                            ? (
                              <div>
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the component level after performing the VTI inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="vtiCorrosionSeverity" value={vtiCorrosionSeverity} label="VTI Component Corrosion Severity?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="None" value="None" />
                                  <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                  <E2GSelectFieldOption label="Minor" value="Minor" />
                                  <E2GSelectFieldOption label="Major" value="Major" />
                                  <E2GSelectFieldOption label="Severe" value="Severe" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the coating level after performing the VTI inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="coatingConditionVTI" value={coatingConditionVTI} label="VTI Coating Damage Severity?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="None" value="None" />
                                  <E2GSelectFieldOption label="Minimal" value="Minimal" />
                                  <E2GSelectFieldOption label="Minor" value="Minor" />
                                  <E2GSelectFieldOption label="Major" value="Major" />
                                  <E2GSelectFieldOption label="Severe" value="Severe" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                                {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>Insulation wet at VTI refers to whether there was an indication or not if the insulation was wet during the inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                <E2GSelectField name="insulationWetAtVTI" value={insulationWetAtVTI} label="Insulation Wet at VTI?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="Yes" value="Yes" />
                                  <E2GSelectFieldOption label="No" value="No" />
                                </E2GSelectField>
                                {/* </E2GInfoButton> */}
                              </div>
                            )
                            : ''}
                          {probabilisticInputFieldFlag === true
                            ? (
                              <div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the component level after performing the VTI inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="vtiCorrosionSeverityProb"
                                    jsonEditable
                                    value={vtiCorrosionSeverityProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'VTI Component Corrosion Severity?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The degree of corrosion found at the coating level after performing the VTI inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="coatingConditionVTIProb"
                                    jsonEditable
                                    value={coatingConditionVTIProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'VTI Coating Damage Severity?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>Insulation wet at VTI refers to whether there was an indication or not if the insulation was wet during the inspection.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="insulationWetAtVTIProb"
                                    jsonEditable
                                    value={insulationWetAtVTIProb}
                                    variableType="discrete"
                                    InputProps={{
                                      label: 'Insulation Wet at VTI?',
                                      jsonKeyPath: 'median',
                                      isNumber: false,
                                      required: true,
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                              </div>
                            )
                            : ''}
                        </div>
                      )
                      : ''}
                    {problemType === 'PROB2'
                      ? (
                        <div>
                          <E2GTextField name="lastIntrusiveVisualInspectionTime" value={lastIntrusiveVisualInspectionTime} label="Last Intrusive Visual Inspection Time" type="number" required />
                          <E2GSelectField name="vtiCorrosionSeverity" value={vtiCorrosionSeverity} label="VTI Component Corrosion Severity?">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="None" value="None" />
                            <E2GSelectFieldOption label="Minimal" value="Minimal" />
                            <E2GSelectFieldOption label="Minor" value="Minor" />
                            <E2GSelectFieldOption label="Major" value="Major" />
                            <E2GSelectFieldOption label="Severe" value="Severe" />
                          </E2GSelectField>
                          <E2GSelectField name="coatingConditionVTI" value={coatingConditionVTI} label="VTI Coating Damage Severity?">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="None" value="None" />
                            <E2GSelectFieldOption label="Minimal" value="Minimal" />
                            <E2GSelectFieldOption label="Minor" value="Minor" />
                            <E2GSelectFieldOption label="Major" value="Major" />
                            <E2GSelectFieldOption label="Severe" value="Severe" />
                          </E2GSelectField>
                          <E2GSelectField name="insulationWetAtVTI" value={insulationWetAtVTI} label="Insulation Wet at VTI?">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="Yes" value="Yes" />
                            <E2GSelectFieldOption label="No" value="No" />
                          </E2GSelectField>
                        </div>
                      )
                      : ''}
                  </div>
                )
                : ''}
            </div>
          )
          : ''}
      </div>
      <Submit label="Next" />
    </div>
  )
}

Step4.propTypes = {
  problemType: PropTypes.string,
  priorIntrusiveVisualInspection: PropTypes.string,
  lastIntrusiveVisualInspectionTime: PropTypes.number,
  vtiCorrosionSeverity: PropTypes.string,
  coatingFailureVTI: PropTypes.string,
  coatingConditionVTI: PropTypes.string,
  insulationWetAtVTI: PropTypes.string,
  inspectionInputType: PropTypes.string,
  inspectionMaintenanceTable: PropTypes.array,
  vtiTable: PropTypes.array,
  probabilisticInputFieldFlag: PropTypes.bool,
  vtiCorrosionSeverityProb: PropTypes.string,
  coatingConditionVTIProb: PropTypes.string,
  insulationWetAtVTIProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  priorIntrusiveVisualInspection: state.workflow.fields.priorIntrusiveVisualInspection,
  lastIntrusiveVisualInspectionTime: state.workflow.fields.lastIntrusiveVisualInspectionTime,
  vtiCorrosionSeverity: state.workflow.fields.vtiCorrosionSeverity,
  coatingFailureVTI: state.workflow.fields.coatingFailureVTI,
  coatingConditionVTI: state.workflow.fields.coatingConditionVTI,
  insulationWetAtVTI: state.workflow.fields.insulationWetAtVTI,
  inspectionInputType: state.workflow.fields.inspectionInputType,
  inspectionMaintenanceTable: state.workflow.fields.inspectionMaintenanceTable,
  vtiTable: state.workflow.fields.vtiTable,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  vtiCorrosionSeverityProb: state.workflow.fields.vtiCorrosionSeverityProb,
  coatingConditionVTIProb: state.workflow.fields.coatingConditionVTIProb,
  insulationWetAtVTIProb: state.workflow.fields.insulationWetAtVTIProb,
})

export default connect(mapStateToProps)(Step4)
