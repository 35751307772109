export default [
  {
    name: 'jacketingVaporBarrierFailureLikelihood',
    keywords: [
      {
        keyword: 'jacketingVaporBarrierFailureLikelihoods',
        colHeaders: {
          USCustomary: ['Vapor Barrier Jacketing Failure Likelihood (%)'],
          Metric: ['Vapor Barrier Jacketing Failure Likelihood (%)'],
          alignOptions: ['center'],
        },
        units: {
          USCustomary: '%',
          Metric: '%',
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      type: 'numeric',
      columns: [
        { type: 'numeric', numericFormat: { pattern: '0.00' } },
      ],
      colWidths: [250],
      height: 120,
      minSpareRows: 0,
      maxRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'jacketingFailureLikelihood',
    keywords: [
      {
        keyword: 'jacketingFailureLikelihoods',
        colHeaders: {
          USCustomary: ['Jacketing Failure Likelihood (%)'],
          Metric: ['Jacketing Failure Likelihood (%)'],
          alignOptions: ['center'],
        },
        units: {
          USCustomary: '%',
          Metric: '%',
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      type: 'numeric',
      columns: [
        { type: 'numeric', numericFormat: { pattern: '0.00' } },
      ],
      colWidths: [250],
      height: 120,
      minSpareRows: 0,
      maxRows: 3,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'dewPointHistory',
    keywords: [
      {
        keyword: 'dewPointHistory',
        colHeaders: {
          USCustomary: ['Hourly Dew Point History (F)'],
          Metric: ['Hourly Dew Point History (C)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['F'],
          Metric: ['C'],
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        {
          type: 'numeric',
        },
      ],
      colWidths: [240],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'tempHistory',
    keywords: [
      {
        keyword: 'tempHistory',
        colHeaders: {
          USCustomary: ['Hourly Temperature History (F)'],
          Metric: ['Hourly Temperature History (C)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['F'],
          Metric: ['C'],
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        {
          type: 'numeric',
        },
      ],
      colWidths: [240],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'ambientTempHistory',
    keywords: [
      {
        keyword: 'ambientTempHistory',
        colHeaders: {
          USCustomary: ['Hourly Ambient Temperature History (F)'],
          Metric: ['Hourly Ambient Temperature History (C)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['F'],
          Metric: ['C'],
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        {
          type: 'numeric',
        },
      ],
      colWidths: [240],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'relativeHumidityHistory',
    keywords: [
      {
        keyword: 'relativeHumidityHistory',
        colHeaders: {
          USCustomary: ['Hourly Relative Humidity History (%)'],
          Metric: ['Hourly Relative Humidity History (%)'],
          alignOptions: ['middle'],
        },
        units: {
          USCustomary: ['%'],
          Metric: ['%'],
        },
      },
    ],
    columnDisplayOptions: [
      {
        dataType: 'float',
      },
    ],
    type: 'input',
    options: {
      columns: [
        {
          type: 'numeric',
        },
      ],
      colWidths: [240],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'cdfTimeMinMax',
    keywords: [
      {
        keyword: 'cdfTimeMinMax',
      },
    ],
    type: 'output',
  },
  {
    name: 'cdfTime',
    keywords: [
      {
        keyword: 'cdfTime',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureCDFTraceBefore',
    keywords: [
      {
        keyword: 'failureCDFTraceBefore',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_01MultTableBeforeInspectionsMissingJacketing',
    keywords: [
      {
        keyword: 'lb_01MultTableBeforeInspectionsMissingJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_1MultTableBeforeInspectionsMissingJacketing',
    keywords: [
      {
        keyword: 'lb_1MultTableBeforeInspectionsMissingJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lbMultTableBeforeInspectionsMissingJacketing',
    keywords: [
      {
        keyword: 'lbMultTableBeforeInspectionsMissingJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_01MultTableBeforeInspectionsSealantJacketing',
    keywords: [
      {
        keyword: 'lb_01MultTableBeforeInspectionsSealantJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_1MultTableBeforeInspectionsSealantJacketing',
    keywords: [
      {
        keyword: 'lb_1MultTableBeforeInspectionsSealantJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lbMultTableBeforeInspectionsSealantJacketing',
    keywords: [
      {
        keyword: 'lbMultTableBeforeInspectionsSealantJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_01MultTableBeforeInspectionsCorrosionJacketing',
    keywords: [
      {
        keyword: 'lb_01MultTableBeforeInspectionsCorrosionJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_1MultTableBeforeInspectionsCorrosionJacketing',
    keywords: [
      {
        keyword: 'lb_1MultTableBeforeInspectionsCorrosionJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lbMultTableBeforeInspectionsCorrosionJacketing',
    keywords: [
      {
        keyword: 'lbMultTableBeforeInspectionsCorrosionJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_01MultTableBeforeInspectionsMissingVaporBarrier',
    keywords: [
      {
        keyword: 'lb_01MultTableBeforeInspectionsMissingVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_1MultTableBeforeInspectionsMissingVaporBarrier',
    keywords: [
      {
        keyword: 'lb_1MultTableBeforeInspectionsMissingVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'lbMultTableBeforeInspectionsMissingVaporBarrier',
    keywords: [
      {
        keyword: 'lbMultTableBeforeInspectionsMissingVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_01MultTableBeforeInspectionsSealantVaporBarrier',
    keywords: [
      {
        keyword: 'lb_01MultTableBeforeInspectionsSealantVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'lb_1MultTableBeforeInspectionsSealantVaporBarrier',
    keywords: [
      {
        keyword: 'lb_1MultTableBeforeInspectionsSealantVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'lbMultTableBeforeInspectionsSealantVaporBarrier',
    keywords: [
      {
        keyword: 'lbMultTableBeforeInspectionsSealantVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'medMultTableBeforeInspectionsMissingJacketing',
    keywords: [
      {
        keyword: 'medMultTableBeforeInspectionsMissingJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'medMultTableBeforeInspectionsSealantJacketing',
    keywords: [
      {
        keyword: 'medMultTableBeforeInspectionsSealantJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'medMultTableBeforeInspectionsCorrosionJacketing',
    keywords: [
      {
        keyword: 'medMultTableBeforeInspectionsCorrosionJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'medMultTableBeforeInspectionsMissingVaporBarrier',
    keywords: [
      {
        keyword: 'medMultTableBeforeInspectionsMissingVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'medMultTableBeforeInspectionsSealantVaporBarrier',
    keywords: [
      {
        keyword: 'medMultTableBeforeInspectionsSealantVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'upMultTableBeforeInspectionsMissingJacketing',
    keywords: [
      {
        keyword: 'upMultTableBeforeInspectionsMissingJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'upMultTableBeforeInspectionsSealantJacketing',
    keywords: [
      {
        keyword: 'upMultTableBeforeInspectionsSealantJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'upMultTableBeforeInspectionsCorrosionJacketing',
    keywords: [
      {
        keyword: 'upMultTableBeforeInspectionsCorrosionJacketing',
      },
    ],
    type: 'output',
  },
  {
    name: 'upMultTableBeforeInspectionsMissingVaporBarrier',
    keywords: [
      {
        keyword: 'upMultTableBeforeInspectionsMissingVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'upMultTableBeforeInspectionsSealantVaporBarrier',
    keywords: [
      {
        keyword: 'upMultTableBeforeInspectionsSealantVaporBarrier',
      },
    ],
    type: 'output',
  },
  {
    name: 'timeMultTableBeforeInspections',
    keywords: [
      {
        keyword: 'timeMultTableBeforeInspections',
      },
    ],
    type: 'output',
  },
  {
    name: 'lbMultTableBeforeInspections_01',
    keywords: [
      {
        keyword: 'lbMultTableBeforeInspections_01',
      },
    ],
    type: 'output',
  },
  {
    name: 'lbMultTableBeforeInspections_1',
    keywords: [
      {
        keyword: 'lbMultTableBeforeInspections_1',
      },
    ],
    type: 'output',
  },
  {
    name: 'lbMultTableBeforeInspections',
    keywords: [
      {
        keyword: 'lbMultTableBeforeInspections',
      },
    ],
    type: 'output',
  },
  {
    name: 'medMultTableBeforeInspections',
    keywords: [
      {
        keyword: 'medMultTableBeforeInspections',
      },
    ],
    type: 'output',
  },
  {
    name: 'upMultTableBeforeInspections',
    keywords: [
      {
        keyword: 'upMultTableBeforeInspections',
      },
    ],
    type: 'output',
  },
  {
    name: 'upMultTableBeforeInspections_99',
    keywords: [
      {
        keyword: 'upMultTableBeforeInspections_99',
      },
    ],
    type: 'output',
  },
  {
    name: 'inspectionTimes',
    keywords: [
      {
        keyword: 'inspectionTimes',
      },
    ],
    type: 'output',
  },
  {
    name: 'multTableLowerBound',
    keywords: [
      {
        keyword: 'multTableLowerBound',
      },
    ],
    type: 'output',
  },
  {
    name: 'multTableMedian',
    keywords: [
      {
        keyword: 'multTableMedian',
      },
    ],
    type: 'output',
  },
  {
    name: 'multTableUpperBound',
    keywords: [
      {
        keyword: 'multTableUpperBound',
      },
    ],
    type: 'output',
  },
  {
    name: 'tminTraceCol0',
    keywords: [
      {
        keyword: 'tminTraceCol0',
      },
    ],
    type: 'output',
  },
  {
    name: 'tminTraceACol0',
    keywords: [
      {
        keyword: 'tminTraceACol0',
      },
    ],
    type: 'output',
  },
  {
    name: 'tminTraceBCol0',
    keywords: [
      {
        keyword: 'tminTraceBCol0',
      },
    ],
    type: 'output',
  },
  {
    name: 'tminTraceCCol0',
    keywords: [
      {
        keyword: 'tminTraceCCol0',
      },
    ],
    type: 'output',
  },
  {
    name: 'tminTraceDCol0',
    keywords: [
      {
        keyword: 'tminTraceDCol0',
      },
    ],
    type: 'output',
  },
  {
    name: 'tminTraceECol0',
    keywords: [
      {
        keyword: 'tminTraceECol0',
      },
    ],
    type: 'output',
  },
  {
    name: 'timeMultTables',
    keywords: [
      {
        keyword: 'timeMultTables',
      },
    ],
    type: 'output',
  },
  {
    name: 'time',
    keywords: [
      {
        keyword: 'time',
      },
    ],
    type: 'output',
  },
  {
    name: 'timeThickness',
    keywords: [
      {
        keyword: 'timeThickness',
      },
    ],
    type: 'output',
  },
  {
    name: 'timeUpdatedThickness',
    keywords: [
      {
        keyword: 'timeUpdatedThickness',
      },
    ],
    type: 'output',
  },
  {
    name: 'vertHeight',
    keywords: [
      {
        keyword: 'vertHeight',
      },
    ],
    type: 'output',
  },
  {
    name: 'horzLength',
    keywords: [
      {
        keyword: 'horzLength',
      },
    ],
    type: 'output',
  },
  {
    name: 'jacketingFailureTimeVERT',
    keywords: [
      {
        keyword: 'jacketingFailureTimeVERT',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingFailureTimeVERT',
    keywords: [
      {
        keyword: 'coatingFailureTimeVERT',
      },
    ],
    type: 'output',
  },
  {
    name: 'componentInitiationTimeVERT',
    keywords: [
      {
        keyword: 'componentInitiationTimeVERT',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureThicknessHORZ',
    keywords: [
      {
        keyword: 'failureThicknessHORZ',
      },
    ],
    type: 'output',
  },
  {
    name: 'startingThicknessBeliefPrior',
    keywords: [
      {
        keyword: 'startingThicknessBeliefPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'startingThicknessStatesPrior',
    keywords: [
      {
        keyword: 'startingThicknessStatesPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'actualThicknessBelief',
    keywords: [
      {
        keyword: 'actualThicknessBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'actualThicknessStates',
    keywords: [
      {
        keyword: 'actualThicknessStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'startingThicknessBelief',
    keywords: [
      {
        keyword: 'startingThicknessBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'startingThicknessStates',
    keywords: [
      {
        keyword: 'startingThicknessStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingFailureTimeBeliefPrior',
    keywords: [
      {
        keyword: 'coatingFailureTimeBeliefPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingFailureTimeStatesPrior',
    keywords: [
      {
        keyword: 'coatingFailureTimeStatesPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingFailureTimeBelief',
    keywords: [
      {
        keyword: 'coatingFailureTimeBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingFailureTimeStates',
    keywords: [
      {
        keyword: 'coatingFailureTimeStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'jacketingFailureTimeBeliefPrior',
    keywords: [
      {
        keyword: 'jacketingFailureTimeBeliefPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'jacketingFailureTimeStatesPrior',
    keywords: [
      {
        keyword: 'jacketingFailureTimeStatesPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'jacketingFailureTimeBelief',
    keywords: [
      {
        keyword: 'jacketingFailureTimeBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'jacketingFailureTimeStates',
    keywords: [
      {
        keyword: 'jacketingFailureTimeStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'initiationTimeBeliefPrior',
    keywords: [
      {
        keyword: 'initiationTimeBeliefPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'initiationTimeStatesPrior',
    keywords: [
      {
        keyword: 'initiationTimeStatesPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'initiationTimeBelief',
    keywords: [
      {
        keyword: 'initiationTimeBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'initiationTimeStates',
    keywords: [
      {
        keyword: 'initiationTimeStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'damageRateBeliefPrior',
    keywords: [
      {
        keyword: 'damageRateBeliefPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'damageRateStatesPrior',
    keywords: [
      {
        keyword: 'damageRateStatesPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'damageRateBelief',
    keywords: [
      {
        keyword: 'damageRateBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'damageRateStates',
    keywords: [
      {
        keyword: 'damageRateStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeBeliefPrior',
    keywords: [
      {
        keyword: 'failureTimeBeliefPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeStatesPrior',
    keywords: [
      {
        keyword: 'failureTimeStatesPrior',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeBelief',
    keywords: [
      {
        keyword: 'failureTimeBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeStates',
    keywords: [
      {
        keyword: 'failureTimeStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeDecisionsBelief',
    keywords: [
      {
        keyword: 'failureTimeDecisionsBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeDecisionsStates',
    keywords: [
      {
        keyword: 'failureTimeDecisionsStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureDecisionsBelief',
    keywords: [
      {
        keyword: 'failureDecisionsBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureDecisionsStates',
    keywords: [
      {
        keyword: 'failureDecisionsStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'api581MethodAdjustedCRBelief',
    keywords: [
      {
        keyword: 'api581MethodAdjustedCRBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'api581MethodAdjustedCRStates',
    keywords: [
      {
        keyword: 'api581MethodAdjustedCRStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'api581MethodFailureBelief',
    keywords: [
      {
        keyword: 'api581MethodFailureBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'api581MethodFailureStates',
    keywords: [
      {
        keyword: 'api581MethodFailureStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'api581MethodFailureTimeBelief',
    keywords: [
      {
        keyword: 'api581MethodFailureTimeBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'api581MethodFailureTimeStates',
    keywords: [
      {
        keyword: 'api581MethodFailureTimeStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'bpMethodPrimaryScoreBelief',
    keywords: [
      {
        keyword: 'bpMethodPrimaryScoreBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'bpMethodPrimaryScoreStates',
    keywords: [
      {
        keyword: 'bpMethodPrimaryScoreStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'bpMethodQualitativeLikelihoodBelief',
    keywords: [
      {
        keyword: 'bpMethodQualitativeLikelihoodBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'bpMethodQualitativeLikelihoodStates',
    keywords: [
      {
        keyword: 'bpMethodQualitativeLikelihoodStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'dnvglMethodPOFTotalBelief',
    keywords: [
      {
        keyword: 'dnvglMethodPOFTotalBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'dnvglMethodPOFTotalStates',
    keywords: [
      {
        keyword: 'dnvglMethodPOFTotalStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'ineosMethodPrimaryScoreBelief',
    keywords: [
      {
        keyword: 'ineosMethodPrimaryScoreBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'ineosMethodPrimaryScoreStates',
    keywords: [
      {
        keyword: 'ineosMethodPrimaryScoreStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'ineosMethodQualitativePOFBelief',
    keywords: [
      {
        keyword: 'ineosMethodQualitativePOFBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'ineosMethodQualitativePOFStates',
    keywords: [
      {
        keyword: 'ineosMethodQualitativePOFStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'McVayMethodPrimaryScoreBelief',
    keywords: [
      {
        keyword: 'McVayMethodPrimaryScoreBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'McVayMethodPrimaryScoreStates',
    keywords: [
      {
        keyword: 'McVayMethodPrimaryScoreStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'McVayMethodQualitativeLikelihoodBelief',
    keywords: [
      {
        keyword: 'McVayMethodQualitativeLikelihoodBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'McVayMethodQualitativeLikelihoodStates',
    keywords: [
      {
        keyword: 'McVayMethodQualitativeLikelihoodStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'api583TotalScoreBelief',
    keywords: [
      {
        keyword: 'api583TotalScoreBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'api583TotalScoreStates',
    keywords: [
      {
        keyword: 'api583TotalScoreStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'api583LikelihoodBelief',
    keywords: [
      {
        keyword: 'api583LikelihoodBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'api583LikelihoodStates',
    keywords: [
      {
        keyword: 'api583LikelihoodStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'euroMethodPrimaryScoreBelief',
    keywords: [
      {
        keyword: 'euroMethodPrimaryScoreBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'euroMethodPrimaryScoreStates',
    keywords: [
      {
        keyword: 'euroMethodPrimaryScoreStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'euroMethodLikelihoodBelief',
    keywords: [
      {
        keyword: 'euroMethodLikelihoodBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'euroMethodLikelihoodStates',
    keywords: [
      {
        keyword: 'euroMethodLikelihoodStates',
      },
    ],
    type: 'output',
  },
  {
    name: 'sealantFailureTimeBeliefOutput',
    keywords: [
      {
        keyword: 'sealantFailureTimeBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'sealantFailureTimeStatesOutput',
    keywords: [
      {
        keyword: 'sealantFailureTimeStatesOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'sealantDamageRateBeliefOutput',
    keywords: [
      {
        keyword: 'sealantDamageRateBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'sealantDamageRateStatesOutput',
    keywords: [
      {
        keyword: 'sealantDamageRateStatesOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeMissingJacketingBeliefOutput',
    keywords: [
      {
        keyword: 'failureTimeMissingJacketingBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeMissingJacketingStatesOutput',
    keywords: [
      {
        keyword: 'failureTimeMissingJacketingStatesOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'corrosionFailureTimeBeliefOutput',
    keywords: [
      {
        keyword: 'corrosionFailureTimeBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'corrosionFailureTimeStatesOutput',
    keywords: [
      {
        keyword: 'corrosionFailureTimeStatesOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'corrosionJacketDamageRateBeliefOutput',
    keywords: [
      {
        keyword: 'corrosionJacketDamageRateBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'corrosionJacketDamageRateStatesOutput',
    keywords: [
      {
        keyword: 'corrosionJacketDamageRateStatesOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingFirstFailureTimeBeliefOutput',
    keywords: [
      {
        keyword: 'coatingFirstFailureTimeBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingFirstFailureTimeStatesOutput',
    keywords: [
      {
        keyword: 'coatingFirstFailureTimeStatesOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingDamageRateBeliefOutput',
    keywords: [
      {
        keyword: 'coatingDamageRateBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'coatingDamageRateStatesOutput',
    keywords: [
      {
        keyword: 'coatingDamageRateStatesOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'actualThicknessBelief',
    keywords: [
      {
        keyword: 'actualThicknessBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'missingJacketingComponentFailureTimeBeliefOutput',
    keywords: [
      {
        keyword: 'missingJacketingComponentFailureTimeBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'condensationJacketingComponentFailureTimeBeliefOutput',
    keywords: [
      {
        keyword: 'condensationJacketingComponentFailureTimeBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'missingVaporBarrierComponentFailureTimeBeliefOutput',
    keywords: [
      {
        keyword: 'missingVaporBarrierComponentFailureTimeBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'sealantJacketingComponentFailureTimeBeliefOutput',
    keywords: [
      {
        keyword: 'sealantJacketingComponentFailureTimeBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'corrosionComponentFailureTimeBeliefOutput',
    keywords: [
      {
        keyword: 'corrosionComponentFailureTimeBeliefOutput',
      },
    ],
    type: 'output',
  },
  {
    name: 'condensationFailureTimeBelief',
    keywords: [
      {
        keyword: 'condensationFailureTimeBelief',
      },
    ],
    type: 'output',
  },
  {
    name: 'qualLikelihoodProbTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'float' },
      { dataType: 'float' },
      { dataType: 'float' },
    ],
    keywords: [
      {
        keyword: 'methodQualProb',
        colHeaders: { USCustomary: [''], Metric: [''] },
      },
      {
        keyword: 'lowQualProb',
        colHeaders: { USCustomary: ['Low'], Metric: ['Low'] },
      },
      {
        keyword: 'modQualProb',
        colHeaders: { USCustomary: ['Moderate'], Metric: ['Moderate'] },
      },
      {
        keyword: 'highQualProb',
        colHeaders: { USCustomary: ['High'], Metric: ['High'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'numeric' },
        { type: 'numeric' },
        { type: 'numeric' },
      ],
    },
    colWidths: [120, 120, 120, 120],
    height: 120,
  },
  {
    name: 'qualPOFProbTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'float' },
      { dataType: 'float' },
      { dataType: 'float' },
      { dataType: 'float' },
      { dataType: 'float' },
    ],
    keywords: [
      {
        keyword: 'methodQualPOF',
        colHeaders: { USCustomary: [''], Metric: [''] },
      },
      {
        keyword: 'vlowQualPOF',
        colHeaders: { USCustomary: ['Very Low'], Metric: ['Very Low'] },
      },
      {
        keyword: 'lowQualPOF',
        colHeaders: { USCustomary: ['Low'], Metric: ['Low'] },
      },
      {
        keyword: 'modQualPOF',
        colHeaders: { USCustomary: ['Moderate'], Metric: ['Moderate'] },
      },
      {
        keyword: 'highQualPOF',
        colHeaders: { USCustomary: ['High'], Metric: ['High'] },
      },
      {
        keyword: 'vhighQualPOF',
        colHeaders: { USCustomary: ['Very High'], Metric: ['Very High'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'numeric' },
        { type: 'numeric' },
        { type: 'numeric' },
        { type: 'numeric' },
        { type: 'numeric' },
      ],
    },
    colWidths: [120, 120, 120, 120, 120, 120],
    height: 120,
  },
  {
    name: 'decisionsTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      // { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'strategyCol01',
        colHeaders: { USCustomary: ['Strategy'], Metric: ['Strategy'] },
      },
      {
        keyword: 'recoatCol02',
        colHeaders: { USCustomary: ['Recoat'], Metric: ['Recoat'] },
      },
      {
        keyword: 'recoatTimeCol03',
        colHeaders: { USCustomary: ['Recoat Time (yrs)'], Metric: ['Recoat Time (yrs)'] },
      },
      {
        keyword: 'rejacketCol04',
        colHeaders: { USCustomary: ['Rejacket'], Metric: ['Rejacket'] },
      },
      {
        keyword: 'rejacketTimeCol05',
        colHeaders: { USCustomary: ['Rejacket Time (yrs)'], Metric: ['Rejacket Time (yrs)'] },
      },
      {
        keyword: 'resealCol06',
        colHeaders: { USCustomary: ['Reseal'], Metric: ['Reseal'] },
      },
      {
        keyword: 'resealTimeCol07',
        colHeaders: { USCustomary: ['Reseal Time (yrs)'], Metric: ['Reseal Time (yrs)'] },
      },
      // {
      //   keyword: 'replaceCol08',
      //   colHeaders: { USCustomary: ['Replace'], Metric: ['Replace'] },
      // },
      {
        keyword: 'replaceTimeCol09',
        colHeaders: { USCustomary: ['Replace Time (yrs)'], Metric: ['Replace Time (yrs)'] },
      },
      {
        keyword: 'totalUtilityCol10',
        colHeaders: { USCustomary: ['Total Return ($)'], Metric: ['Total Return ($)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        // { type: 'text' },
        { type: 'numeric', numericFormat: { pattern: '0.000' } },
        { type: 'numeric', numericFormat: { pattern: '0.000' } },
      ],
    },
    colWidths: [120, 100, 100, 100, 100, 100, 100, 100, 100],
    height: 120,
  },
  {
    name: 'inspectionMaintenanceTable',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'inspectionDatesAll',
        colHeaders: {
          USCustomary: ['Event Date'],
          Metric: ['Event Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'inspectionVTEBool',
        colHeaders: {
          USCustomary: ['VTE Inspection'],
          Metric: ['VTE Inspection'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'inspectionVTIBool',
        colHeaders: {
          USCustomary: ['VTI Inspection'],
          Metric: ['VTI Inspection'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'inspectionNDEBool',
        colHeaders: {
          USCustomary: ['NDE Inspection'],
          Metric: ['NDE Inspection'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'maintenanceBoolTable',
        colHeaders: {
          USCustomary: ['Maintenance Event'],
          Metric: ['Maintenance Event'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [200, 200, 200, 200, 200],
      minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'maintenanceTableNoCondensation',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'maintenanceDates1',
        colHeaders: {
          USCustomary: ['Maintenance Date'],
          Metric: ['Maintenance Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'pastMaintenanceTable1',
        colHeaders: {
          USCustomary: ['Past Portion Inspection?'],
          Metric: ['Past Portion Inspection?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'componentReplacedTable1',
        colHeaders: {
          USCustomary: ['Component Replaced?'],
          Metric: ['Component Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'coatingReplacedTable1',
        colHeaders: {
          USCustomary: ['Coating Replaced?'],
          Metric: ['Coating Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'jacketingReplacedTable1',
        colHeaders: {
          USCustomary: ['Jacketing Replaced?'],
          Metric: ['Jacketing Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'jacketingReplacedSealantTable1',
        colHeaders: {
          USCustomary: ['Jacketing Sealant Replaced?'],
          Metric: ['Jacketing Sealant Replaced?'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [150, 150, 150, 150, 150, 150],
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'maintenanceTableCondensation',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'maintenanceDates2',
        colHeaders: {
          USCustomary: ['Maintenance Date'],
          Metric: ['Maintenance Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'pastMaintenanceTable2',
        colHeaders: {
          USCustomary: ['Past Portion Inspection?'],
          Metric: ['Past Portion Inspection?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'componentReplacedTable2',
        colHeaders: {
          USCustomary: ['Component Replaced?'],
          Metric: ['Component Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'coatingReplacedTable2',
        colHeaders: {
          USCustomary: ['Coating Replaced?'],
          Metric: ['Coating Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'jacketingReplacedTable2',
        colHeaders: {
          USCustomary: ['Jacketing Replaced?'],
          Metric: ['Jacketing Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'jacketingReplacedSealantTable2',
        colHeaders: {
          USCustomary: ['Jacketing Sealant Replaced?'],
          Metric: ['Jacketing Sealant Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vaporBarrierReplacedTable',
        colHeaders: {
          USCustomary: ['Vapor Barrier Replaced?'],
          Metric: ['Vapor Barrier Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vaporBarrierSealantReplacedTable',
        colHeaders: {
          USCustomary: ['Vapor Barrier Sealant Replaced?'],
          Metric: ['Vapor Barrier Sealant Replaced?'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [150, 150, 150, 150, 150, 150, 150, 150],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'maintenanceTableCondensationForHybridMethod',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      // { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'maintenanceDatesForHybridMethod',
        colHeaders: {
          USCustomary: ['Maintenance Date'],
          Metric: ['Maintenance Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'pastMaintenanceTableForHybridMethod',
        colHeaders: {
          USCustomary: ['Past Portion Inspection?'],
          Metric: ['Past Portion Inspection?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'componentReplacedTableForHybridMethod',
        colHeaders: {
          USCustomary: ['Component Replaced?'],
          Metric: ['Component Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'coatingReplacedTableForHybridMethod',
        colHeaders: {
          USCustomary: ['Coating Replaced?'],
          Metric: ['Coating Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'jacketingReplacedTableForHybridMethod',
        colHeaders: {
          USCustomary: ['Jacketing Replaced?'],
          Metric: ['Jacketing Replaced?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'jacketingReplacedSealantTableForHybridMethod',
        colHeaders: {
          USCustomary: ['Jacketing Sealant Replaced?'],
          Metric: ['Jacketing Sealant Replaced?'],
        },
        rowHeaders: false,
      },
      // {
      //   keyword: 'vaporBarrierReplacedTableForHybridMethod',
      //   colHeaders: {
      //     USCustomary: ['Vapor Barrier Replaced?'],
      //     Metric: ['Vapor Barrier Replaced?'],
      //   },
      //   rowHeaders: false,
      // },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        // {
        //   type: 'autocomplete',
        //   source: ['unknown', 'Yes', 'No'],
        //   strict: true,
        //   allowInvalid: false,
        // },
      ],
      // colWidths: [150, 150, 150, 150, 150, 150, 150],
      colWidths: [150, 150, 150, 150, 150, 150],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'vteTable',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'inspectionDatesVTE1',
        colHeaders: {
          USCustomary: ['Inspection Date'],
          Metric: ['Inspection Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteSealantConditionTable1',
        colHeaders: {
          USCustomary: ['VTE Jacketing Sealant Condition?'],
          Metric: ['VTE Jacketing Sealant Condition?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketMissingTable1',
        colHeaders: {
          USCustomary: ['Jacketing Missing at VTE?'],
          Metric: ['Jacketing Missing at VTE?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketConditionTable1',
        colHeaders: {
          USCustomary: ['VTE Jacketing Damage Severity?'],
          Metric: ['VTE Jacketing Damage Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteCorrosionSeverityTable',
        colHeaders: {
          USCustomary: ['VTE Component Corrosion Severity?'],
          Metric: ['VTE Component Corrosion Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteCoatingConditionTable1',
        colHeaders: {
          USCustomary: ['VTE Coating Damage Severity?'],
          Metric: ['VTE Coating Damage Severity?'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Sealed', 'Cracks no Gaps', 'Open Gaps'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [200, 200, 200, 200, 200, 200],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'vteTablePROB3',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'inspectionDatesVTE3',
        colHeaders: {
          USCustomary: ['Inspection Date'],
          Metric: ['Inspection Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteSealantConditionTable3',
        colHeaders: {
          USCustomary: ['VTE Jacketing Sealant Condition?'],
          Metric: ['VTE Jacketing Sealant Condition?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketMissingTable3',
        colHeaders: {
          USCustomary: ['Jacketing Missing at VTE?'],
          Metric: ['Jacketing Missing at VTE?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketConditionTable3',
        colHeaders: {
          USCustomary: ['VTE Corrosion Jacketing Damage Severity?'],
          Metric: ['VTE Corrosion Jacketing Damage Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketConditionGeneralTable3',
        colHeaders: {
          USCustomary: ['VTE General Jacketing Damage Severity?'],
          Metric: ['VTE General Jacketing Damage Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteCorrosionSeverityTable2',
        colHeaders: {
          USCustomary: ['VTE Component Corrosion Severity?'],
          Metric: ['VTE Component Corrosion Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteCoatingConditionTable3',
        colHeaders: {
          USCustomary: ['VTE Coating Damage Severity?'],
          Metric: ['VTE Coating Damage Severity?'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Sealed', 'Cracks no Gaps', 'Open Gaps'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [200, 200, 200, 200, 200, 200, 200],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'vteTableSS',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'inspectionDatesVTE2',
        colHeaders: {
          USCustomary: ['Inspection Date'],
          Metric: ['Inspection Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteSealantConditionTable2',
        colHeaders: {
          USCustomary: ['VTE Jacketing Sealant Condition?'],
          Metric: ['VTE Jacketing Sealant Condition?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketMissingTable2',
        colHeaders: {
          USCustomary: ['Jacketing Missing at VTE?'],
          Metric: ['Jacketing Missing at VTE?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketConditionTable2',
        colHeaders: {
          USCustomary: ['VTE Jacketing Damage Severity?'],
          Metric: ['VTE Jacketing Damage Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteCoatingConditionTable2',
        colHeaders: {
          USCustomary: ['VTE Coating Damage Severity?'],
          Metric: ['VTE Coating Damage Severity?'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Sealed', 'Cracks no Gaps', 'Open Gaps'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [200, 200, 200, 200, 200],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'vteTableSSPROB3',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'inspectionDatesVTE4',
        colHeaders: {
          USCustomary: ['Inspection Date'],
          Metric: ['Inspection Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteSealantConditionTable4',
        colHeaders: {
          USCustomary: ['VTE Jacketing Sealant Condition?'],
          Metric: ['VTE Jacketing Sealant Condition?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketMissingTable4',
        colHeaders: {
          USCustomary: ['Jacketing Missing at VTE?'],
          Metric: ['Jacketing Missing at VTE?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketConditionTable4',
        colHeaders: {
          USCustomary: ['VTE Corrosion Jacketing Damage Severity?'],
          Metric: ['VTE Corrosion Jacketing Damage Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteJacketConditionGeneralTable4',
        colHeaders: {
          USCustomary: ['VTE Corrosion Jacketing Damage Severity?'],
          Metric: ['VTE Corrosion Jacketing Damage Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vteCoatingConditionTable4',
        colHeaders: {
          USCustomary: ['VTE Coating Damage Severity?'],
          Metric: ['VTE Coating Damage Severity?'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Sealed', 'Cracks no Gaps', 'Open Gaps'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [200, 200, 200, 200, 200, 200],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },

  {
    name: 'vtiTable',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'inspectionDatesVTI',
        colHeaders: {
          USCustomary: ['Inspection Date'],
          Metric: ['Inspection Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vtiInsulationWetTable',
        colHeaders: {
          USCustomary: ['Insulation Wet at VTI?'],
          Metric: ['Insulation Wet at VTI?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vtiCorrosionSeverityTable',
        colHeaders: {
          USCustomary: ['VTI Component Corrosion Severity?'],
          Metric: ['VTI Component Corrosion Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'vtiCoatingConditionTable',
        colHeaders: {
          USCustomary: ['VTI Coating Damage Severity?'],
          Metric: ['VTI Coating Damage Severity?'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'Yes', 'No'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [200, 200, 200, 200],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },

  {
    name: 'ndeTable',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'float' },
      { dataType: 'text' },
      { dataType: 'float' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'inspectionDatesNDE1',
        colHeaders: {
          USCustomary: ['Inspection Date'],
          Metric: ['Inspection Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'ndeCorrosionSeverityTable1',
        colHeaders: {
          USCustomary: ['NDE Component Corrosion Severity?'],
          Metric: ['NDE Component Corrosion Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'ndeCoatingConditionTable1',
        colHeaders: {
          USCustomary: ['NDE Coating Damage Severity?'],
          Metric: ['NDE Coating Damage Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'measuredThicknessTable1',
        colHeaders: {
          USCustomary: ['Measured Thickness (in)'],
          Metric: ['Measured Thickness (mm)'],
        },
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'ndeMeasurementErrorTypeTable1',
        colHeaders: {
          USCustomary: ['NDE Measurement Error Type'],
          Metric: ['NDE Measurement Error Type'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'measurementErrorPercentSlashThicknessTable1',
        colHeaders: {
          USCustomary: ['NDE Measurement Error Percent (%) or Thickness(mil)'],
          Metric: ['NDE Measurement Error Percent (%) or Thickness(mm)'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'measurementErrorConfidenceTable1',
        colHeaders: {
          USCustomary: ['NDE Measurement Error Confidence'],
          Metric: ['NDE Measurement Error Confidence'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric' },
        {
          type: 'autocomplete',
          source: ['percent', 'thickness'],
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric' },
        {
          type: 'autocomplete',
          source: ['Low', 'Moderate', 'High'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [150, 150, 150, 150, 150, 150, 150],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },
  {
    name: 'ndeTableSS',
    columnDisplayOptions: [
      { dataType: 'date' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'text' },
      { dataType: 'float' },
      { dataType: 'float' },
      { dataType: 'text' },
      { dataType: 'float' },
      { dataType: 'text' },
    ],
    keywords: [
      {
        keyword: 'inspectionDatesNDE2',
        colHeaders: {
          USCustomary: ['Inspection Date'],
          Metric: ['Inspection Date'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'ndeCorrosionSeverityTable2',
        colHeaders: {
          USCustomary: ['NDE Component Corrosion Severity?'],
          Metric: ['NDE Component Corrosion Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'ndeCoatingConditionTable2',
        colHeaders: {
          USCustomary: ['NDE Coating Damage Severity?'],
          Metric: ['NDE Coating Damage Severity?'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'ndeCrackSeverityTable',
        colHeaders: {
          USCustomary: ['NDE Crack Severity'],
          Metric: ['NDE Crack Severity'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'measuredFlawDepthTable',
        colHeaders: {
          USCustomary: ['NDE Measured Flaw Depth (in)'],
          Metric: ['NDE Measured Flaw Depth (mm)'],
        },
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'measuredThicknessTable2',
        colHeaders: {
          USCustomary: ['Measured Thickness (in)'],
          Metric: ['Measured Thickness (mm)'],
        },
        units: {
          USCustomary: ['in'],
          Metric: ['mm'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'ndeMeasurementErrorTypeTable2',
        colHeaders: {
          USCustomary: ['NDE Measurement Error Type'],
          Metric: ['NDE Measurement Error Type'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'measurementErrorPercentSlashThicknessTable2',
        colHeaders: {
          USCustomary: ['NDE Measurement Error Percent (%) or Thickness(mil)'],
          Metric: ['NDE Measurement Error Percent (%) or Thickness(mm)'],
        },
        rowHeaders: false,
      },
      {
        keyword: 'measurementErrorConfidenceTable2',
        colHeaders: {
          USCustomary: ['NDE Measurement Error Confidence'],
          Metric: ['NDE Measurement Error Confidence'],
        },
        rowHeaders: false,
      },
    ],
    type: 'input',
    options: {
      cells(row, col) {
        if(col === 0) {
          this.renderer = function dateFormat(instance, td, row, col, prop, value, cellProperties) {
            if(value === null || value === '') {
              td.className = 'htCenter'
              td.style.color = '#999'
              td.textContent = 'MM/DD/YYYY'
            } else {
              td.className = 'htCenter'
              td.textContent = value
            }
          }
        }
      },
      columns: [
        { type: 'text' },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        {
          type: 'autocomplete',
          source: ['unknown', 'None', 'Minimal', 'Minor', 'Major', 'Severe'],
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric' },
        { type: 'numeric' },
        {
          type: 'autocomplete',
          source: ['percent', 'thickness'],
          strict: true,
          allowInvalid: false,
        },
        { type: 'numeric' },
        {
          type: 'autocomplete',
          source: ['Low', 'Moderate', 'High'],
          strict: true,
          allowInvalid: false,
        },
      ],
      colWidths: [150, 150, 150, 150, 150, 150, 150, 150, 150],
      // minSpareRows: 2,
      contextMenu: ['row_above',
        'row_below',
        '---------',
        'remove_row',
        '---------',
        'undo',
        'redo',
        '---------',
        'copy',
        'cut',
      ],
    },
  },

  {
    name: 'xAllArrayTimesOrg',
    keywords: [
      {
        keyword: 'xAllArrayTimesOrg',
      },
    ],
    type: 'output',
  },
  {
    name: 'xAllArrayTimes',
    keywords: [
      {
        keyword: 'xAllArrayTimes',
      },
    ],
    type: 'output',
  },
  {
    name: 'xAllArrayTimesReplacement',
    keywords: [
      {
        keyword: 'xAllArrayTimesReplacement',
      },
    ],
    type: 'output',
  },
  {
    name: 'yAllArrayExpectedUtilitiesOrg',
    keywords: [
      {
        keyword: 'yAllArrayExpectedUtilitiesOrg',
      },
    ],
    type: 'output',
  },
  {
    name: 'yAllArrayExpectedUtilities',
    keywords: [
      {
        keyword: 'yAllArrayExpectedUtilities',
      },
    ],
    type: 'output',
  },
  {
    name: 'yAllArrayExpectedUtilitiesReplacement',
    keywords: [
      {
        keyword: 'yAllArrayExpectedUtilitiesReplacement',
      },
    ],
    type: 'output',
  },
  {
    name: 'failureTimeXInterpProb',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'failureTimeXInterpProb00',
        colHeaders: { USCustomary: ['Inspection'], Metric: ['Inspection'] },
      },
      {
        keyword: 'failureTimeXInterpProb0',
        colHeaders: { USCustomary: ['50th Percentile Failure Time (yrs)'], Metric: ['50th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProb1',
        colHeaders: { USCustomary: ['90th Percentile Failure Time (yrs)'], Metric: ['90th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProb2',
        colHeaders: { USCustomary: ['95th Percentile Failure Time (yrs)'], Metric: ['95th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProb3',
        colHeaders: { USCustomary: ['99th Percentile Failure Time (yrs)'], Metric: ['99th Percentile Failure Time (yrs)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [200, 200, 200, 200, 200],
    height: 120,
  },
  {
    name: 'failureTimeXInterpProbAfterInspections',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'failureTimeXInterpProbAfterInspections00',
        colHeaders: { USCustomary: ['Inspection'], Metric: ['Inspection'] },
      },
      {
        keyword: 'failureTimeXInterpProbAfterInspections0',
        colHeaders: { USCustomary: ['50th Percentile Failure Time (yrs)'], Metric: ['50th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProbAfterInspections1',
        colHeaders: { USCustomary: ['90th Percentile Failure Time (yrs)'], Metric: ['90th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProbAfterInspections2',
        colHeaders: { USCustomary: ['95th Percentile Failure Time (yrs)'], Metric: ['95th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProbAfterInspections3',
        colHeaders: { USCustomary: ['99th Percentile Failure Time (yrs)'], Metric: ['99th Percentile Failure Time (yrs)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [200, 200, 200, 200, 200],
    height: 120,
  },
  {
    name: 'failureTimeXInterpMult',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'failureTimeXInterMultInspectionNames',
        colHeaders: { USCustomary: ['Inspection'], Metric: ['Inspection'] },
      },
      {
        keyword: 'failureTimeXInterpProbMult0',
        colHeaders: { USCustomary: ['50th Percentile Failure Time (yrs)'], Metric: ['50th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProbMult1',
        colHeaders: { USCustomary: ['90th Percentile Failure Time (yrs)'], Metric: ['90th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProbMult2',
        colHeaders: { USCustomary: ['95th Percentile Failure Time (yrs)'], Metric: ['95th Percentile Failure Time (yrs)'] },
      },
      {
        keyword: 'failureTimeXInterpProbMult3',
        colHeaders: { USCustomary: ['99th Percentile Failure Time (yrs)'], Metric: ['99th Percentile Failure Time (yrs)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [200, 200, 200, 200, 200],
    height: 120,
  },
  {
    name: 'v0ComponentPOFsFromAnalysisDateReg',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'columnPOFTimes',
        colHeaders: { USCustomary: ['POF Times'], Metric: ['POF Times'] },
      },
      {
        keyword: 'v0ComponentFailureTime',
        colHeaders: { USCustomary: ['Component Failure POF (%)'], Metric: ['Component Failure POF (%)'] },
      },
      {
        keyword: 'v0ComponentJacketngFailureTime',
        colHeaders: { USCustomary: ['Component Jacketing Failure POF (%)'], Metric: ['Component Jacketing Failure POF (%)'] },
      },
      {
        keyword: 'v0ComponentCoatingFailureTime',
        colHeaders: { USCustomary: ['Component Coating Failure POF (%)'], Metric: ['Component Coating Failure POF (%)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [150, 150, 150, 150],
    height: 120,
  },
  {
    name: 'v2ComponentPOFsFromAnalysisDate',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'columnPOFTimes',
        colHeaders: { USCustomary: ['POF Times'], Metric: ['POF Times'] },
      },
      {
        keyword: 'v2ComponentFailureTime',
        colHeaders: { USCustomary: ['Component Failure POF (%)'], Metric: ['Component Failure POF (%)'] },
      },
      {
        keyword: 'v2ComponentJacketngFailureTime',
        colHeaders: { USCustomary: ['Component Jacketing Failure POF (%)'], Metric: ['Component Jacketing Failure POF (%)'] },
      },
      {
        keyword: 'v2ComponentCoatingFailureTime',
        colHeaders: { USCustomary: ['Component Coating Failure POF (%)'], Metric: ['Component Coating Failure POF (%)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [150, 150, 150, 150],
    height: 120,
  },
  {
    name: 'v2ComponentPOFsFromAnalysisDatePrior',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'columnPOFTimes',
        colHeaders: { USCustomary: ['POF Times'], Metric: ['POF Times'] },
      },
      {
        keyword: 'v2ComponentFailureTimePrior',
        colHeaders: { USCustomary: ['Component Failure POF (%)'], Metric: ['Component Failure POF (%)'] },
      },
      {
        keyword: 'v2ComponentJacketngFailureTimePrior',
        colHeaders: { USCustomary: ['Component Jacketing Failure POF (%)'], Metric: ['Component Jacketing Failure POF (%)'] },
      },
      {
        keyword: 'v2ComponentCoatingFailureTimePrior',
        colHeaders: { USCustomary: ['Component Coating Failure POF (%)'], Metric: ['Component Coating Failure POF (%)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [150, 150, 150, 150],
    height: 120,
  },
  {
    name: 'v0ComponentPOFsFromAnalysisDateCond',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'columnPOFTimes',
        colHeaders: { USCustomary: ['POF Times'], Metric: ['POF Times'] },
      },
      {
        keyword: 'v0ComponentFailureTime',
        colHeaders: { USCustomary: ['Component POF (%)'], Metric: ['Component POF (%)'] },
      },
      {
        keyword: 'v0ComponentJacketngFailureTime',
        colHeaders: { USCustomary: ['Component Jacketing POF (%)'], Metric: ['Component Jacketing POF (%)'] },
      },
      {
        keyword: 'v0ComponentCoatingFailureTime',
        colHeaders: { USCustomary: ['Component Coating POF (%)'], Metric: ['Component Coating POF (%)'] },
      },
      {
        keyword: 'v0ComponentMissingVaportBarrierFailureTime',
        colHeaders: { USCustomary: ['Component Missing Vapor Barrier POF (%)'], Metric: ['Component Missing Vapor Barrier POF (%)'] },
      },
      {
        keyword: 'v0ComponentSealantVaporBarrierFailureTime',
        colHeaders: { USCustomary: ['Component Sealant Vapor Barrier POF (%)'], Metric: ['Component Sealant Vapor Barrier POF (%)'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [150, 150, 150, 150, 150, 150],
    height: 120,
  },
  {
    name: 'v1ComponentPOFsFromAnalysisDateReg',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'columnPOFTimes',
        colHeaders: { USCustomary: ['POF Times'], Metric: ['POF Times'] },
      },
      {
        keyword: 'v1ComponentFailureTime',
        colHeaders: { USCustomary: ['Component POF (%)'], Metric: ['Component POF (%)'] },
      },
      {
        keyword: 'v1FirstCoatingFailureTime',
        colHeaders: { USCustomary: ['Coating POF (%)'], Metric: ['Coating POF (%)'] },
      },
      {
        keyword: 'v1FirstMissingJacketngFailureTime',
        colHeaders: { USCustomary: ['Missing Jacketing POF (%)'], Metric: ['Missing Jacketing POF (%)'] },
      },
      {
        keyword: 'v1FirstSealantJacketngFailureTime',
        colHeaders: { USCustomary: ['Sealant Jacketing POF (%)'], Metric: ['Sealant Jacketing POF (%)'] },
      },
      {
        keyword: 'v1FirstCorrosionJacketngFailureTime',
        colHeaders: { USCustomary: ['Corrosion Jacketing POF (%)'], Metric: ['Corrosion Jacketing POF (%)'] },
      },

    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [150, 150, 150, 150, 150, 150],
    height: 120,
  },
  {
    name: 'v1ComponentPOFsFromAnalysisDateCond',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'columnPOFTimes',
        colHeaders: { USCustomary: ['POF Times'], Metric: ['POF Times'] },
      },
      {
        keyword: 'v1ComponentFailureTime',
        colHeaders: { USCustomary: ['Component POF (%)'], Metric: ['Component POF (%)'] },
      },
      {
        keyword: 'v1FirstCoatingFailureTime',
        colHeaders: { USCustomary: ['Coating POF (%)'], Metric: ['Coating POF (%)'] },
      },
      {
        keyword: 'v1FirstMissingJacketngFailureTime',
        colHeaders: { USCustomary: ['Missing Jacketing POF (%)'], Metric: ['Missing Jacketing POF (%)'] },
      },
      {
        keyword: 'v1FirstSealantJacketngFailureTime',
        colHeaders: { USCustomary: ['Sealant Jacketing POF (%)'], Metric: ['Sealant Jacketing POF (%)'] },
      },
      {
        keyword: 'v1FirstCorrosionJacketngFailureTime',
        colHeaders: { USCustomary: ['Corrosion Jacketing POF (%)'], Metric: ['Corrosion Jacketing POF (%)'] },
      },
      {
        keyword: 'v1MissingVaportBarrierFailureTime',
        colHeaders: { USCustomary: ['Missing Vapor Barrier POF (%)'], Metric: ['Missing Vapor Barrier POF (%)'] },
      },
      {
        keyword: 'v1SealantVaporBarrierFailureTime',
        colHeaders: { USCustomary: ['Sealant Vapor Barrier POF (%)'], Metric: ['Sealant Vapor Barrier POF (%)'] },
      },

    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [150, 150, 150, 150, 150, 150, 150, 150],
    height: 120,
  },
  {
    name: 'convergenceTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'inspectionCycleInfo',
        colHeaders: { USCustomary: ['Cycle Information'], Metric: ['Cycle Information'] },
      },
      {
        keyword: 'failureTimeAtTargetMinPOF',
        colHeaders: { USCustomary: ['Failure Time (yrs) at Target Min POF'], Metric: ['Failure Time (yrs) at Target Min POF'] },
      },
      {
        keyword: 'failureTimeDiff',
        colHeaders: { USCustomary: ['Failure Time (yrs) Difference Between Cycles'], Metric: ['Failure Time (yrs) Difference Between Cycles'] },
      },
      {
        keyword: 'pofAtTargetMinPOF',
        colHeaders: { USCustomary: ['POF (%)'], Metric: ['POF (%)'] },
      },
      {
        keyword: 'pofDiff',
        colHeaders: { USCustomary: ['POF (%) Difference Between Cycles'], Metric: ['POF (%) Difference Between Cycles'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
      ],
    },
    colWidths: [150, 150, 150, 150, 150],
    height: 120,
  },
  {
    name: 'performanceMetricTable',
    columnDisplayOptions: [
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
      // { dataType: 'string' },
    ],
    keywords: [
      {
        keyword: 'performanceMetricsNetworkName',
        colHeaders: { USCustomary: ['Network Name'], Metric: ['Network Name'] },
      },
      // {
      //   keyword: 'performanceMetricsComputeTime',
      //   colHeaders: { USCustomary: ['Compute Time (Sec)'], Metric: ['Compute Time (Sec)F'] },
      // },
      // {
      //   keyword: 'performanceMetricsCpuUsagePercent',
      //   colHeaders: { USCustomary: ['CPU (%)'], Metric: ['CPU (%)'] },
      // },
      // {
      //   keyword: 'performanceMetricsRamUsagePercent',
      //   colHeaders: { USCustomary: ['RAM Usage (%)'], Metric: ['RAM Usage (%)'] },
      // },
      // {
      //   keyword: 'performanceMetricsRamUsage',
      //   colHeaders: { USCustomary: ['RAM Usage (GB)'], Metric: ['RAM Usage (GB)'] },
      // },
      // {
      //   keyword: 'performanceMetricsRamUsageCalc',
      //   colHeaders: { USCustomary: ['RAM Usage Calculated (GB)'], Metric: ['RAM Usage Calculated (GB)'] },
      // },
      // {
      //   keyword: 'performanceMetricsDeltaRAMPercent',
      //   colHeaders: { USCustomary: ['Delta RAM (%)'], Metric: ['Delta RAM (%)'] },
      // },
      // {
      //   keyword: 'performanceMetricsDeltaRAMUsage',
      //   colHeaders: { USCustomary: ['Delta RAM Usage (GB)'], Metric: ['Delta RAM Usage (GB)'] },
      // },
      // {
      //   keyword: 'performanceMetricsSumOfAllCPTs',
      //   colHeaders: { USCustomary: ['Sum of All CPTs Shape'], Metric: ['Sum of All CPTs Shape'] },
      // },
      // {
      //   keyword: 'performanceMetricsSumAllCPTCombinations',
      //   colHeaders: { USCustomary: ['All CPTs Combinations'], Metric: ['All CPTs Combinations'] },
      // },
      // {
      //   keyword: 'performanceMetricsMaxCPTSize',
      //   colHeaders: { USCustomary: ['Max CPT Size'], Metric: ['Max CPT Size'] },
      // },
      // {
      //   keyword: 'performanceMetricsGBSizeOfAllCPTs',
      //   colHeaders: { USCustomary: ['Sum of All CPT Size (GB)'], Metric: ['Sum of All CPT Size (GB)'] },
      // },
      {
        keyword: 'performanceMetricsCoreLevelCombinations',
        colHeaders: { USCustomary: ['Core Node Sum of Levels'], Metric: ['Core Node Sum of Levels'] },
      },
      // {
      //   keyword: 'performanceMetricsLengthOfNodes',
      //   colHeaders: { USCustomary: ['Length of Nodes'], Metric: ['Length of Nodes'] },
      // },
      {
        keyword: 'performanceMetricsRamPredicted',
        colHeaders: { USCustomary: ['Predicted RAM (GB)'], Metric: ['Predicted RAM (GB)'] },
      },
      {
        keyword: 'performanceMetricsRamAllotted',
        colHeaders: { USCustomary: ['RAM (GB) Allotted'], Metric: ['RAM (GB) Allotted'] },
      },
      {
        keyword: 'performanceMetricsCpuAllotted',
        colHeaders: { USCustomary: ['CPU Allotted'], Metric: ['CPU Allotted'] },
      },
    ],
    type: 'output',
    options: {
      className: 'htCenter',
      columns: [
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
        // { type: 'text' },
      ],
    },
    colWidths: [150, 150, 150, 150, 150],
    height: 120,
  },
]
