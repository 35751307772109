/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step1({ problemType, accessibility, runQualMethodsBool, attachments, pipingComplexity, supportRings, airSoilInterface, steamTracing, branchesGeometryChanges, steamTracingVariance, steamTracingAge, steamTracingQuality, probabilisticInputFieldFlag, attachmentsProb, pipingComplexityProb, accessibilityProb, steamTracingVarianceProb, steamTracingQualityProb, steamTracingProb }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB3'
        ? (
          <div>
            {probabilisticInputFieldFlag === false
              ? (
                <div className="cf w-100">
                  <E2GSelectField name="accessibility" value={accessibility} label="Component Accessibility">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Accessible" value="Accessible" />
                    <E2GSelectFieldOption label="Congested" value="Congested" />
                    <E2GSelectFieldOption label="Elevated" value="Elevated" />
                    <E2GSelectFieldOption label="Congested and Elevated" value="Congested and Elevated" />
                  </E2GSelectField>
                  <E2GSelectField name="pipingComplexity" value={pipingComplexity} label="Component/Piping Complexity">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Simple" value="Simple" />
                    <E2GSelectFieldOption label="Average" value="Average" />
                    <E2GSelectFieldOption label="Complex" value="Complex" />
                  </E2GSelectField>
                  <E2GSelectField name="attachments" value={attachments} label="Attachments/Protrusions">
                    <E2GSelectFieldOption label="Unknown" value="unknown" />
                    <E2GSelectFieldOption label="Few" value="Few" />
                    <E2GSelectFieldOption label="Some" value="Some" />
                    <E2GSelectFieldOption label="Many" value="Many" />
                  </E2GSelectField>
                  <E2GSelectField name="steamTracing" value={steamTracing} label="Steam Tracing Presence">
                    <E2GSelectFieldOption label="No" value="No" />
                    <E2GSelectFieldOption label="Yes" value="Yes" />
                  </E2GSelectField>
                  {steamTracing === 'Yes'
                    ? (
                      <div>
                        <E2GTextField name="steamTracingAge" value={steamTracingAge} label="Steam Tracing Age" type="number" />
                        <E2GSelectField name="steamTracingQuality" value={steamTracingQuality} label="Steam Tracing Quality">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Poor" value="Poor" />
                          <E2GSelectFieldOption label="Average" value="Average" />
                          <E2GSelectFieldOption label="Quality" value="Quality" />
                        </E2GSelectField>
                        <E2GSelectField name="steamTracingVariance" value={steamTracingVariance} label="Steam Tracing Failure Time Uncertainty">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Low" value="Low" />
                          <E2GSelectFieldOption label="Moderate" value="Moderate" />
                          <E2GSelectFieldOption label="High" value="High" />
                        </E2GSelectField>
                      </div>
                    )
                    : ''}
                </div>
              )
              : ''}
            {probabilisticInputFieldFlag === true
              ? (
                <div className="cf w-100">
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="accessibilityProb"
                      jsonEditable
                      value={accessibilityProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Component Accessibility',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="pipingComplexityProb"
                      jsonEditable
                      value={pipingComplexityProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Component/Piping Complexity',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="attachmentsProb"
                      jsonEditable
                      value={attachmentsProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Attachments/Protrusions',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="steamTracingProb"
                      jsonEditable
                      value={steamTracingProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Steam Tracing Presence',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  {probabilisticInputFieldFlag && steamTracingProb && JSON.parse(steamTracingProb).evidence?.[0] !== 1 &&
                    (
                      <div>
                        <div className="pt3">
                          <ProbabilisticInputField
                            name="steamTracingQualityProb"
                            jsonEditable
                            value={steamTracingQualityProb}
                            variableType="discrete"
                            InputProps={{
                              label: 'Steam Tracing Quality',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                        </div>
                        <div className="pt3">
                          <ProbabilisticInputField
                            name="steamTracingVarianceProb"
                            jsonEditable
                            value={steamTracingVarianceProb}
                            variableType="discrete"
                            InputProps={{
                              label: 'Steam Tracing Failure Time Uncertainty',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                        </div>
                        <E2GTextField name="steamTracingAge" value={steamTracingAge} label="Steam Tracing Age" type="number" />
                      </div>
                    )}
                </div>
              )
              : ''}
          </div>
        )
        : ''}
      <Submit label="Next" />
    </div>
  )
}

Step1.propTypes = {
  problemType: PropTypes.string,
  accessibility: PropTypes.string,
  attachments: PropTypes.string,
  pipingComplexity: PropTypes.string,
  supportRings: PropTypes.string,
  airSoilInterface: PropTypes.string,
  steamTracing: PropTypes.string,
  branchesGeometryChanges: PropTypes.string,
  runQualMethodsBool: PropTypes.bool,
  steamTracingVariance: PropTypes.string,
  steamTracingAge: PropTypes.number,
  steamTracingQuality: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  attachmentsProb: PropTypes.string,
  pipingComplexityProb: PropTypes.string,
  accessibilityProb: PropTypes.string,
  steamTracingVarianceProb: PropTypes.string,
  steamTracingQualityProb: PropTypes.string,
  steamTracingProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  accessibility: state.workflow.fields.accessibility,
  attachments: state.workflow.fields.attachments,
  pipingComplexity: state.workflow.fields.pipingComplexity,
  supportRings: state.workflow.fields.supportRings,
  airSoilInterface: state.workflow.fields.airSoilInterface,
  steamTracing: state.workflow.fields.steamTracing,
  branchesGeometryChanges: state.workflow.fields.branchesGeometryChanges,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  steamTracingVariance: state.workflow.fields.steamTracingVariance,
  steamTracingAge: state.workflow.fields.steamTracingAge,
  steamTracingQuality: state.workflow.fields.steamTracingQuality,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  attachmentsProb: state.workflow.fields.attachmentsProb,
  pipingComplexityProb: state.workflow.fields.pipingComplexityProb,
  accessibilityProb: state.workflow.fields.accessibilityProb,
  steamTracingVarianceProb: state.workflow.fields.steamTracingVarianceProb,
  steamTracingQualityProb: state.workflow.fields.steamTracingQualityProb,
  steamTracingProb: state.workflow.fields.steamTracingProb,
})

export default connect(mapStateToProps)(Step1)
