import { getColumnFrom2DArray, getUnique } from '@e2grnd/ui-core/dist/core/lib'

export function getNextTableDataMaintenance(inspectionMaintenanceTable, currentTableMaintenance, returnSizerBool, condensationVaporBarrierBool) {
  const col00Maintenance = getColumnFrom2DArray(inspectionMaintenanceTable, 0)
  const col01Maintenance = getColumnFrom2DArray(inspectionMaintenanceTable, 4)
  const col2Maintenance = getColumnFrom2DArray(currentTableMaintenance, 1)
  const col3Maintenance = getColumnFrom2DArray(currentTableMaintenance, 2)
  const col4Maintenance = getColumnFrom2DArray(currentTableMaintenance, 3)
  const col5Maintenance = getColumnFrom2DArray(currentTableMaintenance, 4)
  const col6Maintenance = getColumnFrom2DArray(currentTableMaintenance, 5)
  let col7Maintenance
  let col8Maintenance
  if(condensationVaporBarrierBool === true) {
    col7Maintenance = getColumnFrom2DArray(currentTableMaintenance, 6)
    col8Maintenance = getColumnFrom2DArray(currentTableMaintenance, 7)
  }
  const ucol0Maintenance = getUnique(col00Maintenance)
  const yesInspectMaintenance = []
  const newDataMaintenanceYesInspect = []
  let count = -1
  for(let i = 0; i < ucol0Maintenance.length; i += 1) {
    if(ucol0Maintenance[i] !== null && ucol0Maintenance[i] !== '') {
      yesInspectMaintenance.push([col01Maintenance[i]])
    }
  }
  for(let i = 0; i < yesInspectMaintenance.length; i += 1) {
    if(yesInspectMaintenance[i][0] === 'Yes') {
      count += 1
      if(condensationVaporBarrierBool === false) {
        newDataMaintenanceYesInspect.push([ucol0Maintenance[i], col2Maintenance[count], col3Maintenance[count], col4Maintenance[count], col5Maintenance[count], col6Maintenance[count]])
      } else if(condensationVaporBarrierBool === true) {
        newDataMaintenanceYesInspect.push([ucol0Maintenance[i], col2Maintenance[count], col3Maintenance[count], col4Maintenance[count], col5Maintenance[count], col6Maintenance[count], col7Maintenance[count], col8Maintenance[count]])
      }
    }
  }
  if(newDataMaintenanceYesInspect.length === 1) {
    if(condensationVaporBarrierBool === false) {
      newDataMaintenanceYesInspect.push([null, null, null, null, null, null])
    } else if(condensationVaporBarrierBool === true) {
      newDataMaintenanceYesInspect.push([null, null, null, null, null, null, null, null])
    }
  }
  if(returnSizerBool === true) {
    return newDataMaintenanceYesInspect.length
  } else {
    return newDataMaintenanceYesInspect
  }
}

export function getNextTableDataMaintenanceHybrid(inspectionMaintenanceTable, currentTableMaintenance, returnSizerBool, condensationVaporBarrierBool) {
  const col00Maintenance = getColumnFrom2DArray(inspectionMaintenanceTable, 0)
  const col01Maintenance = getColumnFrom2DArray(inspectionMaintenanceTable, 4)
  const col2Maintenance = getColumnFrom2DArray(currentTableMaintenance, 1)
  const col3Maintenance = getColumnFrom2DArray(currentTableMaintenance, 2)
  const col4Maintenance = getColumnFrom2DArray(currentTableMaintenance, 3)
  const col5Maintenance = getColumnFrom2DArray(currentTableMaintenance, 4)
  const col6Maintenance = getColumnFrom2DArray(currentTableMaintenance, 5)
  let col7Maintenance
  if(condensationVaporBarrierBool === true) {
    col7Maintenance = getColumnFrom2DArray(currentTableMaintenance, 6)
  }
  const ucol0Maintenance = getUnique(col00Maintenance)
  const yesInspectMaintenance = []
  const newDataMaintenanceYesInspect = []
  let count = -1
  for(let i = 0; i < ucol0Maintenance.length; i += 1) {
    if(ucol0Maintenance[i] !== null && ucol0Maintenance[i] !== '') {
      yesInspectMaintenance.push([col01Maintenance[i]])
    }
  }
  for(let i = 0; i < yesInspectMaintenance.length; i += 1) {
    if(yesInspectMaintenance[i][0] === 'Yes') {
      count += 1
      if(condensationVaporBarrierBool === false) {
        newDataMaintenanceYesInspect.push([ucol0Maintenance[i], col2Maintenance[count], col3Maintenance[count], col4Maintenance[count], col5Maintenance[count], col6Maintenance[count]])
      } else if(condensationVaporBarrierBool === true) {
        newDataMaintenanceYesInspect.push([ucol0Maintenance[i], col2Maintenance[count], col3Maintenance[count], col4Maintenance[count], col5Maintenance[count], col6Maintenance[count], col7Maintenance[count]])
      }
    }
  }
  if(newDataMaintenanceYesInspect.length === 1) {
    if(condensationVaporBarrierBool === false) {
      newDataMaintenanceYesInspect.push([null, null, null, null, null, null])
    } else if(condensationVaporBarrierBool === true) {
      newDataMaintenanceYesInspect.push([null, null, null, null, null, null, null])
    }
  }
  if(returnSizerBool === true) {
    return newDataMaintenanceYesInspect.length
  } else {
    return newDataMaintenanceYesInspect
  }
}

export function getNextTableDataVTE(inspectionMaintenanceTable, currentTableVTE, returnSizerBool, materialOfConstructionBool) {
  const col00VTE = getColumnFrom2DArray(inspectionMaintenanceTable, 0)
  const col01VTE = getColumnFrom2DArray(inspectionMaintenanceTable, 1)
  const col2VTE = getColumnFrom2DArray(currentTableVTE, 1)
  const col3VTE = getColumnFrom2DArray(currentTableVTE, 2)
  const col4VTE = getColumnFrom2DArray(currentTableVTE, 3)
  let col5VTE
  let col6VTE
  if(materialOfConstructionBool === false) {
    col5VTE = getColumnFrom2DArray(currentTableVTE, 4)
    col6VTE = getColumnFrom2DArray(currentTableVTE, 5)
  } else {
    col5VTE = getColumnFrom2DArray(currentTableVTE, 4)
  }
  const ucol0VTE = getUnique(col00VTE)
  const yesInspectVTE = []
  const newDataVTEYesInspect = []
  let count = -1
  for(let i = 0; i < ucol0VTE.length; i += 1) {
    if(ucol0VTE[i] !== null && ucol0VTE[i] !== '') {
      yesInspectVTE.push([col01VTE[i]])
    }
  }
  for(let i = 0; i < yesInspectVTE.length; i += 1) {
    if(yesInspectVTE[i][0] === 'Yes') {
      count += 1
      if(materialOfConstructionBool === false) {
        newDataVTEYesInspect.push([ucol0VTE[i], col2VTE[count], col3VTE[count], col4VTE[count], col5VTE[count], col6VTE[count]])
      } else {
        newDataVTEYesInspect.push([ucol0VTE[i], col2VTE[count], col3VTE[count], col4VTE[count], col5VTE[count]])
      }
    }
  }
  if(newDataVTEYesInspect.length === 1) {
    if(materialOfConstructionBool === false) {
      newDataVTEYesInspect.push([null, null, null, null, null, null])
    } else {
      newDataVTEYesInspect.push([null, null, null, null, null])
    }
  }
  if(returnSizerBool === true) {
    return newDataVTEYesInspect.length
  } else {
    return newDataVTEYesInspect
  }
}

export function getNextTableDataVTEPROB3(inspectionMaintenanceTable, currentTableVTE, returnSizerBool, materialOfConstructionBool) {
  const col00VTE = getColumnFrom2DArray(inspectionMaintenanceTable, 0)
  const col01VTE = getColumnFrom2DArray(inspectionMaintenanceTable, 1)
  const col2VTE = getColumnFrom2DArray(currentTableVTE, 1)
  const col3VTE = getColumnFrom2DArray(currentTableVTE, 2)
  const col4VTE = getColumnFrom2DArray(currentTableVTE, 3)
  const col5VTE = getColumnFrom2DArray(currentTableVTE, 4)
  let col6VTE
  let col7VTE
  if(materialOfConstructionBool === false) {
    col6VTE = getColumnFrom2DArray(currentTableVTE, 5)
    col7VTE = getColumnFrom2DArray(currentTableVTE, 6)
  } else {
    col6VTE = getColumnFrom2DArray(currentTableVTE, 5)
  }
  const ucol0VTE = getUnique(col00VTE)
  const yesInspectVTE = []
  const newDataVTEYesInspect = []
  let count = -1
  for(let i = 0; i < ucol0VTE.length; i += 1) {
    if(ucol0VTE[i] !== null && ucol0VTE[i] !== '') {
      yesInspectVTE.push([col01VTE[i]])
    }
  }
  for(let i = 0; i < yesInspectVTE.length; i += 1) {
    if(yesInspectVTE[i][0] === 'Yes') {
      count += 1
      if(materialOfConstructionBool === false) {
        newDataVTEYesInspect.push([ucol0VTE[i], col2VTE[count], col3VTE[count], col4VTE[count], col5VTE[count], col6VTE[count], col7VTE[count]])
      } else {
        newDataVTEYesInspect.push([ucol0VTE[i], col2VTE[count], col3VTE[count], col4VTE[count], col5VTE[count], col6VTE[count]])
      }
    }
  }
  if(newDataVTEYesInspect.length === 1) {
    if(materialOfConstructionBool === false) {
      newDataVTEYesInspect.push([null, null, null, null, null, null, null])
    } else {
      newDataVTEYesInspect.push([null, null, null, null, null, null])
    }
  }
  if(returnSizerBool === true) {
    return newDataVTEYesInspect.length
  } else {
    return newDataVTEYesInspect
  }
}

export function getNextTableDataVTI(inspectionMaintenanceTable, currentTableVTI, returnSizerBool) {
  const col00VTI = getColumnFrom2DArray(inspectionMaintenanceTable, 0)
  const col01VTI = getColumnFrom2DArray(inspectionMaintenanceTable, 2)
  const col2VTI = getColumnFrom2DArray(currentTableVTI, 1)
  const col3VTI = getColumnFrom2DArray(currentTableVTI, 2)
  const col4VTI = getColumnFrom2DArray(currentTableVTI, 3)
  const ucol0VTI = getUnique(col00VTI)
  const yesInspectVTI = []
  const newDataVTIYesInspect = []
  let count = -1
  for(let i = 0; i < ucol0VTI.length; i += 1) {
    if(ucol0VTI[i] !== null && ucol0VTI[i] !== '') {
      yesInspectVTI.push([col01VTI[i]])
    }
  }
  for(let i = 0; i < yesInspectVTI.length; i += 1) {
    if(yesInspectVTI[i][0] === 'Yes') {
      count += 1
      newDataVTIYesInspect.push([ucol0VTI[i], col2VTI[count], col3VTI[count], col4VTI[count]])
    }
  }
  if(newDataVTIYesInspect.length === 1) {
    newDataVTIYesInspect.push([null, null, null, null])
  }
  if(returnSizerBool === true) {
    return newDataVTIYesInspect.length
  } else {
    return newDataVTIYesInspect
  }
}

export function getNextTableDataNDE(inspectionMaintenanceTable, currentTableNDE, returnSizerBool, materialOfConstructionBool) {
  const col00NDE = getColumnFrom2DArray(inspectionMaintenanceTable, 0)
  const col01NDE = getColumnFrom2DArray(inspectionMaintenanceTable, 3)
  const col2NDE = getColumnFrom2DArray(currentTableNDE, 1)
  const col3NDE = getColumnFrom2DArray(currentTableNDE, 2)
  let col4NDE
  let col5NDE
  let col6NDE
  let col7NDE
  let col8NDE
  let col9NDE
  if(materialOfConstructionBool === true) {
    col4NDE = getColumnFrom2DArray(currentTableNDE, 3)
    col5NDE = getColumnFrom2DArray(currentTableNDE, 4)
    col6NDE = getColumnFrom2DArray(currentTableNDE, 5)
    col7NDE = getColumnFrom2DArray(currentTableNDE, 6)
    col8NDE = getColumnFrom2DArray(currentTableNDE, 7)
    col9NDE = getColumnFrom2DArray(currentTableNDE, 8)
  } else {
    col4NDE = getColumnFrom2DArray(currentTableNDE, 3)
    col5NDE = getColumnFrom2DArray(currentTableNDE, 4)
    col6NDE = getColumnFrom2DArray(currentTableNDE, 5)
    col7NDE = getColumnFrom2DArray(currentTableNDE, 6)
  }
  const ucol0NDE = getUnique(col00NDE)
  const yesInspectNDE = []
  const newDataNDEYesInspect = []
  let count = -1
  for(let i = 0; i < ucol0NDE.length; i += 1) {
    if(ucol0NDE[i] !== null && ucol0NDE[i] !== '') {
      yesInspectNDE.push([col01NDE[i]])
    }
  }
  for(let i = 0; i < yesInspectNDE.length; i += 1) {
    if(yesInspectNDE[i][0] === 'Yes') {
      count += 1
      if(materialOfConstructionBool === true) {
        newDataNDEYesInspect.push([ucol0NDE[i], col2NDE[count], col3NDE[count], col4NDE[count], col5NDE[count], col6NDE[count], col7NDE[count], col8NDE[count], col9NDE[count]])
      } else {
        newDataNDEYesInspect.push([ucol0NDE[i], col2NDE[count], col3NDE[count], col4NDE[count], col5NDE[count], col6NDE[count], col7NDE[count]])
      }
    }
  }
  if(newDataNDEYesInspect.length === 1) {
    if(materialOfConstructionBool === true) {
      newDataNDEYesInspect.push([null, null, null, null, null, null, null, null, null])
    } else {
      newDataNDEYesInspect.push([null, null, null, null, null, null, null])
    }
  }
  if(returnSizerBool === true) {
    return newDataNDEYesInspect.length
  } else {
    return newDataNDEYesInspect
  }
}
