/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step2({ problemType, runCondensation, seamsAmount, properSeamAdhesian, componentType, verticalTelescoping, endCapType, protrusionsCovered, horizontalLapJoints, insulationType, lowPointWaterTrap, pooling, highTempSilicone, runQualMethodsBool, insulationMaintanceInsectionQuality, properDrainage, waterRepellent, jacketInstallQuality, jacketingMaterial, jacketPostInstallQAQC, moistureAccumulationSites, sealantAmount, highTempSealant, sealantPostInstallQAQC, vaporBarrierPostInstallQaQc, vaporBarrierInstallQuality, sealantInstallQuality, jacketMetallic, probabilisticInputFieldFlag, insulationTypeProb, properDrainageProb, moistureAccumulationSitesProb, lowPointWaterTrapProb, protrusionsCoveredProb, horizontalLapJointsProb, verticalTelescopingProb, endCapTypeProb, jacketingMaterialProb, jacketMetallicProb, jacketInstallQualityProb, jacketPostInstallQAQCProb, sealantAmountProb, highTempSealantProb, sealantPostInstallQAQCProb, sealantInstallQualityProb }) {
  return (
    <div className="cf w-100">
      {problemType === 'PROB3'
        ? (
          <div>
            {probabilisticInputFieldFlag === false
              ? (
                <div>
                  <div className="fl w-50-l w-100">
                    <E2GSelectField name="insulationType" value={insulationType} label="Insulation Type">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Calcium Silicate High Chloride" value="Calcium Silicate High Chloride" />
                      <E2GSelectFieldOption label="Calcium Silicate Low Chloride" value="Calcium Silicate Low Chloride" />
                      <E2GSelectFieldOption label="Mineral Wool" value="Mineral Wool" />
                      <E2GSelectFieldOption label="Perlite" value="Perlite" />
                      <E2GSelectFieldOption label="Foam Glass" value="Foam Glass" />
                      <E2GSelectFieldOption label="Expanded Perlite" value="Expanded Perlite" />
                      <E2GSelectFieldOption label="Modern Aerogel" value="Modern Aerogel" />
                    </E2GSelectField>
                    <E2GSelectField name="properDrainage" value={properDrainage} label="Proper Drainage">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="moistureAccumulationSites" value={moistureAccumulationSites} label="Moisture Accumulation Sites">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="lowPointWaterTrap" value={lowPointWaterTrap} label="Low Point or Water Trap">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                      <E2GSelectFieldOption label="No" value="No" />
                    </E2GSelectField>
                    <E2GSelectField name="protrusionsCovered" value={protrusionsCovered} label="Protrusions Covered to Shed Water">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="No" value="No" />
                      <E2GSelectFieldOption label="Yes" value="Yes" />
                    </E2GSelectField>
                    <E2GSelectField name="horizontalLapJoints" value={horizontalLapJoints} label="Lap Joint Design">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Poor Placement and Overlap" value="Poor Placement and Overlap" />
                      <E2GSelectFieldOption label="Poor Placement Only" value="Poor Placement Only" />
                      <E2GSelectFieldOption label="Poor Overlap Only" value="Poor Overlap Only" />
                      <E2GSelectFieldOption label="Proper Placement and Overlap" value="Proper Placement and Overlap" />
                    </E2GSelectField>
                    <E2GSelectField name="endCapType" value={endCapType} label="End Cap Design Quality">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Open" value="Open" />
                      <E2GSelectFieldOption label="Poor Design" value="Poor Design" />
                      <E2GSelectFieldOption label="Quality Design" value="Quality Design" />
                    </E2GSelectField>
                    {componentType === 'Vertical Run' || componentType === 'Top of Vertical Elbow' || componentType === 'Bottom of Vertical Elbow' || componentType === 'Horizontal Run after Vertical Run'
                      ? (
                        <E2GSelectField name="verticalTelescoping" value={verticalTelescoping} label="Vertical Telescoping Design">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Up" value="Up" />
                          <E2GSelectFieldOption label="None" value="None" />
                          <E2GSelectFieldOption label="Down" value="Down" />
                        </E2GSelectField>
                      )
                      : ''}
                  </div>
                </div>
              )
              : <div>
                <div className="fl w-50-l w-100">
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="insulationTypeProb"
                      jsonEditable
                      value={insulationTypeProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Insulation Type',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="properDrainageProb"
                      jsonEditable
                      value={properDrainageProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Proper Drainage',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="moistureAccumulationSitesProb"
                      jsonEditable
                      value={moistureAccumulationSitesProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Moisture Accumulation Sites',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="lowPointWaterTrapProb"
                      jsonEditable
                      value={lowPointWaterTrapProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Low Point or Water Trap',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="protrusionsCoveredProb"
                      jsonEditable
                      value={protrusionsCoveredProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Protrusions Covered to Shed Water',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="horizontalLapJointsProb"
                      jsonEditable
                      value={horizontalLapJointsProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'Lap Joint Design',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  <div className="pt3">
                    <ProbabilisticInputField
                      name="endCapTypeProb"
                      jsonEditable
                      value={endCapTypeProb}
                      variableType="discrete"
                      InputProps={{
                        label: 'End Cap Design Quality',
                        jsonKeyPath: 'median',
                        isNumber: false,
                        required: true,
                      }}
                    />
                  </div>
                  {componentType !== 'unknown' && componentType !== 'Horizontal Run' && componentType !== 'Horizontal Elbow' && componentType !== 'Vertical Run'
                    ? (
                      <div className="pt3">
                        <ProbabilisticInputField
                          name="verticalTelescopingProb"
                          jsonEditable
                          value={verticalTelescopingProb}
                          variableType="discrete"
                          InputProps={{
                            label: 'Vertical Telescoping Design',
                            jsonKeyPath: 'median',
                            isNumber: false,
                            required: true,
                          }}
                        />
                        {/* </E2GInfoButton> */}
                      </div>
                    )
                    : ''}
                </div>
              </div>}
          </div>
        )
        : ''}
      <div className="fl w-100">
        <Submit label="Next" />
      </div>
    </div>
  )
}

Step2.propTypes = {
  problemType: PropTypes.string,
  properDrainage: PropTypes.string,
  waterRepellent: PropTypes.string,
  jacketInstallQuality: PropTypes.string,
  jacketingMaterial: PropTypes.string,
  jacketPostInstallQAQC: PropTypes.string,
  runQualMethodsBool: PropTypes.bool,
  insulationMaintanceInsectionQuality: PropTypes.string,
  highTempSilicone: PropTypes.string,
  pooling: PropTypes.string,
  lowPointWaterTrap: PropTypes.string,
  insulationType: PropTypes.string,
  protrusionsCovered: PropTypes.string,
  horizontalLapJoints: PropTypes.string,
  verticalTelescoping: PropTypes.string,
  endCapType: PropTypes.string,
  moistureAccumulationSites: PropTypes.string,
  sealantAmount: PropTypes.string,
  componentType: PropTypes.string,
  runCondensation: PropTypes.bool,
  seamsAmount: PropTypes.string,
  properSeamAdhesian: PropTypes.string,
  highTempSealant: PropTypes.string,
  sealantPostInstallQAQC: PropTypes.string,
  vaporBarrierPostInstallQaQc: PropTypes.string,
  vaporBarrierInstallQuality: PropTypes.string,
  sealantInstallQuality: PropTypes.string,
  jacketMetallic: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  insulationTypeProb: PropTypes.string,
  properDrainageProb: PropTypes.string,
  moistureAccumulationSitesProb: PropTypes.string,
  lowPointWaterTrapProb: PropTypes.string,
  protrusionsCoveredProb: PropTypes.string,
  horizontalLapJointsProb: PropTypes.string,
  verticalTelescopingProb: PropTypes.string,
  endCapTypeProb: PropTypes.string,
  jacketingMaterialProb: PropTypes.string,
  jacketMetallicProb: PropTypes.string,
  jacketInstallQualityProb: PropTypes.string,
  jacketPostInstallQAQCProb: PropTypes.string,
  sealantAmountProb: PropTypes.string,
  highTempSealantProb: PropTypes.string,
  sealantPostInstallQAQCProb: PropTypes.string,
  sealantInstallQualityProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  properDrainage: state.workflow.fields.properDrainage,
  waterRepellent: state.workflow.fields.waterRepellent,
  jacketInstallQuality: state.workflow.fields.jacketInstallQuality,
  jacketingMaterial: state.workflow.fields.jacketingMaterial,
  jacketPostInstallQAQC: state.workflow.fields.jacketPostInstallQAQC,
  runQualMethodsBool: state.workflow.fields.runQualMethodsBool,
  insulationMaintanceInsectionQuality: state.workflow.fields.insulationMaintanceInsectionQuality,
  highTempSilicone: state.workflow.fields.highTempSilicone,
  pooling: state.workflow.fields.pooling,
  lowPointWaterTrap: state.workflow.fields.lowPointWaterTrap,
  insulationType: state.workflow.fields.insulationType,
  protrusionsCovered: state.workflow.fields.protrusionsCovered,
  horizontalLapJoints: state.workflow.fields.horizontalLapJoints,
  verticalTelescoping: state.workflow.fields.verticalTelescoping,
  endCapType: state.workflow.fields.endCapType,
  moistureAccumulationSites: state.workflow.fields.moistureAccumulationSites,
  sealantAmount: state.workflow.fields.sealantAmount,
  componentType: state.workflow.fields.componentType,
  runCondensation: state.workflow.fields.runCondensation,
  seamsAmount: state.workflow.fields.seamsAmount,
  properSeamAdhesian: state.workflow.fields.properSeamAdhesian,
  highTempSealant: state.workflow.fields.highTempSealant,
  sealantPostInstallQAQC: state.workflow.fields.sealantPostInstallQAQC,
  vaporBarrierPostInstallQaQc: state.workflow.fields.vaporBarrierPostInstallQaQc,
  vaporBarrierInstallQuality: state.workflow.fields.vaporBarrierInstallQuality,
  sealantInstallQuality: state.workflow.fields.sealantInstallQuality,
  jacketMetallic: state.workflow.fields.jacketMetallic,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  insulationTypeProb: state.workflow.fields.insulationTypeProb,
  properDrainageProb: state.workflow.fields.properDrainageProb,
  moistureAccumulationSitesProb: state.workflow.fields.moistureAccumulationSitesProb,
  lowPointWaterTrapProb: state.workflow.fields.lowPointWaterTrapProb,
  protrusionsCoveredProb: state.workflow.fields.protrusionsCoveredProb,
  horizontalLapJointsProb: state.workflow.fields.horizontalLapJointsProb,
  verticalTelescopingProb: state.workflow.fields.verticalTelescopingProb,
  endCapTypeProb: state.workflow.fields.endCapTypeProb,
  jacketingMaterialProb: state.workflow.fields.jacketingMaterialProb,
  jacketMetallicProb: state.workflow.fields.jacketMetallicProb,
  jacketInstallQualityProb: state.workflow.fields.jacketInstallQualityProb,
  jacketPostInstallQAQCProb: state.workflow.fields.jacketPostInstallQAQCProb,
  sealantAmountProb: state.workflow.fields.sealantAmountProb,
  highTempSealantProb: state.workflow.fields.highTempSealantProb,
  sealantPostInstallQAQCProb: state.workflow.fields.sealantPostInstallQAQCProb,
  sealantInstallQualityProb: state.workflow.fields.sealantInstallQualityProb,
})

export default connect(mapStateToProps)(Step2)
