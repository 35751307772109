/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

import { getNextTableDataMaintenance, getNextTableDataMaintenanceHybrid } from '../../lib/tableFunctions'

function Step2({ problemType, maintenanceTableNoCondensation, maintenanceTableCondensation, maintenanceTableCondensationForHybridMethod, inspectionMaintenanceTable, inspectionInputType, runCondensation, componentReplaced, componentReplacedTime, vaporBarrierReplaced, vaporBarrierSealantReplaced, vaporBarrierReplacedTime, vaporBarrierSealantReplacedTime, pastMaintenance, pastMaintenanceTime, jacketingSealantReplaced, jacketingReplaced, jacketingSealantReplacedTime, jacketingMissingReplacedTime, jacketingReplacedTime, coatingReplaced, coatingReplacedTime, coatingType, coatingMaterial, probabilisticInputFieldFlag, coatingMaterialProb }) {
  let valCheck = 0
  if(inspectionInputType === 'manyTable') {
    valCheck = getNextTableDataMaintenance(inspectionMaintenanceTable, maintenanceTableNoCondensation, true, false)
  }
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        {inspectionInputType === 'manyTable'
          ? (
            <div>
              {valCheck !== 0
                ? (
                  <div className="fl w-100">
                    {runCondensation === false
                      ? (
                        <div className="fl w-100">
                          <DataTableField
                            id="maintenanceTableNoCondensation"
                            name="maintenanceTableNoCondensation"
                            value={getNextTableDataMaintenance(inspectionMaintenanceTable, maintenanceTableNoCondensation, false, false)}
                            startRows={4}
                            rowHeaders={false}
                            colHeaders={{
                              USCustomary: [
                                'Maintenance Date<br/>(MM/DD/YYYY) ',
                                'Past Port<br/>Inspection?',
                                'Component<br/>Replaced?',
                                'Coating<br/>Replaced?',
                                'Jacketing<br/>Replaced?',
                                'Jacketing<br/>Sealant<br/>Replaced?',
                              ],
                              Metric: [
                                'Maintenance Date<br/>(MM/DD/YYYY) ',
                                'Past Port<br/>Inspection?',
                                'Component<br/>Replaced?',
                                'Coating<br/>Replaced?',
                                'Jacketing<br/>Replaced?',
                                'Jacketing<br/>Sealant<br/>Replaced?',
                              ],
                            }}
                          />
                        </div>
                      )
                      : ''}
                    {runCondensation === true
                      ? (
                        <div className="fl w-100">
                          {problemType !== 'PROB3'
                            ? (
                              <div>
                                <DataTableField
                                  id="maintenanceTableCondensation"
                                  name="maintenanceTableCondensation"
                                  value={getNextTableDataMaintenance(inspectionMaintenanceTable, maintenanceTableCondensation, false, true)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Maintenance Date<br/>(MM/DD/YYYY) ',
                                      'Past Port<br/>Inspection?',
                                      'Component<br/>Replaced?',
                                      'Coating<br/>Replaced?',
                                      'Jacketing<br/>Replaced?',
                                      'Jacketing<br/>Sealant<br/>Replaced?',
                                      'Vapor Barrier<br/>Replaced?',
                                      'Vapor Barrier<br/>Sealant<br/>Replaced?',
                                    ],
                                    Metric: [
                                      'Maintenance Date<br/>(MM/DD/YYYY) ',
                                      'Past Port<br/>Inspection?',
                                      'Component<br/>Replaced?',
                                      'Coating<br/>Replaced?',
                                      'Jacketing<br/>Replaced?',
                                      'Jacketing<br/>Sealant<br/>Replaced?',
                                      'Vapor Barrier<br/>Replaced?',
                                      'Vapor Barrier<br/>Sealant<br/>Replaced?',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                          {problemType === 'PROB3'
                            ? (
                              <div>
                                <DataTableField
                                  id="maintenanceTableCondensationForHybridMethod"
                                  name="maintenanceTableCondensationForHybridMethod"
                                  value={getNextTableDataMaintenanceHybrid(inspectionMaintenanceTable, maintenanceTableCondensationForHybridMethod, false, false)}
                                  startRows={4}
                                  rowHeaders={false}
                                  colHeaders={{
                                    USCustomary: [
                                      'Maintenance Date<br/>(MM/DD/YYYY) ',
                                      'Past Port<br/>Inspection?',
                                      'Component<br/>Replaced?',
                                      'Coating<br/>Replaced?',
                                      'Jacketing<br/>Replaced?',
                                      'Jacketing<br/>Sealant<br/>Replaced?',
                                    ],
                                    Metric: [
                                      'Maintenance Date<br/>(MM/DD/YYYY) ',
                                      'Past Port<br/>Inspection?',
                                      'Component<br/>Replaced?',
                                      'Coating<br/>Replaced?',
                                      'Jacketing<br/>Replaced?',
                                      'Jacketing<br/>Sealant<br/>Replaced?',
                                    ],
                                  }}
                                />
                              </div>
                            )
                            : ''}
                        </div>
                      )
                      : ''}
                  </div>

                )
                : 'No maintenance performed based on prior main inspection table'}
            </div>
          )
          : ''}
        {inspectionInputType === 'lastSingle'
          ? (
            <div>
              <div className="fl w-third-l w-50-m w-100">
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Past port inspection refers to the past inspection of the installed in-service component.</p>
                    <p>If inspected, then indicate the time at which is was evaluated from the analysis date.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="pastMaintenance" value={pastMaintenance} label="Past Port Inspection?">
                  {/* <E2GSelectFieldOption label="Unknown" value="unknown" /> */}
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Component replaced is referring to whether the component has been replaced or not.</p>
                    <p>If replaced, then indicate the time at which is was replaced from the analysis date.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="componentReplaced" value={componentReplaced} label="Component Replaced?">
                  {/* <E2GSelectFieldOption label="Unknown" value="unknown" /> */}
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Jacketing sealant replaced is referring to whether the jacketing sealant of the component under evaluation has been replaced or not.</p>
                    <p>If replaced, then indicate the time at which is was replaced from the analysis date.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="jacketingSealantReplaced" value={jacketingSealantReplaced} label="Jacketing Sealant Replaced?">
                  {/* <E2GSelectFieldOption label="Unknown" value="unknown" /> */}
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Jacketing  replaced is referring to whether the whole jacketing of the component under evaluation has been replaced or not.</p>
                    <p>If replaced, then indicate the time at which is was replaced from the analysis date.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="jacketingReplaced" value={jacketingReplaced} label="Jacketing Replaced?">
                  {/* <E2GSelectFieldOption label="Unknown" value="unknown" /> */}
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {problemType === 'PROB1'
                  ? (
                    <div>
                      {coatingType !== 'None' && coatingMaterial !== 'None'
                        ? (
                          // <E2GInfoButton
                          //   tooltip={<div>
                          //     <p>Coating replaced is referring to whether the coating of the component under evaluation has been replaced or not.</p>
                          //     <p>If replaced, then indicate the time at which is was replaced from the analysis date.</p>
                          //   </div>}
                          //   dialog
                          // >
                          <E2GSelectField name="coatingReplaced" value={coatingReplaced} label="Coating Replaced?">
                            {/* <E2GSelectFieldOption label="Unknown" value="unknown" /> */}
                            <E2GSelectFieldOption label="Yes" value="Yes" />
                            <E2GSelectFieldOption label="No" value="No" />
                          </E2GSelectField>
                          // </E2GInfoButton>
                        )
                        : '' }
                    </div>
                  )
                  : '' }
                {problemType === 'PROB2'
                  ? (
                    <div>
                      {coatingMaterial !== 'None (0 yrs)'
                        ? (
                          // <E2GInfoButton
                          //   tooltip={<div>
                          //     <p>Coating replaced is referring to whether the coating of the component under evaluation has been replaced or not.</p>
                          //     <p>If replaced, then indicate the time at which is was replaced from the analysis date.</p>
                          //   </div>}
                          //   dialog
                          // >
                          <E2GSelectField name="coatingReplaced" value={coatingReplaced} label="Coating Replaced?">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="Yes" value="Yes" />
                            <E2GSelectFieldOption label="No" value="No" />
                          </E2GSelectField>
                          // </E2GInfoButton>
                        )
                        : '' }
                    </div>
                  )
                  : '' }
                {problemType === 'PROB3'
                  ? (
                    <div>
                      {probabilisticInputFieldFlag === false
                        ? (
                          <div>
                            {coatingMaterial !== 'None (0 yrs)'
                              ? (
                                <E2GSelectField name="coatingReplaced" value={coatingReplaced} label="Coating Replaced?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="Yes" value="Yes" />
                                  <E2GSelectFieldOption label="No" value="No" />
                                </E2GSelectField>
                              )
                              : '' }
                          </div>
                        )
                        : '' }
                      {probabilisticInputFieldFlag === true
                        ? (
                          <div>
                            {probabilisticInputFieldFlag && coatingMaterialProb && JSON.parse(coatingMaterialProb).evidence?.[0] !== 1 &&
                              (
                                <E2GSelectField name="coatingReplaced" value={coatingReplaced} label="Coating Replaced?">
                                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                                  <E2GSelectFieldOption label="Yes" value="Yes" />
                                  <E2GSelectFieldOption label="No" value="No" />
                                </E2GSelectField>
                              )}
                          </div>
                        )
                        : '' }
                    </div>
                  )
                  : '' }
                {runCondensation === true
                  ? (
                    <div>
                      {problemType !== 'PROB3'
                        ? (
                          <div>
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Vapor barrier replaced is referring to whether the whole vapor barrier of the component under evaluation has been replaced or not.</p>
                                <p>If replaced, then indicate the time at which is was replaced from the analysis date.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="vaporBarrierReplaced" value={vaporBarrierReplaced} label="Vapor Barrier Replaced?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="Yes" value="Yes" />
                              <E2GSelectFieldOption label="No" value="No" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Vapor barrier sealant replaced is referring to whether the vapor barrier sealant of the component under evaluation has been replaced or not.</p>
                                <p>If replaced, then indicate the time at which is was replaced from the analysis date.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="vaporBarrierSealantReplaced" value={vaporBarrierSealantReplaced} label="Vapor Barrier Sealant Replaced?">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="Yes" value="Yes" />
                              <E2GSelectFieldOption label="No" value="No" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
              <div className="fl w-third-l w-50-m w-100">
                {pastMaintenance === 'Yes'
                  ? (
                    <div className="cf mv3 w-100">

                      <E2GTextField name="pastMaintenanceTime" value={pastMaintenanceTime} label="Past Port Inspection Time" type="number" required />
                    </div>
                  )
                  : ''}
                {componentReplaced === 'Yes'
                  ? (
                    <div className="cf mv3 w-100">
                      <E2GTextField name="componentReplacedTime" value={componentReplacedTime} label="Component Replaced Time" type="number" required />
                    </div>
                  )
                  : ''}
                {jacketingSealantReplaced === 'Yes'
                  ? (
                    <div className="cf mv3 w-100">
                      <E2GTextField name="jacketingSealantReplacedTime" value={jacketingSealantReplacedTime} label="Jacketing Sealant Replaced Time" type="number" required />
                    </div>
                  )
                  : ''}
                {jacketingReplaced === 'Yes'
                  ? (
                    <div className="cf mv3 w-100">
                      <E2GTextField name="jacketingReplacedTime" value={jacketingReplacedTime} label="Jacketing Replaced Time" type="number" required />
                    </div>
                  )
                  : ''}
                {problemType === 'PROB1'
                  ? (
                    <div>
                      {coatingType !== 'None' && coatingMaterial !== 'None'
                        ? (
                          <div>
                            {coatingReplaced === 'Yes'
                              ? (
                                <div className="cf mv3 w-100">
                                  <E2GTextField name="coatingReplacedTime" value={coatingReplacedTime} label="Coating Replaced Time" type="number" required />
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : '' }
                    </div>
                  )
                  : '' }
                {problemType === 'PROB2'
                  ? (
                    <div>
                      {coatingMaterial !== 'None (0 yrs)'
                        ? (
                          <div>
                            {coatingReplaced === 'Yes'
                              ? (
                                <div className="cf mv3 w-100">
                                  <E2GTextField name="coatingReplacedTime" value={coatingReplacedTime} label="Coating Replaced Time" type="number" required />
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : '' }
                    </div>
                  )
                  : '' }
                {problemType === 'PROB3'
                  ? (
                    <div>
                      {probabilisticInputFieldFlag === false
                        ? (
                          <div>
                            {coatingMaterial !== 'None (0 yrs)'
                              ? (
                                <div>
                                  {coatingReplaced === 'Yes'
                                    ? (
                                      <div className="cf mv3 w-100">
                                        <E2GTextField name="coatingReplacedTime" value={coatingReplacedTime} label="Coating Replaced Time" type="number" required />
                                      </div>
                                    )
                                    : ''}
                                </div>
                              )
                              : '' }
                          </div>
                        )
                        : '' }
                      {probabilisticInputFieldFlag === true
                        ? (
                          <div>
                            {typeof coatingMaterialProb !== 'undefined' // && coatingMaterialProbEvidenceVar[0] !== 1
                              ? (
                                <div>
                                  {coatingReplaced === 'Yes'
                                    ? (
                                      <div className="cf mv3 w-100">
                                        <E2GTextField name="coatingReplacedTime" value={coatingReplacedTime} label="Coating Replaced Time" type="number" required />
                                      </div>
                                    )
                                    : ''}
                                </div>
                              )
                              : '' }
                          </div>
                        )
                        : '' }
                    </div>
                  )
                  : '' }
                {runCondensation === true && problemType !== 'PROB3'
                  ? (
                    <div>
                      {vaporBarrierReplaced === 'Yes'
                        ? (
                          <div>
                            <div className="cf mv3 w-100">
                              <E2GTextField name="vaporBarrierReplacedTime" value={vaporBarrierReplacedTime} label="Vapor Barrier Replaced Time" type="number" required />
                            </div>
                          </div>
                        )
                        : ''}
                      {vaporBarrierSealantReplaced === 'Yes'
                        ? (
                          <div>
                            <div className="cf mv3 w-100">
                              <E2GTextField name="vaporBarrierSealantReplacedTime" value={vaporBarrierSealantReplacedTime} label="Vapor Barrier Sealant Replaced Time" type="number" required />
                            </div>
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
            </div>
          )
          : ''}
      </div>
      <Submit label="Next" />
    </div>
  )
}

Step2.propTypes = {
  problemType: PropTypes.string,
  pastMaintenance: PropTypes.string,
  pastMaintenanceTime: PropTypes.number,
  jacketingSealantReplaced: PropTypes.string,
  jacketingReplaced: PropTypes.string,
  componentReplaced: PropTypes.string,
  jacketingMissingReplacedTime: PropTypes.number,
  jacketingSealantReplacedTime: PropTypes.number,
  jacketingReplacedTime: PropTypes.number,
  componentReplacedTime: PropTypes.number,
  coatingReplaced: PropTypes.string,
  coatingReplacedTime: PropTypes.number,
  coatingType: PropTypes.string,
  coatingMaterial: PropTypes.string,
  runCondensation: PropTypes.bool,
  vaporBarrierReplaced: PropTypes.string,
  vaporBarrierSealantReplaced: PropTypes.string,
  vaporBarrierReplacedTime: PropTypes.number,
  vaporBarrierSealantReplacedTime: PropTypes.number,
  inspectionInputType: PropTypes.string,
  inspectionMaintenanceTable: PropTypes.array,
  maintenanceTableNoCondensation: PropTypes.array,
  maintenanceTableCondensation: PropTypes.array,
  maintenanceTableCondensationForHybridMethod: PropTypes.array,
  probabilisticInputFieldFlag: PropTypes.bool,
  coatingMaterialProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  pastMaintenance: state.workflow.fields.pastMaintenance,
  pastMaintenanceTime: state.workflow.fields.pastMaintenanceTime,
  jacketingSealantReplaced: state.workflow.fields.jacketingSealantReplaced,
  jacketingReplaced: state.workflow.fields.jacketingReplaced,
  jacketingMissingReplacedTime: state.workflow.fields.jacketingMissingReplacedTime,
  jacketingSealantReplacedTime: state.workflow.fields.jacketingSealantReplacedTime,
  jacketingReplacedTime: state.workflow.fields.jacketingReplacedTime,
  componentReplacedTime: state.workflow.fields.componentReplacedTime,
  componentReplaced: state.workflow.fields.componentReplaced,
  coatingReplaced: state.workflow.fields.coatingReplaced,
  coatingReplacedTime: state.workflow.fields.coatingReplacedTime,
  coatingType: state.workflow.fields.coatingType,
  coatingMaterial: state.workflow.fields.coatingMaterial,
  runCondensation: state.workflow.fields.runCondensation,
  vaporBarrierReplaced: state.workflow.fields.vaporBarrierReplaced,
  vaporBarrierSealantReplaced: state.workflow.fields.vaporBarrierSealantReplaced,
  vaporBarrierReplacedTime: state.workflow.fields.vaporBarrierReplacedTime,
  vaporBarrierSealantReplacedTime: state.workflow.fields.vaporBarrierSealantReplacedTime,
  inspectionInputType: state.workflow.fields.inspectionInputType,
  inspectionMaintenanceTable: state.workflow.fields.inspectionMaintenanceTable,
  maintenanceTableNoCondensation: state.workflow.fields.maintenanceTableNoCondensation,
  maintenanceTableCondensation: state.workflow.fields.maintenanceTableCondensation,
  maintenanceTableCondensationForHybridMethod: state.workflow.fields.maintenanceTableCondensationForHybridMethod,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  coatingMaterialProb: state.workflow.fields.coatingMaterialProb,
})

export default connect(mapStateToProps)(Step2)
