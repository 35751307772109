/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
// import pick from 'lodash/pick'
import PropTypes from 'prop-types'

import DataGraphDisplay from '@e2grnd/ui-core/dist/containers/DataGraphDisplay'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'

import * as histogramQual from '../lib/histogramQual'
import * as histogramQual2 from '../lib/histogramQual2'

class QualMethodsProp extends Component {
  static propTypes = {
    style: PropTypes.object,
    results: PropTypes.object,
    graphs: PropTypes.object,
    activeUnitSystem: PropTypes.string,
    includeClientMethods: PropTypes.bool,
    qualLikelihoodProbTable: PropTypes.object,
    qualPOFProbTable: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open })
  }

  render() {
    const {
      results,
      style,
      graphs,
      activeUnitSystem,
      includeClientMethods,
      qualLikelihoodProbTable,
      qualPOFProbTable,
    } = this.props

    let graphsToDisplay
    if(includeClientMethods === true) {
      graphsToDisplay = histogramQual.plotBarResults(results, activeUnitSystem)
    } else if(includeClientMethods === false) {
      graphsToDisplay = histogramQual2.plotBarResults(results, activeUnitSystem)
    }
    return (
      <div className="cf w-100">
        <div className="cf w-100">
          <div className="fl w-25-l w-50-m w-100">
            <E2GTextField name="api581PercentProbFailureYes" style={style} value={results.api581PercentProbFailureYes} label="API 581 - Failure  Probability" type="number" textWidth="75%" disabled />
          </div>
        </div>
        <div className="cf w-100">
          <div className="fl w-25-l w-50-m w-100">
            <E2GTextField name="api581FailureTimeEV" style={style} value={results.api581FailureTimeEV} label="API 581 - Failure Time Mean" type="number" textWidth="75%" disabled />
            {includeClientMethods === true
              ? (
                <div>
                  <E2GTextField name="bpFailureTimeEV" style={style} value={results.bpFailureTimeEV} label="BP Method - Mean Score" type="number" textWidth="75%" disabled />
                  <E2GTextField name="ineosFailureTimeEV" style={style} value={results.ineosFailureTimeEV} label="Ineos Method - Mean Score" type="number" textWidth="75%" disabled />
                </div>
              )
              : ''}
            <E2GTextField name="mcvayFailureTimeEV" style={style} value={results.mcvayFailureTimeEV} label="McVay Method - Mean Score" type="number" textWidth="75%" disabled />
            <E2GTextField name="api583FailureTimeEV" style={style} value={results.api583FailureTimeEV} label="API 583 - Mean Score" type="number" textWidth="75%" disabled />
            <E2GTextField name="euroMethodPrimaryScoreEV" style={style} value={results.euroMethodPrimaryScoreEV} label="Euro Method - Mean Score" type="number" textWidth="75%" disabled />
          </div>
          <div className="fl w-25-l w-50-m w-100">
            <E2GTextField name="api581FailureTimeSD" style={style} value={results.api581FailureTimeSD} label="API 581 - Failure Time Standard Deviation" type="number" textWidth="75%" disabled />
            {includeClientMethods === true
              ? (
                <div>
                  <E2GTextField name="bpFailureTimeSD" style={style} value={results.bpFailureTimeSD} label="BP Method - Standard Deviation Score" type="number" textWidth="75%" disabled />
                  <E2GTextField name="ineosFailureTimeSD" style={style} value={results.ineosFailureTimeSD} label="Ineos Method - Standard Deviation Score" type="number" textWidth="75%" disabled />
                </div>
              )
              : ''}
            <E2GTextField name="mcvayFailureTimeSD" style={style} value={results.mcvayFailureTimeSD} label="McVay Method - Standard Deviation Score" type="number" textWidth="75%" disabled />
            <E2GTextField name="api583FailureTimeSD" style={style} value={results.api583FailureTimeSD} label="API 583 - Standard Deviation Score" type="number" textWidth="75%" disabled />
            <E2GTextField name="euroMethodPrimaryScoreSD" style={style} value={results.euroMethodPrimaryScoreSD} label="Euro Method - Standard Deviation Score" type="number" textWidth="75%" disabled />
          </div>
          <div className="fl w-50-l w-50-m w-100">
            {graphs
              ? <DataGraphDisplay graphs={graphsToDisplay} />
              : ''}
          </div>
        </div>
        <div className="cf w-100">
          <p>Most Likely Qualitative Probability (%)</p>
          <DataTableDisplay
            id="qualPOFProbTable"
            table={qualPOFProbTable}
            colHeaders={{
              USCustomary: [
                '',
                'Very Low',
                'Low',
                'Moderate',
                'High',
                'Very High'
              ],
              Metric: [
                '',
                'Very Low',
                'Low',
                'Moderate',
                'High',
                'Very High'
              ],
            }}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  style: state.ui.style,
  results: state.workflow.response ? state.workflow.response.results : undefined,
  graphs: state.workflow.response ? state.workflow.response.results.graphs : undefined,
  activeUnitSystem: state.workflow.activeUnitSystem,
  includeClientMethods: state.workflow.fields.includeClientMethods,
  qualLikelihoodProbTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.qualLikelihoodProbTable : undefined,
  qualPOFProbTable: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.qualPOFProbTable : undefined,
})
export default connect(mapStateToProps, null)(QualMethodsProp)
