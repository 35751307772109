/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataTableField from '@e2grnd/ui-core/dist/containers/DataTableField'
import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'

function Step1({ problemType, temperatureSummer, temperatureFall, temperatureSpring, temperatureWinter, relativeHumidity, runCondensation, expertExposureConfidenceKnown, internalPh, externalEnvironment, windSpeedFraction1, elevation, steamVentExposure, otherDirectExposure, ambientTemperature, activeUnitSystem, highWindLikelihood, environment, environmentConfidence, annualRainfall, annualRainfall1, environmentCorrosivity, uvExposure, ozoneExposure, exposureConfidence, expertExposureConfidence, expertExposureFractionMean, ambientTemperatureInputType, ambientTempHistory, annualRainfall2, temperatureAm, temperaturePm, relativeHumidityAm, relativeHumidityPm, externalEnvironment1, temperatureSummerConfidence, temperatureFallConfidence, temperatureWinterConfidence, temperatureSpringConfidence, relativeHumidityConfidence, temperatureAmConfidence, temperaturePmConfidence, relativeHumidityAmConfidence, relativeHumidityPmConfidence, condensationParametersVarianceType, temperatureAmSD, temperaturePmSD, relativeHumidityAmSD, relativeHumidityPmSD, temperatureAmCV, temperaturePmCV, relativeHumidityAmCV, relativeHumidityPmCV, temperatureSummerSD, temperatureFallSD, temperatureSpringSD, temperatureWinterSD, relativeHumiditySD, temperatureSummerCV, temperatureFallCV, temperatureSpringCV, temperatureWinterCV, relativeHumidityCV, relativeHumidityInputType, relativeHumidityHistory, probabilisticInputFieldFlag, externalEnvironment1Prob, ambientTemperatureInputTypeProb, relativeHumidityInputTypeProb, temperatureAmProb, temperaturePmProb, relativeHumidityAmProb, relativeHumidityPmProb, annualRainfall2Prob, windSpeedFraction1Prob, elevationProb, internalPhProb, steamVentExposureProb, otherDirectExposureProb, uvExposureProb }) {
  return (
    <div className="cf w-100">
      <div className="cf w-100">
        {problemType === 'PROB1'
          ? (
            <div className="fl w-100">
              <div className="fl w-20-l w-50-m w-100">
                <E2GSelectField name="ambientTemperatureInputType" value={ambientTemperatureInputType} label="Ambient Temperature Input Type">
                  <E2GSelectFieldOption label="Constant" value="constant" />
                  <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                </E2GSelectField>
                {runCondensation === true
                  ? (
                    <div>
                      <E2GSelectField name="relativeHumidityInputType" value={relativeHumidityInputType} label="Relative Humidity Input Type">
                        <E2GSelectFieldOption label="Constant" value="constant" />
                        <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                      </E2GSelectField>
                    </div>
                  )
                  : ''}
                {ambientTemperatureInputType === 'temporal'
                  ? (
                    <div>
                      <DataTableField
                        id="ambientTempHistory"
                        name="ambientTempHistory"
                        startRows={2}
                        containerHeight="400px"
                        value={ambientTempHistory}
                        rowHeaders={['1', '2']}
                        colHeaders={{ USCustomary: ['Hourly Ambient<br>Temperature History (F)'], Metric: ['Hourly Ambient<br>Temperature History (C)'] }}
                      />
                    </div>
                  )
                  : ''}
                {runCondensation === true
                  ? (
                    <div>
                      {relativeHumidityInputType === 'temporal'
                        ? (
                          <div>
                            <DataTableField
                              id="relativeHumidityHistory"
                              name="relativeHumidityHistory"
                              startRows={2}
                              containerHeight="400px"
                              value={relativeHumidityHistory}
                              rowHeaders={['1', '2']}
                              colHeaders={{ USCustomary: ['Hourly Relative<br>Humidity History (%)'], Metric: ['Hourly Relative<br>Humidity History (%)'] }}
                            />
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
              <div className="fl pa2 w-20-l w-50-m w-100">
                {ambientTemperatureInputType === 'constant'
                  ? (
                    <div>
                      {activeUnitSystem !== 'Metric'
                        ? (
                          <div>
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Different from room temperature, the average annual ambient temperature is the average temperature of the air over the year.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="ambientTemperature" value={ambientTemperature} label="Average Annual Ambient Temperature (°F)">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="Low (<40F)" value="Low" />
                              <E2GSelectFieldOption label="Moderate (40-80F)" value="Moderate" />
                              <E2GSelectFieldOption label="High (>80F)" value="High" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>
                        )
                        : <div>
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>Different from room temperature, the average annual ambient temperature is the average temperature of the air over the year.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GSelectField name="ambientTemperature" value={ambientTemperature} label="Average Annual Ambient Temperature (°C)">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="Low (<5C)" value="Low" />
                            <E2GSelectFieldOption label="Moderate (5-15C)" value="Moderate" />
                            <E2GSelectFieldOption label="High (>15C)" value="High" />
                          </E2GSelectField>
                          {/* </E2GInfoButton> */}
                        </div>}
                    </div>
                  )
                  : ''}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Environmental severity refers to the corrosivity of the local environment in a given location or region. It is a measure of how harsh environmental conditions are, particularly in terms of their impact on materials and structures. Factors contributing to environmental severity include:</p>
                    <li><strong>Climatological</strong>: Temperature, humidity, and precipitation levels.</li>
                    <li><strong>Geographical</strong>: Proximity to the sea, altitude, and terrain.</li>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="environment" value={environment} label="Environment Severity">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Dry" value="Dry" />
                  <E2GSelectFieldOption label="Mild" value="Mild" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="Severe" value="Severe" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {environment !== 'unknown'
                  ? (
                    <E2GSelectField name="environmentConfidence" value={environmentConfidence} label="Environment Confidence">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="Low (50%)" value="Low" />
                      <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                      <E2GSelectFieldOption label="High (90%)" value="High" />
                    </E2GSelectField>
                  )
                  : ''}
                {environment === 'unknown'
                  ? (
                    <div>
                      {activeUnitSystem !== 'Metric'
                        ? (
                          // <E2GInfoButton
                          //   tooltip={<div>
                          //     <p>The average annual rainfall is the average amount of precipitation that falls in a specific location over the course of a year.</p>
                          //   </div>}
                          //   dialog
                          // >
                          <E2GSelectField name="annualRainfall" value={annualRainfall} label="Average Annual Rainfall">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="None (In Building)" value="<15in" />
                            <E2GSelectFieldOption label="Indirect (Sheltered)" value="<15in" />
                            <E2GSelectFieldOption label="<15in" value="<15in" />
                            <E2GSelectFieldOption label="15-30in" value="15-30in" />
                            <E2GSelectFieldOption label="30-60in" value="30-60in" />
                            <E2GSelectFieldOption label=">60in" value=">60in" />
                          </E2GSelectField>
                          // </E2GInfoButton>
                        )
                        : <div>
                          {/* <E2GInfoButton
                            tooltip={<div>
                              <p>The average annual rainfall is the average amount of precipitation that falls in a specific location over the course of a year.</p>
                            </div>}
                            dialog
                          > */}
                          <E2GSelectField name="annualRainfall" value={annualRainfall} label="Average Annual Rainfall">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="None (In Building)" value="<15in" />
                            <E2GSelectFieldOption label="Indirect (Sheltered)" value="<15in" />
                            <E2GSelectFieldOption label="<381mm" value="<15in" />
                            <E2GSelectFieldOption label="381-762mm" value="15-30in" />
                            <E2GSelectFieldOption label="762-1524mm" value="30-60in" />
                            <E2GSelectFieldOption label=">1524mm" value=">60in" />
                          </E2GSelectField>
                          {/* </E2GInfoButton> */}
                        </div>}
                      {/* <E2GInfoButton
                        tooltip={<div>
                          <p>Environmental corrosivity refers to the potential of an environment to cause corrosion in materials, particularly metals. This is influenced by various factors like pollutants (i.e., presence of salts or acids) and the proximity to the sea (i.e., closer to the sea, the more salt in the air).</p>
                        </div>}
                        dialog
                      > */}
                      <E2GSelectField name="environmentCorrosivity" value={environmentCorrosivity} label="Environment Corrosivity">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="Non Acid/Salt Rain" value="Non Acid/Salt Rain" />
                        <E2GSelectFieldOption label="Acid Salt Rain" value="Acid Salt Rain" />
                      </E2GSelectField>
                      {/* </E2GInfoButton> */}
                    </div>
                  )
                  : ''}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>UV exposure can impact pipelines, particularly those that are above ground and exposed to sunlight for extended periods. Some key points are the following:</p>
                    <li><strong>Degradation of Coatings</strong>: UV radiation from the sun can degrade the protective coatings on pipelines, making them more susceptible to corrosion. This degradation can lead to leaks, spills, or even catastrophic failures.</li>
                    <li><strong>Material Vulnerability</strong>: Certain materials, like PVC, can be particularly vulnerable to UV radiation. To mitigate this, additives are often included in the material formulation to inhibit UV damage.</li>
                    <li><strong>Epoxy Coatings</strong>: Fusion bonded epoxy (FBE) coatings, commonly used for pipeline protection, are designed for underground service. When exposed to UV light, these coatings can undergo photooxidation, leading to polymer degradation.</li>
                    <li><strong>Preventive Measures</strong>: To protect pipelines from UV exposure, measures such as using UV-resistant coatings, burying pipelines, or applying additional protective layers can be employed.</li>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="uvExposure" value={uvExposure} label="UV Exposure">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Ozone exposure can impact pipelines, particularly those used in water and wastewater systems. Some key points are the following:</p>
                    <li><strong>Material Degradation</strong>: Ozone is a strong oxidizing agent and can cause degradation of certain materials used in pipelines, such as rubber gaskets and some types of plastics.</li>
                    <li><strong>Disinfection</strong>: Ozone is also used as a disinfectant in water treatment processes. It is effective in killing bacteria, viruses, and other pathogens, making it a valuable tool for ensuring the safety of drinking water. However, the ozone must be carefully controlled to prevent damage to the pipeline materials.</li>
                    <li><strong>Environmental Impact</strong>: Ozone exposure in the environment can lead to the formation of secondary pollutants, which can further impact the integrity of pipelines and other infrastructure.</li>
                    <li><strong>Preventive Measures</strong>: To mitigate the effects of ozone exposure, pipelines can be constructed with ozone-resistant materials, and regular maintenance can help identify and address any early signs of degradation.</li>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="ozoneExposure" value={ozoneExposure} label="Ozone Exposure">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
              </div>
              <div className="fl w-20-l w-50-m w-100">
                {activeUnitSystem !== 'Metric'
                  ? (
                    <div>
                      {/* <E2GInfoButton
                        tooltip={<div>
                          <p>Elevation refers to the height above sea level that the pipeline resides.</p>
                        </div>}
                        dialog
                      > */}
                      <E2GSelectField name="elevation" value={elevation} label="Elevation (ft)">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="0 to 50" value="0 to 50" />
                        <E2GSelectFieldOption label="50 to 100" value="50 to 100" />
                        <E2GSelectFieldOption label="100 to 150" value="100 to 150" />
                        <E2GSelectFieldOption label="150 to 200" value="150 to 200" />
                        <E2GSelectFieldOption label="200 to 250" value="200 to 250" />
                        <E2GSelectFieldOption label="250 to 300" value="250 to 300" />
                      </E2GSelectField>
                      {/* </E2GInfoButton> */}
                    </div>
                  )
                  : <div>
                    {/* <E2GInfoButton
                      tooltip={<div>
                        <p>Elevation refers to the height above sea level that the pipeline resides.</p>
                      </div>}
                      dialog
                    > */}
                    <E2GSelectField name="elevation" value={elevation} label="Elevation (m)">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="0 to 15.24" value="0 to 50" />
                      <E2GSelectFieldOption label="15.24 to 30.48" value="50 to 100" />
                      <E2GSelectFieldOption label="30.48 to 45.72" value="100 to 150" />
                      <E2GSelectFieldOption label="45.72 to 60.96" value="150 to 200" />
                      <E2GSelectFieldOption label="60.96 to 76.2" value="200 to 250" />
                      <E2GSelectFieldOption label="76.2 to 91.44" value="250 to 300" />
                    </E2GSelectField>
                    {/* </E2GInfoButton> */}
                  </div>}
                {/* <E2GInfoButton
                  tooltip={<div>
                    <p>Average annual wind speed is the measure of how fast air is moving from one place to another over the course of the year.</p>
                  </div>}
                  dialog
                > */}
                <E2GSelectField name="windSpeedFraction1" value={windSpeedFraction1} label="Average Annual Wind Speed (mph)">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="0 to 30" value="0 to 30" />
                  <E2GSelectFieldOption label="30 to 60" value="30 to 60" />
                  <E2GSelectFieldOption label="60 to 100" value="60 to 100" />
                </E2GSelectField>
                {/* </E2GInfoButton> */}
                <E2GSelectField name="expertExposureConfidenceKnown" value={expertExposureConfidenceKnown} label="Include Expert Prior for Exposure Fraction?">
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                {expertExposureConfidenceKnown === 'Yes'
                  ? (
                    <div>
                      <div className="cf mv2 w-100">
                        {/* <E2GInfoButton
                          tooltip={<div>
                            <p>The exposure fraction is the percent per year that the pipeline is expose to the environment.</p>
                          </div>}
                          dialog
                        > */}
                        <E2GTextField name="expertExposureFractionMean" value={expertExposureFractionMean} label="Mean Expert Exposure Fraction" type="number" required />
                        {/* </E2GInfoButton> */}
                      </div>
                      <E2GSelectField name="expertExposureConfidence" value={expertExposureConfidence} label="Expert Exposure Fraction Confidence">
                        <E2GSelectFieldOption label="Low (CV=0.3)" value="Low" />
                        <E2GSelectFieldOption label="Moderate (CV=0.2)" value="Moderate" />
                        <E2GSelectFieldOption label="High (CV=0.1)" value="High" />
                      </E2GSelectField>
                    </div>
                  )
                  : ''}
              </div>
              <div className="fl w-20-l w-50-m w-100">
                {runCondensation === true
                  ? (
                    <div>
                      {ambientTemperatureInputType === 'constant'
                        ? (
                          <div>
                            <div className="cf mv2 w-100">
                              {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>The average summer temperature is the average ambient summer temperature during the year.</p>
                                </div>}
                                dialog
                              > */}
                              <E2GTextField name="temperatureSummer" value={temperatureSummer} label="Average Summer Temperature" type="number" />
                              {/* </E2GInfoButton> */}
                            </div>
                            <div className="cf mv3 w-100">
                              {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>The average fall temperature is the average ambient summer temperature during the year.</p>
                                </div>}
                                dialog
                              > */}
                              <E2GTextField name="temperatureFall" value={temperatureFall} label="Average Fall Temperature" type="number" />
                              {/* </E2GInfoButton> */}
                            </div>
                            <div className="cf mv3 w-100">
                              {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>The average winter temperature is the average ambient summer temperature during the year.</p>
                                </div>}
                                dialog
                              > */}
                              <E2GTextField name="temperatureWinter" value={temperatureWinter} label="Average Winter Temperature" type="number" />
                              {/* </E2GInfoButton> */}
                            </div>
                            <div className="cf mv3 w-100">
                              {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>The average spring temperature is the average ambient summer temperature during the year.</p>
                                </div>}
                                dialog
                              > */}
                              <E2GTextField name="temperatureSpring" value={temperatureSpring} label="Average Spring Temperature" type="number" />
                              {/* </E2GInfoButton> */}
                            </div>
                          </div>
                        )
                        : ''}
                      {relativeHumidityInputType === 'constant' && ambientTemperatureInputType === 'constant'
                        ? (
                          <div>
                            <div className="cf mv3 w-100">
                              {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>The average relative humidity is the average relative humidity during the year.</p>
                                </div>}
                                dialog
                              > */}
                              <E2GTextField name="relativeHumidity" value={relativeHumidity} label="Average Relative Humidity" type="number" />
                              {/* </E2GInfoButton> */}
                            </div>
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
              <div className="fl w-20-l w-50-m w-100">
                {runCondensation === true
                  ? (
                    <div>
                      {ambientTemperatureInputType === 'constant' || relativeHumidityInputType === 'constant'
                        ? (
                          <div>
                            <E2GSelectField name="condensationParametersVarianceType" value={condensationParametersVarianceType} label="Select Variance Type">
                              <E2GSelectFieldOption label="Confidence" value="Confidence" />
                              <E2GSelectFieldOption label="Standard Deviation" value="Standard Deviation" />
                              <E2GSelectFieldOption label="CV" value="CV" />
                            </E2GSelectField>
                          </div>
                        )
                        : ''}
                      {relativeHumidityInputType === 'constant' && ambientTemperatureInputType !== 'constant'
                        ? (
                          <div>
                            <div className="cf mv3 w-100">
                              {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>The average relative humidity is the average relative humidity during the year.</p>
                                </div>}
                                dialog
                              > */}
                              <E2GTextField name="relativeHumidity" value={relativeHumidity} label="Average Relative Humidity" type="number" />
                              {/* </E2GInfoButton> */}
                            </div>
                          </div>
                        )
                        : ''}
                      {condensationParametersVarianceType === 'Confidence'
                        ? (
                          <div>
                            {ambientTemperatureInputType === 'constant'
                              ? (
                                <div>
                                  <E2GSelectField name="temperatureSummerConfidence" value={temperatureSummerConfidence} label="Average Summer Temperature Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                  <E2GSelectField name="temperatureFallConfidence" value={temperatureFallConfidence} label="Average Fall Temperature Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                  <E2GSelectField name="temperatureWinterConfidence" value={temperatureWinterConfidence} label="Average Winter Temperature Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                  <E2GSelectField name="temperatureSpringConfidence" value={temperatureSpringConfidence} label="Average Spring Temperature Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                </div>
                              )
                              : ''}
                            {relativeHumidityInputType === 'constant'
                              ? (
                                <div>
                                  <E2GSelectField name="relativeHumidityConfidence" value={temperatureSpringConfidence} label="Average Relative Humidity Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                </div>
                              )
                              : ''}
                          </div>

                        )
                        : ''}
                      {condensationParametersVarianceType === 'Standard Deviation'
                        ? (
                          <div>
                            {ambientTemperatureInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv2 w-100">
                                    <E2GTextField name="temperatureSummerSD" value={temperatureSummerSD} label="Summer Temperature Standard Deviation" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureFallSD" value={temperatureFallSD} label="Fall Temperature Standard Deviation" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureWinterSD" value={temperatureWinterSD} label="Winter Temperature Standard Deviation" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureSpringSD" value={temperatureSpringSD} label="Spring Temperature Standard Deviation" type="number" />
                                  </div>
                                </div>
                              )
                              : ''}
                            {relativeHumidityInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="relativeHumiditySD" value={relativeHumiditySD} label="Relative Humidity Standard Deviation" type="number" />
                                  </div>
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                      {condensationParametersVarianceType === 'CV'
                        ? (
                          <div>
                            {ambientTemperatureInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv2 w-100">
                                    <E2GTextField name="temperatureSummerCV" value={temperatureSummerCV} label="Summer Temperature CV" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureFallCV" value={temperatureFallCV} label="Fall Temperature CV" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureWinterCV" value={temperatureWinterCV} label="Winter Temperature CV" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureSpringCV" value={temperatureSpringCV} label="Spring Temperature CV" type="number" />
                                  </div>
                                </div>
                              )
                              : ''}
                            {relativeHumidityInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="relativeHumidityCV" value={relativeHumidityCV} label="Relative Humidity CV" type="number" />
                                  </div>
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
            </div>
          )
          : ''}
        {problemType === 'PROB3'
          ? (
            <div className="fl w-100">
              <div className="fl w-50-l w-100">
                {probabilisticInputFieldFlag === false
                  ? (
                    <div>
                      <E2GSelectField name="ambientTemperatureInputType" value={ambientTemperatureInputType} label="Ambient Temperature Input Type">
                        <E2GSelectFieldOption label="Constant" value="constant" />
                        <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                      </E2GSelectField>
                      {runCondensation === true
                        ? (
                          <div>
                            <E2GSelectField name="relativeHumidityInputType" value={relativeHumidityInputType} label="Relative Humidity Input Type">
                              <E2GSelectFieldOption label="Constant" value="constant" />
                              <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                            </E2GSelectField>
                          </div>
                        )
                        : ''}
                      {ambientTemperatureInputType === 'temporal'
                        ? (
                          <div>
                            <DataTableField
                              id="ambientTempHistory"
                              name="ambientTempHistory"
                              startRows={2}
                              containerHeight="400px"
                              value={ambientTempHistory}
                              rowHeaders={['1', '2']}
                              colHeaders={{ USCustomary: ['Hourly Ambient<br>Temperature History (F)'], Metric: ['Hourly Ambient<br>Temperature History (C)'] }}
                            />
                          </div>
                        )
                        : <div>
                          <E2GTextField name="temperatureAm" value={temperatureAm} label="Average Temperature (Day)" type="number" />
                          <E2GTextField name="temperaturePm" value={temperaturePm} label="Average Temperature (Night)" type="number" />
                        </div>}
                      {runCondensation === true
                        ? (
                          <div>
                            {relativeHumidityInputType === 'temporal'
                              ? (
                                <div>
                                  <DataTableField
                                    id="relativeHumidityHistory"
                                    name="relativeHumidityHistory"
                                    startRows={2}
                                    containerHeight="400px"
                                    value={relativeHumidityHistory}
                                    rowHeaders={['1', '2']}
                                    colHeaders={{ USCustomary: ['Hourly Relative<br>Humidity History (%)'], Metric: ['Hourly Relative<br>Humidity History (%)'] }}
                                  />
                                </div>
                              )
                              : <div>
                                <E2GTextField name="relativeHumidityAm" value={relativeHumidityAm} label="Average Relative Humidity (Day)" type="number" />
                                <E2GTextField name="relativeHumidityPm" value={relativeHumidityPm} label="Average Relative Humidity (Night)" type="number" />
                              </div>}
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : <div>
                    <E2GSelectField name="ambientTemperatureInputTypeProb" value={ambientTemperatureInputTypeProb} label="Ambient Temperature Input Type">
                      <E2GSelectFieldOption label="Probabilistic" value="probabilistic" />
                      <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                    </E2GSelectField>
                    {runCondensation === true
                      ? (
                        <div>
                          <E2GSelectField name="relativeHumidityInputTypeProb" value={relativeHumidityInputTypeProb} label="Relative Humidity Input Type">
                            <E2GSelectFieldOption label="Probabilistic" value="probabilistic" />
                            <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                          </E2GSelectField>
                        </div>
                      )
                      : ''}
                    {ambientTemperatureInputTypeProb === 'probabilistic'
                      ? (
                        <div>
                          <div className="pt3">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The average day time temperature is the average ambient temperature during the day over the course of the year.</p>
                              </div>}
                              dialog
                            > */}
                            <ProbabilisticInputField
                              name="temperatureAmProb"
                              jsonEditable
                              value={temperatureAmProb}
                              variableType="continuous"
                              InputProps={{
                                label: 'Temperature (Day) (50th Percentile)',
                                jsonKeyPath: 'median',
                                units: {
                                  USCustomary: 'F',
                                  Metric: 'C',
                                },
                                isNumber: false,
                                required: true,
                              }}
                              DialogProps={{
                                inputFields: [{
                                  label: 'Distribution Type',
                                  jsonKeyPath: 'distributionType',
                                  select: true,
                                  options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                }, {
                                  label: 'Variance Type',
                                  jsonKeyPath: 'varianceType',
                                  select: true,
                                  options(parsedJson) {
                                    if(parsedJson.distributionType === 'normal') {
                                      return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                    }
                                    return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                  },
                                }, {
                                  label: 'Standard Deviation',
                                  jsonKeyPath: 'standardDeviation',
                                  isNumber: true,
                                  units: {
                                    USCustomary: 'F',
                                    Metric: 'C',
                                  },
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'standardDeviation'
                                  },
                                }, {
                                  label: 'Upper Bound',
                                  jsonKeyPath: 'upperBound',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: 'F',
                                    Metric: 'C',
                                  },
                                }, {
                                  label: 'Upper Bound Probability',
                                  jsonKeyPath: 'upperBoundProbability',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: '%',
                                    Metric: '%',
                                  },
                                }, {
                                  label: 'Scale Parameter',
                                  jsonKeyPath: 'scale',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'scale'
                                  },
                                }, {
                                  label: 'CV (SD/Mean)',
                                  jsonKeyPath: 'cv',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'cv'
                                  },
                                }],
                              }}
                            />
                            {/* </E2GInfoButton> */}
                          </div>
                          <div className="pt3">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The average night time temperature is the average ambient temperature during the evening over the course of the year.</p>
                              </div>}
                              dialog
                            > */}
                            <ProbabilisticInputField
                              name="temperaturePmProb"
                              jsonEditable
                              value={temperaturePmProb}
                              variableType="continuous"
                              InputProps={{
                                label: 'Temperature (Night) (50th Percentile)',
                                jsonKeyPath: 'median',
                                units: {
                                  USCustomary: 'F',
                                  Metric: 'C',
                                },
                                isNumber: false,
                                required: true,
                              }}
                              DialogProps={{
                                inputFields: [{
                                  label: 'Distribution Type',
                                  jsonKeyPath: 'distributionType',
                                  select: true,
                                  options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                }, {
                                  label: 'Variance Type',
                                  jsonKeyPath: 'varianceType',
                                  select: true,
                                  options(parsedJson) {
                                    if(parsedJson.distributionType === 'normal') {
                                      return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                    }
                                    return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                  },
                                }, {
                                  label: 'Standard Deviation',
                                  jsonKeyPath: 'standardDeviation',
                                  isNumber: true,
                                  units: {
                                    USCustomary: 'F',
                                    Metric: 'C',
                                  },
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'standardDeviation'
                                  },
                                }, {
                                  label: 'Upper Bound',
                                  jsonKeyPath: 'upperBound',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: 'F',
                                    Metric: 'C',
                                  },
                                }, {
                                  label: 'Upper Bound Probability',
                                  jsonKeyPath: 'upperBoundProbability',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: '%',
                                    Metric: '%',
                                  },
                                }, {
                                  label: 'Scale Parameter',
                                  jsonKeyPath: 'scale',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'scale'
                                  },
                                }, {
                                  label: 'CV (SD/Mean)',
                                  jsonKeyPath: 'cv',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'cv'
                                  },
                                }],
                              }}
                            />
                            {/* </E2GInfoButton> */}
                          </div>
                        </div>
                      )
                      : <div>
                        <DataTableField
                          id="ambientTempHistory"
                          name="ambientTempHistory"
                          startRows={2}
                          containerHeight="400px"
                          value={ambientTempHistory}
                          rowHeaders={['1', '2']}
                          colHeaders={{ USCustomary: ['Hourly Ambient<br>Temperature History (F)'], Metric: ['Hourly Ambient<br>Temperature History (C)'] }}
                        />
                      </div>}
                    {runCondensation === true
                      ? (
                        <div>
                          {relativeHumidityInputTypeProb === 'probabilistic'
                            ? (
                              <div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The average day time relative humidty is the average relative humidty during the day over the course of the year.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="relativeHumidityAmProb"
                                    jsonEditable
                                    value={relativeHumidityAmProb}
                                    variableType="continuous"
                                    InputProps={{
                                      label: 'Relative Humidity (Day) (50th Percentile)',
                                      jsonKeyPath: 'median',
                                      units: {
                                        USCustomary: '%',
                                        Metric: '%',
                                      },
                                      isNumber: false,
                                      required: true,
                                    }}
                                    DialogProps={{
                                      inputFields: [{
                                        label: 'Distribution Type',
                                        jsonKeyPath: 'distributionType',
                                        select: true,
                                        options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                      }, {
                                        label: 'Variance Type',
                                        jsonKeyPath: 'varianceType',
                                        select: true,
                                        options(parsedJson) {
                                          if(parsedJson.distributionType === 'normal') {
                                            return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                          }
                                          return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                        },
                                      }, {
                                        label: 'Standard Deviation',
                                        jsonKeyPath: 'standardDeviation',
                                        isNumber: true,
                                        units: {
                                          USCustomary: '%',
                                          Metric: '%',
                                        },
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'standardDeviation'
                                        },
                                      }, {
                                        label: 'Upper Bound',
                                        jsonKeyPath: 'upperBound',
                                        isNumber: true,
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'upperBound'
                                        },
                                        units: {
                                          USCustomary: '%',
                                          Metric: '%',
                                        },
                                      }, {
                                        label: 'Upper Bound Probability',
                                        jsonKeyPath: 'upperBoundProbability',
                                        isNumber: true,
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'upperBound'
                                        },
                                        units: {
                                          USCustomary: '%',
                                          Metric: '%',
                                        },
                                      }, {
                                        label: 'Scale Parameter',
                                        jsonKeyPath: 'scale',
                                        isNumber: true,
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'scale'
                                        },
                                      }, {
                                        label: 'CV (SD/Mean)',
                                        jsonKeyPath: 'cv',
                                        isNumber: true,
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'cv'
                                        },
                                      }],
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                                <div className="pt3">
                                  {/* <E2GInfoButton
                                  tooltip={<div>
                                    <p>The average night time relative humidty is the average relative humidty during the evening over the course of the year.</p>
                                  </div>}
                                  dialog
                                > */}
                                  <ProbabilisticInputField
                                    name="relativeHumidityPmProb"
                                    jsonEditable
                                    value={relativeHumidityPmProb}
                                    variableType="continuous"
                                    InputProps={{
                                      label: 'Relative Humidity (Night) (50th Percentile)',
                                      jsonKeyPath: 'median',
                                      units: {
                                        USCustomary: '%',
                                        Metric: '%',
                                      },
                                      isNumber: false,
                                      required: true,
                                    }}
                                    DialogProps={{
                                      inputFields: [{
                                        label: 'Distribution Type',
                                        jsonKeyPath: 'distributionType',
                                        select: true,
                                        options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                      }, {
                                        label: 'Variance Type',
                                        jsonKeyPath: 'varianceType',
                                        select: true,
                                        options(parsedJson) {
                                          if(parsedJson.distributionType === 'normal') {
                                            return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                          }
                                          return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                        },
                                      }, {
                                        label: 'Standard Deviation',
                                        jsonKeyPath: 'standardDeviation',
                                        isNumber: true,
                                        units: {
                                          USCustomary: '%',
                                          Metric: '%',
                                        },
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'standardDeviation'
                                        },
                                      }, {
                                        label: 'Upper Bound',
                                        jsonKeyPath: 'upperBound',
                                        isNumber: true,
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'upperBound'
                                        },
                                        units: {
                                          USCustomary: '%',
                                          Metric: '%',
                                        },
                                      }, {
                                        label: 'Upper Bound Probability',
                                        jsonKeyPath: 'upperBoundProbability',
                                        isNumber: true,
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'upperBound'
                                        },
                                        units: {
                                          USCustomary: '%',
                                          Metric: '%',
                                        },
                                      }, {
                                        label: 'Scale Parameter',
                                        jsonKeyPath: 'scale',
                                        isNumber: true,
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'scale'
                                        },
                                      }, {
                                        label: 'CV (SD/Mean)',
                                        jsonKeyPath: 'cv',
                                        isNumber: true,
                                        hide(parsedJson) {
                                          return parsedJson.varianceType !== 'cv'
                                        },
                                      }],
                                    }}
                                  />
                                  {/* </E2GInfoButton> */}
                                </div>
                              </div>
                            )
                            : <div>
                              <DataTableField
                                id="relativeHumidityHistory"
                                name="relativeHumidityHistory"
                                startRows={2}
                                containerHeight="400px"
                                value={relativeHumidityHistory}
                                rowHeaders={['1', '2']}
                                colHeaders={{ USCustomary: ['Hourly Relative<br>Humidity History (%)'], Metric: ['Hourly Relative<br>Humidity History (%)'] }}
                              />
                            </div>}
                        </div>
                      )
                      : ''}
                  </div>}
              </div>
              <div className="fl pa2 w-50-m w-100">
                <div>
                  {probabilisticInputFieldFlag === false
                    ? (
                      <div>
                        {/* <E2GInfoButton
                          tooltip={<div>
                            <p>External environment refers to the potential of an environment to cause corrosion in materials, particularly metals. This is influenced by various factors like pollutants (i.e., presence of salts or acids) and the proximity to the sea (i.e., closer to the sea, the more salt in the air).</p>
                          </div>}
                          dialog
                        > */}
                        <E2GSelectField name="externalEnvironment1" value={externalEnvironment1} label="External Environment">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Water" value="Water" />
                          <E2GSelectFieldOption label="Non-Saline Cooling Tower" value="Non-Saline Cooling Tower" />
                          <E2GSelectFieldOption label="Low % Marine Salt" value="Low % Marine Salt" />
                          <E2GSelectFieldOption label="Low % Acid Rain" value="Low % Acid Rain" />
                          <E2GSelectFieldOption label="Saline Cooling Tower" value="Saline Cooling Tower" />
                          <E2GSelectFieldOption label="High % Marine Salt" value="High % Marine Salt" />
                          <E2GSelectFieldOption label="High % Acid Rain" value="High % Acid Rain" />
                        </E2GSelectField>
                        {/* </E2GInfoButton> */}
                        {activeUnitSystem !== 'Metric'
                          ? (
                            <div>
                              {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>The annual rainfall is the amount of precipitation that falls in a specific location over the course of a year.</p>
                                </div>}
                                dialog
                              > */}
                              <E2GSelectField name="annualRainfall2" value={annualRainfall2} label="Annual Rainfall">
                                <E2GSelectFieldOption label="Unknown" value="unknown" />
                                <E2GSelectFieldOption label="None (In Building)" value="None (In Building)" />
                                <E2GSelectFieldOption label="Indirect (Sheltered)" value="Indirect (Sheltered)" />
                                <E2GSelectFieldOption label="<15in" value="<15in" />
                                <E2GSelectFieldOption label="15-30in" value="15-30in" />
                                <E2GSelectFieldOption label="30-60in" value="30-60in" />
                                <E2GSelectFieldOption label=">60in" value=">60in" />
                              </E2GSelectField>
                              {/* </E2GInfoButton> */}
                            </div>
                          )
                          : <div>
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>The annual rainfall is the amount of precipitation that falls in a specific location over the course of a year.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="annualRainfall2" value={annualRainfall2} label="Annual Rainfall">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="None (In Building)" value="None (In Building)" />
                              <E2GSelectFieldOption label="Indirect (Sheltered)" value="Indirect (Sheltered)" />
                              <E2GSelectFieldOption label="<381mm" value="<15in" />
                              <E2GSelectFieldOption label="381-762mm" value="15-30in" />
                              <E2GSelectFieldOption label="762-1524mm" value="30-60in" />
                              <E2GSelectFieldOption label=">1524mm" value=">60in" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>}
                        {/* <E2GInfoButton
                          tooltip={<div>
                            <p>Average annual wind speed is the measure of how fast air is moving from one place to another over the course of the year.</p>
                          </div>}
                          dialog
                        > */}
                        <E2GSelectField name="windSpeedFraction1" value={windSpeedFraction1} label="Average Annual Wind Speed (mph)">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="0 to 30" value="0 to 30" />
                          <E2GSelectFieldOption label="30 to 60" value="30 to 60" />
                          <E2GSelectFieldOption label="60 to 100" value="60 to 100" />
                        </E2GSelectField>
                        {/* </E2GInfoButton> */}
                        {activeUnitSystem !== 'Metric'
                          ? (
                            <div>
                              {/* <E2GInfoButton
                                tooltip={<div>
                                  <p>Elevation refers to the height above sea level that the pipeline resides.</p>
                                </div>}
                                dialog
                              > */}
                              <E2GSelectField name="elevation" value={elevation} label="Elevation (ft)">
                                <E2GSelectFieldOption label="Unknown" value="unknown" />
                                <E2GSelectFieldOption label="0 to 50" value="0 to 50" />
                                <E2GSelectFieldOption label="50 to 100" value="50 to 100" />
                                <E2GSelectFieldOption label="100 to 150" value="100 to 150" />
                                <E2GSelectFieldOption label="150 to 200" value="150 to 200" />
                                <E2GSelectFieldOption label="200 to 250" value="200 to 250" />
                                <E2GSelectFieldOption label="250 to 300" value="250 to 300" />
                              </E2GSelectField>
                              {/* </E2GInfoButton> */}
                            </div>
                          )
                          : <div>
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Elevation refers to the height above sea level that the pipeline resides.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GSelectField name="elevation" value={elevation} label="Elevation (m)">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="0 to 15.24" value="0 to 50" />
                              <E2GSelectFieldOption label="15.24 to 30.48" value="50 to 100" />
                              <E2GSelectFieldOption label="30.48 to 45.72" value="100 to 150" />
                              <E2GSelectFieldOption label="45.72 to 60.96" value="150 to 200" />
                              <E2GSelectFieldOption label="60.96 to 76.2" value="200 to 250" />
                              <E2GSelectFieldOption label="76.2 to 91.44" value="250 to 300" />
                            </E2GSelectField>
                            {/* </E2GInfoButton> */}
                          </div>}
                        {/* <E2GInfoButton
                          tooltip={<div>
                            <p>In the context of insulation systems, pH refers to the measure of acidity or alkalinity of the materials used. This is important because the pH level can affect the material's performance and longevity, especially when moisture is present. For instance, an insulation material with an inappropriate pH level might contribute to corrosion of the system it is meant to protect.</p>
                          </div>}
                          dialog
                        > */}
                        <E2GSelectField name="internalPh" value={internalPh} label="Insulation System pH">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Alkaline (<6)" value="Alkaline)" />
                          <E2GSelectFieldOption label="Neutral (6-8)" value="Neutral)" />
                          <E2GSelectFieldOption label="Acidic (>8)" value="Acidic" />
                        </E2GSelectField>
                        {/* </E2GInfoButton> */}
                        {/* <E2GInfoButton
                          tooltip={<div>
                            <p>Steam vent exposure refers to the potential risks and effects of coming into contact with steam released from a vent.</p>
                          </div>}
                          dialog
                        > */}
                        <E2GSelectField name="steamVentExposure" value={steamVentExposure} label="Steam Vent Exposure">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Yes" value="Yes" />
                          <E2GSelectFieldOption label="No" value="No" />
                        </E2GSelectField>
                        {/* </E2GInfoButton> */}
                        {/* <E2GInfoButton
                          tooltip={<div>
                            <p>Other types of direct exposure could be the following:</p>
                            <li>Chemical exposure (i.e., hydrocarbons, solvents, and other substances used in pipeline operations)</li>
                            <li>Physical exposure (i.e., high-pressure systems, extreme temperatures, and mechanical hazards from equipment)</li>
                            <li>Environmental exposure (i.e., harsh weather conditions, wildlife, and natural disasters)</li>
                            <li>Biological exposure (i.e., contact with contaminated soil or water, exposure to bacteria and other pathogens, etc.)</li>
                            <li>Radiation exposure (i.e., transporation of radioactive materials)</li>
                          </div>}
                          dialog
                        > */}
                        <E2GSelectField name="otherDirectExposure" value={otherDirectExposure} label="Other Direct Exposure">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Yes" value="Yes" />
                          <E2GSelectFieldOption label="No" value="No" />
                        </E2GSelectField>
                        {/* </E2GInfoButton> */}
                        {/* <E2GInfoButton
                          tooltip={<div>
                            <p>UV exposure can impact pipelines, particularly those that are above ground and exposed to sunlight for extended periods. Some key points are the following:</p>
                            <li><strong>Degradation of Coatings</strong>: UV radiation from the sun can degrade the protective coatings on pipelines, making them more susceptible to corrosion. This degradation can lead to leaks, spills, or even catastrophic failures.</li>
                            <li><strong>Material Vulnerability</strong>: Certain materials, like PVC, can be particularly vulnerable to UV radiation. To mitigate this, additives are often included in the material formulation to inhibit UV damage.</li>
                            <li><strong>Epoxy Coatings</strong>: Fusion bonded epoxy (FBE) coatings, commonly used for pipeline protection, are designed for underground service. When exposed to UV light, these coatings can undergo photooxidation, leading to polymer degradation.</li>
                            <li><strong>Preventive Measures</strong>: To protect pipelines from UV exposure, measures such as using UV-resistant coatings, burying pipelines, or applying additional protective layers can be employed.</li>
                          </div>}
                          dialog
                        > */}
                        <E2GSelectField name="uvExposure" value={uvExposure} label="UV Exposure">
                          <E2GSelectFieldOption label="Unknown" value="unknown" />
                          <E2GSelectFieldOption label="Low" value="Low" />
                          <E2GSelectFieldOption label="Moderate" value="Moderate" />
                          <E2GSelectFieldOption label="High" value="High" />
                        </E2GSelectField>
                        {/* </E2GInfoButton> */}
                      </div>
                    )
                    : ''}
                  {probabilisticInputFieldFlag === true
                    ? (
                      <div>
                        <div className="pt3">
                          {/* <E2GInfoButton
                          tooltip={<div>
                            <p>External environment refers to the potential of an environment to cause corrosion in materials, particularly metals. This is influenced by various factors like pollutants (i.e., presence of salts or acids) and the proximity to the sea (i.e., closer to the sea, the more salt in the air).</p>
                          </div>}
                          dialog
                        > */}
                          <ProbabilisticInputField
                            name="externalEnvironment1Prob"
                            jsonEditable
                            value={externalEnvironment1Prob}
                            variableType="discrete"
                            InputProps={{
                              label: 'External Environment',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                          {/* </E2GInfoButton> */}
                        </div>
                        <div className="pt3">
                          {/* <E2GInfoButton
                          tooltip={<div>
                            <p>The annual rainfall is the amount of precipitation that falls in a specific location over the course of a year.</p>
                          </div>}
                          dialog
                        > */}
                          <ProbabilisticInputField
                            name="annualRainfall2Prob"
                            jsonEditable
                            value={annualRainfall2Prob}
                            variableType="discrete"
                            InputProps={{
                              label: 'Annual Rainfall',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                          {/* </E2GInfoButton> */}
                        </div>
                        <div className="pt3">
                          {/* <E2GInfoButton
                          tooltip={<div>
                            <p>Average annual wind speed is the measure of how fast air is moving from one place to another over the course of the year.</p>
                          </div>}
                          dialog
                        > */}
                          <ProbabilisticInputField
                            name="windSpeedFraction1Prob"
                            jsonEditable
                            value={windSpeedFraction1Prob}
                            variableType="discrete"
                            InputProps={{
                              label: 'Average Annual Wind Speed (mph)',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                          {/* </E2GInfoButton> */}
                        </div>
                        <div className="pt3">
                          {/* <E2GInfoButton
                          tooltip={<div>
                            <p>Elevation refers to the height above sea level that the pipeline resides.</p>
                          </div>}
                          dialog
                        > */}
                          <ProbabilisticInputField
                            name="elevationProb"
                            jsonEditable
                            value={elevationProb}
                            variableType="discrete"
                            InputProps={{
                              label: 'Elevation (ft)',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                          {/* </E2GInfoButton> */}
                        </div>
                        <div className="pt3">
                          {/* <E2GInfoButton
                          tooltip={<div>
                            <p>In the context of insulation systems, pH refers to the measure of acidity or alkalinity of the materials used. This is important because the pH level can affect the material's performance and longevity, especially when moisture is present. For instance, an insulation material with an inappropriate pH level might contribute to corrosion of the system it is meant to protect.</p>
                          </div>}
                          dialog
                        > */}
                          <ProbabilisticInputField
                            name="internalPhProb"
                            jsonEditable
                            value={internalPhProb}
                            variableType="discrete"
                            InputProps={{
                              label: 'Insulation System pH',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                          {/* </E2GInfoButton> */}
                        </div>
                        <div className="pt3">
                          {/* <E2GInfoButton
                          tooltip={<div>
                            <p>Steam vent exposure refers to the potential risks and effects of coming into contact with steam released from a vent.</p>
                          </div>}
                          dialog
                        > */}
                          <ProbabilisticInputField
                            name="steamVentExposureProb"
                            jsonEditable
                            value={steamVentExposureProb}
                            variableType="discrete"
                            InputProps={{
                              label: 'Steam Vent Exposure',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                          {/* </E2GInfoButton> */}
                        </div>
                        <div className="pt3">
                          {/* <E2GInfoButton
                          tooltip={<div>
                            <p>Other types of direct exposure could be the following:</p>
                            <li>Chemical exposure (i.e., hydrocarbons, solvents, and other substances used in pipeline operations)</li>
                            <li>Physical exposure (i.e., high-pressure systems, extreme temperatures, and mechanical hazards from equipment)</li>
                            <li>Environmental exposure (i.e., harsh weather conditions, wildlife, and natural disasters)</li>
                            <li>Biological exposure (i.e., contact with contaminated soil or water, exposure to bacteria and other pathogens, etc.)</li>
                            <li>Radiation exposure (i.e., transporation of radioactive materials)</li>
                          </div>}
                          dialog
                        > */}
                          <ProbabilisticInputField
                            name="otherDirectExposureProb"
                            jsonEditable
                            value={otherDirectExposureProb}
                            variableType="discrete"
                            InputProps={{
                              label: 'Other Direct Exposure',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                          {/* </E2GInfoButton> */}
                        </div>
                        <div className="pt3">
                          {/* <E2GInfoButton
                          tooltip={<div>
                            <p>UV exposure can impact pipelines, particularly those that are above ground and exposed to sunlight for extended periods. Some key points are the following:</p>
                            <li><strong>Degradation of Coatings</strong>: UV radiation from the sun can degrade the protective coatings on pipelines, making them more susceptible to corrosion. This degradation can lead to leaks, spills, or even catastrophic failures.</li>
                            <li><strong>Material Vulnerability</strong>: Certain materials, like PVC, can be particularly vulnerable to UV radiation. To mitigate this, additives are often included in the material formulation to inhibit UV damage.</li>
                            <li><strong>Epoxy Coatings</strong>: Fusion bonded epoxy (FBE) coatings, commonly used for pipeline protection, are designed for underground service. When exposed to UV light, these coatings can undergo photooxidation, leading to polymer degradation.</li>
                            <li><strong>Preventive Measures</strong>: To protect pipelines from UV exposure, measures such as using UV-resistant coatings, burying pipelines, or applying additional protective layers can be employed.</li>
                          </div>}
                          dialog
                        > */}
                          <ProbabilisticInputField
                            name="uvExposureProb"
                            jsonEditable
                            value={uvExposureProb}
                            variableType="discrete"
                            InputProps={{
                              label: 'UV Exposure',
                              jsonKeyPath: 'median',
                              isNumber: false,
                              required: true,
                            }}
                          />
                          {/* </E2GInfoButton> */}
                        </div>
                      </div>
                    )
                    : ''}
                </div>
              </div>
              <div className="fl w-25-l w-50-m w-100">
                {probabilisticInputFieldFlag === false
                  ? (
                    <div>
                      {ambientTemperatureInputType === 'constant' || (relativeHumidityInputType === 'constant' && runCondensation === true)
                        ? (
                          <div>
                            <E2GSelectField name="condensationParametersVarianceType" value={condensationParametersVarianceType} label="Select Variance Type">
                              <E2GSelectFieldOption label="Confidence" value="Confidence" />
                              <E2GSelectFieldOption label="Standard Deviation" value="Standard Deviation" />
                              <E2GSelectFieldOption label="CV" value="CV" />
                            </E2GSelectField>
                            {runCondensation === true && relativeHumidityInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv2 w-100">
                                    {/* <E2GInfoButton
                                      tooltip={<div>
                                        <p>The average day time relative humidty is the average relative humidty during the day over the course of the year.</p>
                                      </div>}
                                      dialog
                                    > */}

                                    {/* </E2GInfoButton> */}
                                  </div>
                                  <div className="cf mv2 w-100">
                                    {/* <E2GInfoButton
                                      tooltip={<div>
                                        <p>The average night time relative humidty is the average relative humidty during the evening over the course of the year.</p>
                                      </div>}
                                      dialog
                                    > */}

                                    {/* </E2GInfoButton> */}
                                  </div>
                                </div>
                              )
                              : ''}
                            {ambientTemperatureInputType === 'constant'
                              ? (
                                <div>
                                  {condensationParametersVarianceType === 'Confidence'
                                    ? (
                                      <div>
                                        <E2GSelectField name="temperatureAmConfidence" value={temperatureAmConfidence} label="Average Temperature (Day) Confidence">
                                          <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                          <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                          <E2GSelectFieldOption label="High (90%)" value="High" />
                                        </E2GSelectField>
                                        <E2GSelectField name="temperaturePmConfidence" value={temperaturePmConfidence} label="Average Temperature (Night) Confidence">
                                          <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                          <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                          <E2GSelectFieldOption label="High (90%)" value="High" />
                                        </E2GSelectField>
                                      </div>
                                    )
                                    : ''}
                                  {condensationParametersVarianceType === 'Standard Deviation'
                                    ? (
                                      <div>
                                        <div className="cf mv2 w-100">
                                          <E2GTextField name="temperatureAmSD" value={temperatureAmSD} label="Temperature (Day) Standard Deviation" type="number" />
                                        </div>
                                        <div className="cf mv2 w-100">
                                          <E2GTextField name="temperaturePmSD" value={temperaturePmSD} label="Temperature (Night) Standard Deviation" type="number" />
                                        </div>
                                      </div>
                                    )
                                    : ''}
                                  {condensationParametersVarianceType === 'CV'
                                    ? (
                                      <div>
                                        <div className="cf mv2 w-100">
                                          <E2GTextField name="temperatureAmCV" value={temperatureAmCV} label="Temperature (Day) CV" type="number" />
                                        </div>
                                        <div className="cf mv2 w-100">
                                          <E2GTextField name="temperaturePmCV" value={temperaturePmCV} label="Temperature (Night) CV" type="number" />
                                        </div>
                                      </div>
                                    )
                                    : ''}
                                </div>
                              )
                              : ''}
                            {runCondensation === true && relativeHumidityInputType === 'constant'
                              ? (
                                <div>
                                  {condensationParametersVarianceType === 'Confidence'
                                    ? (
                                      <div>
                                        <E2GSelectField name="relativeHumidityAmConfidence" value={relativeHumidityAmConfidence} label="Average Relative Humidity (Day) Confidence">
                                          <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                          <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                          <E2GSelectFieldOption label="High (90%)" value="High" />
                                        </E2GSelectField>
                                        <E2GSelectField name="relativeHumidityPmConfidence" value={relativeHumidityPmConfidence} label="Average Relative Humidity (Night) Confidence">
                                          <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                          <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                          <E2GSelectFieldOption label="High (90%)" value="High" />
                                        </E2GSelectField>
                                      </div>
                                    )
                                    : ''}
                                  {condensationParametersVarianceType === 'Standard Deviation'
                                    ? (
                                      <div>
                                        <div className="cf mv2 w-100">
                                          <E2GTextField name="relativeHumidityAmSD" value={relativeHumidityAmSD} label="Relative Humidity (Day) Standard Deviation" type="number" />
                                        </div>
                                        <div className="cf mv2 w-100">
                                          <E2GTextField name="relativeHumidityPmSD" value={relativeHumidityPmSD} label="Relative Humidity (Night) Standard Deviation" type="number" />
                                        </div>
                                      </div>
                                    )
                                    : ''}
                                  {condensationParametersVarianceType === 'CV'
                                    ? (
                                      <div>
                                        <div className="cf mv2 w-100">
                                          <E2GTextField name="relativeHumidityAmCV" value={relativeHumidityAmCV} label="Relative Humidity (Day) CV" type="number" />
                                        </div>
                                        <div className="cf mv2 w-100">
                                          <E2GTextField name="relativeHumidityPmCV" value={relativeHumidityPmCV} label="Relative Humidity (Night) CV" type="number" />
                                        </div>
                                      </div>
                                    )
                                    : ''}
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
            </div>
          )
          : ''}
        {problemType === 'PROB2'
          ? (
            <div className="fl w-100">
              <div className="fl w-25-l w-50-m w-100">
                <E2GSelectField name="ambientTemperatureInputType" value={ambientTemperatureInputType} label="Ambient Temperature Input Type">
                  <E2GSelectFieldOption label="Constant" value="constant" />
                  <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                </E2GSelectField>
                {runCondensation === true
                  ? (
                    <div>
                      <E2GSelectField name="relativeHumidityInputType" value={relativeHumidityInputType} label="Relative Humidity Input Type">
                        <E2GSelectFieldOption label="Constant" value="constant" />
                        <E2GSelectFieldOption label="Hourly Time-History" value="temporal" />
                      </E2GSelectField>
                    </div>
                  )
                  : ''}
                {ambientTemperatureInputType === 'constant'
                  ? (
                    <div>
                      {activeUnitSystem !== 'Metric'
                        ? (
                          <div>
                            <E2GSelectField name="ambientTemperature" value={ambientTemperature} label="Average Annual Ambient Temperature (°F)">
                              <E2GSelectFieldOption label="Unknown" value="unknown" />
                              <E2GSelectFieldOption label="Low (<40F)" value="Low" />
                              <E2GSelectFieldOption label="Moderate (40-60F)" value="Moderate" />
                              <E2GSelectFieldOption label="High (>60F)" value="High" />
                            </E2GSelectField>
                          </div>
                        )
                        : <div>
                          <E2GSelectField name="ambientTemperature" value={ambientTemperature} label="Average Annual Ambient Temperature (°C)">
                            <E2GSelectFieldOption label="Unknown" value="unknown" />
                            <E2GSelectFieldOption label="Low (<5C)" value="Low" />
                            <E2GSelectFieldOption label="Moderate (5-15C)" value="Moderate" />
                            <E2GSelectFieldOption label="High (>15C)" value="High" />
                          </E2GSelectField>
                        </div>}
                    </div>
                  )
                  : ''}
                {ambientTemperatureInputType === 'temporal'
                  ? (
                    <div>
                      <DataTableField
                        id="ambientTempHistory"
                        name="ambientTempHistory"
                        startRows={2}
                        containerHeight="400px"
                        value={ambientTempHistory}
                        rowHeaders={['1', '2']}
                        colHeaders={{ USCustomary: ['Hourly Ambient<br>Temperature History (F)'], Metric: ['Hourly Ambient<br>Temperature History (C)'] }}
                      />
                    </div>
                  )
                  : ''}
                {runCondensation === true
                  ? (
                    <div>
                      {relativeHumidityInputType === 'temporal'
                        ? (
                          <div>
                            <DataTableField
                              id="relativeHumidityHistory"
                              name="relativeHumidityHistory"
                              startRows={2}
                              containerHeight="400px"
                              value={relativeHumidityHistory}
                              rowHeaders={['1', '2']}
                              colHeaders={{ USCustomary: ['Hourly Relative<br>Humidity History (%)'], Metric: ['Hourly Relative<br>Humidity History (%)'] }}
                            />
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}

                <E2GSelectField name="externalEnvironment" value={externalEnvironment} label="External Environment">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Water" value="water" />
                  <E2GSelectFieldOption label="Non-Saline Cooling Tower" value="nonSalineCoolingTower" />
                  <E2GSelectFieldOption label="Low % Marine Salt" value="lowMarineSalt" />
                  <E2GSelectFieldOption label="Low % Acid Rain" value="lowAcidRain" />
                  <E2GSelectFieldOption label="Saline Cooling Tower" value="salineCoolingTower" />
                  <E2GSelectFieldOption label="High % Marine Salt" value="highMarineSalt" />
                  <E2GSelectFieldOption label="High % Acid Rain" value="highAcidRain" />
                </E2GSelectField>
                {activeUnitSystem !== 'Metric'
                  ? (
                    <div>
                      <E2GSelectField name="annualRainfall1" value={annualRainfall1} label="Annual Rainfall">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="None (In Building)" value="None" />
                        <E2GSelectFieldOption label="Indirect (Sheltered)" value="Indirect" />
                        <E2GSelectFieldOption label="<15in" value="<15in" />
                        <E2GSelectFieldOption label="15-30in" value="15-30in" />
                        <E2GSelectFieldOption label="30-60in" value="30-60in" />
                        <E2GSelectFieldOption label=">60in" value=">60in" />
                      </E2GSelectField>
                    </div>)
                  : <div>
                    <E2GSelectField name="annualRainfall1" value={annualRainfall1} label="Annual Rainfall">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="None (In Building)" value="None" />
                      <E2GSelectFieldOption label="Indirect (Sheltered)" value="Indirect" />
                      <E2GSelectFieldOption label="<381mm" value="<15in" />
                      <E2GSelectFieldOption label="381-762mm" value="15-30in" />
                      <E2GSelectFieldOption label="762-1524mm" value="30-60in" />
                      <E2GSelectFieldOption label=">1524mm" value=">60in" />
                    </E2GSelectField>
                  </div>}
              </div>
              <div className="fl pa2 w-25-l w-50-m w-100">
                <E2GSelectField name="windSpeedFraction1" value={windSpeedFraction1} label="Average Annual Wind Speed (mph)">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="0 to 30" value="0 to 30" />
                  <E2GSelectFieldOption label="30 to 60" value="30 to 60" />
                  <E2GSelectFieldOption label="60 to 100" value="60 to 100" />
                </E2GSelectField>
                {activeUnitSystem !== 'Metric'
                  ? (
                    <div>
                      <E2GSelectField name="elevation" value={elevation} label="Elevation (ft)">
                        <E2GSelectFieldOption label="Unknown" value="unknown" />
                        <E2GSelectFieldOption label="0 to 50" value="0 to 50" />
                        <E2GSelectFieldOption label="50 to 100" value="50 to 100" />
                        <E2GSelectFieldOption label="100 to 150" value="100 to 150" />
                        <E2GSelectFieldOption label="150 to 200" value="150 to 200" />
                        <E2GSelectFieldOption label="200 to 250" value="200 to 250" />
                        <E2GSelectFieldOption label="250 to 300" value="250 to 300" />
                      </E2GSelectField>
                    </div>
                  )
                  : <div>
                    <E2GSelectField name="elevation" value={elevation} label="Elevation (m)">
                      <E2GSelectFieldOption label="Unknown" value="unknown" />
                      <E2GSelectFieldOption label="0 to 15.24" value="0 to 50" />
                      <E2GSelectFieldOption label="15.24 to 30.48" value="50 to 100" />
                      <E2GSelectFieldOption label="30.48 to 45.72" value="100 to 150" />
                      <E2GSelectFieldOption label="45.72 to 60.96" value="150 to 200" />
                      <E2GSelectFieldOption label="60.96 to 76.2" value="200 to 250" />
                      <E2GSelectFieldOption label="76.2 to 91.44" value="250 to 300" />
                    </E2GSelectField>
                  </div>}
                <E2GSelectField name="internalPh" value={internalPh} label="Insulation System pH">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Alkaline (<6)" value="Alkaline" />
                  <E2GSelectFieldOption label="Neutral (6-8)" value="Neutral" />
                  <E2GSelectFieldOption label="Acidic (>8)" value="Acidic" />
                </E2GSelectField>
                <E2GSelectField name="steamVentExposure" value={steamVentExposure} label="Steam Vent Exposure">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                <E2GSelectField name="otherDirectExposure" value={otherDirectExposure} label="Other Direct Exposure">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Yes" value="Yes" />
                  <E2GSelectFieldOption label="No" value="No" />
                </E2GSelectField>
                <E2GSelectField name="uvExposure" value={uvExposure} label="UV Exposure">
                  <E2GSelectFieldOption label="Unknown" value="unknown" />
                  <E2GSelectFieldOption label="Low" value="Low" />
                  <E2GSelectFieldOption label="Moderate" value="Moderate" />
                  <E2GSelectFieldOption label="High" value="High" />
                </E2GSelectField>
              </div>
              <div className="fl w-25-l w-50-m w-100">
                {runCondensation === true
                  ? (
                    <div>
                      {ambientTemperatureInputType === 'constant'
                        ? (
                          <div>
                            <div className="cf mv2 w-100">
                              <E2GTextField name="temperatureSummer" value={temperatureSummer} label="Average Summer Temperature" type="number" />
                            </div>
                            <div className="cf mv3 w-100">
                              <E2GTextField name="temperatureFall" value={temperatureFall} label="Average Fall Temperature" type="number" />
                            </div>
                            <div className="cf mv3 w-100">
                              <E2GTextField name="temperatureWinter" value={temperatureWinter} label="Average Winter Temperature" type="number" />
                            </div>
                            <div className="cf mv3 w-100">
                              <E2GTextField name="temperatureSpring" value={temperatureSpring} label="Average Spring Temperature" type="number" />
                            </div>
                          </div>
                        )
                        : ''}
                      {relativeHumidityInputType === 'constant'
                        ? (
                          <div>
                            <div className="cf mv3 w-100">
                              <E2GTextField name="relativeHumidity" value={relativeHumidity} label="Average Relative Humidity" type="number" />
                            </div>
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
              <div className="fl w-25-l w-50-m w-100">
                {runCondensation === true
                  ? (
                    <div>
                      {ambientTemperatureInputType === 'constant' && relativeHumidityInputType === 'constant'
                        ? (
                          <div>
                            <E2GSelectField name="condensationParametersVarianceType" value={condensationParametersVarianceType} label="Select Variance Type">
                              <E2GSelectFieldOption label="Confidence" value="Confidence" />
                              <E2GSelectFieldOption label="Standard Deviation" value="Standard Deviation" />
                              <E2GSelectFieldOption label="CV" value="CV" />
                            </E2GSelectField>
                          </div>
                        )
                        : ''}
                      {condensationParametersVarianceType === 'Confidence'
                        ? (
                          <div>
                            {ambientTemperatureInputType === 'constant'
                              ? (
                                <div>
                                  <E2GSelectField name="temperatureSummerConfidence" value={temperatureSummerConfidence} label="Average Summer Temperature Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                  <E2GSelectField name="temperatureFallConfidence" value={temperatureFallConfidence} label="Average Fall Temperature Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                  <E2GSelectField name="temperatureWinterConfidence" value={temperatureWinterConfidence} label="Average Winter Temperature Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                  <E2GSelectField name="temperatureSpringConfidence" value={temperatureSpringConfidence} label="Average Spring Temperature Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                </div>
                              )
                              : ''}
                            {relativeHumidityInputType === 'constant'
                              ? (
                                <div>
                                  <E2GSelectField name="relativeHumidityConfidence" value={temperatureSpringConfidence} label="Average Relative Humidity Confidence">
                                    <E2GSelectFieldOption label="Low (50%)" value="Low" />
                                    <E2GSelectFieldOption label="Moderate (70%)" value="Moderate" />
                                    <E2GSelectFieldOption label="High (90%)" value="High" />
                                  </E2GSelectField>
                                </div>
                              )
                              : ''}
                          </div>

                        )
                        : ''}
                      {condensationParametersVarianceType === 'Standard Deviation'
                        ? (
                          <div>
                            {ambientTemperatureInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv2 w-100">
                                    <E2GTextField name="temperatureSummerSD" value={temperatureSummerSD} label="Summer Temperature Standard Deviation" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureFallSD" value={temperatureFallSD} label="Fall Temperature Standard Deviation" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureWinterSD" value={temperatureWinterSD} label="Winter Temperature Standard Deviation" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureSpringSD" value={temperatureSpringSD} label="Spring Temperature Standard Deviation" type="number" />
                                  </div>
                                </div>
                              )
                              : ''}
                            {relativeHumidityInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="relativeHumiditySD" value={relativeHumiditySD} label="Relative Humidity Standard Deviation" type="number" />
                                  </div>
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                      {condensationParametersVarianceType === 'CV'
                        ? (
                          <div>
                            {ambientTemperatureInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv2 w-100">
                                    <E2GTextField name="temperatureSummerCV" value={temperatureSummerCV} label="Summer Temperature CV" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureFallCV" value={temperatureFallCV} label="Fall Temperature CV" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureWinterCV" value={temperatureWinterCV} label="Winter Temperature CV" type="number" />
                                  </div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="temperatureSpringCV" value={temperatureSpringCV} label="Spring Temperature CV" type="number" />
                                  </div>
                                </div>
                              )
                              : ''}
                            {relativeHumidityInputType === 'constant'
                              ? (
                                <div>
                                  <div className="cf mv3 w-100">
                                    <E2GTextField name="relativeHumidityCV" value={relativeHumidityCV} label="Relative Humidity CV" type="number" />
                                  </div>
                                </div>
                              )
                              : ''}
                          </div>
                        )
                        : ''}
                    </div>
                  )
                  : ''}
              </div>
            </div>
          )
          : ''}
      </div>
      <Submit label="Next" />
    </div>
  )
}

Step1.propTypes = {
  problemType: PropTypes.string,
  activeUnitSystem: PropTypes.string,
  environment: PropTypes.string,
  environmentConfidence: PropTypes.string,
  annualRainfall: PropTypes.string,
  annualRainfall1: PropTypes.string,
  environmentCorrosivity: PropTypes.string,
  uvExposure: PropTypes.string,
  ozoneExposure: PropTypes.string,
  highWindLikelihood: PropTypes.string,
  ambientTemperature: PropTypes.string,
  steamVentExposure: PropTypes.string,
  otherDirectExposure: PropTypes.string,
  elevation: PropTypes.string,
  windSpeedFraction1: PropTypes.string,
  externalEnvironment: PropTypes.string,
  externalEnvironment1: PropTypes.string,
  internalPh: PropTypes.string,
  exposureConfidence: PropTypes.string,
  expertExposureConfidence: PropTypes.string,
  expertExposureFractionMean: PropTypes.number,
  expertExposureConfidenceKnown: PropTypes.string,
  runCondensation: PropTypes.bool,
  temperatureSummer: PropTypes.number,
  temperatureFall: PropTypes.number,
  temperatureSpring: PropTypes.number,
  temperatureWinter: PropTypes.number,
  relativeHumidity: PropTypes.number,
  temperatureSummerSD: PropTypes.number,
  temperatureFallSD: PropTypes.number,
  temperatureSpringSD: PropTypes.number,
  temperatureWinterSD: PropTypes.number,
  relativeHumiditySD: PropTypes.number,
  temperatureSummerCV: PropTypes.number,
  temperatureFallCV: PropTypes.number,
  temperatureSpringCV: PropTypes.number,
  temperatureWinterCV: PropTypes.number,
  relativeHumidityCV: PropTypes.number,
  ambientTemperatureInputType: PropTypes.string,
  ambientTempHistory: PropTypes.array,
  relativeHumidityHistory: PropTypes.array,
  annualRainfall2: PropTypes.string,
  temperatureAm: PropTypes.number,
  temperaturePm: PropTypes.number,
  relativeHumidityAm: PropTypes.number,
  relativeHumidityPm: PropTypes.number,
  temperatureSummerConfidence: PropTypes.string,
  temperatureFallConfidence: PropTypes.string,
  temperatureWinterConfidence: PropTypes.string,
  temperatureSpringConfidence: PropTypes.string,
  relativeHumidityConfidence: PropTypes.string,
  temperatureAmConfidence: PropTypes.string,
  temperaturePmConfidence: PropTypes.string,
  relativeHumidityAmConfidence: PropTypes.string,
  relativeHumidityPmConfidence: PropTypes.string,
  condensationParametersVarianceType: PropTypes.string,
  temperatureAmSD: PropTypes.number,
  temperaturePmSD: PropTypes.number,
  relativeHumidityAmSD: PropTypes.number,
  relativeHumidityPmSD: PropTypes.number,
  temperatureAmCV: PropTypes.number,
  temperaturePmCV: PropTypes.number,
  relativeHumidityAmCV: PropTypes.number,
  relativeHumidityPmCV: PropTypes.number,
  relativeHumidityInputType: PropTypes.string,
  probabilisticInputFieldFlag: PropTypes.bool,
  externalEnvironment1Prob: PropTypes.string,
  ambientTemperatureInputTypeProb: PropTypes.string,
  relativeHumidityInputTypeProb: PropTypes.string,
  temperatureAmProb: PropTypes.string,
  temperaturePmProb: PropTypes.string,
  relativeHumidityAmProb: PropTypes.string,
  relativeHumidityPmProb: PropTypes.string,
  annualRainfall2Prob: PropTypes.string,
  windSpeedFraction1Prob: PropTypes.string,
  elevationProb: PropTypes.string,
  internalPhProb: PropTypes.string,
  steamVentExposureProb: PropTypes.string,
  otherDirectExposureProb: PropTypes.string,
  uvExposureProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  activeUnitSystem: state.workflow.activeUnitSystem,
  environment: state.workflow.fields.environment,
  environmentConfidence: state.workflow.fields.environmentConfidence,
  annualRainfall: state.workflow.fields.annualRainfall,
  annualRainfall1: state.workflow.fields.annualRainfall1,
  environmentCorrosivity: state.workflow.fields.environmentCorrosivity,
  uvExposure: state.workflow.fields.uvExposure,
  ozoneExposure: state.workflow.fields.ozoneExposure,
  highWindLikelihood: state.workflow.fields.highWindLikelihood,
  ambientTemperature: state.workflow.fields.ambientTemperature,
  steamVentExposure: state.workflow.fields.steamVentExposure,
  otherDirectExposure: state.workflow.fields.otherDirectExposure,
  elevation: state.workflow.fields.elevation,
  windSpeedFraction1: state.workflow.fields.windSpeedFraction1,
  externalEnvironment: state.workflow.fields.externalEnvironment,
  externalEnvironment1: state.workflow.fields.externalEnvironment1,
  internalPh: state.workflow.fields.internalPh,
  exposureConfidence: state.workflow.fields.exposureConfidence,
  expertExposureConfidence: state.workflow.fields.expertExposureConfidence,
  expertExposureFractionMean: state.workflow.fields.expertExposureFractionMean,
  expertExposureConfidenceKnown: state.workflow.fields.expertExposureConfidenceKnown,
  runCondensation: state.workflow.fields.runCondensation,
  temperatureSummer: state.workflow.fields.temperatureSummer,
  temperatureFall: state.workflow.fields.temperatureFall,
  temperatureSpring: state.workflow.fields.temperatureSpring,
  temperatureWinter: state.workflow.fields.temperatureWinter,
  relativeHumidity: state.workflow.fields.relativeHumidity,
  temperatureSummerSD: state.workflow.fields.temperatureSummerSD,
  temperatureFallSD: state.workflow.fields.temperatureFallSD,
  temperatureSpringSD: state.workflow.fields.temperatureSpringSD,
  temperatureWinterSD: state.workflow.fields.temperatureWinterSD,
  relativeHumiditySD: state.workflow.fields.relativeHumiditySD,
  temperatureSummerCV: state.workflow.fields.temperatureSummerCV,
  temperatureFallCV: state.workflow.fields.temperatureFallCV,
  temperatureSpringCV: state.workflow.fields.temperatureSpringCV,
  temperatureWinterCV: state.workflow.fields.temperatureWinterCV,
  relativeHumidityCV: state.workflow.fields.relativeHumidityCV,
  ambientTemperatureInputType: state.workflow.fields.ambientTemperatureInputType,
  ambientTempHistory: state.workflow.fields.ambientTempHistory,
  relativeHumidityHistory: state.workflow.fields.relativeHumidityHistory,
  annualRainfall2: state.workflow.fields.annualRainfall2,
  temperatureAm: state.workflow.fields.temperatureAm,
  temperaturePm: state.workflow.fields.temperaturePm,
  relativeHumidityAm: state.workflow.fields.relativeHumidityAm,
  relativeHumidityPm: state.workflow.fields.relativeHumidityPm,
  temperatureAmSD: state.workflow.fields.temperatureAmSD,
  temperaturePmSD: state.workflow.fields.temperaturePmSD,
  relativeHumidityAmSD: state.workflow.fields.relativeHumidityAmSD,
  relativeHumidityPmSD: state.workflow.fields.relativeHumidityPmSD,
  temperatureAmCV: state.workflow.fields.temperatureAmCV,
  temperaturePmCV: state.workflow.fields.temperaturePmCV,
  relativeHumidityAmCV: state.workflow.fields.relativeHumidityAmCV,
  relativeHumidityPmCV: state.workflow.fields.relativeHumidityPmCV,
  temperatureSummerConfidence: state.workflow.fields.temperatureSummerConfidence,
  temperatureFallConfidence: state.workflow.fields.temperatureFallConfidence,
  temperatureWinterConfidence: state.workflow.fields.temperatureWinterConfidence,
  temperatureSpringConfidence: state.workflow.fields.temperatureSpringConfidence,
  relativeHumidityConfidence: state.workflow.fields.relativeHumidityConfidence,
  temperatureAmConfidence: state.workflow.fields.temperatureAmConfidence,
  temperaturePmConfidence: state.workflow.fields.temperaturePmConfidence,
  relativeHumidityAmConfidence: state.workflow.fields.relativeHumidityAmConfidence,
  relativeHumidityPmConfidence: state.workflow.fields.relativeHumidityPmConfidence,
  condensationParametersVarianceType: state.workflow.fields.condensationParametersVarianceType,
  relativeHumidityInputType: state.workflow.fields.relativeHumidityInputType,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  externalEnvironment1Prob: state.workflow.fields.externalEnvironment1Prob,
  ambientTemperatureInputTypeProb: state.workflow.fields.ambientTemperatureInputTypeProb,
  relativeHumidityInputTypeProb: state.workflow.fields.relativeHumidityInputTypeProb,
  temperatureAmProb: state.workflow.fields.temperatureAmProb,
  temperaturePmProb: state.workflow.fields.temperaturePmProb,
  relativeHumidityAmProb: state.workflow.fields.relativeHumidityAmProb,
  relativeHumidityPmProb: state.workflow.fields.relativeHumidityPmProb,
  annualRainfall2Prob: state.workflow.fields.annualRainfall2Prob,
  windSpeedFraction1Prob: state.workflow.fields.windSpeedFraction1Prob,
  elevationProb: state.workflow.fields.elevationProb,
  internalPhProb: state.workflow.fields.internalPhProb,
  steamVentExposureProb: state.workflow.fields.steamVentExposureProb,
  otherDirectExposureProb: state.workflow.fields.otherDirectExposureProb,
  uvExposureProb: state.workflow.fields.uvExposureProb,
})

export default connect(mapStateToProps)(Step1)
