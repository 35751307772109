/* eslint-disable react/jsx-indent */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import E2GSelectField, { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GTextField from '@e2grnd/ui-core/dist/containers/E2GTextField'
import ProbabilisticInputField from '@e2grnd/ui-core/dist/containers/ProbabilisticInputField/ProbabilisticInputField'
import Submit from '@e2grnd/ui-core/dist/containers/Submit'
import ToggleField from '@e2grnd/ui-core/dist/containers/ToggleField'

function Step2({ problemType, style, runCondensation, expertVaporBarrierDamageRateConfidence, expertVaporBarrierDamageRate, expertMissingVaporBarrierConfidence, includeDamageRateExpertPriors, expertExternalExposureFractionMean, expertExternalExposureFractionConfidence, expertInstantaneousCorrosionRateMean, expertInstantaneousCorrosionRateConfidence, expertEffectiveCorrosionRateMean, expertEffectiveCorrosionRateConfidence, expertDamageRate, expertConfidence, whichExpertModelMethodDR, expertDamageRateSD, weightTheTwoLikelihoodPredVsExpertDamageRate, expertExternalExposureFractionSD, expertInstantaneousCorrosionRateSD, expertEffectiveCorrosionRateSD, confidenceExternalExposureFraction, weightTheTwoLikelihoodPredVsExpertExternalExposureFraction, weightTheTwoLikelihoodPredVsExpertInstantaneousCorrosionRate, weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate, expertWetCorrosionRate, expertWetCorrosionRateConfidence, expertWetCorrosionRateSD, weightTheTwoLikelihoodPredVsExperWetCorrosionRate, probabilisticInputFieldFlag, expertExternalExposureFractionConfidenceProb, expertWetCorrosionRateConfidenceProb, expertEffectiveCorrosionRateConfidenceProb, expertExternalExposureFractionMeanProb, expertWetCorrosionRateProb, expertEffectiveCorrosionRateMeanProb }) {
  return (
    <div className="cf w-100">
      <ToggleField name="includeDamageRateExpertPriors" value={includeDamageRateExpertPriors} label="Include Expert Priors for Damage Rate" />
      {includeDamageRateExpertPriors === true
        ? (
          <div className="fl w-100">
            <div className="fl ph3 w-25-l w-50-m w-100">
              <E2GSelectField name="whichExpertModelMethodDR" style={style} value={whichExpertModelMethodDR} label="Expert Damage Rate Prior Model Option" fullWidth>
                <E2GSelectFieldOption label="Expert Prior Caused by Prediction" value="Expert Prior Caused by Prediction" />
                <E2GSelectFieldOption label="Expert Prior Blended with Prediction" value="Expert Prior Blended with Prediction" />
              </E2GSelectField>
            </div>
          </div>
        )
        : ''}
      {problemType === 'PROB1' && (typeof includeDamageRateExpertPriors !== 'undefined' && includeDamageRateExpertPriors)
        ? (
          <div className="cf w-100">
            {whichExpertModelMethodDR === 'Expert Prior Caused by Prediction'
              ? (
                <div className="cf w-100">
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <div className="cf mv2 w-100">
                      {/* <E2GInfoButton
                        tooltip={<div>
                          <p>Expert mean external damage rate is an average value based on expert knowledge surrounding the component being evaluated.</p>
                        </div>}
                        dialog
                      > */}
                      <E2GTextField name="expertDamageRate" style={style} value={expertDamageRate} label="Expert External Damage Rate Mean" type="number" textWidth="100%" required />
                      {/* </E2GInfoButton> */}
                    </div>
                  </div>
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <E2GSelectField name="expertConfidence" style={style} value={expertConfidence} label="Expert External Damage Rate Confidence" fullWidth>
                      <E2GSelectFieldOption label="Low (SD=5)" value="Low" />
                      <E2GSelectFieldOption label="Moderate (SD=3)" value="Moderate" />
                      <E2GSelectFieldOption label="High (SD=1)" value="High" />
                    </E2GSelectField>
                  </div>
                </div>
              )
              : ''}
            {whichExpertModelMethodDR === 'Expert Prior Blended with Prediction'
              ? (
                <div className="cf w-100">
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <div className="cf mv2 w-100">
                      {/* <E2GInfoButton
                        tooltip={<div>
                          <p>Expert mean external damage rate is an average value based on expert knowledge surrounding the component being evaluated.</p>
                        </div>}
                        dialog
                      > */}
                      <E2GTextField name="expertDamageRate" style={style} value={expertDamageRate} label="Expert External Damage Rate Mean" type="number" textWidth="100%" required />
                      {/* </E2GInfoButton> */}
                    </div>
                  </div>
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <div className="cf mv2 w-100">
                      <E2GTextField name="expertDamageRateSD" style={style} value={expertDamageRateSD} label="Expert External Damage Standard Deviation" type="number" textWidth="100%" required />
                    </div>
                  </div>
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <div className="cf mv2 w-100">
                      <E2GTextField name="weightTheTwoLikelihoodPredVsExpertDamageRate" style={style} value={weightTheTwoLikelihoodPredVsExpertDamageRate} label="External Damage Rate Prediction Weight vs Expert" type="number" textWidth="100%" required />
                    </div>
                  </div>
                </div>
              )
              : ''}
          </div>
        )
        : ''}
      {problemType === 'PROB3' && (typeof includeDamageRateExpertPriors !== 'undefined' && includeDamageRateExpertPriors)
        ? (
          <div className="cf w-100">
            {probabilisticInputFieldFlag === false
              ? (
                <div>
                  {whichExpertModelMethodDR === 'Expert Prior Caused by Prediction'
                    ? (
                      <div className="fl w-100">
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <div className="cf mv2 w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Expert mean external exposure fraction (i.e., the amount of exposure to the environment during the year)  is an average value based on expert knowledge surrounding the component being evaluated.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="expertExternalExposureFractionMean" style={style} value={expertExternalExposureFractionMean} label="Expert External Exposure Fraction Mean" type="number" textWidth="100%" required />
                            {/* </E2GInfoButton> */}
                          </div>
                          <div className="cf mv3 w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Expert mean wet corrosion rate is an average value based on expert knowledge surrounding the component being evaluated. Note that this type of corrosion rate refers to the speed at which corrosion occurs in a metal when it is exposed to a moist or aqueous environment.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="expertWetCorrosionRate" style={style} value={expertWetCorrosionRate} label="Expert Wet Corrosion Rate Mean" type="number" textWidth="100%" required/>
                            {/* </E2GInfoButton> */}
                          </div>
                          <div className="cf mv3 w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Expert mean max effective corrosion rate is an average value based on expert knowledge surrounding the component being evaluated. Note that this corrosion rate refers to the highest rate at which corrosion occurs under specific condition.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="expertEffectiveCorrosionRateMean" style={style} value={expertEffectiveCorrosionRateMean} label="Expert Max Effective Corrosion Rate Mean" type="number" textWidth="100%" required />
                            {/* </E2GInfoButton> */}
                          </div>
                        </div>
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <E2GSelectField name="expertExternalExposureFractionConfidence" style={style} value={expertExternalExposureFractionConfidence} label="Expert External Exposure Fraction Confidence" fullWidth>
                            <E2GSelectFieldOption label="Low (SD=10)" value="Low" />
                            <E2GSelectFieldOption label="Moderate (SD=5)" value="Moderate" />
                            <E2GSelectFieldOption label="High (SD=1)" value="High" />
                          </E2GSelectField>
                          <E2GSelectField name="expertWetCorrosionRateConfidence" style={style} value={expertWetCorrosionRateConfidence} label="Expert Wet Corrosion Rate Confidence" fullWidth >
                            <E2GSelectFieldOption label="Low (SD=5)" value="Low" />
                            <E2GSelectFieldOption label="Moderate (SD=3)" value="Moderate" />
                            <E2GSelectFieldOption label="High (SD=1)" value="High" />
                          </E2GSelectField>
                          <E2GSelectField name="expertEffectiveCorrosionRateConfidence" style={style} value={expertEffectiveCorrosionRateConfidence} label="Expert Max Effective Corrosion Rate Confidence" fullWidth>
                            <E2GSelectFieldOption label="Low (SD=5)" value="Low" />
                            <E2GSelectFieldOption label="Moderate (SD=3)" value="Moderate" />
                            <E2GSelectFieldOption label="High (SD=1)" value="High" />
                          </E2GSelectField>
                        </div>
                      </div>
                    )
                    : ''}
                  {whichExpertModelMethodDR === 'Expert Prior Blended with Prediction'
                    ? (
                      <div className="fl w-100">
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <div className="cf mv2 w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Expert mean external exposure fraction (i.e., the amount of exposure to the environment during the year) is an average value based on expert knowledge surrounding the component being evaluated.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="expertExternalExposureFractionMean" style={style} value={expertExternalExposureFractionMean} label="Expert External Exposure Fraction Mean" type="number" textWidth="100%" required />
                            {/* </E2GInfoButton> */}
                          </div>
                          <div className="cf mv3 w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Expert mean wet corrosion rate is an average value based on expert knowledge surrounding the component being evaluated. Note that this type of corrosion rate refers to the speed at which corrosion occurs in a metal when it is exposed to a moist or aqueous environment.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="expertWetCorrosionRate" style={style} value={expertWetCorrosionRate} label="Expert Wet Corrosion Rate Mean" type="number" textWidth="100%" required/>
                            {/* </E2GInfoButton> */}
                          </div>
                          <div className="cf mv3 w-100">
                            {/* <E2GInfoButton
                              tooltip={<div>
                                <p>Expert mean max effective corrosion rate is an average value based on expert knowledge surrounding the component being evaluated. Note that this corrosion rate refers to the highest rate at which corrosion occurs under specific condition.</p>
                              </div>}
                              dialog
                            > */}
                            <E2GTextField name="expertEffectiveCorrosionRateMean" style={style} value={expertEffectiveCorrosionRateMean} label="Expert Max Effective Corrosion Rate Mean" type="number" textWidth="100%" required />
                            {/* </E2GInfoButton> */}
                          </div>
                        </div>
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <div className="cf mv2 w-100">
                            <E2GTextField name="expertExternalExposureFractionSD" style={style} value={expertExternalExposureFractionSD} label="Expert External Exposure Fraction Standard Deviation" type="number" textWidth="100%" required />
                          </div>
                          <div className="cf mv3 w-100">
                            <E2GTextField name="expertWetCorrosionRateSD" style={style} value={expertWetCorrosionRateSD} label="Expert Wet Corrosion Rate Standard Deviation" type="number" textWidth="100%" required/>
                          </div>
                          <div className="cf mv3 w-100">
                            <E2GTextField name="expertEffectiveCorrosionRateSD" style={style} value={expertEffectiveCorrosionRateSD} label="Expert Max Effective Corrosion Rate Standard Deviation" type="number" textWidth="100%" required />
                          </div>
                        </div>
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <div className="cf mv2 w-100">
                            <E2GTextField name="weightTheTwoLikelihoodPredVsExpertExternalExposureFraction" style={style} value={weightTheTwoLikelihoodPredVsExpertDamageRate} label="External Exposure Fraction Prediction Weight vs Expert" type="number" textWidth="100%" required />
                          </div>
                          <div className="cf mv3 w-100">
                            <E2GTextField name="weightTheTwoLikelihoodPredVsExperWetCorrosionRate" style={style} value={weightTheTwoLikelihoodPredVsExperWetCorrosionRate} label="Wet Corrosion Rate Prediction Weight vs Expert" type="number" textWidth="100%" required />
                          </div>
                          <div className="cf mv3 w-100">
                            <E2GTextField name="weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate" style={style} value={weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate} label="Effective Corrosion Rate Prediction Weight vs Expert" type="number" textWidth="100%" required />
                          </div>
                        </div>
                      </div>
                    )
                    : ''}
                </div>
              )
              : ''}
            {probabilisticInputFieldFlag === true
              ? (
                <div>
                  {whichExpertModelMethodDR === 'Expert Prior Caused by Prediction'
                    ? (
                      <div>
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <E2GTextField name="expertExternalExposureFractionMean" style={style} value={expertExternalExposureFractionMean} label="Expert External Exposure Fraction Mean" type="number" textWidth="100%" required />
                          <E2GTextField name="expertWetCorrosionRate" style={style} value={expertWetCorrosionRate} label="Expert Wet Corrosion Rate Mean" type="number" textWidth="100%" required/>
                          <E2GTextField name="expertEffectiveCorrosionRateMean" style={style} value={expertEffectiveCorrosionRateMean} label="Expert Max Effective Corrosion Rate Mean" type="number" textWidth="100%" required />
                        </div>
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <div className="pt3">
                            <ProbabilisticInputField
                              name="expertExternalExposureFractionConfidenceProb"
                              jsonEditable
                              value={expertExternalExposureFractionConfidenceProb}
                              variableType="discrete"
                              InputProps={{
                                label: 'Expert External Exposure Fraction Confidence',
                                jsonKeyPath: 'median',
                                isNumber: false,
                                required: true,
                                fullWidth: true,
                              }}
                            />
                          </div>
                          <div className="pt3">
                            <ProbabilisticInputField
                              name="expertWetCorrosionRateConfidenceProb"
                              jsonEditable
                              value={expertWetCorrosionRateConfidenceProb}
                              variableType="discrete"
                              InputProps={{
                                label: 'Expert Wet Corrosion Rate Confidence',
                                jsonKeyPath: 'median',
                                isNumber: false,
                                required: true,
                                fullWidth: true,
                              }}
                            />
                          </div>
                          <div className="pt3">
                            <ProbabilisticInputField
                              name="expertEffectiveCorrosionRateConfidenceProb"
                              jsonEditable
                              value={expertEffectiveCorrosionRateConfidenceProb}
                              variableType="discrete"
                              InputProps={{
                                label: 'Expert Max Effective Corrosion Rate Confidence',
                                jsonKeyPath: 'median',
                                isNumber: false,
                                required: true,
                                fullWidth: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                    : ''}
                  {whichExpertModelMethodDR === 'Expert Prior Blended with Prediction'
                    ? (
                      <div>
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <E2GTextField name="weightTheTwoLikelihoodPredVsExpertExternalExposureFraction" style={style} value={weightTheTwoLikelihoodPredVsExpertDamageRate} label="External Exposure Fraction Prediction Weight vs Expert" type="number" textWidth="100%" required />
                          <E2GTextField name="weightTheTwoLikelihoodPredVsExperWetCorrosionRate" style={style} value={weightTheTwoLikelihoodPredVsExperWetCorrosionRate} label="Wet Corrosion Rate Prediction Weight vs Expert" type="number" textWidth="100%" required />
                          <E2GTextField name="weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate" style={style} value={weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate} label="Effective Corrosion Rate Prediction Weight vs Expert" type="number" textWidth="100%" required />
                        </div>
                        <div className="fl ph3 w-25-l w-50-m w-100">
                          <div className="pt3">
                            <ProbabilisticInputField
                              name="expertExternalExposureFractionMeanProb"
                              jsonEditable
                              value={expertExternalExposureFractionMeanProb}
                              variableType="continuous"
                              InputProps={{
                                label: 'Expert External Exposure Fraction (50th Percentile)',
                                jsonKeyPath: 'median',
                                units: {
                                  USCustomary: '%',
                                  Metric: '%',
                                },
                                isNumber: false,
                                required: true,
                                fullWidth: true,
                              }}
                              DialogProps={{
                                inputFields: [{
                                  label: 'Distribution Type',
                                  jsonKeyPath: 'distributionType',
                                  select: true,
                                  options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                }, {
                                  label: 'Variance Type',
                                  jsonKeyPath: 'varianceType',
                                  select: true,
                                  options(parsedJson) {
                                    if(parsedJson.distributionType === 'normal') {
                                      return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                    }
                                    return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                  },
                                }, {
                                  label: 'Standard Deviation',
                                  jsonKeyPath: 'standardDeviation',
                                  isNumber: true,
                                  units: {
                                    USCustomary: '%',
                                    Metric: '%',
                                  },
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'standardDeviation'
                                  },
                                }, {
                                  label: 'Upper Bound',
                                  jsonKeyPath: 'upperBound',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: '%',
                                    Metric: '%',
                                  },
                                }, {
                                  label: 'Upper Bound Probability',
                                  jsonKeyPath: 'upperBoundProbability',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: '%',
                                    Metric: '%',
                                  },
                                }, {
                                  label: 'Scale Parameter',
                                  jsonKeyPath: 'scale',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'scale'
                                  },
                                }, {
                                  label: 'CV (SD/Mean)',
                                  jsonKeyPath: 'cv',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'cv'
                                  },
                                }],
                              }}
                            />
                          </div>
                          <div className="pt3">
                            <ProbabilisticInputField
                              name="expertWetCorrosionRateProb"
                              jsonEditable
                              value={expertWetCorrosionRateProb}
                              variableType="continuous"
                              InputProps={{
                                label: 'Expert Wet Corrosion Rate (50th Percentile)',
                                jsonKeyPath: 'median',
                                units: {
                                  USCustomary: 'mil/yr',
                                  Metric: 'mm/yr',
                                },
                                isNumber: false,
                                required: true,
                                fullWidth: true,
                              }}
                              DialogProps={{
                                inputFields: [{
                                  label: 'Distribution Type',
                                  jsonKeyPath: 'distributionType',
                                  select: true,
                                  options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                }, {
                                  label: 'Variance Type',
                                  jsonKeyPath: 'varianceType',
                                  select: true,
                                  options(parsedJson) {
                                    if(parsedJson.distributionType === 'normal') {
                                      return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                    }
                                    return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                  },
                                }, {
                                  label: 'Standard Deviation',
                                  jsonKeyPath: 'standardDeviation',
                                  isNumber: true,
                                  units: {
                                    USCustomary: 'mil/yr',
                                    Metric: 'mm/yr',
                                  },
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'standardDeviation'
                                  },
                                }, {
                                  label: 'Upper Bound',
                                  jsonKeyPath: 'upperBound',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: 'mil/yr',
                                    Metric: 'mm/yr',
                                  },
                                }, {
                                  label: 'Upper Bound Probability',
                                  jsonKeyPath: 'upperBoundProbability',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: '%',
                                    Metric: '%',
                                  },
                                }, {
                                  label: 'Scale Parameter',
                                  jsonKeyPath: 'scale',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'scale'
                                  },
                                }, {
                                  label: 'CV (SD/Mean)',
                                  jsonKeyPath: 'cv',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'cv'
                                  },
                                }],
                              }}
                            />
                          </div>
                          <div className="pt3">
                            <ProbabilisticInputField
                              name="expertEffectiveCorrosionRateMeanProb"
                              jsonEditable
                              value={expertEffectiveCorrosionRateMeanProb}
                              variableType="continuous"
                              InputProps={{
                                label: 'Expert Max Effective Corrosion Rate (50th Percentile)',
                                jsonKeyPath: 'median',
                                units: {
                                  USCustomary: 'mil/yr',
                                  Metric: 'mm/yr',
                                },
                                isNumber: false,
                                required: true,
                                fullWidth: true,
                              }}
                              DialogProps={{
                                inputFields: [{
                                  label: 'Distribution Type',
                                  jsonKeyPath: 'distributionType',
                                  select: true,
                                  options: [{ value: 'normal', label: 'Normal' }, { value: 'logNormal', label: 'Log Normal' }],
                                }, {
                                  label: 'Variance Type',
                                  jsonKeyPath: 'varianceType',
                                  select: true,
                                  options(parsedJson) {
                                    if(parsedJson.distributionType === 'normal') {
                                      return [{ value: 'standardDeviation', label: 'Standard Deviation' }, { value: 'cv', label: 'CV (SD/Mean)' }, { value: 'upperBound', label: 'Upper Bound' }]
                                    }
                                    return [{ value: 'scale', label: 'Scale Parameter' }, { value: 'upperBound', label: 'Upper Bound' }]
                                  },
                                }, {
                                  label: 'Standard Deviation',
                                  jsonKeyPath: 'standardDeviation',
                                  isNumber: true,
                                  units: {
                                    USCustomary: 'mil/yr',
                                    Metric: 'mm/yr',
                                  },
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'standardDeviation'
                                  },
                                }, {
                                  label: 'Upper Bound',
                                  jsonKeyPath: 'upperBound',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: 'mil/yr',
                                    Metric: 'mm/yr',
                                  },
                                }, {
                                  label: 'Upper Bound Probability',
                                  jsonKeyPath: 'upperBoundProbability',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'upperBound'
                                  },
                                  units: {
                                    USCustomary: '%',
                                    Metric: '%',
                                  },
                                }, {
                                  label: 'Scale Parameter',
                                  jsonKeyPath: 'scale',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'scale'
                                  },
                                }, {
                                  label: 'CV (SD/Mean)',
                                  jsonKeyPath: 'cv',
                                  isNumber: true,
                                  hide(parsedJson) {
                                    return parsedJson.varianceType !== 'cv'
                                  },
                                }],
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                    : ''}
                </div>
              )
              : ''}
          </div>
        )
        : ''}
      {problemType === 'PROB2' && (typeof includeDamageRateExpertPriors !== 'undefined' && includeDamageRateExpertPriors)
        ? (
          <div className="cf w-100">
            {whichExpertModelMethodDR === 'Expert Prior Caused by Prediction'
              ? (
                <div className="fl w-100">
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <div className="cf mv2 w-100">
                      <E2GTextField name="expertExternalExposureFractionMean" style={style} value={expertExternalExposureFractionMean} label="Expert External Exposure Fraction Mean" type="number" textWidth="100%" required />
                    </div>
                    <div className="cf mv3 w-100">
                      <E2GTextField name="expertInstantaneousCorrosionRateMean" style={style} value={expertInstantaneousCorrosionRateMean} label="Expert Instantaneous Corrosion Rate Mean" type="number" textWidth="100%" required/>
                    </div>
                    <div className="cf mv3 w-100">
                      <E2GTextField name="expertEffectiveCorrosionRateMean" style={style} value={expertEffectiveCorrosionRateMean} label="Expert Max Effective Corrosion Rate Mean" type="number" textWidth="100%" required />
                    </div>
                  </div>
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <E2GSelectField name="expertExternalExposureFractionConfidence" style={style} value={expertExternalExposureFractionConfidence} label="Expert External Exposure Fraction Confidence" fullWidth>
                      <E2GSelectFieldOption label="Low (SD=10)" value="Low" />
                      <E2GSelectFieldOption label="Moderate (SD=5)" value="Moderate" />
                      <E2GSelectFieldOption label="High (SD=1)" value="High" />
                    </E2GSelectField>
                    <E2GSelectField name="expertInstantaneousCorrosionRateConfidence" style={style} value={expertInstantaneousCorrosionRateConfidence} label="Expert Instantaneous Corrosion Rate Confidence" fullWidth >
                      <E2GSelectFieldOption label="Low (SD=5)" value="Low" />
                      <E2GSelectFieldOption label="Moderate (SD=3)" value="Moderate" />
                      <E2GSelectFieldOption label="High (SD=1)" value="High" />
                    </E2GSelectField>
                    <E2GSelectField name="expertEffectiveCorrosionRateConfidence" style={style} value={expertEffectiveCorrosionRateConfidence} label="Expert Max Effective Corrosion Rate Confidence" fullWidth>
                      <E2GSelectFieldOption label="Low (SD=5)" value="Low" />
                      <E2GSelectFieldOption label="Moderate (SD=3)" value="Moderate" />
                      <E2GSelectFieldOption label="High (SD=1)" value="High" />
                    </E2GSelectField>
                  </div>
                </div>
              )
              : ''}
            {whichExpertModelMethodDR === 'Expert Prior Blended with Prediction'
              ? (
                <div className="fl w-100">
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <div className="cf mv2 w-100">
                      <E2GTextField name="expertExternalExposureFractionMean" style={style} value={expertExternalExposureFractionMean} label="Expert External Exposure Fraction Mean" type="number" textWidth="100%" required />
                    </div>
                    <div className="cf mv3 w-100">
                      <E2GTextField name="expertInstantaneousCorrosionRateMean" style={style} value={expertInstantaneousCorrosionRateMean} label="Expert Instantaneous Corrosion Rate Mean" type="number" textWidth="100%" required/>
                    </div>
                    <div className="cf mv3 w-100">
                      <E2GTextField name="expertEffectiveCorrosionRateMean" style={style} value={expertEffectiveCorrosionRateMean} label="Expert Max Effective Corrosion Rate Mean" type="number" textWidth="100%" required />
                    </div>
                  </div>
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <div className="cf mv2 w-100">
                      <E2GTextField name="expertExternalExposureFractionSD" style={style} value={expertExternalExposureFractionSD} label="Expert External Exposure Fraction Standard Deviation" type="number" textWidth="100%" required />
                    </div>
                    <div className="cf mv3 w-100">
                      <E2GTextField name="expertInstantaneousCorrosionRateSD" style={style} value={expertInstantaneousCorrosionRateSD} label="Expert Instantaneous Corrosion Rate Standard Deviation" type="number" textWidth="100%" required/>
                    </div>
                    <div className="cf mv3 w-100">
                      <E2GTextField name="expertEffectiveCorrosionRateSD" style={style} value={expertEffectiveCorrosionRateSD} label="Expert Max Effective Corrosion Rate Standard Deviation" type="number" textWidth="100%" required />
                    </div>
                  </div>
                  <div className="fl ph3 w-25-l w-50-m w-100">
                    <div className="cf mv2 w-100">
                      <E2GTextField name="weightTheTwoLikelihoodPredVsExpertExternalExposureFraction" style={style} value={weightTheTwoLikelihoodPredVsExpertDamageRate} label="External Exposure Fraction Prediction Weight vs Expert" type="number" textWidth="100%" required />
                    </div>
                    <div className="cf mv3 w-100">
                      <E2GTextField name="weightTheTwoLikelihoodPredVsExpertInstantaneousCorrosionRate" style={style} value={weightTheTwoLikelihoodPredVsExpertInstantaneousCorrosionRate} label="Instantaneous Corrosion Rate Prediction Weight vs Expert" type="number" textWidth="100%" required />
                    </div>
                    <div className="cf mv3 w-100">
                      <E2GTextField name="weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate" style={style} value={weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate} label="Effective Corrosion Rate Prediction Weight vs Expert" type="number" textWidth="100%" required />
                    </div>
                  </div>
                </div>
              )
              : ''}
          </div>
        )
        : ''}
      <Submit label="Next" />
    </div>
  )
}

Step2.propTypes = {
  problemType: PropTypes.string,
  includeDamageRateExpertPriors: PropTypes.bool,
  expertDamageRate: PropTypes.number,
  expertConfidence: PropTypes.string,
  style: PropTypes.object,
  expertExternalExposureFractionMean: PropTypes.number,
  expertExternalExposureFractionConfidence: PropTypes.string,
  expertInstantaneousCorrosionRateMean: PropTypes.number,
  expertInstantaneousCorrosionRateConfidence: PropTypes.string,
  expertEffectiveCorrosionRateMean: PropTypes.number,
  expertEffectiveCorrosionRateConfidence: PropTypes.string,
  expertVaporBarrierDamageRate: PropTypes.number,
  expertMissingVaporBarrierConfidence: PropTypes.string,
  runCondensation: PropTypes.bool,
  expertVaporBarrierDamageRateConfidence: PropTypes.string,
  whichExpertModelMethodDR: PropTypes.string,
  expertDamageRateSD: PropTypes.number,
  weightTheTwoLikelihoodPredVsExpertDamageRate: PropTypes.number,
  expertExternalExposureFractionSD: PropTypes.number,
  expertInstantaneousCorrosionRateSD: PropTypes.number,
  expertEffectiveCorrosionRateSD: PropTypes.number,
  weightTheTwoLikelihoodPredVsExpertExternalExposureFraction: PropTypes.number,
  weightTheTwoLikelihoodPredVsExpertInstantaneousCorrosionRate: PropTypes.number,
  weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate: PropTypes.number,
  expertWetCorrosionRate: PropTypes.number,
  expertWetCorrosionRateConfidence: PropTypes.string,
  expertWetCorrosionRateSD: PropTypes.number,
  weightTheTwoLikelihoodPredVsExperWetCorrosionRate: PropTypes.number,
  probabilisticInputFieldFlag: PropTypes.bool,
  expertExternalExposureFractionConfidenceProb: PropTypes.string,
  expertWetCorrosionRateConfidenceProb: PropTypes.string,
  expertEffectiveCorrosionRateConfidenceProb: PropTypes.string,
  expertExternalExposureFractionMeanProb: PropTypes.string,
  expertWetCorrosionRateProb: PropTypes.string,
  expertEffectiveCorrosionRateMeanProb: PropTypes.string,
}

const mapStateToProps = state => ({
  problemType: state.workflow.fields.problemType,
  includeDamageRateExpertPriors: state.workflow.fields.includeDamageRateExpertPriors,
  expertDamageRate: state.workflow.fields.expertDamageRate,
  expertConfidence: state.workflow.fields.expertConfidence,
  style: state.ui.style,
  expertExternalExposureFractionMean: state.workflow.fields.expertExternalExposureFractionMean,
  expertExternalExposureFractionConfidence: state.workflow.fields.expertExternalExposureFractionConfidence,
  expertInstantaneousCorrosionRateMean: state.workflow.fields.expertInstantaneousCorrosionRateMean,
  expertInstantaneousCorrosionRateConfidence: state.workflow.fields.expertInstantaneousCorrosionRateConfidence,
  expertEffectiveCorrosionRateMean: state.workflow.fields.expertEffectiveCorrosionRateMean,
  expertEffectiveCorrosionRateConfidence: state.workflow.fields.expertEffectiveCorrosionRateConfidence,
  expertVaporBarrierDamageRate: state.workflow.fields.expertVaporBarrierDamageRate,
  expertMissingVaporBarrierConfidence: state.workflow.fields.expertMissingVaporBarrierConfidence,
  runCondensation: state.workflow.fields.runCondensation,
  expertVaporBarrierDamageRateConfidence: state.workflow.fields.expertVaporBarrierDamageRateConfidence,
  whichExpertModelMethodDR: state.workflow.fields.whichExpertModelMethodDR,
  expertDamageRateSD: state.workflow.fields.expertDamageRateSD,
  weightTheTwoLikelihoodPredVsExpertDamageRate: state.workflow.fields.weightTheTwoLikelihoodPredVsExpertDamageRate,
  expertExternalExposureFractionSD: state.workflow.fields.expertExternalExposureFractionSD,
  expertInstantaneousCorrosionRateSD: state.workflow.fields.expertInstantaneousCorrosionRateSD,
  expertEffectiveCorrosionRateSD: state.workflow.fields.expertEffectiveCorrosionRateSD,
  weightTheTwoLikelihoodPredVsExpertExternalExposureFraction: state.workflow.fields.weightTheTwoLikelihoodPredVsExpertExternalExposureFraction,
  weightTheTwoLikelihoodPredVsExpertInstantaneousCorrosionRate: state.workflow.fields.weightTheTwoLikelihoodPredVsExpertInstantaneousCorrosionRate,
  weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate: state.workflow.fields.weightTheTwoLikelihoodPredVsExpertEffectiveCorrosionRate,
  expertWetCorrosionRate: state.workflow.fields.expertWetCorrosionRate,
  expertWetCorrosionRateConfidence: state.workflow.fields.expertWetCorrosionRateConfidence,
  expertWetCorrosionRateSD: state.workflow.fields.expertWetCorrosionRateSD,
  weightTheTwoLikelihoodPredVsExperWetCorrosionRate: state.workflow.fields.weightTheTwoLikelihoodPredVsExperWetCorrosionRate,
  probabilisticInputFieldFlag: state.workflow.fields.probabilisticInputFieldFlag,
  expertExternalExposureFractionConfidenceProb: state.workflow.fields.expertExternalExposureFractionConfidenceProb,
  expertWetCorrosionRateConfidenceProb: state.workflow.fields.expertWetCorrosionRateConfidenceProb,
  expertEffectiveCorrosionRateConfidenceProb: state.workflow.fields.expertEffectiveCorrosionRateConfidenceProb,
  expertExternalExposureFractionMeanProb: state.workflow.fields.expertExternalExposureFractionMeanProb,
  expertWetCorrosionRateProb: state.workflow.fields.expertWetCorrosionRateProb,
  expertEffectiveCorrosionRateMeanProb: state.workflow.fields.expertEffectiveCorrosionRateMeanProb,
})

export default connect(mapStateToProps)(Step2)
