import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Stack } from '@mui/material'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import BayesViewer from '@e2grnd/ui-core/dist/components/bayes/BayesViewer'
import BeliefTable from '@e2grnd/ui-core/dist/components/bayes/BeliefTable'
import Dialog from '@e2grnd/ui-core/dist/components/ImageDialog'
import Close from '@e2grnd/ui-core/dist/components/ImageDialogClose'
import { E2GSelectFieldOption } from '@e2grnd/ui-core/dist/containers/E2GSelectField'
import E2GSelectFieldIndexer from '@e2grnd/ui-core/dist/containers/E2GSelectFieldIndexer'
import TextArea from '@e2grnd/ui-core/dist/containers/TextArea'

function BayesNetBuilderProb1({ results, decisionsStrategy, problemType }) {
  let bayesNetExposureFraction
  let bayesNetDamageRate
  let bayesNetJacketingFailureTime
  let bayesNetCoatingFailureTime
  let bayesNetComponentFailureTime
  let bayesNetActualThicknessProb01
  let bayesNetActualThicknessProb02
  let bayesNetActualThicknessProb03
  let bayesNetReplaceDecision01
  let bayesNetReplaceDecision02
  let bayesNetReplaceDecision03
  let bayesNetReplaceDecision04
  let bayesNetReplaceDecision05
  let bayesNetNetworkCondensation01
  let bayesNetNetworkCondensation02
  let whichString
  if(problemType === 'PROB1') {
    if(typeof results.poppedJsonOutReplaceDecision !== 'undefined' || typeof results.poppedJsonOutMaintenanceStrat01 !== 'undefined' || typeof results.poppedJsonOutMaintenanceStrat02 !== 'undefined' || typeof results.poppedJsonOutMaintenanceStrat03 !== 'undefined' || typeof results.poppedJsonOutMaintenanceStrat04 !== 'undefined') {
      bayesNetExposureFraction = JSON.parse(results.poppedJsonOutExposureFraction)
      bayesNetDamageRate = JSON.parse(results.poppedJsonOutDamageRate)
      bayesNetJacketingFailureTime = JSON.parse(results.poppedJsonOutJacketingFailureTime)
      bayesNetCoatingFailureTime = JSON.parse(results.poppedJsonOutCoatingFailureTime)
      bayesNetComponentFailureTime = JSON.parse(results.poppedJsonOutComponentFailureTime)
      if(typeof results.poppedJsonOutActualThicknessProb_1 !== 'undefined') {
        bayesNetActualThicknessProb01 = JSON.parse(results.poppedJsonOutActualThicknessProb_1)
      } if(typeof results.poppedJsonOutActualThicknessProb_2 !== 'undefined') {
        bayesNetActualThicknessProb02 = JSON.parse(results.poppedJsonOutActualThicknessProb_2)
      } if(typeof results.poppedJsonOutActualThicknessProb_3 !== 'undefined') {
        bayesNetActualThicknessProb03 = JSON.parse(results.poppedJsonOutActualThicknessProb_3)
      }
      if(typeof results.poppedJsonOutReplaceDecision !== 'undefined') {
        bayesNetReplaceDecision01 = JSON.parse(results.poppedJsonOutReplaceDecision)
        bayesNetReplaceDecision02 = JSON.parse(results.poppedJsonOutMaintenanceStrat01)
        bayesNetReplaceDecision03 = JSON.parse(results.poppedJsonOutMaintenanceStrat02)
        bayesNetReplaceDecision04 = JSON.parse(results.poppedJsonOutMaintenanceStrat03)
        bayesNetReplaceDecision05 = JSON.parse(results.poppedJsonOutMaintenanceStrat04)
      }
      if(results.returnCondensationBool[0] === 1) {
        bayesNetNetworkCondensation01 = JSON.parse(results.poppedJsonOutProblemType1Condensation01)
        bayesNetNetworkCondensation02 = JSON.parse(results.poppedJsonOutProblemType1Condensation02)
      }
      whichString = results.whichNetwork[0]
    } else {
      bayesNetExposureFraction = JSON.parse(results.poppedJsonOutExposureFraction)
      bayesNetDamageRate = JSON.parse(results.poppedJsonOutDamageRate)
      bayesNetJacketingFailureTime = JSON.parse(results.poppedJsonOutJacketingFailureTime)
      bayesNetCoatingFailureTime = JSON.parse(results.poppedJsonOutCoatingFailureTime)
      bayesNetComponentFailureTime = JSON.parse(results.poppedJsonOutComponentFailureTime)
      if(typeof results.poppedJsonOutActualThicknessProb_1 !== 'undefined') {
        bayesNetActualThicknessProb01 = JSON.parse(results.poppedJsonOutActualThicknessProb_1)
      } if(typeof results.poppedJsonOutActualThicknessProb_2 !== 'undefined') {
        bayesNetActualThicknessProb02 = JSON.parse(results.poppedJsonOutActualThicknessProb_2)
      } if(typeof results.poppedJsonOutActualThicknessProb_3 !== 'undefined') {
        bayesNetActualThicknessProb03 = JSON.parse(results.poppedJsonOutActualThicknessProb_3)
      }
      if(results.returnCondensationBool[0] === 1) {
        bayesNetNetworkCondensation01 = JSON.parse(results.poppedJsonOutProblemType1Condensation01)
        bayesNetNetworkCondensation02 = JSON.parse(results.poppedJsonOutProblemType1Condensation02)
      }
      whichString = results.whichNetwork[0]
    }
  }
  let nodeNew
  const [states, setStates] = useState([])
  const [beliefs, setBeliefs] = useState([])
  const [netIndex, setNetIndex] = useState('5')

  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const onNodeClick = (node) => {
    if(node) {
      if(problemType === 'PROB1') {
        if(results.whichNetwork[0] === '1') {
          nodeNew = bayesNetExposureFraction.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '2') {
          nodeNew = bayesNetDamageRate.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '3') {
          nodeNew = bayesNetJacketingFailureTime.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '4') {
          nodeNew = bayesNetCoatingFailureTime.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '5') {
          nodeNew = bayesNetComponentFailureTime.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '6a') {
          nodeNew = bayesNetReplaceDecision01.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '6b') {
          nodeNew = bayesNetReplaceDecision02.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '6c') {
          nodeNew = bayesNetReplaceDecision03.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '6d') {
          nodeNew = bayesNetReplaceDecision04.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '6e') {
          nodeNew = bayesNetReplaceDecision05.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '7') {
          nodeNew = bayesNetActualThicknessProb01.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '8') {
          nodeNew = bayesNetActualThicknessProb02.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '9') {
          nodeNew = bayesNetActualThicknessProb03.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '10') {
          nodeNew = bayesNetNetworkCondensation01.nodes.find((n) => n.name === node)
        } else if(results.whichNetwork[0] === '11') {
          nodeNew = bayesNetNetworkCondensation02.nodes.find((n) => n.name === node)
        }
      }
      if(typeof nodeNew.states !== 'undefined' && typeof nodeNew.belief !== 'undefined') {
        setStates(nodeNew.states.states || [])
        setBeliefs(nodeNew.belief || [])
      } else {
        setStates([])
        setBeliefs([])
      }
    }
  }

  useEffect(() => {
    setStates([])
    setBeliefs([])
  }, [whichString])
  return (
    <div>
      {problemType === 'PROB1'
        ? (
          <div>
            {decisionsStrategy === '1'
              ? (
                <div>
                  {bayesNetActualThicknessProb01 === undefined && bayesNetActualThicknessProb02 === undefined && bayesNetActualThicknessProb03 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="6a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="6b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="6c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="6d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="6e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="10" />
                              <E2GSelectFieldOption label="Condensation 2 - Jacketing Failure Time" value="11" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="6a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="6b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="6c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="6d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="6e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb01 !== undefined && bayesNetActualThicknessProb02 === undefined && bayesNetActualThicknessProb03 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="6a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="6b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="6c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="6d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="6e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="10" />
                              <E2GSelectFieldOption label="Condensation 2 - Jacketing Failure Time" value="11" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="6a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="6b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="6c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="6d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="6e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb01 !== undefined && bayesNetActualThicknessProb02 !== undefined && bayesNetActualThicknessProb03 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 2" value="8" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="6a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="6b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="6c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="6d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="6e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="10" />
                              <E2GSelectFieldOption label="Condensation 2 - Jacketing Failure Time" value="11" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 2" value="8" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="6a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="6b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="6c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="6d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="6e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb01 !== undefined && bayesNetActualThicknessProb02 !== undefined && bayesNetActualThicknessProb03 !== undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 2" value="8" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 3" value="9" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="6a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="6b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="6c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="6d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="6e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="10" />
                              <E2GSelectFieldOption label="Condensation 2 - Jacketing Failure Time" value="11" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 2" value="8" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 3" value="9" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                              <E2GSelectFieldOption label="Decisions Network - Replace Only" value="6a" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat Only" value="6b" />
                              <E2GSelectFieldOption label="Decisions Network - Rejacket Only" value="6c" />
                              <E2GSelectFieldOption label="Decisions Network - Reseal Only" value="6d" />
                              <E2GSelectFieldOption label="Decisions Network - Recoat and Rejacket" value="6e" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                </div>
              )
              : '' }
            {decisionsStrategy === '0'
              ? (
                <div>
                  {bayesNetActualThicknessProb01 === undefined && bayesNetActualThicknessProb02 === undefined && bayesNetActualThicknessProb03 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="10" />
                              <E2GSelectFieldOption label="Condensation 2 - Jacketing Failure Time" value="11" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb01 !== undefined && bayesNetActualThicknessProb02 === undefined && bayesNetActualThicknessProb03 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="10" />
                              <E2GSelectFieldOption label="Condensation 2 - Jacketing Failure Time" value="11" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb01 !== undefined && bayesNetActualThicknessProb02 !== undefined && bayesNetActualThicknessProb03 === undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 2" value="8" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="10" />
                              <E2GSelectFieldOption label="Condensation 2 - Jacketing Failure Time" value="11" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 2" value="8" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }
                  {bayesNetActualThicknessProb01 !== undefined && bayesNetActualThicknessProb02 !== undefined && bayesNetActualThicknessProb03 !== undefined
                    ? (
                      <div>
                        {results.returnCondensationBool[0] === 0
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 2" value="8" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 3" value="9" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                        {results.returnCondensationBool[0] === 1
                          ? (
                            <E2GSelectFieldIndexer name="whichNetwork" value={results.whichNetwork[0]} label="Select Network to View">
                              <E2GSelectFieldOption label="Exposure Fraction" value="1" />
                              <E2GSelectFieldOption label="Damage Rate" value="2" />
                              <E2GSelectFieldOption label="Jacketing Failure Time" value="3" />
                              <E2GSelectFieldOption label="Condensation 1 - Dew Point" value="10" />
                              <E2GSelectFieldOption label="Condensation 2 - Jacketing Failure Time" value="11" />
                              <E2GSelectFieldOption label="Coating Failure Time" value="4" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 1" value="7" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 2" value="8" />
                              <E2GSelectFieldOption label="Actual Thickness Unique Time Inspection 3" value="9" />
                              <E2GSelectFieldOption label="Component Failure Time" value="5" />
                            </E2GSelectFieldIndexer >
                          )
                          : '' }
                      </div>
                    )
                    : '' }

                </div>
              )
              : '' }
            <Stack>
              <div className="cf w-100">
                {results.whichNetwork[0] === '1'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Exposure Fraction
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Exposure Fraction" style={{ height: '50vh' }} value={JSON.stringify(bayesNetExposureFraction, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '2'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Damage Rate
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Damage Rate" style={{ height: '50vh' }} value={JSON.stringify(bayesNetDamageRate, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '3'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Failure Time
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Failure Time" style={{ height: '50vh' }} value={JSON.stringify(bayesNetJacketingFailureTime, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '4'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Coating Failure Time
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Coating Failure Time" style={{ height: '50vh' }} value={JSON.stringify(bayesNetCoatingFailureTime, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '5'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Component Failure Time
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Component Failure Time" style={{ height: '50vh' }} value={JSON.stringify(bayesNetComponentFailureTime, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6a'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Replace Only
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Replace Only" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision01, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6b'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Recoat Only
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Recoat Only" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision02, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6c'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Rejacket Only
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Rejacket Only" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision03, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6d'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Reseal Only
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Reseal Only" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision04, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6e'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Decisions Network - Recoat and Rejacket
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Decisions Network - Recoat and Rejacket" style={{ height: '50vh' }} value={JSON.stringify(bayesNetReplaceDecision05, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '7'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Actual Thickness Unique Time Inspection 1
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Actual Thickness Unique Time Inspection 1" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb01, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '8'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Actual Thickness Unique Time Inspection 2
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Actual Thickness Unique Time Inspection 2" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb02, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '9'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Actual Thickness Unique Time Inspection 3
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Actual Thickness Unique Time Inspection 3" style={{ height: '50vh' }} value={JSON.stringify(bayesNetActualThicknessProb03, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '10'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Dew Point
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Dew Point" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation01, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '11'
                  ? (
                    <div className="fl w-100">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={toggleOpen}
                      >
                    Bayes Net Output File for Jacketing Condensation Failure Time
                      </Button>
                      <Dialog
                        title={<div><span className="fr"><Close className="dim" onClick={toggleOpen} /></span></div>}
                        modal={false}
                        open={open}
                        fullWidth
                      >
                        <TextArea label="Bayes Net Output File for Jacketing Condensation Failure Time" style={{ height: '50vh' }} value={JSON.stringify(bayesNetNetworkCondensation02, null, 2)} disabled/>
                      </Dialog>
                    </div>
                  )
                  : ''}
                <div className="pa5">
                  <p>First, click GENERATE LAYOUT to view the network. Then click any nature node (green) to view the corrosponding belief table. Note, there are no belief tables for decision (blue) and utility (orange) nodes.</p>
                </div>
                {results.whichNetwork[0] === '1'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetExposureFraction} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '2'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetDamageRate} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '3'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetJacketingFailureTime} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '4'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetCoatingFailureTime} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '5'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetComponentFailureTime} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6a'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision01} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6b'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision02} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6c'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision03} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6d'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision04} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '6e'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetReplaceDecision05} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '7'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb01} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '8'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb02} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '9'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetActualThicknessProb03} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '10'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation01} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
                {results.whichNetwork[0] === '11'
                  ? (
                    <div>
                      <div className="fl w-third-l w-50-m w-100">
                        <BeliefTable states={states} beliefs={beliefs} />
                      </div>
                      <div className="fl w-two-thirds-l w-50-m w-100">
                        <Box height={500}> <BayesViewer bayesNet={bayesNetNetworkCondensation02} onNodeClick={onNodeClick} /> </Box>
                      </div>
                    </div>
                  )
                  : ''}
              </div>
            </Stack>
          </div>
        )
        : ''}
    </div>
  )
}

BayesNetBuilderProb1.propTypes = {
  results: PropTypes.object,
  decisionsStrategy: PropTypes.string,
  problemType: PropTypes.string,
}

const mapStateToProps = (state) => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  decisionsStrategy: state.workflow.fields.decisionsStrategy,
  problemType: state.workflow.fields.problemType,
})

export default connect(mapStateToProps, null)(BayesNetBuilderProb1)
